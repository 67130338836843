import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

import {StepperService} from '../../services/stepper.service';
import {MobileService} from '../../services/mobile.service';
import {ProfileService} from '../../services/profile.service';
import {ConfirmationComponent} from '../modals/confirmation/confirmation.component';

import {PROFILE_TABS} from '../../utils/enum';
import {UserEntity} from '@models/user/user.entity';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-trainer-profile',
  templateUrl: './trainer-profile.component.html',
  styleUrls: ['./trainer-profile.component.scss'],
})
export class TrainerProfileComponent implements OnInit, OnDestroy {
  public availableTabs: PROFILE_TABS[];
  public availableTabsTrainer: PROFILE_TABS[] = [
    PROFILE_TABS.PERSONAL,
    PROFILE_TABS.LOCATION,
    PROFILE_TABS.EDUCATION,
    // PROFILE_TABS.EXPERTISE,
    PROFILE_TABS.PAYMENT,
    PROFILE_TABS.PRICING,
  ];
  public availableTabsPartner: PROFILE_TABS[] = [
    PROFILE_TABS.PERSONAL,
    PROFILE_TABS.LOCATION,
    PROFILE_TABS.EDUCATION,
    PROFILE_TABS.PAYMENT,
  ];
  public currentTab: PROFILE_TABS;
  public showHint = false;
  public trainer: ProfileEntity;
  public user: UserEntity;
  public canEditProfile: boolean;
  public editingDisabled: boolean;
  public isStepperMobileOpened: boolean;

  private subs: Subscription;
  private destroy$ = new Subject<void>();

  get isDesktop() {
    return this.mobileService.isDesktop;
  }

  get isTrainer() {
    return this.profileService.isProfile('TRAINER_TYPE');
  }

  get canSendForApproval() {
    return this.stepperService.canSendForApprovalValue;
  }

  constructor(
    public profileService: ProfileService,
    public stepperService: StepperService,
    public mobileService: MobileService,
    private translate: TranslateService,
    public dialog: MatDialog,
  ) {
    this.canEditProfile = true;
    this.editingDisabled = false;
    this.availableTabs = this.isTrainer ? this.availableTabsTrainer : this.availableTabsPartner;
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.profileService.user
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((user) => {
        this.user = user;
      });

    this.profileService.trainer
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((trainer) => {
        this.trainer = trainer;
        if (trainer.under_consideration) {
          this.showHint = true;
        }
      });

    this.subs.add(
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      }),
    );
    this.subs.add(
      this.profileService.formInvalidStatusChangeEvent.subscribe(event => {
        this.editingDisabled = event;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();

    this.profileService.changesValid = false;
    this.profileService.changes = false;
  }

  /**
   * Sends trainer profile for approval
   * @param type  true to send for approval. false - to cancel request
   */
  sendForApproval(type: boolean) {
    if (this.canSendForApproval || !type) {
      this.profileService.sendForApproval(type).subscribe(response => {
        if (this.trainer.under_consideration) {
          this.showHint = true;
        }
      });
    }
  }

  toggleHint(): void {
    this.showHint = !this.showHint;
  }

  editProfile() {
    this.profileService.editProfile(false);
  }

  saveProfile() {
    this.profileService.editProfile(true);
    this.profileService.saveProfile();
  }

  cancelEditProfile() {
    this.openConfirmDialog();
  }

  changeTab(tab: PROFILE_TABS) {
    this.currentTab = tab;
  }

  openConfirmDialog() {
    const dialog = this.dialog.open(ConfirmationComponent, {
      data: {
        actionTxt: this.translate.instant('profile.confirmation.button2'),
        bodyTxt: this.translate.instant('profile.confirmation.message'),
        headerTxt: this.translate.instant('profile.confirmation.header2'),
      },
    });

    dialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirm: boolean) => {
        if(confirm) {
          this.confirmCancelEditProfile();
        }
      });
  }

  confirmCancelEditProfile(): void {
    this.profileService.editProfile(true);
    this.profileService.cancelEditProfile();
    this.dialog.closeAll();
  }

  toggleStepperMobile() {
    this.isStepperMobileOpened = !this.isStepperMobileOpened;
  }
}
