import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

import {DataService} from '../../services/data.service';
import {ProfileService} from '../../services/profile.service';
import {PROFILE_TYPES} from '../../utils/enum';
import {fadeInOut} from '../../modules/material/material-animations/fade-in-out';
import {dropDown} from '../../modules/material/material-animations/drop-down';
import {MobileService} from '../../services/mobile.service';
import {CalendarService} from '../../services/calendar.service';
import {LanguageModalComponent} from '../mobile/language-modal/language-modal.component';
import {ScrollLockService} from '../../services/scroll-lock.service';
import {UserEntity} from '@models/user/user.entity';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInOut, dropDown],
})
export class HeaderComponent implements OnInit {
  user: UserEntity;
  profile: ProfileEntity;
  menuOpened: boolean;
  showSettings: boolean;
  listOfProfiles: any;
  actions: any[];

  @ViewChild('profileAvatar', { static: false }) avatar: any;

  get isMobile() {
    return this.mobileService.isMobile;
  }

  constructor(
    public profileService: ProfileService,
    public translate: TranslateService,
    public data: DataService,
    public mobileService: MobileService,
    public calendarService: CalendarService,
    private dialog: MatDialog,
    protected scrollLockService: ScrollLockService,
  ) {
    this.listOfProfiles = {};
  }

  ngOnInit() {
    this.menuOpened = false;
    this.profileService.user.subscribe(user => {
      this.user = user;

      if (user && user.profiles) {
        user.profiles.forEach(profile => {
          this.listOfProfiles[profile.type] = true;
        });
      }
    });
    this.profileService.trainer.subscribe(trainer => {
      this.profile = trainer;
    });
  }


  /**
   * Method to change application language.
   * @param language e.g. 'en', 'nb-nn'
   */
  changeLang(language: string) {
    this.data.setLanguage(language);
    location.reload();
  }

  hasProfile(type: string): boolean {
    return this.listOfProfiles[PROFILE_TYPES[type]];
  }

  /**
   * Mobile only
   * Opens language modal
   */
  openLanguageModal(): void {
    this.dialog.open(LanguageModalComponent);
  }
}
