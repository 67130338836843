import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CertificatesService} from '../../../services/certificates.service';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-education-certificate-modal',
  templateUrl: './education-certificate-modal.component.html',
  styleUrls: ['./education-certificate-modal.component.scss'],
})
export class EducationCertificateModalComponent implements OnInit, AfterViewInit {
  public isPdf = false;
  @ViewChild('canvasContainer', { static: true }) canvasContainer;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private certificatesService: CertificatesService,
  ) {
  }

  ngOnInit() {
    this.data.image = this.data.form.get('file_link').value;
    this.isPdf = this.certificatesService.isPdf(this.data);
  }

  ngAfterViewInit(): void {
    this.certificatesService.renderMedia(this.data, this.canvasContainer.nativeElement);
  }

  changeImage() {
    this.data.fileInput.click();
  }

}
