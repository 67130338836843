import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '@services/data.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ImageInputComponent,
    multi: true,
  }],
})
export class ImageInputComponent implements OnInit, OnDestroy {
  @ViewChild('input', {static: false}) input: ElementRef;

  @Input() label: string;
  @Input() maxImageSize = 10000000;
  @Input() maxImageSizeText = '10MB';
  @Input() formControl: UntypedFormControl;
  @Input() fileFormat = 'image/jpeg, image/png';
  @Input() preloader = false;
  @Input() readonly;
  @Input() emptyStateText: string;
  @Input()
  set openDialog(open: boolean) {
    if (this.input) {
      this.input.nativeElement.click();
    }

  }

  @Output()
  imageUploaded = new EventEmitter();
  control: UntypedFormControl;

  imageBase64: string;

  private destroy$ = new Subject<void>();

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
  ) { }

  get imageUrl() {
    const url = this.imageBase64;
    return url ? `url(${url})` : '';
  }

  ngOnInit() {
    this.formInit();
    this.listenControl();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(): void {
  }

  registerOnChange(): void {
  }

  registerOnTouched(): void {
  }

  formInit() {
    if (this.formControl) {
      this.control = this.formControl;

      if (this.control.value && typeof this.control.value !== 'string') {
        this.getBase64(this.control.value);
      } else {
        this.setBase64Image(this.control.value);
      }
    }
  }

  /**
   * Upload image
   */
  imageUpload(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      if (event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/png') {
        this.dataService.showNotification(this.translate.instant('common.wrongImageFormat'), null, 2000, 'error');
        this.input.nativeElement.value = null;
        return;
      }

      if (event.target.files[0].size >= this.maxImageSize) {
        this.dataService.showNotification(this.translate.instant('common.imgTooLarge', { maxSize: this.maxImageSizeText }), null, 2000, 'error');
        this.input.nativeElement.value = null;
        return;
      }

      reader.onload = (readerEvent: any) => {
        this.setBase64Image(readerEvent.target.result);
        this.control.patchValue(event.target.files[0]);

        this.imageUploaded.emit();
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  changeImage(input) {
    if (this.readonly) {
      return;
    }

    this.control.setValue(null);
    input.click();
  }

  setBase64Image(base64) {
    if (base64) {
      this.imageBase64 = base64;
    }
  }

  getBase64(file): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setBase64Image(reader.result);
    };
  }

  private listenControl(): void {
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (!value) {
          this.input.nativeElement.type = '';
          this.input.nativeElement.type = 'file';
          this.imageBase64 = null;
          return;
        }

        if (!(value instanceof File)) {
          this.setBase64Image(value);
        }
      });

  }
}
