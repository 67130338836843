import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {MobileGuard} from '@services/mobile.service';
import {AuthGuard} from './guards/auth.guard';

import {LoginComponent} from '@modules/auth/login/login.component';
import {RequestsComponent} from '@components/requests/requests.component';
import {CalendarComponent} from '@components/calendar/calendar.component';
import {HistoryComponent} from '@components/history/history.component';
import {PlansComponent} from '@components/plans/plans.component';
import {PaymentComponent} from '@components/payment/payment.component';
import {TieUpStripeComponent} from '@components/tie-up-stripe/tie-up-stripe.component';
import {BillingHistoryComponent} from '@components/billing-history/billing-history.component';
import {TrainerProfileComponent} from '@components/trainer-profile/trainer-profile.component';
import {StripeGuideComponent} from '@components/stripe-guide/stripe-guide.component';
import {AnonGuard} from './guards/anon.guard';
import {CompanySignInComponent} from '@modules/auth/sign-in/company-sign-in.component';
import {SignUpComponent} from '@modules/auth/sign-up/sign-up.component';
import {RestorePasswordComponent} from '@modules/auth/restore-password/restore-password.component';
import {ChangePasswordComponent} from '@modules/auth/change-password/change-password.component';
import {CompanyGuard} from './guards/company.guard';
import {TermAndPolicyComponent} from '@modules/shared-pages/term-and-policy/term-and-policy.component';
import { AGREEMENT_PAGE_TYPE } from '@util/enum';
import { environment } from '../environments/environment';
import { ProfileComponent } from '@modules/company/profile/profile.component';
import { CompanyEnabledGuard } from './guards/company-enabled.guard';
import { PaiScoreComponent } from '@modules/company/pai-score/pai-score.component';
import { EventsAndGroupsComponent } from '@modules/company/events-and-groups/events-and-groups.component';
import { CompanyWithLicenceGuard } from './guards/company-with-licence.guard';
import { EmployeesComponent } from '@modules/company/employees/employees.component';

function loginComponentFactory() {
  if (environment.IS_COMPANY_DASHBOARD) {
    return CompanySignInComponent;
  }

  return LoginComponent;
}

function profileComponentFactory() {
  if (environment.IS_COMPANY_DASHBOARD) {
    return ProfileComponent;
  }

  return TrainerProfileComponent;
}

function profileGuardsFactory() {
  if (environment.IS_COMPANY_DASHBOARD) {
    return [AuthGuard, CompanyGuard];
  }

  return [AuthGuard];
}

function getDefaultRedirect() {
  if (environment.IS_COMPANY_DASHBOARD) {
    return 'profile'
  }

  return 'utv';
}

const userRoutes: Routes = [
  {
    path: 'requests',
    component: RequestsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'plans',
    component: PlansComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'pricing',
  //   component: PricingComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tie_up_stripe',
    pathMatch: 'prefix',
    component: TieUpStripeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'billing-history',
    component: BillingHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'stripe-guide',
    component: StripeGuideComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'utv',
    loadChildren: () => import('./modules/utv/utv.module').then(m => m.UtvModule),
    canActivate: [AuthGuard],
  },
];

const companyRoutes: Routes = [
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [AnonGuard],
  },
  {
    path: 'employees',
    component: EmployeesComponent,
    canActivate: [AuthGuard, CompanyGuard, CompanyEnabledGuard],
  },
  {
    path: 'pai-score',
    component: PaiScoreComponent,
    canActivate: [AuthGuard, CompanyGuard, CompanyEnabledGuard],

  },
  {
    path: 'events-and-groups',
    component: EventsAndGroupsComponent,
    canActivate: [AuthGuard, CompanyGuard, CompanyWithLicenceGuard],
  },
  {
    path: 'chat',
    loadChildren: () => import('./modules/chats/chats.module').then(m => m.ChatsModule),
    canActivate: [AuthGuard, CompanyGuard],
  },
];

const commonRoutes: Routes = [
  {
    path: '',
    redirectTo: getDefaultRedirect(),
    pathMatch: 'full',
  },
  {
    path: 'restore-pass',
    component: RestorePasswordComponent,
    canActivate: [AnonGuard],
  },
  {
    path: 'forgot-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'login',
    component: loginComponentFactory(),
    canActivate: [AnonGuard],
  },
  {
    path: 'profile',
    component: profileComponentFactory(),
    canActivate: profileGuardsFactory(),
  },
  {
    path: AGREEMENT_PAGE_TYPE.PRIVATE_POLICY,
    component: TermAndPolicyComponent,
    canActivate: [],
    data: {type: AGREEMENT_PAGE_TYPE.PRIVATE_POLICY},
  },
  {
    path: AGREEMENT_PAGE_TYPE.COMPANY_AGREEMENT,
    component: TermAndPolicyComponent,
    canActivate: [],
    data: {type: AGREEMENT_PAGE_TYPE.COMPANY_AGREEMENT},
  },
  {
    path: '**',
    redirectTo: getDefaultRedirect(),
  },
];

@NgModule({
  imports: [RouterModule.forRoot([...userRoutes, ...companyRoutes, ...commonRoutes], { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
