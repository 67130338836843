import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, filter, finalize, map, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

import {ProfileService} from '../../../../services/profile.service';
import {Certificate} from '../../../../models/certificate';
import {SystemCertificatesService} from '../../../../services/system-certificates.service';
import {SystemCertificate} from '../../../../models/system-certificate';
import {CertificatesService} from '../../../../services/certificates.service';
import {ErrorService} from '../../../../services/error.service';
import {ERROR_CODES} from '../../../../utils/enum';
import {ClientMessageService} from '../../../../services/client-message.service';
import {EducationCertificateModalComponent} from '../../education-certificate-modal/education-certificate-modal.component';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-system-certificates-list',
  templateUrl: './system-certificates-list.component.html',
  styleUrls: ['./system-certificates-list.component.scss'],
})
export class SystemCertificatesListComponent implements OnInit, OnDestroy {
  public MAX_CERTIFICATES_NUMBER: number = 10;

  trainer: ProfileEntity;
  cetrificates: Certificate[] = [];
  systemCertificates: SystemCertificate[] = [];
  certificatesFormArray: UntypedFormArray;
  formName = 'certificates';

  public canEditProfile: boolean;
  private destroy$ = new Subject<void>();
  private subs: Subscription;

  constructor(
    private profileService: ProfileService,
    private systemCertificatesService: SystemCertificatesService,
    private certificateService: CertificatesService,
    private errorService: ErrorService,
    private clientMessageService: ClientMessageService,
  ) {
    this.canEditProfile = true;
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.subs.add(
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      }),
    );

    this.getCertificates();
    this.getSystemCertificates();
    this.formInit(this.cetrificates);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Get all sertificates
   * Filtered them by system-setrificate-id
   */
  getCertificates() {
    this.profileService.trainer
      .pipe(
        tap((trainer: ProfileEntity) => {
          this.trainer = trainer;
        }),
        map((trainer: ProfileEntity) => {
          return trainer.certificates;
        }),
        map((certificates: Certificate[]) => {
          return certificates.filter((certificate: Certificate) => {
            return Number.isInteger(certificate.system_certificate_id);
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result: Certificate[]) => {
        this.cetrificates = result.reverse();
      });
  }

  /**
   * Get system certificates
   */
  getSystemCertificates() {
    this.systemCertificatesService.systemSertificates
      .pipe(
        filter((certificates: SystemCertificate[]) => {
          return certificates.length > 0;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result: SystemCertificate[]) => {
        this.systemCertificates = result;
      });
  }

  /**
   * Generate form from certificates list
   */
  formInit(cetrificates: Certificate[]) {
    const cetrificatesControls = cetrificates.map((certificate: Certificate) => {
      return new UntypedFormGroup({
        id: new UntypedFormControl(certificate.id, [Validators.required]),
        system_certificate_id: new UntypedFormControl(certificate.system_certificate_id, [Validators.required]),
        is_featured: new UntypedFormControl(certificate.is_featured),
        file_link: new UntypedFormControl(certificate.file_link),
        file: new UntypedFormControl(''),
      });
    });

    this.certificatesFormArray = new UntypedFormArray(cetrificatesControls);

    this.certificatesFormArray.controls.forEach((control: UntypedFormGroup) => {
      this.listenCertificateChanges(control);
    });

    // if there are no any certificates we need add one
    if (this.certificatesFormArray.length < 1) {
      this.addCertificate();
    }
  }

  /**
   * Get certificate ID value
   */
  getCertificateIdValue(certificate: UntypedFormGroup) {
    return certificate.get('id').value;
  }

  /**
   * Get system certificate ID
   */
  getSystemCertificateIdControl(certificate: UntypedFormGroup) {
    return certificate.get('system_certificate_id');
  }

  /**
   * Get system certificate feature
   */
  getCertificateFeatureControl(certificate: UntypedFormGroup) {
    return certificate.get('is_featured');
  }

  /**
   * Get system certificate image value
   */
  getCertificateImageControlValue(certificate: UntypedFormGroup) {
    return certificate.get('file_link').value;
  }

  /**
   * Get system certificate image url
   */
  getCertificateImageUrl(certificate: UntypedFormGroup) {
    const url = certificate.get('file_link').value;
    return url ? `url(${url})` : '';
  }

  /**
   * Get system certificate image control
   */
  getCertificateImageControl(certificate: UntypedFormGroup) {
    return certificate.get('file_link');
  }

  /**
   * Add certificate
   */
  addCertificate() {
    const newCertificateGroup = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      system_certificate_id: new UntypedFormControl(null, [Validators.required]),
      is_featured: new UntypedFormControl(false),
      file_link: new UntypedFormControl('', [Validators.required]),
      file: new UntypedFormControl(''),
    });

    this.certificatesFormArray.push(newCertificateGroup);

    // add listener
    this.listenCertificateChanges(newCertificateGroup);
  }

  /**
   * Add listener to certificate
   */
  listenCertificateChanges(certificateGroup: UntypedFormGroup) {
    certificateGroup.valueChanges
      .pipe(
        tap(() => {
          this.certificateService.markFormGroupTouched(certificateGroup);
        }),
        takeWhile(() => {
          // trick for unsubscribe from this FormGroup by takeWhile
          return !certificateGroup.contains('remove');
        }),
        filter(() => {
          return certificateGroup.valid;
        }),
        debounceTime(300),
        takeUntil(this.destroy$),
      )
      .subscribe((result: any) => {
        if (Number.isInteger(result.id)) {
          this.updateCertificate(result, certificateGroup);
        } else {
          this.createCertificate(result, certificateGroup);
        }
      });
  }

  /**
   * Update certificate data
   */
  updateCertificate(certificateData, certificateGroup: UntypedFormGroup) {
    const formData = this.getFormData(certificateData);

    this.certificateService.update(this.formName, formData, certificateData.id)
      .pipe(
        finalize(() => {
          certificateGroup.enable({
            emitEvent: false,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        // save new certificates list
        const extractedData = this.extractData(this.certificatesFormArray);
        this.profileService.setCertificates(extractedData, 'certificate');
      }, (error) => {
        this.handleErrorResponse(error, certificateGroup);
      });
  }

  /**
   * Update certificate data
   */
  createCertificate(certificateData, certificateGroup: UntypedFormGroup) {
    const formData = this.getFormData(certificateData);

    this.certificateService.create(this.formName, formData)
      .pipe(
        finalize(() => {
          certificateGroup.enable({
            emitEvent: false,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result: Certificate) => {
        // set id for certificateGroup
        certificateGroup.patchValue({
          id: result.id,
        }, {
          emitEvent: false,
        });
        // save new certificates list
        const extractedData = this.extractData(this.certificatesFormArray);
        this.profileService.setCertificates(extractedData, 'certificate');
      }, (error) => {
        this.handleErrorResponse(error, certificateGroup);
      });
  }

  /**
   * Remove sertificate
   */
  removeCertificate(index: number, certificateGroup: UntypedFormGroup) {
    const id = this.getCertificateIdValue(certificateGroup);

    // trick for unsubscribe from this FormGroup by takeWhile
    certificateGroup.setControl('remove', new UntypedFormControl());

    if (id === null) {
      this.removeCertificateByIndex(index);
    } else {
      this.removeCertificateById(certificateGroup, id, index);
    }
  }

  /**
   * Remove sertificate by index from client
   */
  removeCertificateByIndex(index) {
    this.certificatesFormArray.removeAt(index);

    // if there are no any certificates we need add one
    if (this.certificatesFormArray.length < 1) {
      this.addCertificate();
    }
  }

  /**
   * Remove sertificate by ID from server
   * if successfull then remove on client
   */
  removeCertificateById(certificateGroup: UntypedFormGroup, id, index) {
    certificateGroup.disable({
      emitEvent: false,
    });

    this.certificateService.remove(this.formName, id)
      .pipe(
        finalize(() => {
          certificateGroup.enable({
            emitEvent: false,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.certificatesFormArray.removeAt(index);
        // save new certificates list
        const extractedData = this.extractData(this.certificatesFormArray);
        this.profileService.setCertificates(extractedData, 'certificate');

        // if there are no any certificates we need add one
        if (this.certificatesFormArray.length < 1) {
          this.addCertificate();
        }
      }, (error) => {
        this.handleErrorResponse(error);
      });
  }

  /**
   * Get FormData for certificate
   */
  getFormData(certificate): FormData {
    const { id, file, system_certificate_id, is_featured } = certificate;
    const formData = new FormData();

    if (!Number.isInteger(id)) {
      formData.append('profile_id', this.trainer.id.toString());
    }

    if (file) {
      formData.append('file_link', file);
    }

    if (is_featured !== undefined) {
      formData.append('is_featured', is_featured ? '1' : '0');
    }

    // system_certificate_id can be integer or object with 'id'
    // this is requaired field
    const systemCertificateId = Number.isInteger(system_certificate_id) ? system_certificate_id : system_certificate_id.id;
    formData.append('system_certificate_id', systemCertificateId);

    return formData;
  }

  /**
   * Open modal for view/change certificate
   */
  openCertificateModal(imageInput, certificateGroup: UntypedFormGroup): void {
    if (!this.canEditProfile) {
      this.certificateService.openCertificateModal(imageInput, certificateGroup, EducationCertificateModalComponent);
    }
  }

  /**
   * Emit when image field was changed
   */
  imageUpload(event, certificate: UntypedFormGroup): void {
    this.certificateService.imageUpload(event, certificate);
  }

  /**
   * Handle http errors
   */
  handleErrorResponse(errorResponse: HttpErrorResponse, certificateGroup?: UntypedFormGroup) {
    const { data, message, code } = errorResponse.error;

    if (data.errorCode === ERROR_CODES.INVALID_CERTIFICATE_FEATURED) {
      this.errorService.catchHttpError(errorResponse, {
        message,
      });

      if (certificateGroup) {
        certificateGroup.get('is_featured').setValue(false, {
          emitEvent: false,
        });
      }
    } else {
      console.error(errorResponse.error);
    }
  }

  /**
   * Extract data for save it to global user
   */
  extractData(formArray: UntypedFormArray): Certificate[] {
    const certificates: Certificate[] = formArray.controls
      .map((formGroup: UntypedFormGroup): Certificate => {
        return {
          id: formGroup.get('id').value,
          file_link: formGroup.get('file_link').value || '',
          is_featured: formGroup.get('is_featured').value || false,
          system_certificate_id: formGroup.get('system_certificate_id').value || null,
        };
      });

    return certificates;
  }

}
