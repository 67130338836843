import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pai-score-header',
  templateUrl: './pai-score-header.component.html',
  styleUrls: ['./pai-score-header.component.scss'],
})
export class PaiScoreHeaderComponent {

  @Input()
  public fromDate: Date = this.getMonthAgo();
  @Input()
  public toDate: Date = new Date();

  @Output()
  fromDateChange: EventEmitter<Date> = new EventEmitter();
  @Output()
  toDateChange: EventEmitter<Date> = new EventEmitter();
  @Output()
  resetDate: EventEmitter<void> = new EventEmitter();
  isDateChanged: boolean = false;

  public minFromDate: Date = this.getYearAgo();
  public get maxFromDate(): Date {
    return this.getDayAgo(this.toDate);
  }
  public get minToDate(): Date {
    return this.getDayLater(this.fromDate);
  }
  public maxToDate: Date = new Date();

  changeFromDate(fromDate: string): void {
    this.fromDate = new Date(fromDate);
    this.fromDateChange.emit(this.fromDate);
    this.isDateChanged = true;
  }

  changeToDate(toDate: string): void {
    this.toDate = new Date(toDate);
    this.toDateChange.emit(this.toDate);
    this.isDateChanged = true;
  }

  emitResetDate(): void {
    this.resetDate.emit();
    this.isDateChanged = false;
  }

  private getYearAgo(): Date {
    const today = this.getToday();

    return new Date(today.setMonth(today.getMonth() - 12));
  }

  private getMonthAgo(): Date {
    const today = this.getToday();

    return new Date(today.setMonth(today.getMonth() - 1));
  }

  private getDayAgo(date: Date = new Date()): Date {
    date = new Date(date);
    return new Date(date.setDate(date.getDate() - 1));
  }

  private getDayLater(date: Date = new Date()): Date {
    date = new Date(date);
    return new Date(date.setDate(date.getDate() + 1));
  }

  private getToday(): Date {
    const today = new Date();

    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    return today;
  }
}
