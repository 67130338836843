<div class="login">
  <div class="login-wrapper">

    <div class="login-wrapper-header">
      <div class="logo">
        <img src="/assets/logo.svg" alt="MIA logo" class="login-wrapper-logo">
      </div>
    </div>

    <div class="login-wrapper-form"
          [formGroup]="loginForm"
          *ngIf="loginForm">
      <app-material-input formControlName="email"
                          type="email"
                          autofocus
                          [label]="'profile.personal.email' | translate"
                          [errors]="{
                            required: 'common.errors.required' | translate,
                            pattern: 'common.errors.emailInvalid' | translate
                          }">

      </app-material-input>

      <app-material-input
        formControlName="password"
        type="password"
        [label]="'common.password' | translate"
        [errors]="{
          required: 'common.errors.required' | translate,
          pattern: 'common.errors.passwordPattern' | translate
        }"
      >
      </app-material-input>

      <a (click)="navigateToRestorePassword()" class="login-wrapper-restore">
        <span>{{'login.forgot' | translate}} {{'common.password' | translate}}</span>
      </a>
      <div class="login-wrapper-buttons">
        <app-button
          color="accent"
          class="login-wrapper-submit login-wrapper-submit--no-icon"
          (click)="login()"
          [disabled]="loginForm.invalid"
        >
          {{'common.signIn' | translate}}
        </app-button>
      </div>

      <div class="login-wrapper-social">
        <p>
          {{'login.signInSocial' | translate}}
        </p>
        <div class="login-wrapper-social__buttons">
          <asl-google-signin-button type='standard' size='large'></asl-google-signin-button>
        </div>
      </div>
    </div>

    <span *ngFor="let message of messages">{{message}} <br></span>

    <div class="splash"></div>
  </div>
</div>
