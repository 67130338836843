import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

import {DataService} from '../../services/data.service';
import {HttpService} from '../../services/http.service';
import {ProfileService} from '../../services/profile.service';

import {generateRandomString} from '../../utils/random-string';

import {ConfirmationComponent} from '../modals/confirmation/confirmation.component';
import {environment} from '../../../environments/environment';
import {UserEntity} from '@models/user/user.entity';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  user: UserEntity;
  trainerProfile: any;
  trainerStripeAccountId: number;
  appLanguage: string;
  subs: Subscription[];

  translationsContainer: any;

  private destroy$ = new Subject<void>();

  constructor(
    private data: DataService,
    private http: HttpService,
    private profile: ProfileService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.appLanguage = this.data.getCurrentLanguage();
    this.subs = [
      this.data.language.subscribe(
        (res: any) => {
          if (this.appLanguage === res) {
            return;
          }
          this.appLanguage = res;
        }, (error) => {
          throw error;
        },
      ),
      this.profile.trainer.subscribe(
        (trainer) => {
          this.trainerProfile = trainer;
          this.trainerStripeAccountId = this.trainerProfile.stripe_connected_account_id;
        }, (error) => {
          throw error;
        },
      ),
    ];
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerStripeAccount() {
    const pre = this.generateRandomString(8);
    const past = this.generateRandomString(8);
    const b64encode = btoa(pre + this.trainerProfile.id + past);
    const redirectURL = location.origin + '/tie_up_stripe';
    // tslint:disable-next-line: max-line-length
    const goToUrl = 'https://connect.stripe.com/oauth/authorize?response_type=code&redirect_uri=' + redirectURL + '&client_id=' + environment.STRIPE_CLIENT_ID + '&state=' + b64encode + '&scope=read_write';
    window.location.assign(goToUrl);
  }

  generateRandomString(length: number) {
    return generateRandomString(length);
  }

  goToStripeAccount() {
    window.location.assign('https://connect.stripe.com/dashboard');
  }

  detachStripeAccount() {
    const dialog = this.dialog.open(ConfirmationComponent, {
      data: {
        actionTxt: this.translate.instant('payment.confirmation.button'),
        bodyTxt: this.translate.instant('payment.confirmation.message'),
        headerTxt: this.translate.instant('payment.confirmation.header'),
      },
    });

    dialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirm: boolean) => {
        if(confirm) {
          this.popupCCFunction();
        }
      });
  }

  popupCCFunction = () => {
    const requestOptions = {
      responseType: 'text',
    };

    this.http.postData('/payment/stripe/deauthorize', {profile_id: this.trainerProfile.id}, requestOptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.data.showNotification(this.translate.instant('payment.confirmation.success'), null, 2000, 'success');
          this.dialog.closeAll();
          this.profile.getProfile();
        }, (error) => {
          this.data.showNotification(this.translate.instant('payment.confirmation.fail'), null, 2000, 'error');
          throw error;
        },
      );
  };
}
