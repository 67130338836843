<div class="pages_container">
  <div (click)="changePage(0)"
       *ngIf="lastPage > 5"
       [class.not_available]="selectedPage === 0"
       class="first_page pagination_button">
    {{'pagination.first' | translate}}
  </div>

  <div *ngIf="lastPage > 5" class="previous_page pagination_button" (click)="shiftPages(true)"
        [class.not_available]="this.shiftValue === 0">
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.27333 0.94L4.33333 0L0.333328 4L4.33333 8L5.27333 7.06L2.21999 4L5.27333 0.94Z" fill="#606873"/>
    </svg>
  </div>

  <div class="number_pages_container">
    <div class="pages_tape" [style.transform]="'translateX(' + (shiftValue * -32) + 'px)'">
      <div *ngFor="let page of pages"
           (click)="changePage(page.index)"
           class="pagination_button"
           [class.selected]="page.index === selectedPage">
        {{page.value}}
      </div>
    </div>
  </div>

  <div *ngIf="lastPage > 5"
       class="next_page pagination_button"
       (click)="shiftPages(false)"
       [class.not_available]="(this.lastPage - this.shiftValue) <= 5">
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66665 0L0.726654 0.94L3.77999 4L0.726654 7.06L1.66665 8L5.66665 4L1.66665 0Z" fill="#606873"/>
    </svg>
  </div>

  <div [class.not_available]="selectedPage === (lastPage - 1)"
       *ngIf="lastPage > 5"
       class="last_page pagination_button"
       (click)="changePage(lastPage - 1)">
    {{'pagination.last' | translate}}
  </div>
</div>
