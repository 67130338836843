import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {map} from 'rxjs/internal/operators/map';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemCertificatesService {

  private _systemSertificates = new BehaviorSubject([]);

  public get systemSertificates(): Observable<any[]> {
    return this._systemSertificates.asObservable();
  }

  constructor(
    private http: HttpService,
  ) {
    this.getCertificates();
  }

  /**
   * Get list of system sertificates
   */
  getCertificates() {
    const params = {
      '$sort[created_at]': '-1', // sort by date
    };

    return this.http.getData('/system-certificates', params)
      .pipe(
        map((result: any) => {
          return result.data;
        }),
      )
      .subscribe((result: any) => {
        this._systemSertificates.next(result);
      });
  }
}
