import {UserEntity} from '@models/user/user.entity';
import {ProfileEntity} from '@models/user/profile.entity';

export class TrainingSession {

}

export class SessionList {
  begin: string;
  finish: string;
  duration: number;
  id: number;
  profile: ProfileEntity;
  session: string;
  session_type: string;
  training: string;
  training_status: string;
  type: string;
  event_type: string;
  name: string;
  address: string;
  event_status: string;
  is_public: boolean;
}

export class EventSession {
  activity_type: any;
  date: number;
  description: string;
  distance: number;
  duration: number;
  ending_point?: string;
  ending_point_coordinates?: number[];
  enum_type: string;
  event_status: string;
  goal: string;
  id: number;
  is_invited: boolean;
  is_outdoor: boolean;
  is_public: boolean;
  is_suggestion: boolean;
  joined_participants_count: 0;
  level: string;
  location_coordinates: any;
  location_name: any;
  max_participants: number;
  min_participants: number;
  name: string;
  participants: {
    joined: UserEntity[];
    invited: UserEntity[];
  };
  photo: string;
  price: number;
  profile: ProfileEntity;
  starting_point: string;
  starting_point_coordinates: number[];
  type: string;
}
