import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromChat from './chat.reducer';
import { CHAT_TYPE } from '../enums/chat.enum';
import { ChatItem } from '@modules/chats/interfaces';

export const chatState = createFeatureSelector<fromChat.ChatState>(fromChat.chatFeatureKey);

export const chatList = createSelector(chatState, (state) => state.chatList);

export const chatById = (chatId: ChatItem['chatId']) => createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === chatId));

export const employeeList = createSelector(chatState, (state) => state.employeeList.filter((employee) => employee.externalId !== state.userExternalId));

export const chatHistory = createSelector(chatState, (state) => state.chatHistory);

export const lastCreatedPrivateChat = createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === state.lastCreatedPrivateChatId));

export const lastCreatedGroupChat = createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === state.lastCreatedGroupChatId));

export const activePrivateChat = createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === state.activePrivateChatId));

export const activeGroupChat = createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === state.activeGroupChatId));

export const userExternalId = createSelector(chatState, (state) => state.userExternalId);

export const chatHistoryPending = createSelector(chatState, (state) => state.chatHistoryPending);

export const isChatDeleteSuccess = createSelector(chatState, (state) => state.isChatDeleteSuccess);

export const isEditGroupChatSuccess = createSelector(chatState, (state) => state.editGroupChatSuccess);

export const isChatMemberDeleteSuccess = createSelector(chatState, (state) => state.isChatMemberDeleteSuccess);

export const isChatMembersAddedSuccess = createSelector(chatState, (state) => state.isChatMembersAddedSuccess);

export const hasUnreadMessages = createSelector(chatState, (state) => !!state.chatList.find((chat) => !!chat.newMessagesCount));

export const privateChatList = createSelector(chatState, (state) => {
  return state.chatList.filter(chat => chat.chatType === CHAT_TYPE.PRIVATE).sort((chatA, chatB) => {
    const dateA = chatA.lastMessage.createdAt;
    const dateB = chatB.lastMessage.createdAt;

    return dateA > dateB ? -1 : 1;
  });
});

export const groupChatList = createSelector(chatState, (state) => {
  return state.chatList
    .filter(chat => chat.chatType === CHAT_TYPE.GROUP)
    .sort((chatA, chatB) => {
      const dateA = chatA.lastMessage.createdAt;
      const dateB = chatB.lastMessage.createdAt;

      return dateA > dateB ? -1 : 1;
    });
});
