import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { CompanyService } from '@services/company.service';
import { DataService } from '@services/data.service';
import { MatDialogRef } from '@angular/material/dialog';
import { EMAIL_REG_EXP } from '@shared/utils';

@Component({
  selector: 'app-add-employee-modal',
  templateUrl: './add-employee-modal.component.html',
  styleUrls: ['./add-employee-modal.component.scss'],
})
export class AddEmployeeModalComponent implements OnInit {
  public emailsForm: UntypedFormGroup;
  public emails: UntypedFormArray;

  constructor(
    private dialogRef: MatDialogRef<AddEmployeeModalComponent>,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private translate: TranslateService,
    private companyService: CompanyService,
  ) {}

  ngOnInit() {
    this.emails = this.fb.array([
      this.fb.control('', [
        Validators.required,
        Validators.pattern(EMAIL_REG_EXP),
      ]),
    ]);
    this.emailsForm = this.fb.group({
      emails: this.emails,
    });
  }

  addNewEmail() {
    this.emails.push(
      this.fb.control('', [
        Validators.required,
        Validators.pattern(EMAIL_REG_EXP),
      ]),
    );
  }

  removeEmail(index: number) {
    this.emails.removeAt(index);
  }

  submitEmails() {
    this.companyService.createEmployees(this.emailsForm.value.emails).subscribe(
      (res) => {
        debugger;

        if (res.error) {
          this.data.showNotification(
            res.error.message,
            null,
            Infinity,
            'warning',
          );
          debugger;
        } else {
          this.data.showNotification(
            this.translate.instant('company.employeesAdded'),
            null,
            3000,
            'success',
          );
        }
        this.dialogRef.close(true);
      },
      (error) => {
        this.data.showNotification(error.error.message, null, 4000, 'error');
        debugger;
      },
    );
  }

  close() {
    this.dialogRef.close();
  }
}
