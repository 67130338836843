import {EventEmitter, Injectable} from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import {cloneDeep} from 'lodash';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

import {HttpService} from './http.service';
import {StorageService} from './storage.service';
import {Country} from '../models/country';
import {Postcode} from '../models/postcode';
import {City} from '../models/city';
import {Region} from '../models/region';
import {Goal} from '../models/goal';
import {CertificatesService} from './certificates.service';
import {PARTNER_TYPE, PROFILE_TYPES, TRAINER_TYPE, CLIENT_TYPE} from '../utils/enum';
import {EventType, EventTypeFlat} from '../models/event-type';
import {Certificate} from '../models/certificate';
import {UserEntity} from '../models/user/user.entity';
import {ProfileEntity} from '../models/user/profile.entity';
import {CompanyEntity} from '../models/company/company.entity';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { setUserExternalId } from '@modules/chats/store/chat.actions';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public changes: boolean;
  public changesValid: boolean;
  public dataToUpdate: any;
  public profileType: string;

  public editProfileEvent: EventEmitter<any> = new EventEmitter<any>();
  public saveProfileEvent: EventEmitter<any> = new EventEmitter<any>();
  public cancelEditProfileEvent: EventEmitter<any> = new EventEmitter<any>();
  public formInvalidStatusChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  private _user = new BehaviorSubject(new UserEntity());
  private _trainer = new BehaviorSubject(new ProfileEntity());
  private _regions = new BehaviorSubject([]);
  private _goals = new BehaviorSubject([]);
  private _trainingTypes = new BehaviorSubject([]);
  private _languages = new BehaviorSubject([]);
  private _eventTypes = new BehaviorSubject([]);
  private _company = new BehaviorSubject(new CompanyEntity());

  private dataStore: {
    user: UserEntity;
    company: CompanyEntity;
    trainer: ProfileEntity;
    countries: Country[];
    cities: City[];
    postcodes: Postcode[];
    regions: Region[];
    goals: Goal[];
    trainingTypes: Goal[];
    languages: any[];
    eventTypes: EventTypeFlat[];
  };

  private userId: any;

  constructor(private http: HttpService,
    private storage: StorageService,
    private certificateService: CertificatesService,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private store: Store,
  ) {

    this.userId = this.storage.storage.getItem('userId');
    this.dataStore = {
      user: new UserEntity(),
      trainer: new ProfileEntity(),
      company: this.storage.storage.getItem('company') ?
        new CompanyEntity(JSON.parse(this.storage.storage.getItem('company'))) :
        new CompanyEntity(),
      countries: [],
      cities: [],
      postcodes: [],
      regions: [],
      goals: [],
      trainingTypes: [],
      languages: [],
      eventTypes: [],
    };
    this.dataToUpdate = {};
    this.profileType = this.storage.storage.getItem('PROFILE_TYPE') || CLIENT_TYPE;

    this.trainer.subscribe(trainer => {
      if (trainer.event_types) {
        trainer.event_types = this.flattenEventTypes(trainer.event_types);
      }
    });
  }

  get isCompany(): boolean {
    return !!this.dataStore.company.id;
  }

  get isCompanyWithLicence(): boolean {
    return !!this.dataStore.company.id && !!this.dataStore.company.licence;
  }

  get isCompanyEnabled(): boolean {
    if (new Date() < new Date(this.dataStore.company.trial_period_expires_at)) {
      return true;
    }
    return this.dataStore.company.enabled;
  }

  public get user(): Observable<UserEntity> {
    return this._user.asObservable();
  }

  public get company(): Observable<CompanyEntity> {
    return this._company.asObservable();
  }

  public get companyValue(): CompanyEntity {
    return this._company.getValue();
  }

  public get userValue(): UserEntity {
    return this._user.getValue();
  }

  public get trainer(): Observable<ProfileEntity> {
    return this._trainer.asObservable();
  }

  public get regions(): Observable<Region[]> {
    return this._regions.asObservable();
  }

  public get goals(): Observable<Goal[]> {
    return this._goals.asObservable();
  }

  public get trainingTypes(): Observable<Goal[]> {
    return this._trainingTypes.asObservable();
  }

  public get languages(): Observable<any[]> {
    return this._languages.asObservable();
  }

  public get eventTypes(): Observable<EventTypeFlat[]> {
    return this._eventTypes.asObservable();
  }

  public editProfile(data?: any) {
    this.editProfileEvent.emit(data);
  }

  public saveProfile(data?: any) {
    this.saveProfileEvent.emit(data);
    this.formInvalidStatusChangeEvent.emit(false);
  }

  public cancelEditProfile(data?: any) {
    this.cancelEditProfileEvent.emit(data);
    this.formInvalidStatusChangeEvent.emit(false);
  }

  public changeFormInvalidStatus(data?: any) {
    this.formInvalidStatusChangeEvent.emit(data);
  }

  public clearDataStore() {
    this.dataStore = {
      user: new UserEntity(),
      trainer: new ProfileEntity(),
      company: new CompanyEntity(),
      countries: [],
      cities: [],
      postcodes: [],
      regions: [],
      goals: [],
      trainingTypes: [],
      languages: [],
      eventTypes: [],
    };
    this.dataToUpdate = {};

    this._trainer.next(cloneDeep(this.dataStore.trainer));
    this._user.next(cloneDeep(this.dataStore.user));

  }

  /**
   * Update certificates list in global
   * @param certificates
   * @param type: type of certificate
   */
  public setCertificates(certificates, type: 'certificate' | 'course') {
    const trainer = this._trainer.getValue();
    const currentList = trainer.certificates;
    let otherType = [];

    switch (type) {
      case 'certificate':
        // filter Courses
        otherType = currentList.filter((certificate: Certificate) => {
          return !certificate.system_certificate_id;
        });
        break;
      case 'course':
        // filter Courses
        otherType = currentList.filter((certificate: Certificate) => {
          return certificate.system_certificate_id;
        });
        break;
    }

    trainer.certificates = [
      ...certificates,
      ...otherType,
    ];

    this._trainer.next(trainer);
  }

  /**
   * Update educations list in global
   * @param educations
   */
  public setEducations(educations) {
    const trainer = this._trainer.getValue();
    trainer.educations = educations;
    this._trainer.next(trainer);
  }

  /**
   * Update experiences list in global
   * @param experiences
   */
  public setExperience(experiences) {
    const trainer = this._trainer.getValue();
    trainer.experiences = experiences;
    this._trainer.next(trainer);
  }

  public setUser(user: UserEntity) {
    this.dataStore.user = new UserEntity(user);

    this.setTrainer(user);
    this.storage.storage.setItem('user', JSON.stringify(user));

    this._user.next(cloneDeep(this.dataStore.user));
    this._trainer.next(cloneDeep(this.dataStore.trainer));
    this.userId = this.dataStore.user.id;
  }

  public getProfile(): void {
    this.http.getData('/users/' + this.userId).subscribe((user: UserEntity) => {
      this.setUser(new UserEntity(user));
    }, this.errorHandler);
  }

  /**
   * GET location list
   * @param $limit
   * @param $skip
   */
  public getRegions($limit: number = 5000, $skip: number = 0): any {
    this.http.getData(`/regions?$limit=${$limit}&$skip=${$skip}`)
      .subscribe((regions: Region[]) => {
        this.dataStore.regions = regions;
        this._regions.next(this.dataStore.regions);
      }, this.errorHandler);
  }

  /**
   * GET list of goals
   * @param force force send request
   */
  public getGoals(force: boolean = false) {
    if (this.dataStore.goals.length && !force) {
      return false;
    }
    this.http.getData(`/goals?available_in_profile=${this.profileType}&$limit=999`).subscribe((response: any) => {
      this.dataStore.goals = response.data;
      this._goals.next(this.dataStore.goals);
    }, this.errorHandler);
  }

  /**
   * GET list of training types
   * @param force force send request
   */
  public getTrainingTypes(force: boolean = false) {
    if (this.dataStore.trainingTypes.length && !force) {
      return false;
    }
    this.http.getData(`/training-types?available_in_profile[$overlap][]=${this.profileType}&$limit=999`).subscribe((response: any) => {
      this.dataStore.trainingTypes = response.data;
      this._trainingTypes.next(this.dataStore.trainingTypes);
    }, this.errorHandler);
  }

  /**
   * GET language list
   * @param force force send request
   */
  public getLanguages(force: boolean = false) {
    if (this.dataStore.languages.length && !force) {
      return false;
    }
    this.http.getData('/languages').subscribe((response: any) => {
      this.dataStore.languages = response.data;
      this._languages.next(this.dataStore.languages);
    });
  }

  public getEventTypes(force: boolean = false) {
    if (this.dataStore.eventTypes.length && !force) {
      return false;
    }

    this.http.getData(`/extended-event-types?$select[]=id&$select[]=type&available_in_profile[$contains][]=${this.profileType}`)
      .pipe(
        map((response: any) => {
          response = this.flattenEventTypes(response.data);
          return response;
        }),
      )
      .subscribe((response: EventTypeFlat[]) => {
        this.dataStore.eventTypes = response;
        this._eventTypes.next(this.dataStore.eventTypes);
      });
  }

  public discard() {
    this.changesValid = false;
    this.changes = false;
    this.dataToUpdate = {};
    this._trainer.next(cloneDeep(this.dataStore.trainer));
    this._user.next(cloneDeep(this.dataStore.user));
  }

  patchUser(user: Partial<UserEntity>): Observable<any> {
    return this.http.patchData(`/users/${this.dataStore.user.id}`, user).pipe(
      map((response: UserEntity) => {
        this.dataStore.user = new UserEntity(response);
        this._user.next(cloneDeep(response));
        return response;
      }),
    );
  }

  patchProfile(trainer: Partial<ProfileEntity>): Observable<any> {
    return this.http.patchData(`/profiles/${this.dataStore.trainer.id}`, trainer).pipe(
      map((user: UserEntity) => {
        this.dataStore.user = new UserEntity(user);
        this.setTrainer(user);

        this._user.next(cloneDeep(this.dataStore.user));
        this._trainer.next(cloneDeep(this.dataStore.trainer));

        return user;
      }),
    );
  }

  // todo do this when work zones are done
  // TODO: Need to be implemented
  /**
   * Updates an regions aka 'WorkZones' in profile
   * @param regions - Array
   */
  patchRegions(regions: any[]): any {
    return this.http.postData('/profile-regions', {
      profile_id: this.dataStore.trainer.id,
      regions,
    }).pipe(
      map((trainer: ProfileEntity) => {
        this.dataStore.trainer = new ProfileEntity(trainer);
        this._trainer.next(cloneDeep(this.dataStore.trainer));
        this._regions.next(cloneDeep(this.dataStore.regions));

        return trainer;
      }),
    );
  }

  patchEventTypes(eventTypes: EventTypeFlat[]) {
    const eventTypesMapped = {};
    eventTypes.forEach(eventType => {
      if (eventType.event_type_id && eventTypesMapped[eventType.event_type_id]) {
        eventTypesMapped[eventType.event_type_id].event_activity_types.push(eventType.id);
      } else if (eventType.event_type_id) {
        eventTypesMapped[eventType.event_type_id] = {
          event_activity_types: [],
          id: eventType.event_type_id,
        };
        eventTypesMapped[eventType.event_type_id].event_activity_types.push(eventType.id);
      } else {
        eventTypesMapped[eventType.id] = {
          id: eventType.id,
          event_activity_types: [],
        };
      }
    });

    const payload = {
      profile_id: this.dataStore.trainer.id,
      event_types: Object.values(eventTypesMapped),
    };

    return this.http.postData('/extended-event-types', payload).pipe(
      map((user: UserEntity) => {
        this.dataStore.user = new UserEntity(user);
        this.setTrainer(user);

        this.dataStore.trainer.goals = cloneDeep(this.dataToUpdate.goals);

        return user;
      }),
    );
  }

  patchGoals(goals: Goal[]): Observable<any> {
    const goalsMapped = goals.map(goal => goal.id);
    return this.http.patchData('/goals?add-goals-to-profile', {
      profile_id: this.dataStore.trainer.id,
      listOfIds: goalsMapped,
    }).pipe(
      map((user: UserEntity) => {
        this.dataStore.user = new UserEntity(user);
        this.setTrainer(user);

        this.dataStore.trainer.goals = cloneDeep(this.dataToUpdate.goals);

        return user;
      }),
    );
  }

  patchTrainingTypes(types: Goal[]): Observable<any> {
    const typesMapped = types.map(type => type.id);
    return this.http.patchData('/training-types?add-training-types-to-profile', {
      profile_id: this.dataStore.trainer.id,
      listOfIds: typesMapped,
    }).pipe(
      map((user: UserEntity) => {
        this.dataStore.user = new UserEntity(user);
        this.setTrainer(user);

        this.dataStore.trainer.trainings = cloneDeep(this.dataToUpdate.goals);

        return user;
      }),
    );
  }

  patchLanguages(languages: any[]): Observable<any> {
    const languagesMapped = languages.map(language => language.id);
    return this.http.patchData('/languages?add-languages-to-profile', {
      profile_id: this.dataStore.trainer.id,
      listOfIds: languagesMapped,
    }).pipe(
      map((user: UserEntity) => {
        this.dataStore.user = new UserEntity(user);
        this.setTrainer(user);

        this.dataStore.trainer.languages = cloneDeep(this.dataToUpdate.goals);

        return user;
      }),
    );
  }

  public sendForApproval(type: boolean): Observable<any> {
    return this.http.patchData('/profiles/' + this.dataStore.trainer.id, {
      under_consideration: type,
    }).pipe(
      map((user: UserEntity) => {
        this.dataStore.user = new UserEntity(user);
        this.setTrainer(user);


        this._user.next(cloneDeep(this.dataStore.user));
        this._trainer.next(cloneDeep(this.dataStore.trainer));
        this.discard();
        return user;
      }),
    );
  }

  /**
   * Change active profile type from AP to trainer and vice versa
   * (only for service providers with 2+ profile types, except for user/client)
   */
  switchProfile(type: string) {
    this.storage.storage.setItem('PROFILE_TYPE', type || TRAINER_TYPE);
    location.reload();
  }

  isProfile(type: any): boolean {
    return true
    return PROFILE_TYPES[type] === this.profileType || type === this.profileType;
  }

  postStripeKey(payload) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const requestOptions = {
      headers,
      responseType: 'text' as const,
    };
    return this.http.postData('/payment/stripe/callback', payload, requestOptions).pipe(
      map((response: any) => {
        if (response.id) {
          this.dataStore.user = new UserEntity(response);
          this.setTrainer(response);

          this._trainer.next(cloneDeep(this.dataStore.trainer));
          this._user.next(cloneDeep(this.dataStore.user));
        } else {
          this.getProfile();
        }
      }),
    );
  }

  setCompany(company: Partial<CompanyEntity>) {
    this.dataStore.company = new CompanyEntity(company);
    this.storage.storage.setItem('company', JSON.stringify(company));
    this._company.next(this.dataStore.company);
  }

  getCompany() {
    if (this.dataStore.company.id) {
      return this.http.getData(`/companies/${this.dataStore.company.id}`).pipe(
        catchError((error: HttpErrorResponse) => {
          if(error.status === 404) {
            this.authService.logoutWithNavigation();
          }
          return EMPTY;
        }),
        map((company: CompanyEntity) => {
          this.setCompany(company);

          return this.dataStore.company;
        }),
      );
    }

    return of();
  }

  patchCompany(company: CompanyEntity) {
    return this.http.patchData(`/companies/${this.dataStore.company.id}`, company.prepareBody()).pipe(
      map((company: CompanyEntity) => {
        this.setCompany(company);
        return this.dataStore.company;
      }),
    );
  }

  public deleteCompany(): Observable<boolean> {
    return this.http.deleteData(`/companies/${this.dataStore.company.id}`)
      .pipe(
        map((res: {success: boolean}) => res?.success),
      );
  }

  private errorHandler = (error) => {
    this.snackbar.open(error.message);
    throw error;
  };

  private setTrainer(user: UserEntity) {
    this.dataStore.trainer = user.profiles.find(profile => profile.type === this.profileType);

    if (!this.dataStore.trainer) {
      this.dataStore.trainer = user.profiles.find(profile => profile.type === TRAINER_TYPE) ||
        user.profiles.find(profile => profile.type === PARTNER_TYPE) ||
        user.profiles.find(profile => profile.type === CLIENT_TYPE);

      this.storage.storage.setItem('PROFILE_TYPE', this.dataStore.trainer.type);
      this.profileType = this.dataStore.trainer.type;
    }

    this.dataStore.trainer.event_types = this.flattenEventTypes(this.dataStore.trainer.event_types);
    this._trainer.next(cloneDeep(this.dataStore.trainer));
  }

  private flattenEventTypes(eventTypes: EventType[]): EventTypeFlat[] {
    let flat = [];
    eventTypes.forEach(eventType => {
      if (eventType.event_activity_types && eventType.event_activity_types.length) {
        flat = [...flat, ...eventType.event_activity_types];
      } else {
        flat.push(eventType);
      }
    });
    return flat;
  }
}
