import {Injectable} from '@angular/core';
import * as feathers from '@feathersjs/client';
import * as authentication from '@feathersjs/authentication-client';
import * as jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import rest from '@feathersjs/rest-client';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {environment} from '../../environments/environment';

@Injectable()
export class FeathersService {
  private _feathers: any;

  // private _socket: any;
  constructor(private http: HttpClient) {
    this._feathers = feathers();
    const restClient = rest(environment.API_URL);
    this._feathers.configure(restClient.angularHttpClient(http, { HttpHeaders }));
    this._feathers.configure(authentication({
      header: 'Authorization', // the default authorization header for REST
      path: '/authentication', // the server-side authentication service path
      jwtStrategy: 'jwt', // the name of the JWT authentication strategy
      entity: 'user', // the entity you are authenticating (ie. a users)
      service: 'users', // the service to look up the entity
      cookie: 'feathers-jwt', // the name of the cookie to parse the JWT from when cookies are enabled server side
      storageKey: 'feathers-jwt', // the key to store the accessToken in localstorage or AsyncStorage on React Native
      storage: window.localStorage, // Passing a WebStorage-compatible object to enable automatic storage on the client.
    }));
  }

  // expose services
  public service(name: string) {
    return this._feathers.service(name);
  }

  // expose authentication
  public authenticate(credentials?): Promise<any> {
    return this._feathers.authenticate(credentials);
  }

  // expose logout
  public logout(): void {
    return this._feathers.logout();
  }

  public isAutentificated(): boolean {
    const token = localStorage.getItem('feathers-jwt') || localStorage.getItem('jwt');
    // apparently passport doesn't always correctly detect if token is expired
    if (token && moment.unix(jwtDecode(token).exp).utc().diff(moment()) < 0) {
      return false;
    }

    return this._feathers.passport.payloadIsValid(token);
  }
}
