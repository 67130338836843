<div class="tie_up_component_container page_container" *ngIf="response">
  <div class="tie_up component_window">
    <div class="message" *ngIf="success">
      {{ "payment.successTiedUp" | translate}}
    </div>
    <div class="message" *ngIf="!success">
      {{ "payment.failTiedUp" | translate}}
    </div>
  </div>
</div>
