import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-material-activity-header',
  templateUrl: './material-activity-header.component.html',
  styleUrls: ['./material-activity-header.component.scss'],
})
export class MaterialActivityHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
