import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent {
  @Input() form: UntypedFormGroup;

  constructor(private translate: TranslateService) {}

  get privacyPolicyUrl(): string {
    return this.translate.currentLang === 'nb-nn' ? 'https://miahealth.no/personvern' : 'https://miahealth.no/privacypolicy';
  }
}
