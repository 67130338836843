import { createReducer, on } from '@ngrx/store';
import {
  addChatMembers,
  addChatMembersSuccess,
  addNewMessage,
  clearChatHistory,
  clearChatStore,
  createGroupChat,
  createGroupChatSuccess,
  createPrivateChat,
  createPrivateChatFailure,
  createPrivateChatSuccess,
  deleteChat,
  deleteChatSuccess,
  editGroupChat,
  editGroupChatSuccess,
  loadChatHistoryPending,
  loadChatHistorySuccess,
  loadChatsListSuccess,
  loadChatSuccess,
  loadEmployeeListSuccess,
  removeChatMember,
  removeChatMemberSuccess,
  setGroupActiveChatId,
  setPrivateActiveChatId,
  setUserExternalId,
} from './chat.actions';
import { ChatHistory, ChatItem } from '../interfaces';
import { uniqBy } from 'lodash';
import { ChatEmployee } from '../interfaces/chat-employee.interface';

export const chatFeatureKey = 'chat';

export interface ChatState {
  chatList: ChatItem[];
  employeeList: ChatEmployee[];
  chatHistory: ChatHistory;
  chatHistoryPending: boolean;
  lastCreatedPrivateChatId: ChatItem['chatId'] | null;
  lastCreatedGroupChatId: ChatItem['chatId'] | null;
  activePrivateChatId: ChatItem['chatId'] | null;
  activeGroupChatId: ChatItem['chatId'] | null;
  userExternalId: number;
  isChatDeleteSuccess: boolean;
  editGroupChatSuccess: boolean;
  isChatMemberDeleteSuccess: boolean;
  isChatMembersAddedSuccess: boolean;
}

export const initialState: ChatState = {
  chatList: [],
  employeeList: [],
  chatHistory: null,
  chatHistoryPending: false,
  lastCreatedPrivateChatId: null,
  lastCreatedGroupChatId: null,
  activePrivateChatId: null,
  activeGroupChatId: null,
  userExternalId: null,
  isChatDeleteSuccess: false,
  editGroupChatSuccess: false,
  isChatMemberDeleteSuccess: false,
  isChatMembersAddedSuccess: false,
};

export const chatReducer = createReducer(
  initialState,
  on(loadChatsListSuccess, (state, { chatList }) => ({ ...state, chatList })),

  on(loadEmployeeListSuccess, (state, { employeeList }) => ({ ...state, employeeList })),

  on(loadChatHistorySuccess, (state, { chatHistory }) => {
    const currentMessages = state.chatHistory?.messages || [];

    const newChatHistory: ChatHistory = {
      ...chatHistory,
      messages: uniqBy([...currentMessages, ...chatHistory.messages], 'messageId'),
    };

    return {
      ...state,
      chatHistory: newChatHistory,
      chatHistoryPending: false,
    };
  }),

  on(clearChatHistory, (state) => ({ ...state, chatHistory: null })),

  on(loadChatHistoryPending, (state) => ({ ...state, chatHistoryPending: true })),

  on(createPrivateChat, (state) => ({ ...state, lastCreatedPrivateChatId: null })),

  on(createPrivateChatSuccess, (state, { chatItem }) => {
    return {
      ...state,
      lastCreatedPrivateChatId: chatItem.chatId,
      chatList: [...state.chatList, chatItem],
    };
  }),

  on(createGroupChatSuccess, (state, { chatItem }) => {
    return {
      ...state,
      lastCreatedGroupChatId: chatItem.chatId,
      chatList: [...state.chatList, chatItem],
    };
  }),
  on(createPrivateChatFailure, (state) => ({ ...state, lastCreatedPrivateChatId: null })),

  on(createGroupChat, (state) => ({ ...state, lastCreatedGroupChatId: null  })),

  on(setPrivateActiveChatId, (state, { id }) => ({ ...state, activePrivateChatId: id })),
  on(setGroupActiveChatId, (state, { id }) => ({ ...state, activeGroupChatId: id })),

  on(addNewMessage, (state, { message }) => {
    const messages = (message.chatId === state.activePrivateChatId) || (message.chatId === state.activeGroupChatId)
      ? [...state.chatHistory.messages, message]
      : state.chatHistory.messages;

    return {
      ...state,
      chatHistory: {
        ...state.chatHistory,
        messages,
      },
    };
  }),

  on(loadChatSuccess, (state, { chat }) => ({...state, chatList: state.chatList.map((chatItem) => chatItem.chatId === chat.chatId ? chat : chatItem)})),

  on(setUserExternalId, (state, { userExternalId }) => ({ ...state, userExternalId })),

  on(deleteChat, (state) => ({ ...state, isChatDeleteSuccess: false })),
  on(deleteChatSuccess, (state, { chatId }) => ({ ...state, chatList: state.chatList.filter((chat) => chat.chatId !== chatId), isChatDeleteSuccess: true })),

  on(editGroupChat, (state) => ({ ...state, editGroupChatSuccess: false })),
  on(editGroupChatSuccess, (state, { chatItem }) => {
    return {
      ...state,
      editGroupChatSuccess: true,
      chatList: state.chatList.map((chat) => chat.chatId === chatItem.chatId ? chatItem : chat),
    };
  }),
  on(removeChatMember, (state) => ({ ...state, isChatMemberDeleteSuccess: false })),
  on(removeChatMemberSuccess, (state, { chatItem }) => {
    return {
      ...state,
      isChatMemberDeleteSuccess: true,
      chatList: state.chatList.map((chat) => chat.chatId === chatItem.chatId ? { ...chat, members: chatItem.members } : chat),
    };
  }),

  on(addChatMembers, (state) => ({ ...state, isChatMembersAddedSuccess: false })),
  on(addChatMembersSuccess, (state) => ({ ...state, isChatMembersAddedSuccess: true })),

  on(clearChatStore, () => initialState),
);
