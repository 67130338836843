import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-material-data-picker',
  templateUrl: './material-data-picker.component.html',
  styleUrls: ['./material-data-picker.component.scss'],
})
export class MaterialDataPickerComponent {

  @Input()
  public minDate: Date;
  @Input()
  public maxDate: Date;
  @Input()
  public placeholder: string = 'Date';
  @Input()
  set dateValue(date: Date) {
    this._dateValue = date;
  }

  get dateValue(): Date {
    return this._dateValue;
  }

  @Output()
  dateChange: EventEmitter<any> = new EventEmitter();

  private _dateValue: any;

  selectDate(date: string): void {
    this._dateValue = date;
    this.dateChange.emit(this._dateValue);
  }

}
