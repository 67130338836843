<form [formGroup]="form">
  <app-material-input formControlName="name"
                      type="text"
                      autofocus
                      [label]="'signUp.companyName' | translate"
                      maxlength="50"
                      [isShowCounter]="false"
                      [errors]="{
                            required: 'common.errors.required' | translate
                          }">

  </app-material-input>
  <app-material-select *ngIf="countries.length"
                      class="country-select form-select"
                      formControlName="country_id"
                      valueFieldName="id"
                      labelFieldName="name"
                      [options]="countries"
                      [label]="'profile.personal.country' | translate"
                      [errors]="{
                            required: 'common.errors.required' | translate
                          }">

  </app-material-select>
  <app-material-input formControlName="address"
                      type="text"
                      [label]="'profile.personal.address' | translate"
                      maxlength="100"
                      [isShowCounter]="false"
                      [errors]="{
                            required: 'common.errors.required' | translate
                          }">

  </app-material-input>
  <app-material-textarea [formControl]="form.get('description')"
                         [label]="'events.description' | translate"
                         [errors]="{
                              required: 'common.errors.required' | translate
                            }"

                         [maxlength]="'500'"
                         [hint]="'common.hints.optionalField' | translate"
                         [rows]="10">

  </app-material-textarea>
</form>
