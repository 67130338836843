<div class="data-pickers">
  <app-material-data-picker
    [dateValue]="fromDate"
    [minDate]="minFromDate"
    [maxDate]="maxFromDate"
    (dateChange)="changeFromDate($event)"
  ></app-material-data-picker>
  <span></span>
  <app-material-data-picker
    [dateValue]="toDate"
    [minDate]="minToDate"
    [maxDate]="maxToDate"
    (dateChange)="changeToDate($event)"
  ></app-material-data-picker>

  <app-button class="close" [icon]="true" [disabled]="!isDateChanged" (click)="emitResetDate()" color="secondary">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6668 4.27337L11.7268 3.33337L8.00016 7.06004L4.2735 3.33337L3.3335 4.27337L7.06016 8.00004L3.3335 11.7267L4.2735 12.6667L8.00016 8.94004L11.7268 12.6667L12.6668 11.7267L8.94016 8.00004L12.6668 4.27337Z" fill="white"/>
    </svg>
  </app-button>
</div>
