import {SIGN_IN_STRATEGY} from '@util/enum';
import {PrototypeEntity} from '../prototype.entity';

export class CompanySigninEntity extends PrototypeEntity {
  strategy: SIGN_IN_STRATEGY = null;
  email: string = null;
  password: string = null;

  constructor(data?: object) {
    super();
    this.setData(data);
  }
}
