import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataService } from '@services/data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss'],
})
export class StepThreeComponent {
  @Input() form: UntypedFormGroup;

  openDialog: boolean = false;

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
  ) { }

  removeLogo(): void {
    this.form.reset();
  }

  openUploadDialog(): void {
    this.openDialog = !this.openDialog;
  }

  showUploadNotification(): void {
    this.dataService.showNotification(
      this.translate.instant('signUp.logoUploaded'),
      null,
      3000,
      'success',
    );
  }
}
