import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatCreationRequest, ChatEdit, ChatItem } from '../interfaces';
import { BehaviorSubject, combineLatest, map, Observable, of, shareReplay, startWith } from 'rxjs';
import { CHAT_TYPE } from '../enums/chat.enum';
import { createGroupChat, editGroupChat } from '../store/chat.actions';
import { Store } from '@ngrx/store';
import { GroupChatInfoForm } from '../interfaces/group-chat-form.interface';
import { RestApiService } from './rest-api.service';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { ChatEmployee } from '../interfaces/chat-employee.interface';
import { localStorage } from '../../../../../../dashboard-admin/src/app/utils/storage';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from './chat.service';
import { activeGroupChat, isEditGroupChatSuccess } from '../store/chat.selectors';

@Injectable({
  providedIn: 'root',
})
export class GroupChatService {

  private readonly _memberForm: FormGroup<Record<string, FormControl>>;
  private readonly _groupChatInfoForm: FormGroup<Record<keyof GroupChatInfoForm, FormControl>>;
  private readonly groupChatInfoEditModeSubject$ = new BehaviorSubject(false);
  private readonly groupChatAddMemberModeSubject$ = new BehaviorSubject(false);

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private restApiService: RestApiService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private chatService: ChatService,
  ) {
    this._memberForm = this.buildMemberForm();
    this._groupChatInfoForm = this.buildGroupChatInfoForm();
  }

  get memberForm(): FormGroup<Record<string, FormControl>> {
    return this._memberForm;
  }

  get groupChatInfoForm(): FormGroup<Record<keyof GroupChatInfoForm, FormControl>> {
    return this._groupChatInfoForm;
  }

  get groupChatInfoEditMode$(): Observable<boolean> {
    return this.groupChatInfoEditModeSubject$.asObservable();
  }

  get groupChatAddMemberMode$(): Observable<boolean> {
    return this.groupChatAddMemberModeSubject$.asObservable();
  }

  get isGroupChatInfoFormValid$(): Observable<boolean> {
    return this.groupChatInfoForm.statusChanges
      .pipe(
        startWith(this.groupChatInfoForm.valid),
        map(() => this.groupChatInfoForm.valid),
      );
  }

  get firstStepNextButtonEnabled$(): Observable<boolean> {
    return this.memberForm.valueChanges
      .pipe(
        map((formControls) => this.isSomeMemberSelected(formControls)),
        startWith(this.isSomeMemberSelected(this.memberForm.value)),
        shareReplay(1),
      );
  }

  get secondStepNextButtonEnabled$(): Observable<boolean> {
    return this.groupChatInfoForm.valueChanges
      .pipe(
        map(() => !!this.groupChatInfoForm.valid),
      );
  }

  get atLeastOneMemberSelected$(): Observable<boolean> {
    return this.memberForm.valueChanges
      .pipe(
        map((members) => Object.values(members).some((value) => !!value)),
      );
  }

  addChatMembers(chatId: ChatItem['chatId']): Observable<any> {
    const newMemberIds = Object.entries(this.memberForm.value)
      .filter(([externalId, value]) => !!value)
      .map(([externalId]) => +externalId);

    return this.chatService.addChatMembers(chatId, newMemberIds);
  }

  changeGroupChatInfoEditMode(value: boolean): void {
    this.groupChatInfoEditModeSubject$.next(value);
  }

  changeGroupChatAddMemberMode(value: boolean): void {
    this.groupChatAddMemberModeSubject$.next(value);
  }

  updateChatInfo(): Observable<ChatItem> {
    const picture = this.groupChatInfoForm.value.picture;

    return combineLatest([
      ((picture instanceof File) ? this.chatService.getFileUrl(picture) : of(picture || null)),
      this.store.select(activeGroupChat).pipe(filter(Boolean)),
    ])
      .pipe(
        take(1),
        tap(([picture, chatItem]) => {
          const body: ChatEdit = {
            chatId: chatItem.chatId,
            options: {
              ... this.groupChatInfoForm.value,
              picture,
              company: chatItem.options.company,
            } as ChatEdit['options'],
          };

          this.store.dispatch(editGroupChat({ body }));
        }),
        switchMap(() => this.store.select(isEditGroupChatSuccess).pipe(take(1))),
        switchMap(() => this.store.select(activeGroupChat).pipe(take(1))),
      );
  }

  addMembersToForm(memberList: ChatEmployee[]): void {
    memberList.forEach(({ externalId }) => {
      this.memberForm.addControl(externalId.toString(), new FormControl(false));
    });
  }

  createGroupChat(): Observable<void> {
    const userIds = Object.entries(this.memberForm.value).filter(([id, value]) => !!value).map(([id]) => +id);
    const { chatName, chatDescription, picture } = this.groupChatInfoForm.value;

    return (picture ? this.chatService.getFileUrl(picture) : of(null))
      .pipe(
        tap((pictureUrl: string) => {
          const companyId = JSON.parse(localStorage.get('company'))?.id;
          const chatCreationRequest: ChatCreationRequest = {
            userIds,
            chatType: CHAT_TYPE.GROUP,
            options: {
              picture: pictureUrl,
              chatName,
              chatDescription,
              company: {
                id: companyId,
              },
            },
          };

          this.store.dispatch(createGroupChat({ chatCreationRequest }));
        }),
        take(1),
        map(() => void 0),
      );
  }

  updateGroupChatInfoForm(chatItem: ChatItem): void {
    const { chatName, chatDescription, picture } = chatItem.options;

    this.groupChatInfoForm.patchValue({
      chatName,
      chatDescription,
      picture,
    });
  }

  disableGroupChatInfoForm(): void {
    this.groupChatInfoForm.disable();
  }

  enableGroupChatInfoForm(): void {
    this.groupChatInfoForm.enable();
  }

  resetForms(): void {
    this.memberForm.reset();
    this.groupChatInfoForm.reset({ chatName: '', picture: null, chatDescription: '' }, { emitEvent: false });
  }

  private isSomeMemberSelected(formControls: Record<string, boolean>): boolean {
    return Object.values(formControls).some((value) => !!value);
  }

  private buildGroupChatInfoForm(): FormGroup<Record<keyof GroupChatInfoForm, FormControl<string>>> {
    return this.formBuilder.group({
      chatName: ['', Validators.required],
      chatDescription: ['', Validators.required],
      picture: '',
    });
  }

  private buildMemberForm(): FormGroup<Record<string, FormControl>> {
    return this.formBuilder.group({});
  }
}
