import {PrototypeEntity} from '@models/prototype.entity';
import {PROFILE_TYPES} from '@util/enum';

export class ProfileEntity extends PrototypeEntity {
  account_type: string = null;
  activities: any = null;
  activity_id: number = null;
  ask_me: boolean = false;
  avg_rating: number = null;
  avg_ratings: any = null;
  background: string = '';
  certificates: any[];
  created_at: string = '';
  educations: any[] = [];
  event_types: any = null;
  experiences: any[] = [];
  first_name: string = '';
  frequency: any = null;
  frequency_id: number = null;
  goals: any[] = [];
  id: number = null;
  is_blocked: boolean = false;
  is_profile_confirmed: boolean = false;
  is_worked: boolean = false;
  languages: any[] = [];
  last_name: string = '';
  min_pre_booking_hours: number = null;
  nettpt_enabled: boolean = false;
  org_name: string = '';
  org_no: number = null;
  police_certificate: any[] = [];
  profile_avatar: string = '';
  rest_time_minutes: number = null;
  stripe_connected_account_id: string = null;
  stripe_customer_id: string = '';
  trainer_level: any = null;
  trainer_level_id: number = null;
  training_location: any = null;
  trainings: any[] = [];
  type: PROFILE_TYPES = PROFILE_TYPES.TRAINER_TYPE;
  under_consideration: boolean = false;
  updated_at: string = null;
  user_id: number = null;
  billing_address: string;
  regions: any[];
  get isTrainer(): boolean {
    return this.type === PROFILE_TYPES.TRAINER_TYPE;
  }

  get isActivityPartner(): boolean {
    return this.type === PROFILE_TYPES.PARTNER_TYPE;
  }

  get isClient(): boolean {
    return this.type === PROFILE_TYPES.CLIENT_TYPE;
  }

  constructor(data?: ProfileEntity) {
    super();
    this.setData(data);
  }
}
