import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LegalService {

  private apiUrl = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  public getPrivatePolicy(): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/legal/privacy-policy`,
      {headers: new HttpHeaders().set('Accept', 'text/html'), responseType: 'text'},
    );
  }

  public getCompanyAgreement(): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/legal/company-agreement`,
      {headers: new HttpHeaders().set('Accept', 'text/html'), responseType: 'text'},
    );
  }
}
