import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, filter, map, takeUntil, tap} from 'rxjs/operators';

import {TrainingLocationService} from '../../../../services/training-location.service';
import {ProfileService} from '../../../../services/profile.service';
import {UserEntity} from '@models/user/user.entity';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-personal-trainer-location',
  templateUrl: './personal-trainer-location.component.html',
  styleUrls: ['./personal-trainer-location.component.scss'],
})
export class PersonalTrainerLocationComponent implements OnInit, OnDestroy {

  destroy$ = new Subject();
  workZonesRadiusGroup: UntypedFormGroup;
  public canEditProfile: boolean;
  private subs: Subscription;
  private workZonesRadiusGroupData: any;

  constructor(
    private profileService: ProfileService,
    private trainingLocationService: TrainingLocationService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.canEditProfile = true;
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.formInit();
    this.locationsInit();
    this.subs.add(
      this.profileService.editProfileEvent.subscribe((event) => {
        this.canEditProfile = event;
      }),
    );
    this.subs.add(
      this.profileService.cancelEditProfileEvent.subscribe(() => {
        this.locationsInit();
      }),
    );
    this.subs.add(
      this.profileService.saveProfileEvent.subscribe(() => {
        this.saveLocation(this.workZonesRadiusGroupData);
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.destroy$.complete();
  }

  formInit() {
    this.workZonesRadiusGroup = new UntypedFormGroup({
      profile_id: new UntypedFormControl(null, [
        Validators.required,
      ]),
      coordinates: new UntypedFormGroup({
        lat: new UntypedFormControl(null, [
          Validators.required,
        ]),
        lng: new UntypedFormControl(null, [
          Validators.required,
        ]),
      }),
      primary_zone: new UntypedFormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(20),
      ]),
      secondary_zone: new UntypedFormControl(null, [
        Validators.min(1),
        Validators.max(20),
      ]),
      tertiary_zone: new UntypedFormControl(null, [
        Validators.min(1),
        Validators.max(20),
      ]),
    }, this.workZonesRadiusGroupVadilator);

    this.formListener();
  }

  /**
   * Listen radius form
   */
  formListener() {
    this.workZonesRadiusGroup.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => {
          let isValid = this.workZonesRadiusGroup.valid;
          if (!isValid) {
            this.profileService.changeFormInvalidStatus(true);
          }
          return isValid;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        this.profileService.changeFormInvalidStatus(false);
        this.workZonesRadiusGroupData = result;
      });
  }

  /**
   * Set user location
   */
  locationsInit() {
    this.profileService.trainer
      .pipe(
        tap((trainer: ProfileEntity) => {
          this.workZonesRadiusGroup.get('profile_id').setValue(trainer.id, {
            emitEvent: false,
          });

          // disable form if under_consideration
          if (trainer.under_consideration) {
            this.workZonesRadiusGroup.disable({
              emitEvent: false,
            });
          } else {
            this.workZonesRadiusGroup.enable({
              emitEvent: false,
            });
          }
        }),
        filter((trainer: ProfileEntity) => {
          // required if we undo the first changes made
          if (!trainer.training_location) {
            this.setInitialNullValues();
          }
          return trainer.training_location;
        }),
        map((trainer: ProfileEntity) => {
          return trainer.training_location[0];
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((training_location) => {
        const {
          coordinates,
          radii,
        } = training_location;

        const deltas = this.trainingLocationService.radiusToDelta(radii);

        this.workZonesRadiusGroup.patchValue({
          coordinates,
          primary_zone: deltas.get('PRIMARY'),
          secondary_zone: deltas.get('SECONDARY'),
          tertiary_zone: deltas.get('TERTIARY'),
        });
      });
  }

  setInitialNullValues() {
    this.workZonesRadiusGroup.patchValue({
      primary_zone: null,
      secondary_zone: null,
      tertiary_zone: null,
    });
  }

  /**
   * Save location setting
   * @param locationData
   */
  saveLocation(locationData: any) {
    if (!locationData || !this.workZonesRadiusGroup.valid) {return;}

    const radiuses = this.trainingLocationService.deltaToRadius(locationData);

    const preparedData = {
      profile_id: locationData.profile_id,
      coordinates: locationData.coordinates,
      radii: radiuses,
    };

    this.trainingLocationService.update(preparedData)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((result: UserEntity) => {
        this.profileService.setUser(result);
      });
  }

  /**
   * Validation for radius form group
   * User can't set tertiary_zone without secondary_zone
   * @param gb
   */
  workZonesRadiusGroupVadilator(gb: UntypedFormGroup) {
    const tertiaryZone = gb.get('tertiary_zone').value;
    const secondaryZone = gb.get('secondary_zone').value;

    if (tertiaryZone && !secondaryZone) {
      return {secondary_zone_required: true};
    }

    return null;
  }

}
