import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Country} from '@models/country';
import {CountriesService} from '@services/countries.service';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss'],
})
export class StepTwoComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;

  countries: Country[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private countriesService: CountriesService,
  ) { }

  ngOnInit(): void {
    this.getCountries();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getCountries(): void {
    this.countriesService.getCountriesList()
      .pipe(
        takeUntil(this.destroy$),
        map(res => res),
      )
      .subscribe((countries: Country[]) => {
        this.countries = countries;
      });
  }

}
