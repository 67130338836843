import {Component, HostBinding} from '@angular/core';

@Component({
  selector: 'app-material-activity',
  templateUrl: './material-activity.component.html',
  styleUrls: ['./material-activity.component.scss'],
})
export class MaterialActivityComponent {
  @HostBinding('class.page_card') cardClass = true;
  @HostBinding('class.page_card--activity') cardActivityClass = true;

  private burger: HTMLElement;

  constructor() {
  }
}
