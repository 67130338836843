<div class="container">
  <div class="content">
    <h1>{{ 'trial_modal.header' | translate }}</h1>

    <div class="intro">
      <p>{{ 'trial_modal.intro_1' | translate }}</p>

      <p>{{ 'trial_modal.intro_2' | translate }}</p>
    </div>

    <h2>{{ 'trial_modal.trial_header' | translate }}</h2>

    <p>{{ 'trial_modal.trial_text' | translate }}</p>
  </div>
</div>

<img class="close" mat-dialog-close src="/assets/img/close.svg" alt="close">
