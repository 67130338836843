import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CompanyService} from '@services/company.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

interface DataInterface {
  email: string;
}

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss']
})
export class SendEmailModalComponent implements OnInit {
  public emailForm: UntypedFormGroup;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataInterface,
    private dialogRef: MatDialogRef<SendEmailModalComponent>,
    private fb: UntypedFormBuilder,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.emailForm = this.fb.group({
      subject: this.fb.control('', [
        Validators.required
      ]),
      message: this.fb.control('', [
        Validators.required
      ])
    })
  }

  get subject() { return this.emailForm.get('subject'); }
  get message() { return this.emailForm.get('message'); }

  submitEmail() {
    this.emailForm.markAllAsTouched();
    if (this.emailForm.invalid) {
      return;
    }

    const emailObj = {
      email: this.data.email,
      subject: this.emailForm.value.subject,
      message: this.emailForm.value.message
    };

    this.companyService.sendEmailToEmployee(emailObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.dialogRef.close(true);
      },
      (error) => {
        throw error;
      });
  }
}
