import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProfileService} from './profile.service';
import {PricingService} from './pricing.service';
import {CalendarService} from './calendar.service';
import {TranslateService} from '@ngx-translate/core';
import {ACCOUNT_TYPES, PROFILE_TABS} from '../utils/enum';
import {ProfileEntity} from '../models/user/profile.entity';

// todo in future there should be as many steppers as there are profile types of service providers
@Injectable({
  providedIn: 'root',
})
export class StepperService {
  public _steps: (() => boolean)[];
  public currentHint: string;
  public currentHeader: string;
  public currentLink: {
    link: string;
    query?: string;
    anchor?: string;
  };

  private _activeStep: BehaviorSubject<number>;
  private _canSendForApproval: BehaviorSubject<boolean>;
  private _canEditProfile: BehaviorSubject<any>;
  private _currentStep: number;
  private _trainer: ProfileEntity;
  private _pricing: any[];
  private _schedule: any[];

  constructor(
    private profileService: ProfileService,
    private pricingService: PricingService,
    private calendarService: CalendarService,
    private translate: TranslateService,
  ) {
    this._currentStep = 0;
    this._activeStep = new BehaviorSubject(this._currentStep);
    this._canSendForApproval = new BehaviorSubject(false);
    this._canEditProfile = new BehaviorSubject(true);
    this._pricing = [];

    this.currentHint = '';
    this.currentHeader = '';
    this.currentLink = {
      link: '',
    };

    this._steps = [];

    this.profileService.trainer.subscribe(trainer => {
      this._trainer = trainer;
      this.evaluateSteps();
      /**
       * STEPS ORDER
       * change the order as you wish
       */
      if (this.profileService.isProfile('TRAINER_TYPE')) {
        this._steps = [
          this.checkProfile.bind(this),
          this.checkLocation.bind(this),
          this.checkForSend.bind(this),
          this.checkStripe.bind(this),
          this.checkPrices.bind(this),
        ];
      }

      if (this.profileService.isProfile('PARTNER_TYPE')) {
        this._steps = [
          this.checkProfile.bind(this),
          this.checkLocation.bind(this),
          this.checkForSend.bind(this),
        ];
      }
    });

    this.pricingService.priceList.subscribe(pricing => {
      this._pricing = pricing;
      this.evaluateSteps();
    });

    this.calendarService.schedule.subscribe(schedule => {
      this._schedule = schedule;
      this.evaluateSteps();
    });
  }

  public get activeStep(): Observable<number> {
    return this._activeStep.asObservable();
  }

  public get canSendForApproval(): Observable<boolean> {
    return this._canSendForApproval.asObservable();
  }

  public get canSendForApprovalValue(): boolean {
    return this._canSendForApproval.getValue();
  }

  public get steps(): void[] {
    return new Array(this._steps.length);
  }

  public get canEditProfile(): Observable<string> {
    return this._canEditProfile.asObservable();
  }

  public evaluateSteps() {
    this._currentStep = 0;
    this._canSendForApproval.next(false);
    if (!this._schedule && !this._pricing && !this._trainer) {
      return false;
    }
    for (let i = 0; i < this._steps.length; i++) {
      const result = this._steps[i]();
      if (!result) {
        break;
      }
      this._currentStep = i + 1;
      this._activeStep.next(i + 1);
    }
  }

  public get currentStep(): number {
    return this._currentStep;
  }

  /**
   * Step which checks whole profile page
   */
  checkProfile(): boolean {
    if (!this._trainer.id) {
      return false;
    }
    this.currentHeader = this.translate.instant('stepsTips.header1');

    // organization name and number
    if (this._trainer.account_type !== ACCOUNT_TYPES.INDIVIDUAL && (!this._trainer.org_no || !this._trainer.org_name)) {
      this.currentLink = {
        link: '/profile',
        query: PROFILE_TABS.PERSONAL,
        anchor: 'organization',
      };
      this.currentHint = this.translate.instant('profile.messages.companyRequired');
      return false;
    }
    // certificates
    if ((!this._trainer.certificates.length ||
      !Object.keys(this._trainer.certificates[0]).length) &&
      this.profileService.isProfile('TRAINER_TYPE')) {
      this.currentLink = {
        link: '/profile',
        query: PROFILE_TABS.EDUCATION,
        anchor: 'certificates',
      };
      this.currentHint = this.translate.instant('profile.messages.certificateRequired');
      return false;
    }

    // educations
    if ((!this._trainer.educations.length ||
      !Object.keys(this._trainer.educations[0]).length) &&
      this.profileService.isProfile('TRAINER_TYPE')) {
      this.currentLink = {
        link: '/profile',
        query: PROFILE_TABS.EDUCATION,
        anchor: 'educations',
      };
      this.currentHint = this.translate.instant('profile.messages.educationRequired');
      return false;
    }

    // fields of expertise
    if (!this._trainer.goals.length && this.profileService.isProfile('TRAINER_TYPE')) {
      this.currentLink = {
        link: '/profile',
        query: PROFILE_TABS.EXPERTISE,
        anchor: 'goals',
      };
      this.currentHint = this.translate.instant('profile.messages.goalRequired');
      return false;
    }

    // specializations
    if (!this._trainer.event_types.length && this.profileService.isProfile('PARTNER_TYPE')) {
      this.currentLink = {
        link: '/profile',
        query: PROFILE_TABS.EXPERTISE,
        anchor: 'events',
      };
      this.currentHint = this.translate.instant('profile.messages.eventTypesRequired');
      return false;
    }

    // training types
    if (!this._trainer.trainings.length && this.profileService.isProfile('TRAINER_TYPE')) {
      this.currentLink = {
        link: '/profile',
        query: PROFILE_TABS.EXPERTISE,
        anchor: 'trainings',
      };
      this.currentHint = this.translate.instant('profile.messages.trainingTypeRequired');
      return false;
    }

    // languages
    if (!this._trainer.languages.length) {
      this.currentLink = {
        link: '/profile',
        query: PROFILE_TABS.EXPERTISE,
        anchor: 'languages',
      };
      this.currentHint = this.translate.instant('profile.messages.languageRequired');
      return false;
    }

    return true;
  }

  /**
   * Step which checks if user has a stripe account
   */
  checkStripe(): boolean {
    if (!this._trainer.id) {
      return false;
    }
    this.currentHeader = this.translate.instant('stepsTips.header4');

    // stripe account
    if (!this._trainer.stripe_connected_account_id) {
      this.currentLink = {
        link: '/payment',
        query: null,
        anchor: null,
      };
      this.currentHint = this.translate.instant('stepsTips.tip2');
      return false;
    }
    return true;
  }

  /**
   * Step which checks whether 'send for approval' button is active
   */
  checkForSend(): boolean {
    if (!this._trainer.id) {
      // todo set message
      return false;
    }
    this.currentHeader = this.translate.instant('stepsTips.header3');

    this._canSendForApproval.next(true);

    if (this._trainer.under_consideration) {
      this.currentLink = {
        link: '/profile',
        query: null,
        anchor: '',
      };
      this.currentHint = this.translate.instant('stepsTips.tip6');
      return false;
    }

    if (!this._trainer.is_profile_confirmed) {
      this.currentLink = {
        link: '/profile',
        query: null,
        anchor: 'send',
      };
      this.currentHint = this.translate.instant('stepsTips.tip3');
      return false;
    }
    return true;
  }

  /**
   * Checks if user has set prices
   */
  checkPrices(): boolean {
    this.currentHeader = this.translate.instant('stepsTips.header5');

    if (!this._pricing.length) {
      this.currentHint = this.translate.instant('stepsTips.tip4');
      this.currentLink = {
        link: '/pricing',
        query: null,
        anchor: null,
      };
      return false;
    }
    return true;
  }

  /**
   * Checks if user has set location
   */
  checkLocation(): boolean {
    this.currentHeader = this.translate.instant('stepsTips.header2');

    if (!this._trainer.training_location) {
      this.currentHint = this.translate.instant('stepsTips.tip7');
      this.currentLink = {
        link: '/profile',
        query: PROFILE_TABS.LOCATION,
        anchor: null,
      };
      return false;
    }

    return true;
  }

  /**
   * checks whether user has schedule for the next two weeks
   */
  checkSchedule(): boolean {
    this.currentHeader = this.translate.instant('stepsTips.header5');

    if (!this._schedule.length) {
      this.currentHint = this.translate.instant('stepsTips.tip5');
      this.currentLink = {
        link: '/calendar',
        query: 'schedule',
        anchor: null,
      };
      return false;
    }
    return true;
  }
}
