<form [formGroup]="form">
  <span class="heading">
    {{'company.logo' | translate}}
  </span>
  <div class="input-container">
    <app-image-input
      class="logo-input"
      [openDialog]="openDialog"
      [formControl]="form.get('logo')"
      [maxImageSize]="256000"
      maxImageSizeText="256kb"
    ></app-image-input>
  </div>
  <div class="buttons">
    <app-button color="secondary" (click)="openUploadDialog()">
      <i>
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.83337 13.3333H13.1667V12H3.83337V13.3333ZM3.83337 6.66667H6.50004V10.6667H10.5V6.66667H13.1667L8.50004 2L3.83337 6.66667Z" fill="#14BFFB"/>
        </svg>
      </i>
      {{'company.upload' | translate}}
    </app-button>

    <app-button color="remove" [icon]="true" [disabled]="!this.form.get('logo').value" class="delete" (click)="removeLogo()">
      <i>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.99992 12.6667C3.99992 13.4 4.59992 14 5.33325 14H10.6666C11.3999 14 11.9999 13.4 11.9999 12.6667V4.66667H3.99992V12.6667ZM12.6666 2.66667H10.3333L9.66659 2H6.33325L5.66659 2.66667H3.33325V4H12.6666V2.66667Z"/>
        </svg>
      </i>
    </app-button>
  </div>
</form>
