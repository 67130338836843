<div class="expertise-header" *ngIf="isTrainer">
  {{'profile.education.specTrainingEducation' | translate}}
</div>
<div class="expertise-header" *ngIf="isPartner">
  {{'profile.education.specEducation' | translate}}
</div>

<app-expertise class="profile-component"></app-expertise>

<!-- user-education-list -->
<div class="user-education-list list-holder" *ngIf="isTrainer" [ngClass]="{ 'list-holder--disabled': tabIsDisabled }">
  <div class="list-header">
<!--     <div class="title">
      {{'profile.education.educationText' | translate}}
    </div> -->

    <div class="descrition">
      {{'profile.education.educationTip' | translate}}
    </div>
  </div>

  <app-user-education-list>
  </app-user-education-list>
</div>

<!-- system-certificates-list -->
<div class="system-certificates-list list-holder" *ngIf="isTrainer" [ngClass]="{ 'list-holder--disabled': tabIsDisabled }">
  <div class="list-header">
    <div class="title">
      {{'profile.education.specialityCertificationsTitle' | translate}}
    </div>

    <div class="descrition descrition-accent">
      {{'profile.education.specialityCertificationsDescription' | translate}}
    </div>
  </div>

  <app-system-certificates-list>
  </app-system-certificates-list>
</div>

<!-- user-certificates-list -->
<div class="user-certificates-list list-holder" *ngIf="isTrainer" [ngClass]="{ 'list-holder--disabled': tabIsDisabled }">
  <div class="list-header">
    <div class="title">
      {{'profile.education.coursesTitle' | translate}}
    </div>

    <div class="descrition">
      {{'profile.education.coursesDescription' | translate}}
    </div>
  </div>

  <app-user-certificates-list>
  </app-user-certificates-list>
</div>

<!-- user-experience-list -->
<div class="user-experience-list list-holder" [ngClass]="{ 'list-holder--disabled': tabIsDisabled }">
  <div class="list-header" *ngIf="isTrainer">
    <div class="title">
      {{'profile.education.experience' | translate}}
    </div>

    <div class="descrition">
      {{'profile.education.experienceTip' | translate}}
    </div>
  </div>

  <div class="list-header" *ngIf="isPartner">
    <div class="title">
      {{'profile.education.educationAndExperience' | translate}}
    </div>

    <div class="descrition">
      {{'profile.education.experienceTip2' | translate}}
    </div>
  </div>

  <app-user-experience-list>
  </app-user-experience-list>
</div>

<div class="cover"></div>
