<label [for]="formControlName || defaultId"
       class="material_label"
       [ngClass]="{
         'material_label--focused': focused,
         'material_label--dirty': control.value,
         'material_label--disabled': control.disabled,
         'material_label--invalid': isInvalid,
         'material_label--readonly': readonly,
         'material_label--error': error
       }"
       #labelEl>
  {{label}}
</label>

<input
  [id]="formControlName || defaultId"
  class="material_input"
  [autofocus]="autofocus"
  [type]="type"
  (focus)="focus()"
  (blur)="blur()"
  [autocomplete]="autocomplete"
  [maxlength]="maxlength"
  [readonly]="readonly"
  [ngClass]="{
    'material_input--focused': focused,
    'material_input--disabled': control.disabled,
    'material_input--invalid': isInvalid,
    'material_input--readonly': readonly,
    'material_input--error': error
  }"
  [formControl]="control"
  (keydown)="keydown($event)"
  [placeholder]="placeholder"
  #inputEl
>

<button class="show-password" *ngIf="isPassword" (click)="type === 'password' ? type = 'text' : type = 'password'">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" *ngIf="type === 'password'">
    <path d="M8 4.66667C9.84 4.66667 11.3333 6.16 11.3333 8C11.3333 8.43333 11.2467 8.84 11.0933 9.22L13.04 11.1667C14.0467 10.3267 14.84 9.24 15.3267 8C14.1733 5.07333 11.3267 3 7.99333 3C7.06 3 6.16666 3.16667 5.34 3.46667L6.78 4.90667C7.16 4.75333 7.56666 4.66667 8 4.66667ZM1.33333 2.84667L2.85333 4.36667L3.16 4.67333C2.05333 5.53333 1.18666 6.68 0.666664 8C1.82 10.9267 4.66666 13 8 13C9.03333 13 10.02 12.8 10.92 12.44L11.2 12.72L13.1533 14.6667L14 13.82L2.18 2L1.33333 2.84667ZM5.02 6.53333L6.05333 7.56667C6.02 7.70667 6 7.85333 6 8C6 9.10667 6.89333 10 8 10C8.14666 10 8.29333 9.98 8.43333 9.94667L9.46666 10.98C9.02 11.2 8.52666 11.3333 8 11.3333C6.16 11.3333 4.66666 9.84 4.66666 8C4.66666 7.47333 4.8 6.98 5.02 6.53333V6.53333ZM7.89333 6.01333L9.99333 8.11333L10.0067 8.00667C10.0067 6.9 9.11333 6.00667 8.00666 6.00667L7.89333 6.01333Z" fill="#606873"/>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" *ngIf="type === 'text'">
    <path d="M7.99999 3C4.66666 3 1.81999 5.07333 0.666656 8C1.81999 10.9267 4.66666 13 7.99999 13C11.3333 13 14.18 10.9267 15.3333 8C14.18 5.07333 11.3333 3 7.99999 3ZM7.99999 11.3333C6.15999 11.3333 4.66666 9.84 4.66666 8C4.66666 6.16 6.15999 4.66667 7.99999 4.66667C9.83999 4.66667 11.3333 6.16 11.3333 8C11.3333 9.84 9.83999 11.3333 7.99999 11.3333ZM7.99999 6C6.89332 6 5.99999 6.89333 5.99999 8C5.99999 9.10667 6.89332 10 7.99999 10C9.10666 10 9.99999 9.10667 9.99999 8C9.99999 6.89333 9.10666 6 7.99999 6Z" fill="#606873"/>
  </svg>
</button>

<div class="description" *ngIf="description">
  {{description}}
</div>

<div class="hints-container">
  <div class="hints">
    <div class="error" *ngIf="(error || formError) && !readonly" [ngClass]="{'error--red': isTouched}">
      *{{error ? error : formError}}
    </div>
    <div class="hint" *ngIf="hint && !error">
      *{{hint}}
    </div>
  </div>

  <div *ngIf="maxlength && isShowCounter" class="input-counter">
    {{counter}} / {{maxlength}}
  </div>
</div>
