import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {BehaviorSubject} from 'rxjs';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import * as pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormGroup} from '@angular/forms';

import {ClientMessageService} from './client-message.service';
import {MobileService} from './mobile.service';

@Injectable({
  providedIn: 'root',
})
export class CertificatesService {
  private _isImg: BehaviorSubject<boolean>;

  private _regExps = {
    image: {
      base64: /^data:image\/(jpeg|png|jpg)/,
      link: /\.(jpeg|png|jpg)$/,
    },
    pdf: {
      base64: /^data:application\/pdf/,
      link: /\.(pdf)$/,
    },
  };

  private _routes = {
    certificates: '/certificates',
    educations: '/educations',
    experiences: '/experiences',
    police: '/police-certificate',
  };

  constructor(
    private http: HttpService,
    private mobile: MobileService,
    private dialog: MatDialog,
    private clientMessageService: ClientMessageService,

  ) {
    this._isImg = new BehaviorSubject(false);
  }

  public isPdf(data): boolean {
    return this.checkFormat(data, 'pdf');
  }

  /**
   *
   * @param type 'certificates', 'educations', 'experiences', 'police'
   * @param form
   */
  create(type: string, form) {
    return this.http.postData(this._routes[type], form);
  }

  /**
   *
   * @param type 'certificates', 'educations', 'experiences', 'police'
   * @param form
   * @param id - certificate id
   */
  update(type: string, form, id: number) {
    return this.http.patchData(this._routes[type] + '/' + id, form);
  }

  /**
   *
   * @param type 'certificates', 'educations', 'experiences', 'police'
   * @param id
   */
  remove(type: string, id: number) {
    return this.http.deleteData(this._routes[type] + '/' + id);
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  markFormGroupTouched(formGroup: UntypedFormGroup, deep = false) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();

      if (deep && control.controls) {
        this.markFormGroupTouched(control, deep);
      }
    });
  }

  /**
   * Open modal for view/change certificate
   */
  openCertificateModal(imageInput, certificateGroup: UntypedFormGroup, modal): void {
    let config: any = {
      data: {
        fileInput: imageInput,
        form: certificateGroup,
      },
    };

    if (this.mobile.isMobile) {
      config = {
        ...config,
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw',
      };
    }

    this.dialog.open(modal, config);
  }

  /**
   * Emit when image field was changed
   */
  imageUpload(event, certificate: UntypedFormGroup): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (readerEvent: any) => {
        certificate.patchValue({
          file_link: readerEvent.target.result,
          file: event.target.files[0],
        });

        this.dialog.closeAll();
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  /**
   * Gets data from each page of pdf and renders it to container of choice
   * @param data
   * @param container
   * @param pages number of pages to render. If value != true (e.g. 0) gets number of available in pdf
   */
  setPdfView(data, container: HTMLElement, pages: number) {
    container.innerHTML = '';

    // "fix GlobalWorkerOptions.workerSrc" error
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    (pdfjs.getDocument(this.processUri(data.image)) as any).then((pdf) => {
      for (let i = 0; i < (pages || pdf.numPages); i++) {
        renderPage(pdf, i + 1);
      }
    });

    // todo perhaps should be in worker
    function renderPage(pdf, pageNum) {
      pdf.getPage(pageNum).then((page) => {
        const scale = 1.5;
        const viewport = page.getViewport(scale);
        // Prepare canvas using PDF page dimensions
        const canvas: any = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        // styles in js is bad thing to do but in this case it is inevitable
        canvas.style.cssText = 'max-width: 100%; max-height: 100%';
        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport,
        };
        const renderTask = page.render(renderContext);
        container.appendChild(canvas);
      });
    }
  }

  /**
   * PDF/image rendering
   * @param data - certificate
   * @param container - container to render pdf
   * @param pageNum - number of pages to render. 0 - no limit (default)
   * @returns boolean. True if media is PDF, false if image
   */
  public renderMedia(data, container, pageNum = 0): boolean {
    const isPdf = this.checkFormat(data, 'pdf');

    if (isPdf) {
      this.setPdfView(data, container, pageNum);
    }

    return isPdf;
  }

  /**
   * Checks for format of content
   * @param format acceptable values: 'image'(default), 'pdf'
   */
  checkFormat(data, format: string = 'image'): boolean {
    return this._regExps[format].link.test(data.image) ||
           this._regExps[format].base64.test(data.image);
  }

  private processUri(data) {
    // if data is base64, it is needed to convert bas64 to Uint8Array so pdf-js would be able to process it
    if (this._regExps.pdf.base64.test(data)) {
      const BASE64_MARKER = ';base64,';
      const base64Index = data.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      const base64 = data.substring(base64Index);
      const raw = window.atob(base64);
      const rawLength = raw.length;
      const array = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }
      return array;
    } else {
      return data;
    }
  }
}
