<div class="modal_header">
  <button class="close" mat-dialog-close>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.186 16.5115L13.6744 12L18.186 7.48835C18.3915 7.28283 18.5581 6.55811 18 5.99998C17.4418 5.44184 16.7171 5.60843 16.5116 5.81393L12 10.3256L7.48835 5.81397C7.28288 5.60845 6.55814 5.44186 6.00002 5.99998C5.44191 6.55809 5.60847 7.28286 5.81397 7.48835L10.3256 12L5.81395 16.5116C5.60848 16.7171 5.44184 17.4418 6 18C6.55816 18.5582 7.2829 18.3915 7.48838 18.1861L12 13.6744L16.5116 18.186C16.7171 18.3915 17.4419 18.5581 18 18C18.5582 17.4418 18.3915 16.717 18.186 16.5115Z"></path>
    </svg>
  </button>
</div>
<div class="modal_content">
  <img [src]="data.image" alt="Certificate" *ngIf="!isPdf">
  <div #canvasContainer [ngClass]="{'canvas_container': isPdf}">

  </div>
</div>
<div class="modal_actions">
  <label class="button" (click)="changeImage()">
    {{'profile.education.change_image' | translate}}
  </label>
</div>
