import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {HttpService} from '@services/http.service';
import {map} from 'rxjs/operators';
import {Country} from '@models/country';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {

  private apiUrl = environment.API_URL;

  constructor(
    private http: HttpService,
  ) { }

  public getCountriesList($limit: number = 195, $skip: number = 0): Observable<Country[]> {
    return this.http.getData(`/countries?$limit=${$limit}&$skip=${$skip}`).pipe(
      map(({data}: {data: Country[]}) => {
        return data.map((country) => new Country(country));
      }),
    );
  }
}
