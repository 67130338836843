import {Directive} from '@angular/core';

@Directive({
  selector: '[appStorage]',
})
export class StorageDirective {

  constructor() {
  }

  getItem(name) {
    return this[name];
  }

  setItem(name, value) {
    this[name] = value;
  }

  removeItem(name) {
    this[name] = null;
  }

}
