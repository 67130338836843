import { inject, InjectionToken } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

export const PREVIOUS_URL_TOKEN = new InjectionToken<Observable<string>>('PreviousUrlToken');

export function getPreviousUrl(): Observable<string> {
  const router = inject(Router);

  return router.events
    .pipe(
      map((event) => {
        if (event instanceof NavigationEnd) {
          return event.url;
        }
      }),
      startWith(router.url),
      filter(Boolean),
      pairwise(),
      map(([previousUrl]) => previousUrl),
      shareReplay(1),
    );
}
