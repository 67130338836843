import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {MaterialInputComponent} from './material-input/material-input.component';
import {MaterialTextareaComponent} from './material-textarea/material-textarea.component';

import {ImageInputComponent} from './image-input/image-input.component';
import {StepperItemComponent} from './stepper-item/stepper-item.component';
import {MaterialButtonComponent} from './material-button/material-button.component';

import {MaterialHeaderComponent} from './material-header/material-header.component';
import {MaterialIconButtonComponent} from './material-icon-button/material-icon-button.component';
import {RouterModule} from '@angular/router';
import {MaterialSelectComponent} from '@modules/material/material-select/material-select.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialCheckboxComponent} from '@modules/material/material-checkbox/material-checkbox.component';
import { MaterialDataPickerComponent } from './material-data-picker/material-data-picker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';

import {MaterialActivityHeaderComponent} from '@modules/material/material-activity/material-activity-header/material-activity-header.component';
import { SafePipe } from '@pipes/safe.pipe';
import { SearchInputComponent } from './search-input/search-input.component';

@NgModule({
  declarations: [
    MaterialInputComponent,
    MaterialTextareaComponent,
    ImageInputComponent,
    StepperItemComponent,
    MaterialButtonComponent,
    MaterialSelectComponent,
    MaterialCheckboxComponent,
    MaterialHeaderComponent,
    MaterialIconButtonComponent,
    MaterialSelectComponent,
    MaterialCheckboxComponent,
    MaterialDataPickerComponent,
    MaterialActivityHeaderComponent,
    SafePipe,
    SearchInputComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    TranslateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [
    MaterialInputComponent,
    MaterialTextareaComponent,
    ImageInputComponent,
    StepperItemComponent,
    MaterialButtonComponent,
    MaterialSelectComponent,
    MaterialCheckboxComponent,
    MaterialHeaderComponent,
    MaterialIconButtonComponent,
    MaterialSelectComponent,
    MaterialCheckboxComponent,
    MaterialDataPickerComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialActivityHeaderComponent,
    SafePipe,
    SearchInputComponent,
  ],
  providers: [
    SafePipe,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class MaterialSharedModule { }
