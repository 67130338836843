import {animate, style, transition, trigger} from '@angular/animations';

export const fadeInOut = trigger(
  'fadeInOut',
  [
    transition(
      ':enter', [
        style({transform: 'scale(0.8)', opacity: 0}),
        animate('100ms', style({transform: 'scale(1)', opacity: 1})),
      ],
    ),
    transition(
      ':leave', [
        style({transform: 'scale(1)', opacity: 1}),
        animate('100ms', style({transform: 'scale(0.8)', opacity: 0})),
      ],
    )],
);
