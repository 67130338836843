import {AfterViewInit, Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[appHighImage]',
})
export class HighImageDirective implements AfterViewInit {

  constructor(
    private renderer: Renderer2, private el: ElementRef,
  ) {}

  ngAfterViewInit() {
    const milliseconds = this.el.nativeElement.naturalHeight ? 0 : 1000;
    this.resizeImage(milliseconds);
  }

  resizeImage(milliseconds: number) {
    setTimeout(() => {
      if (this.el.nativeElement.naturalHeight > this.el.nativeElement.naturalWidth) {
        this.renderer.addClass(this.el.nativeElement, 'higher');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'higher');
      }
    }, milliseconds);
  }

}
