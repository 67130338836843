<div class="datepicker-container">
  <div class="datepicker" [owlDateTimeTrigger]="picker" [class.active]="picker.opened">
    <span *ngIf="dateValue">{{ dateValue | date : 'dd.MM.yyyy'}}</span>
    <span *ngIf="!dateValue">{{ placeholder }}</span>
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3333 2.00001H11.6667V0.666672H10.3333V2.00001H3.66668V0.666672H2.33334V2.00001H1.66668C0.933344 2.00001 0.333344 2.6 0.333344 3.33334V14C0.333344 14.7333 0.933344 15.3333 1.66668 15.3333H12.3333C13.0667 15.3333 13.6667 14.7333 13.6667 14V3.33334C13.6667 2.6 13.0667 2.00001 12.3333 2.00001ZM12.3333 14H1.66668V5.33334H12.3333V14Z" fill="#606873"/>
    </svg>
  </div>

  <input
    class="hidden_input"
    [min]="minDate"
    [max]="maxDate"
    ([ngModel])="dateValue"
    [owlDateTime]="picker"
    placeholder="Date Time"
  >

  <owl-date-time
    #picker
    (afterPickerClosed)="selectDate(picker.selected)"
    pickerType="calendar"
    panelClass="date-picker-popup"
  >
  </owl-date-time>
</div>
