<div class="work-zones-radius">

  <div class="map-holder" [ngClass]="{disable: workZonesRadiusGroup.disabled}">
    <app-workzones-radius-map
      [radiusesForm]="workZonesRadiusGroup"
    >
    </app-workzones-radius-map>
  </div>

  <!-- steps-radius -->
<!--   <div class="steps-radius__step steps-radius__step--step-1 active">
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
        <rect width="16" height="16" x="1" y="1" stroke-width="2" rx="8"></rect>
        <path fill-rule="evenodd"
              d="M12.805 6.203c.26.27.26.708 0 .979L8.36 11.797a.65.65 0 0 1-.943 0L5.195 9.49a.711.711 0 0 1 0-.98.65.65 0 0 1 .943 0l1.75 1.819 3.974-4.126a.65.65 0 0 1 .943 0z"
              clip-rule="evenodd"></path>
      </svg>
    </div>
    <div class="content">
      <div class="title">{{ 'profile.location.step' | translate }} 1</div>
      <div class="description">{{ 'profile.location.putLocationOnMap' | translate }}</div>
    </div>
  </div>

  <div class="steps-radius__step steps-radius__step--step-2" [ngClass]="{active: workZonesRadiusGroup.valid}">
    <div class="icon" >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
        <rect width="16" height="16" x="1" y="1" stroke-width="2" rx="8"></rect>
        <path fill-rule="evenodd"
              d="M12.805 6.203c.26.27.26.708 0 .979L8.36 11.797a.65.65 0 0 1-.943 0L5.195 9.49a.711.711 0 0 1 0-.98.65.65 0 0 1 .943 0l1.75 1.819 3.974-4.126a.65.65 0 0 1 .943 0z"
              clip-rule="evenodd"></path>
      </svg>
    </div>
    <div class="content">
      <div class="title">{{ 'profile.location.step' | translate }} 2</div>
      <div class="description">{{ 'profile.location.setWorkingRadiuses' | translate }}</div>
    </div>
  </div> -->

  <!-- form-radius -->
  <div class="form-radius-holder">
    <div class="title">
      {{'profile.location.setWorkingRadiuses' | translate}}

      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99999 1.33337C4.31999 1.33337 1.33333 4.32004 1.33333 8.00004C1.33333 11.68 4.31999 14.6667 7.99999 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00004C14.6667 4.32004 11.68 1.33337 7.99999 1.33337ZM8.66666 11.3334H7.33333V7.33337H8.66666V11.3334ZM8.66666 6.00004H7.33333V4.66671H8.66666V6.00004Z" fill="#F11C48"/>
      </svg>
    </div>

    <form [formGroup]="workZonesRadiusGroup" class="form-radius">
      <app-material-input
        class="form-radius__input"
        formControlName="primary_zone"
        [type]="'number'"
        [autocomplete]="'off'"
        [readonly]="canEditProfile"
        [description]="'profile.location.scalableFromTo' | translate:{from: 1, to: 20}"
        [label]="'profile.location.primaryRadius' | translate"
        [errors]="{max: 'profile.messages.maxNumber' | translate:{number: '20'},
                  min: 'profile.messages.minNumber' | translate:{number: '1'},
                  required: 'profile.messages.fieldRequired' | translate}">
      </app-material-input>

      <app-material-input
        class="form-radius__input"
        formControlName="secondary_zone"
        [type]="'number'"
        [autocomplete]="'off'"
        [readonly]="canEditProfile"
        [description]="'profile.location.scalableFromTo' | translate:{from: 1, to: 20}"
        [label]="'profile.location.secondaryRadius' | translate"
        [errors]="{max: 'profile.messages.maxNumber' | translate:{number: '20'},
                  min: 'profile.messages.minNumber' | translate:{number: '1'}}">
      </app-material-input>

      <app-material-input
        class="form-radius__input"
        formControlName="tertiary_zone"
        [type]="'number'"
        [autocomplete]="'off'"
        [readonly]="canEditProfile"
        [description]="'profile.location.scalableFromTo' | translate:{from: 1, to: 20}"
        [label]="'profile.location.tertiaryRadius' | translate"
        [errors]="{max: 'profile.messages.maxNumber' | translate:{number: '20'},
                  min: 'profile.messages.minNumber' | translate:{number: '1'}}">
      </app-material-input>

      <div class="form-errors" *ngIf="workZonesRadiusGroup.errors">
        <div class="form-errors__error" *ngIf="workZonesRadiusGroup.errors.secondary_zone_required" >*{{ 'profile.location.secondaryZoneRequired' | translate }}</div>
      </div>

    </form>
  </div>

</div>
