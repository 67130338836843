<div class="body page_content">
  <div class="header">
    <div class="title">
      {{'profile.payment.payment' | translate}}
    </div>
    <div class="descrition">
      {{'profile.payment.linkStripeAccount' | translate}}
    </div>
  </div>
  <div class="button_container">
    <button
      *ngIf="!trainerStripeAccountId"
      (click)="registerStripeAccount()"
    >
      {{ "payment.connectButton" | translate}}
    </button>

    <button
      *ngIf="trainerStripeAccountId"
      (click)="goToStripeAccount()"
    >
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3334 0.666504H1.66671C0.926707 0.666504 0.340041 1.25984 0.340041 1.99984L0.333374 9.99984C0.333374 10.7398 0.926707 11.3332 1.66671 11.3332H12.3334C13.0734 11.3332 13.6667 10.7398 13.6667 9.99984V1.99984C13.6667 1.25984 13.0734 0.666504 12.3334 0.666504ZM12.3334 9.99984H1.66671V5.99984H12.3334V9.99984ZM12.3334 3.33317H1.66671V1.99984H12.3334V3.33317Z" fill="white"/>
      </svg>
      {{ "payment.goStripeButton" | translate}}
    </button>

    <button
      class="primary"
      *ngIf="trainerStripeAccountId"
      (click)="detachStripeAccount()"
    >
      <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.00004 10.6667C1.00004 11.4 1.60004 12 2.33337 12H7.66671C8.40004 12 9.00004 11.4 9.00004 10.6667V2.66667H1.00004V10.6667ZM9.66671 0.666667H7.33337L6.66671 0H3.33337L2.66671 0.666667H0.333374V2H9.66671V0.666667Z" fill="white"/>
      </svg>
      {{ "payment.detach" | translate}}
    </button>
  </div>
  <div class="guidlines">
    <div class="row">
      <div class="logo">
        <svg width="96" height="40" viewBox="0 0 96 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.16748 24.7896V31.1555C0.16748 31.1555 3.2073 32.7047 7.38994 32.7047C11.5726 32.7047 16.62 30.4511 16.62 25.8036V24.5364C16.62 24.5364 16.5368 20.8741 12.9671 19.1845C9.39751 17.4937 6.55363 17.099 6.55363 15.6913C6.55363 14.2826 8.22624 14.1698 9.14684 14.1698C10.0674 14.1698 13.162 14.6209 15.2812 15.9445V9.60627C15.2812 9.60627 12.1582 8.47949 9.92732 8.47949H8.11459C8.11459 8.47949 0.502441 8.56464 0 15.0146V16.5925C0 16.5925 0.0842874 20.0857 4.01625 21.804C7.94711 23.5224 9.92732 24.0852 9.92732 25.2971C9.92732 26.508 8.81188 26.9304 7.44576 26.9304C6.07965 26.9304 2.56584 26.3675 0.16748 24.7896Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M24.456 3.0708V8.84513H29.475V14.6206H24.456V24.536C24.456 24.536 24.5119 26.846 26.9661 26.846C26.9661 26.846 28.5828 26.846 29.475 26.4236V31.8595C29.475 31.8595 27.7192 32.7043 25.265 32.7043C22.8108 32.7043 17.9867 31.1551 17.9867 26.3108V4.56359L24.456 3.0708Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5669 32.1694V8.873H37.2558C37.2558 8.873 37.5064 9.80295 37.6181 10.7329C37.6181 10.7329 38.9842 8.61978 41.2994 8.61978C41.2994 8.61978 42.3305 8.53574 43.1121 8.84536V14.9581C43.1121 14.9581 42.4422 14.7889 41.3826 14.7889C40.3229 14.7889 39.0959 15.2677 38.1764 16.3381V32.1694H31.5669Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M44.7843 32.1694H51.3938V8.87305H44.7843V32.1694Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M44.7847 6.76068V1.46515L51.3941 0V5.35193L44.7847 6.76068Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M65.1972 8.42236C62.13 8.42236 59.9823 10.4227 59.9823 10.4227C59.8991 9.4651 59.7316 8.87352 59.7316 8.87352H53.8479V39.9998L60.4847 38.5347V31.0707C62.604 32.7891 65.3362 32.6476 65.3362 32.6476C69.1849 32.6476 74.2039 29.38 74.2039 20.169C74.2039 10.9579 68.2644 8.42236 65.1972 8.42236ZM63.5246 26.7604C61.4885 26.7604 60.4005 25.4644 60.4005 25.4644V15.6341C60.4005 15.6341 61.656 14.3094 63.4403 14.3094C65.2245 14.3094 67.6787 16.1693 67.6787 20.5205C67.6787 24.8728 65.5595 26.7604 63.5246 26.7604Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M95.0163 22.9386C95.0163 22.9386 95.7596 15.8396 92.711 12.0844C89.6624 8.3281 85.5542 8.42209 85.5542 8.42209C82.9884 8.42209 75.3478 10.1405 75.3478 20.3655C75.3478 30.5917 82.1236 32.676 86.4179 32.676C90.7122 32.676 93.5845 30.9577 93.5845 30.9577V25.6334C90.4429 27.239 86.9477 27.1074 86.9477 27.1074C81.8171 27.1074 82.0032 22.9386 82.0032 22.9386H95.0163ZM81.9288 18.0931C81.9288 18.0931 82.2266 13.8116 85.3868 13.8116C88.5471 13.8116 88.8448 18.0931 88.8448 18.0931H81.9288Z" fill="white"/>
        </svg>
      </div>
      <div class="button button_link">
        <a
          target="_blank"
          routerLink="/stripe-guide">
          <button>
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.00008 0C4.66675 0 1.82008 2.07333 0.666748 5C1.82008 7.92667 4.66675 10 8.00008 10C11.3334 10 14.1801 7.92667 15.3334 5C14.1801 2.07333 11.3334 0 8.00008 0ZM8.00008 8.33333C6.16008 8.33333 4.66675 6.84 4.66675 5C4.66675 3.16 6.16008 1.66667 8.00008 1.66667C9.84008 1.66667 11.3334 3.16 11.3334 5C11.3334 6.84 9.84008 8.33333 8.00008 8.33333ZM8.00008 3C6.89341 3 6.00008 3.89333 6.00008 5C6.00008 6.10667 6.89341 7 8.00008 7C9.10675 7 10.0001 6.10667 10.0001 5C10.0001 3.89333 9.10675 3 8.00008 3Z" fill="#14BFFB"/>
            </svg>
            <span class="button_label">
              {{ "payment.viewGuidlines" | translate}}
            </span>
          </button>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="description">
        {{'profile.payment.stripeInfo' | translate}}
      </div>
    </div>
  </div>
</div>
