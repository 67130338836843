export class ChartLineEntity<T> {
  name: string | number;
  series: ChartSeriesEntity<T>[];

  constructor(name: string, series: ChartSeriesEntity<T>[]) {
    this.name = name;
    this.series = [...series];
  }
}

export class ChartSeriesEntity<T> {
  value: T;
  name: string | number;

  constructor(name: string | number, value: T) {
    this.name = name;
    this.value = value;
  }
}
