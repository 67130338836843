import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Country} from '@models/country';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent {
  @Input() form: UntypedFormGroup;
  @Input() countries: Country[] = [];
  @Input() isEditing: boolean = false;

  constructor() { }

}
