import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {CompanyService} from '@services/company.service';
import {switchMap} from 'rxjs/operators';
import {ProfileService} from '@services/profile.service';
import {CompanyEntity} from '@models/company/company.entity';
import {DataService} from '@services/data.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  openDialog: boolean = false;

  constructor(
    private companyService: CompanyService,
    private profileService: ProfileService,
    private dataService: DataService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.form.get('logo').valueChanges.subscribe((value) => {
      if (value) {
        const formData = new FormData();
        formData.append('logo', value);
        this.companyService.uploadLogo(formData).pipe(
          switchMap((response: {key: string; url: string }) => {
            return this.profileService.patchCompany(new CompanyEntity({
              ...this.form.value,
              logo: response.url,
            }));
          }),
        ).subscribe(() => {
          this.dataService.showNotification(
            this.translate.instant('company.logoUploaded'),
            null,
            2500,
            'success',
          );
        });
      } else {
        this.companyService.removeLogo(this.form.value.id).pipe(
          switchMap((response: {key: string; url: string }) => {
            return this.profileService.patchCompany(new CompanyEntity({
              ...this.form.value,
              logo: response.url,
            }));
          }),
        ).subscribe(() => {
          this.dataService.showNotification(
            this.translate.instant('company.logoDeleted'),
            null,
            2500,
            'success',
          );
        });
      }
    });
  }


  removeLogo(): void {
    this.form.get('logo').markAsTouched();
    this.form.get('logo').markAsDirty();
    this.form.get('logo').reset();
    this.cdr.detectChanges();
  }

  openUploadDialog(): void {
    this.openDialog = !this.openDialog;
  }
}
