import {
  Component,
  EventEmitter,
  Host,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {find, findIndex} from 'lodash';

import {listAnimation, listAnimationUp} from '../material-animations/select-option';
import {MobileService} from '@services/mobile.service';

@Component({
  selector: 'app-material-select-image',
  templateUrl: './material-select-image.component.html',
  styleUrls: ['./material-select-image.component.scss'],
  animations: [
    listAnimation,
    listAnimationUp,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaterialSelectImageComponent,
    multi: true,
  }],
})

export class MaterialSelectImageComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  @Input() formControlName: string;
  @Input() formGroupName: string;
  @Input() options: any[];
  @Input() label: string;
  @Input() description: string;
  @Input() search: boolean;
  @Input() labelValue: string;
  @Input() labelImage: string;
  @Input() idDuplicates: boolean;
  @Input() formControl: UntypedFormControl;
  @Output() changed = new EventEmitter();
  @Input() touchNeeded = true;
  @Input() readonly: boolean;

  @HostBinding('class.host-selected')
  public showList = false;
  public ITEM_SIZE: number = 40;

  public control: AbstractControl;
  public controlObject: any;
  public optionsUp = false;
  onChange: any;
  onTouch: any;
  searchValue: string;
  checkedOptions: any[];
  filteredOptions: any[];
  windowHeight: number;

  @ViewChild('searchEl', { static: false }) searchEl;
  @ViewChild('labelEl', { static: true }) labelEl;

  private sub: Subscription;

  get selectedTitle() {
    return this.controlObject !== undefined ? this.controlObject[this.labelValue] : '';
  }

  get selectedImage() {
    return this.controlObject !== undefined ? this.controlObject[this.labelImage] : '';
  }

  get isInvalid() {
    if (this.touchNeeded) {
      return (this.control.dirty || this.control.touched) && this.control.invalid;
    } else {
      return this.control.invalid;
    }
  }

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private mobile: MobileService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.searchValue = '';
    this.checkedOptions = [];
    this.sub = new Subscription();
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit() {
    if (this.formControl) {
      this.control = this.formControl;
    } else if (this.controlContainer) {
      if (this.formControlName && !this.formGroupName) {
        this.control = this.controlContainer.control.get(this.formControlName);
      }
    }

    this.filteredOptions = [...this.options];

    if (this.mobile.isMobile) {
      this.sub.add(this.route.fragment.subscribe( fragment => {
        if (fragment === null) {
          this.showList = false;
        }
      }));
    }
  }

  /**
   * Prevents bug on the first init of options being unselected
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readonly && !changes.readonly.firstChange) {return;}

    let currentVal = null;

    if (this.controlContainer) {
      if (this.formControlName && !this.formGroupName) {
        currentVal = this.controlContainer.control.get(this.formControlName).value;
      }
    } else if (this.formControl) {
      currentVal = this.formControl.value;
    }

    this.controlObject = this.options.find((item) => {
      return item.id === currentVal;
    });

    this.filteredOptions = [...this.options];
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * Whether to show list of options or not
   */
  toggleOptions($event = null): void {
    if (this.control.disabled || this.readonly) {
      return;
    }
    if ($event) {
      // calculate if options are needed to be shown upwords
      this.optionsUp = $event.clientY + window.innerHeight / 2.7 > window.innerHeight;
    }
    this.showList = !this.showList;
    if (this.search && this.showList) {
      this.searchEl.nativeElement.focus();
    } else if (this.search && !this.showList) {
      this.searchEl.nativeElement.blur();
      this.searchValue = '';
      this.searchEl.nativeElement.value = '';
      this.filter();
    }

    if (this.showList) {
      document.addEventListener('keydown', this.keyListener);
      this.router.navigate([location.pathname], {fragment: 'mat-select'});
    } else {
      document.removeEventListener('keydown', this.keyListener);
      this.router.navigate([location.pathname], {fragment: null});
    }

    if (this.showList && this.mobile.isMobile) {
      window.scrollTo(0, 0);
    }
    this.setWindowHeight();
  }

  /**
   * Used to select option
   * Has different behavior depending on multiselect option
   * @param index index of selected item
   */
  select(index: number): void {
    if (this.searchValue) {
      const param = {
        ...this.filteredOptions[index],
      };

      delete param.checked;
      index = findIndex(this.options, param);
    }

    this.control.setValue(this.options[index]);
    this.controlObject = this.options[index];
    this.showList = false;

    this.changed.emit(this.controlObject);
  }

  /**
   * Method to filter/search through values
   * @param $event
   */
  filter($event?): void {
    this.searchValue = this.searchValue || '';
    if ($event) {
      this.searchValue = $event.target.value;
    }

    this.mapOptions();

    this.filteredOptions = this.filteredOptions.filter( el => {
      return el[this.labelValue].toLowerCase().includes(this.searchValue.toLowerCase());
    });
  }

  /**
   * This is used to tag selected options
   */
  mapOptions() {
    this.checkedOptions = new Array(this.options.length).fill(false);
    this.filteredOptions = [...this.options];

    this.options.forEach((option, index) => {
      // exception for the case if ids can have duplicates
      const firstCondition = this.idDuplicates && find(this.control.value, {enum_type: option.enum_type});
      const secondCondition = !this.idDuplicates && find(this.control.value, {id: option.id});

      if (firstCondition || secondCondition) {
        this.checkedOptions[index] = option;
        this.filteredOptions[index] = {...option, checked: true};
      }
    });
  }

  /**
   * Set options modal height
   */
  private setWindowHeight() {
    if (this.mobile.isMobile) {
      this.windowHeight = window.innerHeight;
    } else {
      if (this.options.length * this.ITEM_SIZE > window.innerHeight * 0.3) {
        this.windowHeight = window.innerHeight * 0.3;
      }
      else {
        this.windowHeight = this.options.length * this.ITEM_SIZE;
      }
    }
  }

  private keyListener = (event) => {
    if (event.key === 'Escape') {
      this.toggleOptions();
    }
  };

}
