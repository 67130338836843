import {Component, Host, Input, OnChanges, OnInit, Optional, SkipSelf} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-material-checkbox',
  templateUrl: './material-checkbox.component.html',
  styleUrls: ['./material-checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaterialCheckboxComponent,
    multi: true,
  }],
})
export class MaterialCheckboxComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() label: string;
  @Input() formControlName: string;
  @Input() checked: boolean;
  @Input() readonly: boolean;
  @Input() formControl: UntypedFormControl;
  @Input() size: 'middle' | 'large' = 'middle';
  @Input() borderColor: 'gray' | 'primary' = 'gray'

  onChange: any;
  onTouch: any;

  get isChecked() {
    return this.control ? this.control.value : false;
  }

  public control: AbstractControl;
  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
  ) {
  }

  ngOnInit(): void {
    if (this.formControl) {
      this.control = this.formControl;
    } else {
      if (this.controlContainer && !this.readonly) {
        if (this.formControlName) {
          this.control = this.controlContainer.control.get(this.formControlName);
        }
      } else {
        console.warn('Can\'t find parent FormGroup directive');
      }
    }
  }

  ngOnChanges(): void {
    if (this.readonly) {
      this.control = new UntypedFormControl(this.checked);
    }
  }

  writeValue(obj: any): void {
    // this.control.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  change(event) {
    this.control.setValue(event.target.checked);
  }
}
