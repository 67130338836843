import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private translate: TranslateService,
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let additionalHeaders: any;
    if (this.translate.currentLang) {
      additionalHeaders = req.headers.set('Accept-version', environment.APP_BACKEND_VERSION).set('Accept-Language', this.translate.currentLang);
    } else {
      additionalHeaders = req.headers.set('Accept-version', environment.APP_BACKEND_VERSION);
    }
    let copiedReq = req.clone({ headers: additionalHeaders });

    if (req.url.includes('authentication')) {
      return next.handle(copiedReq);
    } else {
      const token = localStorage.getItem('jwt');
      if (!token) {
        return next.handle(copiedReq);
      }
      const tokenExpires = jwt_decode(token).exp;
      const currentTimeStamp = new Date().getTime() / 1000;
      if (tokenExpires - currentTimeStamp < 10) {
        return next.handle(copiedReq);
      } else {
        copiedReq = req.clone({
          headers: copiedReq.headers.set('Authorization', localStorage.getItem('jwt')),
        });
        return next.handle(copiedReq);
      }
    }
  }
}
