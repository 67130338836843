import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import { EMAIL_REG_EXP } from '@shared/utils';
import {CompanyService} from '@services/company.service';
import {DataService} from '@services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: [
    './restore-password.component.scss',
    '../login/login.component.scss',
  ],
})
export class RestorePasswordComponent implements OnInit {

  public form: UntypedFormGroup;

  constructor(
    public router: Router,
    private companyService: CompanyService,
    private authService: AuthService,
    private data: DataService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_REG_EXP),
      ]),
    });
  }

  navigateBack(): void {
    const restorePasswordType = localStorage.getItem('restorePasswordType');

    restorePasswordType === 'company' ? this.router.navigate(['company-sign-in']) : this.router.navigate(['login']);
  }

  submitForm(): void {
    const email = this.form.value.email;

    this.authService.setPasswordToken(email)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.data.showNotification(this.translate.instant('alerts.restoreMailSendToEmail'), null, 2500, 'success');
      },
      (error) => {
        if (error.error.code === 404) {
          this.data.showNotification(error.error.message, null, 2500, 'error');
        }
      });
  }

}
