import {AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import * as momentjs from 'moment';
import {Moment} from 'moment';
import {extendMoment} from 'moment-range';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {CdkVirtualScrollViewport, ScrollDispatcher} from '@angular/cdk/scrolling';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

const moment = extendMoment(momentjs);

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() timeRangeCollection: any;
  @Input() focusedInput: boolean | number;
  // only used while editing time range
  @Input() index: number;
  @Output() closeTimePicker = new EventEmitter<boolean>();

  @ViewChild('hoursViewport', { static: true }) hoursViewport: CdkVirtualScrollViewport;
  @ViewChild('minutesViewport', { static: true }) minutesViewport: CdkVirtualScrollViewport;
  public hours: Moment[];
  public minutes: Moment[];
  public elementHeight = 56;
  public form: UntypedFormControl;

  private scrolledHoursIndex: number;
  private scrolledMinutesIndex: number;
  private subs: Subscription;

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    // basically we need to create 24 hours 5 hours before and 5 hours after for seamless infinity loop scroll
    this.hours = new Array(34).fill(undefined).map((hour, index) => {
      return moment().startOf('day').hours(index - 5);
    });

    // same as above but with minutes
    this.minutes = new Array(70).fill(undefined).map((minute, index) => {
      return moment().startOf('day').minutes(index - 5);
    });
  }

  ngOnInit() {
    this.subs = new Subscription();
    this.form = this.data.form;
  }

  ngAfterViewInit(): void {
    if (this.form.value) {
      const time = this.form.value.split(':');
      setTimeout(() => {
        // +- 3 is the offset for smooth infinity loop scroll
        this.hoursViewport.scrollToIndex(+time[0] + 3);
        this.minutesViewport.scrollToIndex(+time[1] + 3);
      });
    } else {
      const time = [moment().hours(), moment().minutes()];
      setTimeout(() => {
        // +- 3 is the offset for smooth infinity loop scroll
        this.hoursViewport.scrollToIndex(+time[0] + 3);
        this.minutesViewport.scrollToIndex(+time[1] + 3);
      });
    }

    // scroll to the last item, so user can see which time is selected
    this.subs.add(
      this.scrollDispatcher.scrolled().pipe(
        debounceTime(500),
      ).subscribe((scroll: any) => {
        const classList = scroll.elementRef.nativeElement.classList;
        if (this.scrolledHoursIndex && classList.contains('time-selection-hours')) {
          const scrolledHour = Math.round(this.hoursViewport.elementRef.nativeElement.scrollTop / this.elementHeight);
          this.hoursViewport.scrollToIndex(scrolledHour, 'smooth');
        }
        if (this.scrolledHoursIndex && classList.contains('time-selection-minutes')) {
          const scrolledMinute = Math.round(this.minutesViewport.elementRef.nativeElement.scrollTop / this.elementHeight);
          this.minutesViewport.scrollToIndex(scrolledMinute, 'smooth');
        }
      }),
    );

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Applies new time range or changes to existing one if is in editing mode. Also makes all merges of time intervals
   */
  apply() {
    let hours; let minutes;
    // 3 is the offset for smooth infinity loop scroll. Same goes for 27 and 63
    if (this.scrolledHoursIndex >= 3 && this.scrolledHoursIndex <= 27) {
      hours = this.scrolledHoursIndex - 3;
    }
    if (this.scrolledHoursIndex >= 3 && this.scrolledHoursIndex <= 63) {
      minutes = this.scrolledMinutesIndex - 3;
    }
    const time = moment().hours(hours).minutes(minutes);
    this.form.patchValue(time.format('HH:mm'));
  }

  /**
   * Fires event when element is scrolled scrolled index is changed
   *
   * @param event index of scrolled element
   * @param type hours/minutes
   */
  onScroll(event: number, type: string) {
    const types = {
      hours: () => {
        this.scrolledHoursIndex = event;

        // +- 4 is the offset for smooth infinity loop scroll

        if (event >= ((this[type].length - 6))) {
          this.hoursViewport.scrollToIndex(4);
          this.scrolledHoursIndex = 4;
        }

        if (event < 2) {
          this.hoursViewport.scrollToIndex(26);
          this.scrolledHoursIndex = 26;
        }
      },
      minutes: () => {
        this.scrolledMinutesIndex = event;

        if (event >= ((this[type].length - 6))) {
          this.minutesViewport.scrollToIndex(4);
          this.scrolledMinutesIndex = 4;
        }

        if (event < 2) {
          this.minutesViewport.scrollToIndex(62);
          this.scrolledMinutesIndex = 62;
        }
      },
    };

    if (!types[type]) {
      console.warn('Unknown type', type);
      return false;
    }

    types[type]();
  }
}
