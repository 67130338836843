import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanySigninEntity } from '@models/company/company-signin.entity';
import { CompanySignupEntity } from '@models/company/company-signup.entity';
import { PaiScoreGroupSummaryStatEntity } from '@models/company/pai-score-group-summary-stat.entity';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Employee } from '@models/employee';
import { Response } from '@shared/interfaces';

interface EmployeeRequestParams {
  skip: number;
  limit: number;
  query?: string;
  isQueryChanged?: boolean;
  role?: 'COMPANY' | 'EMPLOYEE';
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public totalEmployees = 0;

  private readonly employeesAreLoadingSubject$ = new BehaviorSubject<boolean>(false);
  private readonly employeesSubject$ = new BehaviorSubject<Employee[]>([]);
  private readonly urlEmployeeParamsSubject$ = new BehaviorSubject<EmployeeRequestParams>({
    skip: 0,
    limit: 20,
    query: '',
    isQueryChanged: false,
  });

  private apiUrl = environment.API_URL;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
  ) { }

  getEmployees(): Observable<Employee[]> {
    return this.employeesSubject$.asObservable();
  }

  isEmployeesAreLoading(): boolean {
    return this.employeesAreLoadingSubject$.value;
  }

  resetEmployees(): void {
    this.employeesSubject$.next([]);
    this.urlEmployeeParamsSubject$.next({skip: 0, limit: 20, query: '', isQueryChanged: false});
  }

  public signinCompany(data: CompanySigninEntity): Observable<any> {
    return this.http.post(`${this.apiUrl}/authentication`, data);
  }

  public signupCompany(data: CompanySignupEntity): Observable<{success?: boolean}> {
    return this.http.post(`${this.apiUrl}/companies/register`, data);
  }

  public uploadLogo(data: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/company-logo-upload`, data);
  }

  public removeLogo(companyId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/logo-companies/${companyId}/remove`, {});
  }

  public patchCompany(companyId: number, data: Partial<CompanySignupEntity>): Observable<any> {
    return this.http.post(`${this.apiUrl}/companies/${companyId}`, data);
  }

  public getCompany(companyId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/companies/${companyId}`);
  }

  public enableEmployee(id: Employee['id']): Observable<Employee> {
    return this.http.patch<Employee>(`${this.apiUrl}/employees/${ id }`, { enabled: true });
  }

  public disableEmployee(id: Employee['id']): Observable<Employee> {
    return this.http.patch<Employee>(`${this.apiUrl}/employees/${ id }`, { enabled: false });
  }

  updateEmployeeRequestParams(params: EmployeeRequestParams): void {
    const { query } = this.urlEmployeeParamsSubject$.value;

    this.urlEmployeeParamsSubject$.next({
      ...params,
      query: params.query ?? query,
    });
  }

  filterEmployeesById(id: number): void {
    const filteredEmployees = this.employeesSubject$.value.filter((employee) => employee.id !== id);

    this.employeesSubject$.next(filteredEmployees);
  }

  public employeeParamsChanges(): Observable<Response<Employee>> {
    return this.urlEmployeeParamsSubject$.asObservable()
      .pipe(
        switchMap(({ skip, limit, query }) => {
          this.employeesAreLoadingSubject$.next(true);

          return this.getEmployeesRequest(skip, limit, query, 'EMPLOYEE')
            .pipe(
              map((response) => {
                return {
                  ...response,
                  data: response.data.map((employee) => new Employee(employee)),
                };
              }),
              tap(({ data, total }) => {
                const employees = this.urlEmployeeParamsSubject$.value.isQueryChanged ? data : [
                  ...this.employeesSubject$.value,
                  ...this.getUniqueEmployees(data),
                ];

                this.totalEmployees = total;
                this.employeesSubject$.next(employees);
                this.employeesAreLoadingSubject$.next(false);
              }),
            );
        }),
      );
  }

  public resendInvite(id: Employee['id']): Observable<Employee> {
    return this.http.post<Employee>(`${this.apiUrl}/employees/${ id }/resend-invite`, {});
  }

  public createEmployees(emails: string[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/employees`, {emails});
  }

  public deleteEmployee(employeeId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/employees/${employeeId}`, {});
  }

  public getGroupStatictic(fromDate: Date, toDate: Date): Observable<any> {
    const startDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(toDate, 'yyyy-MM-dd');
    return this.http.get(`${this.apiUrl}/company-statistics?startDate=${startDate}&endDate=${endDate}`).pipe(
      map((response: {fitnessAgeDelta: any; statistics: any[]}) => {
        let res = response;

        if (!res.statistics.length) {
          // empty state
          res.statistics = [
            {
              activeUserCount: 0,
              averagePAI: 0,
              fitnessRatings: {
                VeryPoor: 0,
                Poor: 0,
                BelowAverage: 0,
                Average: 0,
                AboveAverage: 0,
                Good: 0,
                Excellent: 0
              },
              fitnessAgeDeltas: [
                {
                  diffBtwnActualAndFitnessAges: 0,
                  numberOfEmployees: 0
                },
                {
                  diffBtwnActualAndFitnessAges: 0,
                  numberOfEmployees: 0
                }
              ],
              paiScoreBands: {
                LowerThan30: 0,
                LowerThan50: 0,
                LowerThan75: 0,
                LowerThan100: 0,
                GreaterOrEqual100: 0
              },
              date: new Date().toString(),
            }
          ];
        }

        return res;
      })
    );
  }

  public getSummaryGroupStatictic(): Observable<PaiScoreGroupSummaryStatEntity> {
    return this.http.get(`${this.apiUrl}/summary-company-statistics`)
      .pipe(
        map((summStat: Object) => {
          return summStat ? new PaiScoreGroupSummaryStatEntity(summStat) : null;
        }),
      );
  }

  public uploadEmployees(file: File): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('csv', file);
    return this.http.post(`${this.apiUrl}/csv-employees`, formData);
  }

  public sendEmailToEmployee(emailObj): Observable<any> {
    return this.http.post(`${this.apiUrl}/employee-emails`, emailObj);
  }

  private getUniqueEmployees(employees: Employee[]): Employee[] {
    return employees
      .filter((responseEmployee) => !this.employeesSubject$.value.find((employee) => employee.id === responseEmployee.id));
  }

  private getEmployeesRequest(skip, limit, query?, role?): Observable<Response<Employee>> {
    const queryParam = query ? `&query=${query}` : '';
    const roleParam = role ? `&role=${role}` : '';

    return this.http.get<Response<Employee>>(`${this.apiUrl}/employees?$limit=${limit}&$skip=${skip}${queryParam}${roleParam}`);
  }
}
