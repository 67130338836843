<div class="location-header">
  <div class="title">
    {{
      (isTrainer ?
        'profile.location.putLocationOnMapAndRadiuses' :
        'profile.location.putLocationOnMap'
      ) | translate
    }}
  </div>
  <div class="descrition">
    {{'profile.location.putLocationOnMapDescription' | translate}}
  </div>

  <app-personal-trainer-location
    *ngIf="isTrainer">
  </app-personal-trainer-location>

  <app-activity-partner-location
    *ngIf="isPartner">
  </app-activity-partner-location>
</div>
