import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaiScoreGroupSummaryStatEntity } from '@models/company/pai-score-group-summary-stat.entity';

@Component({
  selector: 'app-pai-score-number-stat',
  templateUrl: './pai-score-number-stat.component.html',
  styleUrls: ['./pai-score-number-stat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaiScoreNumberStatComponent {

  @Input()
  public data: PaiScoreGroupSummaryStatEntity = null;

  constructor() { }

}
