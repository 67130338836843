// tslint:disable: max-line-length
import {BrowserModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeNb from '@angular/common/locales/nb';
import {DatePipe, registerLocaleData} from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ServiceWorkerModule} from '@angular/service-worker';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UiSwitchModule} from 'ngx-ui-switch';

// Custom modules
import {AppMaterialModule} from './modules/app-material/app-material.module';
import {AppRoutingModule} from './app-routing.module';

// Custom pipes
import {GraduationPipe} from './pipes/graduation.pipe';
import {GraduationPurePipe} from './pipes/graduation-pure.pipe';
import {FilterPipe} from './pipes/filter.pipe';

// Custom directives
import {CustomMaxDirective} from './directives/custom-max-validator.directive';
import {CustomMinDirective} from './directives/custom-min-validator.directive';
import {StorageDirective} from './directives/storage.directive';
import {ClickOutsideDirective} from './directives/click-outside.directive';
import {HighImageDirective} from './directives/high-image.directive';
import {HighCertificateDirective} from './directives/high-certificate.directive';
import {TextareaAutoheightDirective} from './directives/textarea-autoheight.directive';
import {PdfPreviewDirective} from './directives/pdf-preview.directive';

// Custom interceptors
import {AuthInterceptor} from './services/auth.interceptor';
import {GuardInterceptor} from './services/guard.interceptor';
import {ErrorInterceptor} from './services/error.interceptor';

// Custom services
import {DataService} from './services/data.service';
import {HttpService} from './services/http.service';
import {StorageService} from './services/storage.service';
import {FeathersService} from './services/feathers.service';

// Other
import {environment} from '../environments/environment';
import {AuthGuard} from './guards/auth.guard';

// Components
import {AppComponent} from './app.component';
import {LeftMenuComponent} from './components/left-menu/left-menu.component';
import {UserMenuComponent} from './components/left-menu/user-menu/user-menu.component';
import {HeaderComponent} from './components/header/header.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {RequestsComponent} from './components/requests/requests.component';
import {PlansComponent} from './components/plans/plans.component';
import {HistoryComponent} from './components/history/history.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {ConfirmationPopupComponent} from './components/confirmation-popup/confirmation-popup.component';
import {ImagePreviewComponent} from './components/image-preview/image-preview.component';
import {PaymentComponent} from './components/payment/payment.component';
import {IphoneAlikeCheckboxComponent} from './components/iphone-alike-checkbox/iphone-alike-checkbox.component';
import {SessionPreviewComponent} from './components/session-preview/session-preview.component';
import {
  SessionPreviewParticipantsComponent,
} from './components/session-preview/session-preview-participants/session-preview-participants.component';
import {LeaveMessageComponent} from './components/leave-message/leave-message.component';
import {
  CancelScheduledTrainingPopupComponent,
} from './components/session-preview/cancel-scheduled-training-popup/cancel-scheduled-training-popup.component';
import {PricingComponent} from './components/pricing/pricing.component';
import {TieUpStripeComponent} from './components/tie-up-stripe/tie-up-stripe.component';
import {BillingHistoryComponent} from './components/billing-history/billing-history.component';
import {BillingItemComponent} from './components/billing-history/billing-item/billing-item.component';
import {
  SessionPreviewParticipantsStatusContainerComponent,
} from './components/session-preview/session-preview-participants/session-preview-participants-status-container/session-preview-participants-status-container.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {EventViewComponent} from './components/sessions/event-view/event-view.component';
import {TrainingViewComponent} from './components/sessions/training-view/training-view.component';
import {GoogleMapComponent} from './components/google-map/google-map.component';
import {ConfirmationComponent} from './components/modals/confirmation/confirmation.component';
import {LanguageModalComponent} from './components/mobile/language-modal/language-modal.component';
import {ProfileTypeModalComponent} from './components/mobile/profile-type-modal/profile-type-modal.component';
import {ParticipantsComponent} from './components/sessions/event-view/participants/participants.component';
import {
  SystemCertificatesListComponent,
} from './components/trainer-profile/education/system-certificates-list/system-certificates-list.component';
import {
  UserCertificatesListComponent,
} from './components/trainer-profile/education/user-certificates-list/user-certificates-list.component';
import {UserEducationListComponent} from './components/trainer-profile/education/user-education-list/user-education-list.component';
import {UserExperienceListComponent} from './components/trainer-profile/education/user-experience-list/user-experience-list.component';

// Material Components
import {MaterialFabComponent} from './modules/material/material-fab/material-fab.component';
import {MaterialMenuComponent} from './modules/material/material-menu/material-menu.component';
import {MaterialMenuOptionComponent} from './modules/material/material-menu/material-menu-option/material-menu-option.component';
import {MaterialIconsComponent} from './modules/material/material-icons/material-icons.component';
import {MaterialActivityComponent} from './modules/material/material-activity/material-activity.component';
import {MaterialSelectImageComponent} from './modules/material/material-select-image/material-select-image.component';

// Trainer-rofile components
import {ChipsComponent} from './components/trainer-profile/chips/chips.component';
import {TrainerProfileComponent} from './components/trainer-profile/trainer-profile.component';
import {PersonalComponent} from './components/trainer-profile/personal/personal.component';
import {EducationComponent} from './components/trainer-profile/education/education.component';
import {ExpertiseComponent} from './components/trainer-profile/expertise/expertise.component';
import {
  EducationCertificateModalComponent,
} from './components/trainer-profile/education-certificate-modal/education-certificate-modal.component';
import {StarRatingComponent} from './components/trainer-profile/star-rating/star-rating.component';

// Calendar tab components
import {MonthViewComponent} from './components/calendar/month-view/month-view.component';
import {ScheduleModalComponent} from './components/calendar/schedule-modal/schedule-modal.component';
import {TimePickerComponent} from './components/calendar/schedule-modal/time-picker/time-picker.component';
import {DayViewComponent} from './components/calendar/day-view/day-view.component';
import {ScheduleComponent} from './components/calendar/schedule/schedule.component';

// Location tab components
import {LocationComponent} from './components/trainer-profile/location/location.component';
import {
  PersonalTrainerLocationComponent,
} from './components/trainer-profile/location/personal-trainer-location/personal-trainer-location.component';
import {
  ActivityPartnerLocationComponent,
} from './components/trainer-profile/location/activity-partner-location/activity-partner-location.component';
import {
  WorkzonesRadiusMapComponent,
} from './components/trainer-profile/location/personal-trainer-location/workzones-radius-map/workzones-radius-map.component';
import {
  ActivityPartnerMapComponent,
} from './components/trainer-profile/location/activity-partner-location/activity-partner-map/activity-partner-map.component';
import {MaterialSharedModule} from './modules/material/material-shared.module';
import {StepperMobileComponent} from './components/stepper-mobile/stepper-mobile.component';
import {HeaderTabsComponent} from './components/trainer-profile/header-tabs/header-tabs.component';
import {ComingSoonComponent} from './components/coming-soon/coming-soon.component';
import {StripeGuideComponent} from './components/stripe-guide/stripe-guide.component';
import { TrialPeriodComponent } from '@components/modals/trial-period/trial-period.component';
import {AnonGuard} from './guards/anon.guard';
import {AuthModule} from './modules/auth/auth.module';
import { SharedPagesModule } from './modules/shared-pages/shared-pages.module';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { getPreviousUrl, PREVIOUS_URL_TOKEN } from '@shared/tokens';
import { CompanyModule } from '@modules/company/company.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { chatFeatureKey, chatReducer } from '@modules/chats/store/chat.reducer';
import { ChatEffects } from '@modules/chats/store/chat.effects';

registerLocaleData(localeNb, 'nb-nn');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const providers = {
  google: {
    clientIdDev: '216418715926-t2n64he691em3ajrvqo59k5f17k4jte0.apps.googleusercontent.com',
    clientId: '216418715926-t2n64he691em3ajrvqo59k5f17k4jte0.apps.googleusercontent.com',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LeftMenuComponent,
    UserMenuComponent,
    HeaderComponent,
    CalendarComponent,
    RequestsComponent,
    PlansComponent,
    HistoryComponent,
    StarRatingComponent,
    GraduationPipe,
    CustomMaxDirective,
    CustomMinDirective,
    MonthViewComponent,
    GraduationPurePipe,
    StorageDirective,
    ClickOutsideDirective,
    ImagePreviewComponent,
    HighImageDirective,
    HighCertificateDirective,
    PaymentComponent,
    PaginationComponent,
    FilterPipe,
    ConfirmationPopupComponent,
    SessionPreviewComponent,
    IphoneAlikeCheckboxComponent,
    SessionPreviewParticipantsComponent,
    LeaveMessageComponent,
    CancelScheduledTrainingPopupComponent,
    PricingComponent,
    TieUpStripeComponent,
    BillingHistoryComponent,
    BillingItemComponent,
    SessionPreviewParticipantsStatusContainerComponent,
    StepperComponent,
    TrainerProfileComponent,
    PersonalComponent,
    EducationComponent,
    ExpertiseComponent,
    EducationCertificateModalComponent,
    ScheduleModalComponent,
    TimePickerComponent,
    EventViewComponent,
    TrainingViewComponent,
    GoogleMapComponent,
    ConfirmationComponent,
    MaterialFabComponent,
    ChipsComponent,
    MaterialMenuComponent,
    MaterialMenuOptionComponent,
    MaterialIconsComponent,
    LanguageModalComponent,
    ProfileTypeModalComponent,
    DayViewComponent,
    MaterialActivityComponent,
    // MaterialActivityHeaderComponent,
    ScheduleComponent,
    ParticipantsComponent,
    MaterialSelectImageComponent,
    WorkzonesRadiusMapComponent,
    LocationComponent,
    TextareaAutoheightDirective,
    SystemCertificatesListComponent,
    UserCertificatesListComponent,
    PdfPreviewDirective,
    UserEducationListComponent,
    UserExperienceListComponent,
    PersonalTrainerLocationComponent,
    ActivityPartnerLocationComponent,
    ActivityPartnerMapComponent,
    StepperMobileComponent,
    HeaderTabsComponent,
    ComingSoonComponent,
    StripeGuideComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AuthModule,
    CompanyModule,
    SocialLoginModule,
    HttpClientModule,
    AppMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    UiSwitchModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ScrollingModule,
    MaterialSharedModule,
    SharedPagesModule,
    TrialPeriodComponent,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot(),
    StoreModule.forFeature(chatFeatureKey, chatReducer),
    EffectsModule.forFeature([
      ChatEffects,
    ]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GuardInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: PREVIOUS_URL_TOKEN,
      useFactory: getPreviousUrl,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(providers.google.clientId, {
              scopes: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'],
              oneTapEnabled: false,
            }),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    FeathersService,
    DataService,
    HttpService,
    StorageService,
    AuthGuard,
    AnonGuard,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
