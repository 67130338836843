<h3 class="title">
  {{ 'company.totalSummary' | translate | uppercase }}
</h3>

<div class="numbers">
  <div class="numbers-item">
    <span class="numbers-item__value green">
      {{ data.totalAcceptedEmployees}}
    </span>
    <span class="numbers-item__description">
      {{ 'company.totalParticipants' | translate  }}
    </span>
  </div>

  <div class="numbers-item">
    <span class="numbers-item__value blue">
      {{ data.totalActiveEmployees }}
    </span>
    <span class="numbers-item__description">
      {{ 'company.totalEmployees' | translate }}
    </span>
  </div>

  <div class="numbers-item">
    <span class="numbers-item__value">
      {{ data.employeesRate }}%
    </span>
    <span class="numbers-item__description">
      {{ 'company.employeesRate' | translate }}
    </span>
  </div>

  <div class="numbers-item">
    <span class="numbers-item__value">
      {{ data.averagePai }}
    </span>
    <span class="numbers-item__description">
      {{ 'company.averagePai' | translate }}
    </span>
  </div>
</div>
