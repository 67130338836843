import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, filter, map, takeUntil, tap} from 'rxjs/operators';

import {ProfileService} from '../../../../services/profile.service';
import {TrainingLocationService} from '../../../../services/training-location.service';
import {UserEntity} from '@models/user/user.entity';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-activity-partner-location',
  templateUrl: './activity-partner-location.component.html',
  styleUrls: ['./activity-partner-location.component.scss'],
})
export class ActivityPartnerLocationComponent implements OnInit, OnDestroy {

  destroy$ = new Subject();
  locationFormGroup: UntypedFormGroup;

  private subs: Subscription;
  private locationFormGroupData: any;

  constructor(
    private profileService: ProfileService,
    private trainingLocationService: TrainingLocationService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.formInit();
    this.locationsInit();
    this.subs.add(
      this.profileService.cancelEditProfileEvent.subscribe(() => {
        this.locationsInit();
      }),
    );
    this.subs.add(
      this.profileService.saveProfileEvent.subscribe(() => {
        this.saveLocation(this.locationFormGroupData);
      }),
    );
  }

  ngOnDestroy() {
    this.destroy$.complete();
  }

  /**
   * Init form for location
   */
  formInit() {
    this.locationFormGroup = new UntypedFormGroup({
      profile_id: new UntypedFormControl(null, [
        Validators.required,
      ]),
      coordinates: new UntypedFormGroup({
        lat: new UntypedFormControl(null, [
          Validators.required,
        ]),
        lng: new UntypedFormControl(null, [
          Validators.required,
        ]),
      }),
    });

    this.formListener();
  }

  /**
   * Set user location
   */
  locationsInit() {
    this.profileService.trainer
      .pipe(
        tap((trainer: ProfileEntity) => {
          this.locationFormGroup.get('profile_id').setValue(trainer.id, {
            emitEvent: false,
          });

          // disable form if under_consideration
          if (trainer.under_consideration) {
            this.locationFormGroup.disable({
              emitEvent: false,
            });
          } else {
            this.locationFormGroup.enable({
              emitEvent: false,
            });
          }
        }),
        filter((trainer: ProfileEntity) => {
          return trainer.training_location;
        }),
        map((trainer: ProfileEntity) => {
          return trainer.training_location[0];
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((trainingLocation) => {
        const { coordinates } = trainingLocation;

        this.locationFormGroup.patchValue({
          coordinates,
        }, {
          emitEvent: false,
        });
      });
  }

  /**
   * Listen radius form
   */
  formListener() {
    this.locationFormGroup.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => {
          let isValid = this.locationFormGroup.valid;
          if (!isValid) {
            this.profileService.changeFormInvalidStatus(true);
          }
          return isValid;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        this.profileService.changeFormInvalidStatus(false);
        this.locationFormGroupData = result;
      });
  }

  /**
   * Save location setting
   * @param locationData
   */
  saveLocation(locationData: any) {
    if (!locationData || !this.locationFormGroup.valid) {return;}
    const preparedData = {
      profile_id: locationData.profile_id,
      coordinates: locationData.coordinates,
    };

    this.trainingLocationService.update(preparedData)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((result: UserEntity) => {
        this.profileService.setUser(result);
      });
  }

}
