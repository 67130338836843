import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LegalService } from '@services/legal.service';
import { AGREEMENT_PAGE_TYPE } from '@util/enum';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-term-and-policy',
  templateUrl: './term-and-policy.component.html',
  styleUrls: ['./term-and-policy.component.scss'],
})
export class TermAndPolicyComponent implements OnInit {
  html: any;

  constructor(
    private legalService: LegalService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(take(1))
      .subscribe((res) => {
        if (res.type === AGREEMENT_PAGE_TYPE.PRIVATE_POLICY) {
          this.getPrivatePolicy();
        }
        if (res.type === AGREEMENT_PAGE_TYPE.COMPANY_AGREEMENT) {
          this.getCompanyAgreement();
        }
      });
  }

  getPrivatePolicy(): void {
    this.legalService.getPrivatePolicy()
      .pipe(take(1))
      .subscribe(html => {
        this.html = html;
      });
  }

  getCompanyAgreement(): void {
    this.legalService.getCompanyAgreement()
      .pipe(take(1))
      .subscribe(html => {
        this.html = html;
      });
  }

}
