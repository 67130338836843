import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input('itemsQuantity') items;
  @Input('startPage') selectedPage;
  @Input() itemsPerPage;
  @Output() requestParamsBubbling: EventEmitter<{ start: number; quantity: number }> = new EventEmitter<{ start: number; quantity: number }>();

  public pages: any[];
  public shiftValue: number;
  public lastPage: number;
  public moveValue: number;

  constructor() { }

  ngOnInit() {
    this.shiftValue = 0;
    this.moveValue = 0;
    this.constructPages();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.constructPages();
    if (changes.startPage && changes.startPage.currentValue === 0) {
      this.changePage(0);
    }
  }

  shiftPages(backward: boolean) {
    if (backward) {
      if (this.shiftValue !== 0) {
        this.shiftValue--;
      }
    } else {
      if ((this.lastPage - this.shiftValue) > 5) {
        this.shiftValue++;
      }
    }
  }

  changePage(pageIndex: number) {
    if (pageIndex !== this.selectedPage) {
      this.selectedPage = pageIndex;
      this.requestParamsBubbling.emit({
        start: pageIndex,
        quantity: this.itemsPerPage,
      });
      if (this.pages.length > 5) {
        if (pageIndex === 0) {
          this.shiftValue = 0;
        } else if (pageIndex === this.lastPage - 1) {
          this.shiftValue = this.lastPage - 5;
        }
      }
    }
  }

  constructPages() {
    this.pages = [];
    this.lastPage = Math.ceil(this.items / this.itemsPerPage);
    for (let i = 0; i < this.lastPage; i++) {
      const item = {
        index: i,
        value: i + 1,
      };
      this.pages.push(item);
    }
    if (this.pages.length && this.pages.length < 5) {
      this.moveValue = (5 - this.pages.length);
    } else {
      this.moveValue = 0;
    }
  }
}
