import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, filter, finalize, map, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

import {ProfileService} from '../../../../services/profile.service';
import {Course} from '../../../../models/certificate';
import {EducationCertificateModalComponent} from '../../education-certificate-modal/education-certificate-modal.component';
import {CertificatesService} from '../../../../services/certificates.service';
import {ClientMessageService} from '../../../../services/client-message.service';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-user-certificates-list',
  templateUrl: './user-certificates-list.component.html',
  styleUrls: ['./user-certificates-list.component.scss'],
})
export class UserCertificatesListComponent implements OnInit, OnDestroy {
  public MAX_CERTIFICATES_NUMBER: number = 10;

  trainer: ProfileEntity;
  cetrificates: Course[] = [];
  certificatesFormArray: UntypedFormArray;
  formName = 'certificates';
  maxlength = 100;

  public canEditProfile: boolean;
  private destroy$ = new Subject<void>();
  private subs: Subscription;

  constructor(
    private profileService: ProfileService,
    private certificateService: CertificatesService,
    private clientMessageService: ClientMessageService,
  ) {
    this.canEditProfile = true;
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.subs.add(
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      }),
    );
    this.getCertificates();
    this.formInit(this.cetrificates);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Get all sertificates
   * Filtered them by system-setrificate-id
   */
  getCertificates() {
    this.profileService.trainer
      .pipe(
        tap((trainer: ProfileEntity) => {
          this.trainer = trainer;
        }),
        map((trainer: ProfileEntity) => {
          return trainer.certificates;
        }),
        map((certificates: Course[]) => {
          return certificates.filter((certificate: any) => {
            return !Number.isInteger(certificate.system_certificate_id);
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result: Course[]) => {
        this.cetrificates = result.reverse();
      });
  }

  /**
   * Generate form from certificates list
   */
  formInit(cetrificates: Course[]) {
    const cetrificatesControls = cetrificates.map((certificate: Course) => {
      return new UntypedFormGroup({
        id: new UntypedFormControl(certificate.id, [Validators.required]),
        description: new UntypedFormControl(certificate.description, [
          Validators.maxLength(this.maxlength),
        ]),
        title: new UntypedFormControl(certificate.title, [
          Validators.required,
          Validators.maxLength(this.maxlength),
        ]),
        file_link: new UntypedFormControl(certificate.file_link),
        file: new UntypedFormControl(''),
      });
    });

    this.certificatesFormArray = new UntypedFormArray(cetrificatesControls);

    this.certificatesFormArray.controls.forEach((control: UntypedFormGroup) => {
      this.listenCertificateChanges(control);
    });

    // if there are no any certificates we need add one
    if (this.certificatesFormArray.length < 1) {
      this.addCertificate();
    }
  }

  /**
   * Get certificate ID value
   */
  getCertificateIdValue(certificate: UntypedFormGroup) {
    return certificate.get('id').value;
  }

  /**
   * Get certificate title control
   */
  getCertificateTitleControl(certificate: UntypedFormGroup) {
    return certificate.get('title');
  }

  /**
   * Get certificate description
   */
  getCertificateDescriptionControl(certificate: UntypedFormGroup) {
    return certificate.get('description');
  }

  /**
   * Get certificate image value
   */
  getCertificateImageControlValue(certificate: UntypedFormGroup) {
    return certificate.get('file_link').value;
  }

  /**
   * Get certificate image url
   */
  getCertificateImageUrl(certificate: UntypedFormGroup) {
    const url = certificate.get('file_link').value;
    return url ? `url(${url})` : '';
  }

  /**
   * Get certificate image control
   */
  getCertificateImageControl(certificate: UntypedFormGroup) {
    return certificate.get('file_link');
  }

  /**
   * Add certificate
   */
  addCertificate() {
    const newCertificateGroup = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      description: new UntypedFormControl('', [
        Validators.maxLength(this.maxlength),
      ]),
      title: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(this.maxlength),
      ]),
      file_link: new UntypedFormControl('', [Validators.required]),
      file: new UntypedFormControl(''),
    });

    this.certificatesFormArray.push(newCertificateGroup);

    // add listener
    this.listenCertificateChanges(newCertificateGroup);
  }

  /**
   * Add listener to certificate
   */
  listenCertificateChanges(certificateGroup: UntypedFormGroup) {
    certificateGroup.valueChanges
      .pipe(
        tap(() => {
          this.certificateService.markFormGroupTouched(certificateGroup);
        }),
        takeWhile(() => {
          // trick for unsubscribe from this FormGroup by takeWhile
          return !certificateGroup.contains('remove');
        }),
        filter(() => {
          return certificateGroup.valid;
        }),
        debounceTime(500),
        takeUntil(this.destroy$),
      )
      .subscribe((result: any) => {
        if (Number.isInteger(result.id)) {
          this.updateCertificate(result, certificateGroup);
        } else {
          this.createCertificate(result, certificateGroup);
        }
      });
  }

  /**
   * Update certificate data
   */
  updateCertificate(certificateData, certificateGroup: UntypedFormGroup) {
    const formData = this.getFormData(certificateData);

    this.certificateService.update(this.formName, formData, certificateData.id)
      .pipe(
        finalize(() => {
          certificateGroup.enable({
            emitEvent: false,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        // show message
        // this.clientMessageService.showMessage('profile.messages.certificateUpdated');

        // save new certificates list
        const extractedData = this.extractData(this.certificatesFormArray);
        this.profileService.setCertificates(extractedData, 'course');
      }, (error) => {
        this.handleErrorResponse(error);
      });
  }

  /**
   * Update certificate data
   */
  createCertificate(certificateData, certificateGroup: UntypedFormGroup) {
    const formData = this.getFormData(certificateData);

    this.certificateService.create(this.formName, formData)
      .pipe(
        finalize(() => {
          certificateGroup.enable({
            emitEvent: false,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result: Course) => {
        // set id for certificateGroup
        certificateGroup.patchValue({
          id: result.id,
        }, {
          emitEvent: false,
        });

        // show message
        // this.clientMessageService.showMessage('profile.messages.certificateAdded');

        // save new certificates list
        const extractedData = this.extractData(this.certificatesFormArray);
        this.profileService.setCertificates(extractedData, 'course');
      }, (error) => {
        this.handleErrorResponse(error);
      });
  }

  /**
   * Remove sertificate
   */
  removeCertificate(index: number, certificateGroup: UntypedFormGroup) {
    const id = this.getCertificateIdValue(certificateGroup);

    // trick for unsubscribe from this FormGroup by takeWhile
    certificateGroup.setControl('remove', new UntypedFormControl());

    if (id === null) {
      this.removeCertificateByIndex(index);
    } else {
      this.removeCertificateById(certificateGroup, id, index);
    }
  }

  /**
   * Remove sertificate by index from client
   */
  removeCertificateByIndex(index) {
    this.certificatesFormArray.removeAt(index);

    // if there are no any certificates we need add one
    if (this.certificatesFormArray.length < 1) {
      this.addCertificate();
    }
  }

  /**
   * Remove sertificate by ID from server
   * if successfull then remove on client
   */
  removeCertificateById(certificateGroup: UntypedFormGroup, id, index) {
    certificateGroup.disable({
      emitEvent: false,
    });

    this.certificateService.remove(this.formName, id)
      .pipe(
        finalize(() => {
          certificateGroup.enable({
            emitEvent: false,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.certificatesFormArray.removeAt(index);

        // show message
        // this.clientMessageService.showMessage('profile.messages.certificateRemoved');

        // save new certificates list
        const extractedData = this.extractData(this.certificatesFormArray);
        this.profileService.setCertificates(extractedData, 'course');

        // if there are no any certificates we need add one
        if (this.certificatesFormArray.length < 1) {
          this.addCertificate();
        }
      }, (error) => {
        this.handleErrorResponse(error);
      });
  }

  /**
   * Get FormData for certificate
   */
  getFormData(certificate): FormData {
    const { id, file, description, title } = certificate;
    const formData = new FormData();

    if (!Number.isInteger(id)) {
      formData.append('profile_id', this.trainer.id.toString());
    }

    if (file) {
      formData.append('file_link', file);
    }

    if (title) {
      formData.append('title', title);
    }

    formData.append('description', description);

    return formData;
  }

  /**
   * Open modal for view/change certificate
   */
  openCertificateModal(imageInput, certificateGroup: UntypedFormGroup): void {
    if (!this.canEditProfile) {
      this.certificateService.openCertificateModal(imageInput, certificateGroup, EducationCertificateModalComponent);
    }
  }

  /**
   * Emit when image field was changed
   */
  imageUpload(event, certificate: UntypedFormGroup): void {
    this.certificateService.imageUpload(event, certificate);
  }

  /**
   * Handle http errors
   */
  handleErrorResponse(errorResponse: HttpErrorResponse) {
    const { data, message, code } = errorResponse.error;

    console.error(errorResponse.error);
  }

  /**
   * Extract data for save it to global user
   */
  extractData(formArray: UntypedFormArray): Course[] {
    const certificates: Course[] = formArray.controls
      .map((formGroup: UntypedFormGroup): Course => {
        return {
          id: formGroup.get('id').value,
          file_link: formGroup.get('file_link').value || '',
          title: formGroup.get('title').value || '',
          description: formGroup.get('description').value || '',
        };
      });

    return certificates;
  }

}
