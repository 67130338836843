import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {HttpService} from './http.service';
import {FeathersService} from './feathers.service';
import {StorageService} from './storage.service';
import {PricingService} from './pricing.service';
import {CalendarService} from './calendar.service';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from './profile.service';
import {LANGUAGE} from '@util/enum';

@Injectable()
export class DataService {

  spinner = new Subject();
  userNotification = new Subject();
  trainerSchedule = new Subject();
  trainerBookings = new Subject();
  trainerLevel = new Subject();
  user = new Subject();
  language = new Subject();
  // Trainer bookings with additional params
  trainerSpecifiedBookings = new Subject();

  // Trainer pricing subjects

  trainingDurations = new Subject();
  allTrainingTypes = new Subject();
  trainerPriceList = new Subject();

  // Subjects for trainings quantity
  requestsQuantity = new Subject();
  upcomingQuantity = new Subject();
  archivedQuantity = new Subject();

  // Subject for friends logic

  approvedFriends = new BehaviorSubject([]);

  get _approvedFriends() {
    return this.approvedFriends.asObservable();
  }

  // Subject for a billing

  billings = new Subject();

  constructor(
    private http: HttpService,
    private feathers: FeathersService,
    private storageService: StorageService,
    private pricingService: PricingService,
    private calendarService: CalendarService,
    private translate: TranslateService,
    private profileService: ProfileService,
  ) {
    this.calendarService.legacySchedule.subscribe(legacySchedule => {
      this.trainerSchedule.next(legacySchedule);
    });
  }

  setUser(user) {
    this.user.next(user);
  }

  getUpdatedUser(userId) {
    this.http.getData('/users/' + userId).subscribe(
      res => {
        this.setUser(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  showSpinner(show: boolean) {
    this.spinner.next(show);
  }

  getAdmin() {
    return this.user.asObservable();
  }

  getUser() {
    const user = this.storageService.storage.getItem('user');
    this.user.next(JSON.parse(user));
  }

  showNotification(message: string, action?: string, durationVal?: number, additionalClass?: string) {
    if (message === 'close') {
      this.userNotification.next('close');
    } else {
      this.userNotification.next({
        notifyMessage: message,
        notifyAction: action,
        notifyDuration: durationVal,
        class: additionalClass,
      });
    }
  }

  //  Scheduling

  getTrainerSchedule(trainerId) {
    this.http.getData('/trainers-schedule?&profile_id=' + trainerId).subscribe(
      res => this.trainerSchedule.next(res),
      err => {
        console.log(err);
        this.showNotification(err, null, 3000, 'error');
      },
    );
  }

  //  Bookings

  getTrainerBookings() {
    const query = {
      profileType: 'trainer',
      getFrom: 'trainings',
      training_status: {
        $in: ['UPCOMING', 'STARTED', 'FINISHED'],
      },
      $limit: 1000,
    };
    this.feathers.service('calendar-of-trainings').find({ query })
      .then(
        res => {
          this.trainerBookings.next(res);
          this.calendarService._bookings.next(res);
        },
        err => {
          console.log(err);
          this.showNotification(err, null, 3000, 'error');
        },
      );
  }

  getTrainerBookingsWithParams(limit: number, trainingTypes: string[], skip: number = 0) {

    const query: any = {
      profileType: 'trainer',
      // today: false,
      getFrom: 'trainings',
      training_status: {
        $in: trainingTypes,
      },
      $limit: limit,
    };
    // query.$skip = skip || 0;
    this.feathers.service('calendar-of-trainings').find({ query })
      .then(
        res => {
          this.getTrainingsQuantity(true);
          this.trainerSpecifiedBookings.next(res);
        },
        err => {
          console.log(err);

        },
      );
  }

  deleteTrainerBooking(id) {
    return this.http.deleteData('/booking/' + id + '?type=trainer');
  }

  saveTrainerBookings(bookings: any[]) {
    this.trainerBookings.next(bookings);
  }

  getCurrentLanguage() {
    let language = this.storageService.storage.getItem('language');
    language = language ? language : LANGUAGE.NO;
    this.setLanguage(language);
    this.translate.use(language);
    return language;
  }

  setLanguage(language) {
    this.storageService.storage.setItem('language', language);
    this.language.next(language);
  }

  setLanguageReminderFlag() {
    this.storageService.storage.setItem('language-reminder', true);
  }

  getLanguageReminderFlag() {
    let languageReminderFlag = !!this.storageService.storage.getItem('language-reminder');
    return languageReminderFlag;
  }

  getTrainingsQuantity(spinnerIsShowing: boolean = false) {
    const trainingTypesDivided = [
      // This is for getting quantity of unanswered requests
      {
        subject: this.requestsQuantity,
        trainingTypes: ['UNCONFIRMED'],
      },
      // This is for getting quantity of confirmed trainings
      {
        subject: this.upcomingQuantity,
        trainingTypes: ['UPCOMING', 'STARTED'],
      },
      // This is for getting quantity of confirmed trainings
      {
        subject: this.archivedQuantity,
        trainingTypes: ['CANCELED_BY_TRAINER', 'CANCELED_SOS', 'NOT_ENOUGH_MEMBERS', 'EXPIRED', 'FINISHED'],
      },
    ];
    trainingTypesDivided.forEach((trainingType, index) => {
      const query = {
        profileType: 'trainer',
        // today: false,
        getFrom: 'trainings',
        training_status: {
          $in: trainingType.trainingTypes,
        },
        $limit: 0,
      };

      this.feathers.service('calendar-of-trainings').find({ query })
        .then(
          res => {
            trainingType.subject.next(res.total);

            if (spinnerIsShowing && index === trainingTypesDivided.length - 1) {

            }
          },
          err => console.log(err),
        );
    });
  }

  getLevel(profileId: number) {

    this.feathers.service('profiles').get(profileId)
      .then(
        (res: any) => {

          res.trainer_level && this.trainerLevel.next(res.trainer_level);
        },
        err => {
          console.log(err);
        },
      );
  }

  getTrainingDurations(levelId: number) {

    const query = {
      level_id: levelId,
    };

    this.feathers.service('types-of-training-durations').find({ query })
      .then(
        res => {
          this.trainingDurations.next(res.data);

        },
        err => {
          console.log(err);
        },
      );
  }

  getTrainingDurationsData(levelId: number) {
    const query = {
      level_id: levelId,
    };

    return this.feathers.service('types-of-training-durations').find({ query });
  }

  getAllTrainingTypes() {
    this.feathers.service('types-of-training-sessions').find()
      .then(
        res => {
          this.allTrainingTypes.next(res.data);

        },
        err => {

          console.log(err);
        });
  }

  getAllTrainingTypesData() {
    return this.feathers.service('types-of-training-sessions').find();
  }

  getTrainerPriceList(profileId: number) {
    const query = {
      profile_id: profileId,
    };
    this.feathers.service('trainer-price-list').find({ query })
      .then(
        res => {
          this.trainerPriceList.next(res.data);

        },
        err => {
          console.log(err);
        });
  }

  getTrainerPriceListData(profileId: number) {
    const query = {
      profile_id: profileId,
    };

    return this.feathers.service('trainer-price-list').find({ query });
  }

  patchPriceList(profileId, priceList) {
    const payload = {
      profile_id: profileId,
      price_list: priceList,
    };
    return this.pricingService.savePrices(payload).subscribe(
      res => {
        this.trainerPriceList.next(priceList);
      },
      err => {
        this.showNotification('Error happened!', null, 2000, 'error');
        console.log(err);
      },
    );
  }

  getApprovedFriends() {
    this.feathers.service('/friendships?type=friends&$limit=30&$skip=0&profileTypes[]=1,2').find()
      .then(
        res => {
          this.approvedFriends.next(res);
        },
        err => {
          console.log(err);
          this.showNotification('Could not get friends', null, 2000, 'error');
        });
  }

  getBillingHistory(seekFrom?: any, seekTill?: any, limit?: number, skip?: number) {

    const queryItem: any = {
      query: {
        $limit: limit || 1000,
        $sort: {
          created_at: -1,
        },
      },
    };
    if (seekFrom && seekTill) {
      queryItem.query.created_at = {
        $lte: seekTill,
        $gte: seekFrom,
      };
    }
    if (skip) {
      queryItem.query.$skip = skip;
    }
    this.feathers.service('/users-charges').find(queryItem)
      .then(
        res => {
          this.billings.next(res);
        },
        err => {

        },
      );
  }

  getAllBillings(seekFrom?: any, seekTill?: any) {
    const queryItem: any = {
      query: {
        $limit: 1000,
        $sort: {
          created_at: -1,
        },
      },
    };
    if (seekFrom && seekTill) {
      queryItem.query.created_at = {
        $lte: seekTill,
        $gte: seekFrom,
      };
    }
    return this.feathers.service('/users-charges').find(queryItem);
  }


  public getExcel(): void {
    this.http.getBlob(`/users-charges/export?as=${this.profileService.profileType}`).subscribe((response: Blob) => {
      const downloadFile = window.document.createElement('a');
      downloadFile.href = window.URL.createObjectURL(new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      downloadFile.download = 'Billing history.xlsx';
      document.body.appendChild(downloadFile);
      downloadFile.click();
      document.body.removeChild(downloadFile);
    }, error => {
      console.log(error);
    });

  }
}
