<div class="location-screen">

<!--   <div class="location-stepper">
    <div class="location-stepper__step" [ngClass]="{active: locationFormGroup.valid}" >
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
          <rect width="16" height="16" x="1" y="1" stroke-width="2" rx="8"></rect>
          <path fill-rule="evenodd"
                d="M12.805 6.203c.26.27.26.708 0 .979L8.36 11.797a.65.65 0 0 1-.943 0L5.195 9.49a.711.711 0 0 1 0-.98.65.65 0 0 1 .943 0l1.75 1.819 3.974-4.126a.65.65 0 0 1 .943 0z"
                clip-rule="evenodd"></path>
        </svg>
      </div>
      <div class="content">
        <div class="title">{{ 'profile.location.step' | translate }} 1</div>
        <div class="description">{{ 'profile.location.putLocationOnMap' | translate }}</div>
      </div>
    </div>
  </div> -->
  
  <div class="location-map">

    <app-activity-partner-map
      [locationForm]="locationFormGroup"
    ></app-activity-partner-map>
  </div>
</div>