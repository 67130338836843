import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {CompanySignInComponent} from './sign-in/company-sign-in.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {StepOneComponent} from './sign-up/step-one/step-one.component';
import {StepThreeComponent} from './sign-up/step-three/step-three.component';
import {StepTwoComponent} from './sign-up/step-two/step-two.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialSharedModule} from '../material/material-shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {LoginComponent} from './login/login.component';
import {RestorePasswordComponent} from './restore-password/restore-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import { SocialLoginModule } from '@abacritt/angularx-social-login';


@NgModule({
  declarations: [
    LoginComponent,
    SignUpComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    CompanySignInComponent,
    RestorePasswordComponent,
    ChangePasswordComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialSharedModule,
        TranslateModule,
        RouterModule,
        SocialLoginModule,
    ],
})
export class AuthModule { }
