<section class="actions" [ngClass]="{'mobile-content': isMobile}">
  <div class="modal-actions" *ngIf="isConfirmed || isRequest">
    <button (click)="confirm()"
            class="confirm_btn"
            *ngIf="isRequest">
      <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.99998 7.78002L1.21998 5.00002L0.273315 5.94002L3.99998 9.66668L12 1.66668L11.06 0.726685L3.99998 7.78002Z" fill="white"/>
      </svg>
      <span>
        {{ 'common.confirm' | translate}}
      </span>
    </button>
    <button (click)="reject()"
            class="cancel_btn"
            *ngIf="isConfirmed || isRequest">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.66683 1.27325L8.72683 0.333252L5.00016 4.05992L1.2735 0.333252L0.333496 1.27325L4.06016 4.99992L0.333496 8.72659L1.2735 9.66659L5.00016 5.93992L8.72683 9.66659L9.66683 8.72659L5.94016 4.99992L9.66683 1.27325Z" fill="#F11C48"/>
      </svg>
      <span>
        {{ (isRequest ? 'common.reject' : 'requests.cancelEvent') | translate}}
      </span>
    </button>
  </div>
</section>

<section class="tabs">
  <div class="tab"
       (click)="changeTab(TABS.INFO)"
       [ngClass]="{'tab--active': activeTab === TABS.INFO}">
    {{'common.info' | translate}}
  </div>
  <div class="tab"
       (click)="changeTab(TABS.PARTICIPANTS)"
       [ngClass]="{'tab--active': activeTab === TABS.PARTICIPANTS}">
    {{'events.participants' | translate}}
  </div>
</section>

<div class="modal" [ngClass]="{'modal--actions': isConfirmed || isRequest}">
  <div class="modal-body" *ngIf="activeTab === TABS.INFO">
<!--     <section class="modal-body-section">
      <div class="modal-body-section-image"
           [ngClass]="{'modal-body-section-image--no-image': !session?.photo}"
           [style.backgroundImage]="'url(' + (session?.photo || '/assets/img/default_background.png') + ')'">
        <div class="modal-body-section-image-gradient">

        </div>
        <div class="modal-body-section-image-title">
          {{session?.name}}
        </div>
      </div>
    </section> -->

    <section class="modal-body-section">
      <div class="modal-body-section-title">
        {{'events.description' | translate}}
      </div>
      <div class="modal-body-section-cell-title description">
        {{'events.description' | translate}}
      </div>
      <div class="modal-body-section-description">
        {{session?.description}}
      </div>
      <div class="modal-body-section-checkbox"
           [ngClass]="{'modal-body-section-checkbox--checked': session?.is_public}">
        <app-material-checkbox
          [readonly]="true"
          [checked]="session.is_public"
          [label]="'events.publicEvent' | translate">
        </app-material-checkbox>
      </div>
    </section>

    <section class="modal-body-section">
      <div class="modal-body-section-title">
        {{'requests.sessionDetails' | translate}}
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.date' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.date | date:'d MMMM yyyy':0:translate.currentLang }}
        </span>
      </div>

      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.time' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.date | date:'HH:mm':0:translate.currentLang }}
        </span>
      </div>

      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.duration' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.duration }}
        </span>
      </div>

      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.eventType' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.type }}
        </span>
      </div>

      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.goal' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.goal }}
        </span>
      </div>

      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.level' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.level }}
        </span>
      </div>
    </section>

    <section class="modal-body-section" >
      <div class="modal-body-section-title">
        {{'events.routeInfo' | translate}}
      </div>
      <ng-container *ngIf="session?.enum_type === SESSION.HIKING_ACTIVITY">
        <div class="modal-body-section-cell modal-body-section-cell--2-col">
          <div class="modal-body-section-cell-title">
            {{'events.distance' | translate}}
          </div>
          <span class="modal-body-section-cell-text">
          {{session?.distance}} km
        </span>
        </div>
        <div class="modal-body-section-cell">
          <div class="modal-body-section-cell-title">
            {{ 'events.startPoint' | translate}}
          </div>
          <span class="modal-body-section-cell-text">
          {{session?.starting_point}}
        </span>
        </div>
        <div class="modal-body-section-cell">
          <div class="modal-body-section-cell-title">
            {{ 'events.endPoint' | translate}}
          </div>
          <span class="modal-body-section-cell-text">
          {{session?.ending_point}}
        </span>
        </div>
      </ng-container>
      <div class="modal-body-section-cell modal-body-section-cell--2-col"
           *ngIf="session?.enum_type !== SESSION.HIKING_ACTIVITY">
        <div class="modal-body-section-cell-title">
          {{'events.eventLocation' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{session?.location_name}}
        </span>
      </div>
    </section>

    <section class="modal-body-section">
      <div class="modal-body-section-title">
        {{'events.participantsInfo' | translate}}
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{ 'events.participantsMin' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{session?.min_participants}}
        </span>
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{ 'events.participantsMax' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{session?.max_participants}}
        </span>
      </div>
    </section>

    <section class="modal-body-section">
      <div class="modal-body-section-title">
        {{'events.total' | translate}}
      </div>
      <div class="modal-body-section-cell modal-body-section-cell--2-col">
        <div class="modal-body-section-cell-title">
          {{'events.price' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{session?.price}}
        </span>
      </div>
      <div class="modal-body-section-checkbox">
        <app-material-checkbox [checked]="session.price"
                               [readonly]="true"
                               [label]="'events.paidEvent' | translate"></app-material-checkbox>
      </div>
    </section>
  </div>
  <div class="modal-body modal-body--safari-fix" *ngIf="activeTab === TABS.PARTICIPANTS">
    <app-participants [session]="session"></app-participants>
  </div>
</div>
