<div class="login sign-in">
  <div class="login-wrapper">
    <div class="login-wrapper-header">
      <div class="logo">
        <img src="/assets/logo.svg" alt="MIA logo" class="login-wrapper-logo">
      </div>
    </div>

    <div class="login-wrapper-form">
      <h3 class="login-wrapper-form-name">
        {{'company.companySignIn' | translate}}
      </h3>

      <form [formGroup]="form" *ngIf="form">
        <app-material-input formControlName="email"
                            type="email"
                            autofocus
                            [label]="'profile.personal.email' | translate"
                            [errors]="{
                                  required: 'common.errors.required' | translate,
                                  pattern: 'common.errors.emailInvalid' | translate
                                }">

        </app-material-input>
        <app-material-input formControlName="password"
                            type="password"
                            [label]="'common.password' | translate"
                            [errors]="{
                                  required: 'common.errors.required' | translate,
                                  pattern: 'common.errors.passwordPattern' | translate
                                }">

        </app-material-input>
      </form>

      <a (click)="navigateToRestorePassword()" class="login-wrapper-restore">
        <span>{{'login.forgot' | translate}} {{'common.password' | translate}}</span>
      </a>

      <div class="buttons">
        <app-button
          color="primary"
          [disabled]="form.invalid"
          (click)="submitForm()"
        >
          {{'common.signIn' | translate}}
        </app-button>

        <app-button
          color="secondary"
          routerLink="/sign-up"
        >
          {{'signUp.registration' | translate}}
        </app-button>
      </div>
    </div>

    <div class="splash"></div>
  </div>
</div>

