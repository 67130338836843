import {Component, ElementRef, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Subscription} from 'rxjs';

import {CalendarService} from '../../../services/calendar.service';
import {SessionList} from '../../../models/session';
import {ENUM} from '../../../utils/enum';
import {fadeInOut} from '../../../modules/material/material-animations/fade-in-out';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss'],
  animations: [fadeInOut],
})
export class DayViewComponent extends ENUM implements OnInit {
  @Input() date: Moment;
  public hours: Moment[];
  public sessions: SessionList[];
  public hideView = false;

  // hours * minutes
  private segments = 24 * 60;
  // hours * px
  private totalHeight = 24 * 56;
  private subs: Subscription;
  constructor(
    private calendarService: CalendarService,
    private el: ElementRef,
    public translate: TranslateService,
  ) {
    super();
    this.hours = new Array(24).fill(undefined).map((hour, index) => {
      return moment().hour(index).minute(0).second(0);
    });

  }

  ngOnInit() {
    this.subs = new Subscription();
    const [startOfDay, endOfDay] = [
      this.date.clone().startOf('day').toISOString(),
      this.date.clone().endOf('day').toISOString(),
    ];
    this.calendarService.getCalendar([startOfDay, endOfDay], true).subscribe(calendar => {
      this.sessions = calendar;
    });

    this.subs.add(
      this.calendarService.activities.subscribe( activities => {
        this.hideView = !!(activities.session || activities.event);
      }),
    );
  }

  /**
   * Converts date/duration into pixels
   * @param date ISO date string
   * @param duration number of minutes
   */
  public calculatePixels(date: string, duration?: number): number {
    let dateStart = moment(date);
    let dateEnd = dateStart.clone().add(duration, 'minutes');
    const startOfDay = this.date.clone().startOf('day');

    // this part calculates height of the element
    if (duration) {
      if (dateEnd.dayOfYear() > this.date.dayOfYear()) {
        dateEnd = dateStart.clone().endOf('day');
        duration = dateEnd.diff(dateStart) / (1000 * 60);
      } else if (dateEnd.dayOfYear() > dateStart.dayOfYear()) {
        duration = dateEnd.diff(startOfDay) / (1000 * 60);
      }
      return (duration / this.segments) * 100;
    }

    // this part calculates position of the element
    if (this.date.dayOfYear() > dateStart.dayOfYear()) {
      dateStart = startOfDay;
    }

    const hours = dateStart.get('hours');
    const minutes = dateStart.get('minutes');
    return ((hours * 60 + minutes) / this.segments) * 100;
  }

  openSession(session: SessionList): void {
    switch (session.type) {
      case this.SESSION.TRAINING_TYPE: {
        this.calendarService.activity('session', session).open();
        break;
      }
      case this.SESSION.EVENT_TYPE: {
        this.calendarService.activity('event', session).open();
        break;
      }
      default: {
        return;
      }
    }
  }
}
