<div class="page_container billing page">
  <div class="page_header">
    <a class="hidden_link" #hiddenLinkForCSV href=""></a>
  </div>
  <div class="component_window page_card" [class.with_pagination]="billingsQuantity > itemsToShow">
    <app-material-activity-header class="activity-header" [ngClass]="{'mobile-activity-header': isMobile}">
      <div class="aditional-controls-wrapper">
        <span class="back-text">
          {{'common.history' | translate}}
        </span>

        <button class="export export--no-icon mobile-leyout-btn" (click)="exportExcel()">
          <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66665 4H6.99998V0H2.99998V4H0.333313L4.99998 8.66667L9.66665 4ZM0.333313 10V11.3333H9.66665V10H0.333313Z" fill="white"/>
          </svg>
          <span>
            {{ "billingHistory.export" | translate }}.xlsx
          </span>
        </button>
      </div>

      <div class="controls_wrapper">
        <div class="datepicker_wrapper">
          <div class="datepicker-container">
            <div class="datepicker start"
                 [owlDateTimeTrigger]="searchStart">
              {{searchStartValueFormatted || "common.from" | translate}}
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3333 2.00001H11.6667V0.666672H10.3333V2.00001H3.66668V0.666672H2.33334V2.00001H1.66668C0.933344 2.00001 0.333344 2.6 0.333344 3.33334V14C0.333344 14.7333 0.933344 15.3333 1.66668 15.3333H12.3333C13.0667 15.3333 13.6667 14.7333 13.6667 14V3.33334C13.6667 2.6 13.0667 2.00001 12.3333 2.00001ZM12.3333 14H1.66668V5.33334H12.3333V14Z" fill="#606873"/>
              </svg>
            </div>
            <input
              class="hidden_input"
              #startSearch
              [min]="fromMinSearchDate"
              [max]="fromMaxSearchDate"
              [ngModel]="searchStartValue"
              [owlDateTime]="searchStart"
              placeholder="Date Time">
            <owl-date-time
              (afterPickerClosed)="selectDate(searchStart, true)"
              [startAt]="startStartDate"
              [pickerType]="'calendar'"
              #searchStart>
            </owl-date-time>
          </div>
          <div class="divider">&#45;</div>
          <div class="datepicker-container">
            <div class="datepicker finish"
                 [owlDateTimeTrigger]="searchFinish">
              {{searchFinishValueFormatted || "common.to" | translate}}
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3333 2.00001H11.6667V0.666672H10.3333V2.00001H3.66668V0.666672H2.33334V2.00001H1.66668C0.933344 2.00001 0.333344 2.6 0.333344 3.33334V14C0.333344 14.7333 0.933344 15.3333 1.66668 15.3333H12.3333C13.0667 15.3333 13.6667 14.7333 13.6667 14V3.33334C13.6667 2.6 13.0667 2.00001 12.3333 2.00001ZM12.3333 14H1.66668V5.33334H12.3333V14Z" fill="#606873"/>
              </svg>
            </div>
            <input
              class="hidden_input"
              #startSearch
              [min]="toMinSearchDate"
              [max]="toMaxSearchDate"
              [ngModel]="searchFinishValue"
              [owlDateTime]="searchFinish"
              placeholder="Date Time">
            <owl-date-time
              (afterPickerClosed)="selectDate(searchFinish, false)"
              [startAt]="startFinishDate"
              [pickerType]="'calendar'"
              #searchFinish>
            </owl-date-time>
          </div>
          <button [disabled]="!searchStartValue && !searchFinishValue"
                  class="cancel_selection"
                  (click)="clearDatePickers()">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.66665 1.27333L8.72665 0.333328L4.99998 4.06L1.27331 0.333328L0.333313 1.27333L4.05998 5L0.333313 8.72666L1.27331 9.66666L4.99998 5.93999L8.72665 9.66666L9.66665 8.72666L5.93998 5L9.66665 1.27333Z" fill="#E8ECF3"/>
            </svg>
          </button>
        </div>

        <button class="export export--no-icon desktop-leyout-btn" (click)="exportExcel()">
          <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66665 4H6.99998V0H2.99998V4H0.333313L4.99998 8.66667L9.66665 4ZM0.333313 10V11.3333H9.66665V10H0.333313Z" fill="white"/>
          </svg>
          <span>
            {{ "billingHistory.export" | translate }}.xlsx
          </span>
        </button>
      </div>
    </app-material-activity-header>
   <!--  <header class="card_header">
      <div class="tabs">
        <div class="tab tab--active">
          {{'common.history' | translate}}
        </div>
      </div>
      <button class="export export--no-icon" (click)="exportExcel()">
        {{ "billingHistory.export" | translate }} .xlsx
      </button>
    </header> -->
    <div class="table_wrapper">
      <header>
        <div class="table_head_cell">
          {{ 'billingHistory.clientName' | translate }}
        </div>
        <div class="table_head_cell">
          {{ 'requests.date' | translate }}
        </div>
        <div class="table_head_cell">
          {{ 'requests.duration' | translate }}
        </div>
        <div class="table_head_cell">
          {{ 'billingHistory.trainingStatus' | translate }}
        </div>
        <div class="table_head_cell">
          {{ 'billingHistory.price' | translate }}
        </div>
        <div class="table_head_cell">
          {{ 'billingHistory.trainerProfit' | translate }}
        </div>
      </header>
      <div class="page_content">
        <div class="table_body" *ngIf="billings && billings.length">
          <app-billing-item *ngFor="let bill of billings"
                            [bill]="bill">
          </app-billing-item>
        </div>
        <div class="table_body empty_state" *ngIf="billings && !billings.length">
          <div class="empty_notification">
            {{ 'billingHistory.emptyNotification' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-pagination *ngIf="billingsQuantity > itemsToShow"
                  [startPage]="pageSelected"
                  [itemsQuantity]="billingsQuantity"
                  [itemsPerPage]="itemsToShow"
                  (requestParamsBubbling)="getNewData($event)">
  </app-pagination>
</div>
