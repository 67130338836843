import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialSharedModule} from '@modules/material/material-shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {AppMaterialModule} from '../app-material/app-material.module';
import {PaiScoreComponent} from './pai-score/pai-score.component';
import {PaiScoreHeaderComponent} from './pai-score/pai-score-header/pai-score-header.component';
import {PaiScoreNumberStatComponent} from './pai-score/pai-score-number-stat/pai-score-number-stat.component';
import {PaiScoreChartComponent} from './pai-score/pai-score-chart/pai-score-chart.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ProfileComponent} from './profile/profile.component';
import {GeneralComponent} from './profile/general/general.component';
import {ReactiveFormsModule} from '@angular/forms';
import {LogoComponent} from './profile/logo/logo.component';
import {EmployeesComponent} from './employees/employees.component';
import {AddEmployeeModalComponent} from './employees/add-employee-modal/add-employee-modal.component';
import {EventsAndGroupsComponent} from './events-and-groups/events-and-groups.component';
import {SendEmailModalComponent} from './employees/send-email-modal/send-email-modal.component';
import {UploadCsvModalComponent} from './employees/upload-csv-modal/upload-csv-modal.component';
import { PaiScoreDeltaChartComponent } from './pai-score/pai-score-delta-chart/pai-score-delta-chart.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { IsNumberPipe } from '@shared/pipes';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ProfileComponent,
    PaiScoreComponent,
    PaiScoreHeaderComponent,
    PaiScoreNumberStatComponent,
    PaiScoreChartComponent,
    GeneralComponent,
    LogoComponent,
    EventsAndGroupsComponent,
    EmployeesComponent,
    AddEmployeeModalComponent,
    SendEmailModalComponent,
    UploadCsvModalComponent,
    PaiScoreDeltaChartComponent,
  ],
  imports: [
    CommonModule,
    MaterialSharedModule,
    TranslateModule,
    AppMaterialModule,
    NgxChartsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    IsNumberPipe,
    RouterModule,
  ],
  exports: [
    ProfileComponent,
    EmployeesComponent,
    PaiScoreComponent,
    EventsAndGroupsComponent,
  ],
})
export class CompanyModule { }
