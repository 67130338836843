import {animate, style, transition, trigger} from '@angular/animations';

export const listAnimation = trigger(
  'listAnimation',
  [
    transition(
      ':enter', [
        style({transform: 'scale(0.9)', opacity: 0, maxHeight: 0, overflow: 'hidden'}),
        animate('150ms', style({transform: 'scale(1)', opacity: 1, maxHeight: '30vh', overflow: 'hidden'})),
      ],
    ),
    transition(
      ':leave', [
        style({transform: 'scale(1)', opacity: 1, maxHeight: '30vh', overflow: 'hidden'}),
        animate('150ms', style({transform: 'scale(0.9)', opacity: 0, maxHeight: 0, overflow: 'hidden'})),
      ],
    )],
);

export const listAnimationUp = trigger(
  'listAnimationUp',
  [
    transition(
      ':enter', [
        style({transform: 'scale(0.9)', opacity: 0, overflow: 'hidden'}),
        animate('150ms', style({transform: 'scale(1)', opacity: 1, overflow: 'hidden'})),
      ],
    ),
    transition(
      ':leave', [
        style({transform: 'scale(1)', opacity: 1, overflow: 'hidden'}),
        animate('150ms', style({transform: 'scale(0.9)', opacity: 0, overflow: 'hidden'})),
      ],
    )],
);
