import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
// @ts-ignore
import {GestureHandlingOptions, LatLng} from 'googlemaps';

import {TrainingLocationService} from '../../../../../services/training-location.service';
import {ProfileService} from '../../../../../services/profile.service';

@Component({
  selector: 'app-workzones-radius-map',
  templateUrl: './workzones-radius-map.component.html',
  styleUrls: ['./workzones-radius-map.component.scss'],
})
export class WorkzonesRadiusMapComponent implements OnInit, OnDestroy {

  destroy$ = new Subject();
  marker: any;
  radiusCircles: any[] = [];

  @Input() radiusesForm: UntypedFormGroup;

  @ViewChild('map', { static: true }) googleMap: ElementRef;

  get coordinatesGroup() {
    return <UntypedFormGroup>this.radiusesForm.get('coordinates');
  }

  get coordinates() {
    return <{lat: number; lng: number}> this.radiusesForm.get('coordinates').value;
  }

  map: any;
  mapOptions: any;
  public canEditProfile: boolean;
  public isPosExist: boolean;

  // Default settings for map component.
  private defaultPosition = {
    lat: 59.912147,
    lng: 10.753112,
  };

  private defaultMapOptions = {
    zoom: 7,
    disableDoubleClickZoom: true,
    mapTypeControl: false,
    panControl: false,
    streetViewControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    center: this.defaultPosition,
    gestureHandling: 'cooperative' as GestureHandlingOptions,
  };

  private circleOptions = {
    strokeColor: '#f32052',
    strokeOpacity: 0.7,
    strokeWeight: 1,
    fillColor: '#f32052',
    fillOpacity: 0.05,
    clickable: false,
  };
  private subs: Subscription;

  constructor(
    private profileService: ProfileService,
    private trainingLocationService: TrainingLocationService,
  ) {
    this.canEditProfile = true;
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.lisenRadiusChange();
    this.mapInit();
    this.subs.add(
      this.profileService.saveProfileEvent.subscribe(() => {
        this.isPosExist = !!(this.coordinates.lat && this.coordinates.lng);
      }),
    );
    this.subs.add(
      this.profileService.cancelEditProfileEvent.subscribe(() => {
        this.resetMarker();
      }),
    );
    this.subs.add(
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.destroy$.complete();
  }

  /**
   * Create map, set options and listeners
   */
  mapInit() {
    // Initialization of map component.
    this.map = new google.maps.Map(this.googleMap.nativeElement, this.defaultMapOptions);

    // init events
    google.maps.event.addListener(this.map, 'click', (event) => this.replaceMarker(event.latLng));

    // if pos exist
    this.isPosExist = !!(this.coordinates.lat && this.coordinates.lng);
    if (this.coordinates.lat && this.coordinates.lng) {
      const position = new google.maps.LatLng(this.coordinates.lat, this.coordinates.lng);

      this.initMarker(position);
      // change default center to user point
      this.map.setCenter(position);
    }

    this.addLocationButton();
  }

  resetMarker() {
    if (this.isPosExist) {
      const position = new google.maps.LatLng(this.coordinates.lat, this.coordinates.lng);
      this.replaceMarker(position);
    }
    else {
      this.removeMarker();
    }
  }

  /**
   * Add location button on map
   */
  addLocationButton() {
    const button = this.trainingLocationService.createLocationButton();

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(button);

    button.addEventListener('click', () => {
      this.getGeolocation();
    });
  }

  /** *
   * Lisen when radiuses change
   */
  lisenRadiusChange() {
    this.radiusesForm.valueChanges
      .pipe(
        debounceTime(100),
        filter(() => {
          return this.radiusesForm.valid && this.marker;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        this.updateRadiusCircles();
      });
  }

  /**
   * Add marker on map
   * @param position
   */
  initMarker(position: LatLng) {
    this.marker = new google.maps.Marker({
      position,
      map: this.map,
      draggable: true,
    });

    this.marker.addListener('dragend', (event) => this.replaceMarker(event.latLng));

    this.setRadiusCircles(position);
  }

  /**
   * Remove marker from map
   */
  removeMarker() {
    this.clearRadiusesCircles();
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  /**
   * MapEvent: Change position of marker
   * If marker was not created before - init new marker
   * @param event
   */
  replaceMarker(position: LatLng) {
    if (this.marker) {
      this.marker.setPosition(position);
    } else {
      this.initMarker(position);
    }

    this.map.setCenter(position);

    this.saveMarkerPosition(position);
  }

  /**
   * Patch coordinates into form
   * @param position
   */
  saveMarkerPosition(position: LatLng) {
    this.coordinatesGroup.patchValue({
      lat: position.lat(),
      lng: position.lng(),
    });
  }

  /**
   *
   * @param center
   */
  setRadiusCircles(center: LatLng) {
    const radiuses = this.trainingLocationService.deltaToRadius(this.radiusesForm.value);

    radiuses.forEach((item) => {
      const circle = new google.maps.Circle({
        ...this.circleOptions,
        center,
        map: this.map,
        radius: item.radius,
      });

      this.radiusCircles.push(circle);
    });
  }

  /**
   * Update radiuses
   */
  updateRadiusCircles() {
    this.clearRadiusesCircles();

    this.setRadiusCircles(this.marker.position);
  }

  /**
   * Clear all circles
   */
  clearRadiusesCircles() {
    this.radiusCircles.forEach((circle) => {
      circle.setMap(null);
    });

    this.radiusCircles = [];
  }

  /**
   * getGeolocation
   */
  getGeolocation() {
    this.trainingLocationService.getGeolocation((position: LatLng) => {
      if (position) {
        this.replaceMarker(position);
      }
    });
  }

}
