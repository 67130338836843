import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';

import {CalendarService} from '@services/calendar.service';
import {ProfileService} from '@services/profile.service';
import {MobileService} from '@services/mobile.service';

import {ENUM} from '@util/enum';
import {fadeInOut} from '@modules/material/material-animations/fade-in-out';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [fadeInOut],
})
export class CalendarComponent extends ENUM implements OnInit, OnDestroy {

  public currentMonth: Moment;
  public prevMonth: Moment;
  public nextMonth: Moment;
  public selectedDay: Moment;
  public selectedTimeRange: any;
  public selectedSession: any;
  public selectedEvent: any;
  public restTimeForm: UntypedFormGroup;
  public showSettings: boolean;

  @ViewChild('restTime', { static: false }) restTimeInput;
  @ViewChild('card', { static: false }) card;
  @ViewChild('currentMonthEl', { static: false }) currentMonthEl: ElementRef;
  @ViewChild('prevMonthEl', { static: false }) prevMonthEl: ElementRef;
  @ViewChild('nextMonthEl', { static: false }) nextMonthEl: ElementRef;

  private subs: Subscription;

  constructor(
    public calendarService: CalendarService,
    public profileService: ProfileService,
    public mobile: MobileService,
    public translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs = new Subscription();
    this.currentMonth = moment();
    this.prevMonth = this.currentMonth.clone().add('month', -1);
    this.nextMonth = this.currentMonth.clone().add('month', 1);
    this.calendarService.getCalendar().subscribe();
    this.subs.add(
      this.calendarService.activities.subscribe(activities => {
        this.selectedTimeRange = activities.schedule;
        this.selectedDay = activities.day;
        this.selectedSession = activities.session;
        this.selectedEvent = activities.event;
      }),
    );

    this.restTimeForm = new UntypedFormGroup({
      rest_time_minutes: new UntypedFormControl(0, [
        Validators.required,
        Validators.max(this.SESSION.REST_TIME_MINUTES_MAX),
        Validators.min(this.SESSION.REST_TIME_MINUTES_MIN),
        Validators.pattern(/^[0-9]+$/),
      ]),
    });

    this.subs.add(
      this.restTimeForm.get('rest_time_minutes').valueChanges.pipe(
        filter(() => this.restTimeForm.get('rest_time_minutes').valid),
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe( value => {
        this.profileService.patchProfile({rest_time_minutes: +value}).subscribe();
      }),
    );

    this.subs.add(
      this.profileService.trainer.subscribe(trainer => {
        this.restTimeForm.get('rest_time_minutes').patchValue(trainer.rest_time_minutes);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.calendarService.activity('event').closeAll();
  }

  /**
   * Switch between months
   * @param increment
   */
  public switchMonth(increment: number) {
    this.prevMonthEl.nativeElement.style.cssText = 'transform: translateX(-100%)';
    this.currentMonthEl.nativeElement.style.cssText = 'transform: translateX(0)';
    this.nextMonthEl.nativeElement.style.cssText = 'transform: translateX(100%)';
    this.currentMonth = this.currentMonth.clone().add('month', increment);
    this.prevMonth = this.currentMonth.clone().add('month', -1);
    this.nextMonth = this.currentMonth.clone().add('month', 1);
  }

  /**
   * Toggle single day view
   */
  toggleDayView(): void {
    this.calendarService.activity('day').close();
  }

  closeSchedule(): void {
    this.calendarService.activity('schedule').close();
  }

  openSchedule(): void {
    this.calendarService.activity('schedule').open();
  }

  closeSession(): void {
    this.calendarService.activity('session').close();
    this.calendarService.activity('event').close();
  }

  toggleSettings() {
    this.showSettings = !this.showSettings;
    if (this.showSettings) {
      setTimeout(() => {
        this.restTimeInput.inputEl.nativeElement.focus();
      }, 0);
    }
  }
}
