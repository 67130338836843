import {Component, Inject} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface DataInterface {
  id?: number;
  bodyTxt: string;
  headerTxt: string;
  actionTxt: string;
  cancelTxt?: string;
  checkboxTxt?: string;
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  checkbox: UntypedFormControl = new UntypedFormControl(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataInterface,
    private dialogRef: MatDialogRef<ConfirmationComponent>,
  ) { }

  close(confirm: boolean = false): void {
    const res: any = {};

    if (!confirm) {
      this.dialogRef.close(confirm);
      return;
    }
    else {
      if (this.data.id !== undefined) {
        res['id'] = this.data.id;
      }
      if (this.checkbox.value) {
        res['checkbox'] = this.checkbox.value;
      }
    }

    this.dialogRef.close(res);
  }
}
