import { Injectable } from '@angular/core';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';
import { ChatItem } from '../interfaces';
import { ConfirmationComponent } from '../../../components/modals/confirmation/confirmation.component';
import {
  chatById,
  isChatDeleteSuccess,
  isChatMemberDeleteSuccess,
  isChatMembersAddedSuccess,
} from '../store/chat.selectors';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { addChatMembers, deleteChat, removeChatMember } from '../store/chat.actions';
import { ChatEmployee } from '../interfaces/chat-employee.interface';
import { RestApiService } from '@modules/chats/services/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly activeChatItemSubject$ = new BehaviorSubject<ChatItem | null>(null);

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private store: Store,
    private restApiService: RestApiService,
  ) { }

  get activeChatItem(): ChatItem {
    return this.activeChatItemSubject$.value;
  }

  setActiveChatItem(chatItem: ChatItem): void {
    this.activeChatItemSubject$.next(chatItem);
  }

  listenChatId(): Observable<number> {
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
        startWith(this.router.url),
        map((currentUrl) => +currentUrl.split('/').reverse()[0]),
      );
  }

  deleteChat(chatId: ChatItem['chatId']): Observable<boolean> {
    return this.openConfirmDeletingChatDialog()
      .pipe(
        filter(Boolean),
        tap(() => this.store.dispatch(deleteChat({ chatId }))),
        switchMap(() => this.store.select(isChatDeleteSuccess)),
        filter(Boolean),
      );
  }

  removeChatMember(chatId: ChatItem['chatId'], externalId: ChatEmployee['externalId']): Observable<unknown> {
    return this.openConfirmDeletingChatMemberDialog()
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => this.store.dispatch(removeChatMember({ chatId, externalId }))),
        switchMap(() => this.store.select(isChatMemberDeleteSuccess).pipe(filter(Boolean))),
        take(1),
      );
  }

  addChatMembers(chatId: ChatItem['chatId'], userIds: Array<ChatEmployee['externalId']>): Observable<unknown> {
    return this.store.select(chatById(chatId))
      .pipe(
        filter(Boolean),
        switchMap((chatItem) => {
          const groupChat = chatItem.options?.chatName || 'No Name';

          return this.openConfirmAddingChatMemberDialog(groupChat);
        }),
        filter(Boolean),
        tap(() => this.store.dispatch(addChatMembers({ chatId, userIds }))),
        switchMap(() => this.store.select(isChatMembersAddedSuccess).pipe(filter(Boolean))),
        filter(Boolean),
      );
  }

  getFileUrl(file: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('chat_file', file);

    return this.restApiService.uploadImage(formData)
      .pipe(
        map(({ url }) => url),
      );
  }

  private openConfirmDeletingChatDialog(): Observable<boolean> {
    return this.dialog.open(ConfirmationComponent, {
      data: {
        headerTxt: this.translate.instant('chat.delete_chat_modal.header'),
        bodyTxt: this.translate.instant('chat.delete_chat_modal.body'),
        actionTxt: this.translate.instant('chat.delete_chat_modal.confirm'),
      },
      panelClass: 'confirmation-delete-dialog',
    })
      .afterClosed()
      .pipe(
        map((data) => !!data),
      );
  }

  private openConfirmDeletingChatMemberDialog(): Observable<boolean> {
    return this.dialog.open(ConfirmationComponent, {
      data: {
        headerTxt: this.translate.instant('chat.delete_member_modal.header'),
        bodyTxt: this.translate.instant('chat.delete_member_modal.body'),
        actionTxt: this.translate.instant('chat.delete_member_modal.confirm'),
      },
      panelClass: 'confirmation-delete-dialog',
    })
      .afterClosed()
      .pipe(
        map((data) => !!data),
      );
  }

  private openConfirmAddingChatMemberDialog(groupName: string): Observable<boolean> {
    return this.dialog.open(ConfirmationComponent, {
      data: {
        headerTxt: this.translate.instant('chat.add_members_modal.header'),
        bodyTxt: this.translate.instant('chat.add_members_modal.body', { groupName }),
        actionTxt: this.translate.instant('chat.add_members_modal.confirm'),
      },
      panelClass: 'confirmation-delete-dialog',
    })
      .afterClosed()
      .pipe(
        map((data) => !!data),
      );
  }
}
