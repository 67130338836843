import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { WrapPaiScoreGroupStatEntity } from '@models/company/wrap-pai-score-group-stat.entity';
import { PaiScoreGroupStatEntity } from '@models/company/pai-score-group-stat.entity';
import { PaiScoreGroupSummaryStatEntity } from '@models/company/pai-score-group-summary-stat.entity';
import { ChartLineEntity, ChartSeriesEntity } from '@models/shared/chart-line.entitty';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '@services/company.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaiScoreAgeDeltaStatEntity } from '@models/company/pai-score-age-delta-stat.entity';

@Component({
  selector: 'app-pai-score',
  templateUrl: './pai-score.component.html',
  styleUrls: ['./pai-score.component.scss'],
})
export class PaiScoreComponent implements OnInit, OnDestroy {

  public currentFromDate: Date;
  public currentToDate: Date;

  public summaryStat: PaiScoreGroupSummaryStatEntity;
  public averageScoreChart: ChartLineEntity<number>[];
  public activeEmployeesChart: ChartLineEntity<number>[];
  public usersInBandsChart: ChartLineEntity<number>[];
  public fitnessAgeRatingChart: ChartLineEntity<number>[];
  public deltaAgeChart: ChartLineEntity<number>[];
  public deltaAgeTicks: number[];


  private statData: WrapPaiScoreGroupStatEntity;
  private destroy$ = new Subject<void>();

  constructor(
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private translate: TranslateService,
  ) { }

  public get firstDelta(): number {
    if (this.statData) {
      return this.statData.fitnessAgeDelta.statisticForFirstDay ?
        this.statData.fitnessAgeDelta.statisticForFirstDay.fitnessAgeDeltas.expectedValue : null;
    }
  }
  public get secondDelta(): number {
    if (this.statData) {
      return this.statData.fitnessAgeDelta.statisticForLastDay ?
        this.statData.fitnessAgeDelta.statisticForLastDay.fitnessAgeDeltas.expectedValue : null;
    }
  }

  ngOnInit(): void {
    this.setDefaultDate();
    this.getSummaryStatistic();
    this.getChartsStatistic();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFromDate(date: Date): void {
    this.currentFromDate = date;
    this.getChartsStatistic();
  }

  public setToDate(date: Date): void {
    this.currentToDate = date;
    this.getChartsStatistic();
  }

  public resetDate(): void {
    this.setDefaultDate();
    this.getChartsStatistic();
  }

  getSummaryStatistic(): void {
    this.companyService.getSummaryGroupStatictic()
      .pipe(takeUntil(this.destroy$))
      .subscribe(summStat => {
        this.summaryStat = summStat;
      });
  }

  getChartsStatistic(): void {
    this.companyService.getGroupStatictic(this.currentFromDate, this.currentToDate)
      .pipe(takeUntil(this.destroy$))
      .subscribe((statData: WrapPaiScoreGroupStatEntity) => {
        this.statData = statData;
        this.fillAverageScoreChart(this.statData.statistics);
        this.fillActiveEmployeesChart(this.statData.statistics);
        this.fillUsersInBandsChart(this.statData.statistics);
        this.fillFitnessAgeRatingChart(this.statData.statistics);
        this.fillDeltaAgeChart(this.statData.fitnessAgeDelta);
      });
  }

  fillAverageScoreChart(data: PaiScoreGroupStatEntity[]): void {
    const series: ChartSeriesEntity<number>[] = [];

    data.forEach((elem: PaiScoreGroupStatEntity) => {
      const day = new Date(elem.date);
      series.push(new ChartSeriesEntity<number>(this.datePipe.transform(day, 'dd MMM'), elem.averagePAI));
    });

    this.averageScoreChart = [
      new ChartLineEntity<number>(
        this.translate.instant('company.averagePaiScore'),
        series,
      ),
    ];
  }

  fillActiveEmployeesChart(data: PaiScoreGroupStatEntity[]): void {
    const series: ChartSeriesEntity<number>[] = [];

    data.forEach((elem: PaiScoreGroupStatEntity) => {
      const day = new Date(elem.date);
      series.push(new ChartSeriesEntity<number>(this.datePipe.transform(day, 'dd MMM'), elem.activeUserCount));
    });

    this.activeEmployeesChart = [
      new ChartLineEntity<number>(
        this.translate.instant('company.activeEmployees'),
        series,
      ),
    ];
  }

  fillUsersInBandsChart(data: PaiScoreGroupStatEntity[]): void {
    const lowerThan30Series: ChartSeriesEntity<number>[] = [];
    const lowerThan50Series: ChartSeriesEntity<number>[] = [];
    const lowerThan75Series: ChartSeriesEntity<number>[] = [];
    const lowerThan100Series: ChartSeriesEntity<number>[] = [];
    const greaterOrEqual100Series: ChartSeriesEntity<number>[] = [];

    data.forEach((elem: PaiScoreGroupStatEntity) => {
      const day = new Date(elem.date);
      lowerThan30Series.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.paiScoreBands.LowerThan30,
        ),
      );
      lowerThan50Series.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.paiScoreBands.LowerThan50,
        ),
      );
      lowerThan75Series.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.paiScoreBands.LowerThan75,
        ),
      );
      lowerThan100Series.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.paiScoreBands.LowerThan100,
        ),
      );
      greaterOrEqual100Series.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.paiScoreBands.GreaterOrEqual100,
        ),
      );
    });

    this.usersInBandsChart = [
      new ChartLineEntity<number>(
        this.translate.instant('company.greaterOrEqual100'),
        greaterOrEqual100Series,
      ),
      new ChartLineEntity<number>(
        this.translate.instant('company.lowerThan100'),
        lowerThan100Series,
      ),
      new ChartLineEntity<number>(
        this.translate.instant('company.lowerThan75'),
        lowerThan75Series,
      ),
      new ChartLineEntity<number>(
        this.translate.instant('company.lowerThan50'),
        lowerThan50Series,
      ),
      new ChartLineEntity<number>(
        this.translate.instant('company.lowerThan30'),
        lowerThan30Series,
      ),
    ];
  }

  fillFitnessAgeRatingChart(data: PaiScoreGroupStatEntity[]): void {
    const belowAverageSeries: ChartSeriesEntity<number>[] = [];
    const averageSeries: ChartSeriesEntity<number>[] = [];
    const aboveAverageSeries: ChartSeriesEntity<number>[] = [];
    const goodSeries: ChartSeriesEntity<number>[] = [];
    const excellentSeries: ChartSeriesEntity<number>[] = [];

    data.forEach((elem: PaiScoreGroupStatEntity) => {
      const day = new Date(elem.date);
      belowAverageSeries.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.fitnessRatings.BelowAverage + elem.fitnessRatings.Poor + elem.fitnessRatings.VeryPoor,
        ),
      );
      averageSeries.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.fitnessRatings.Average,
        ),
      );
      aboveAverageSeries.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.fitnessRatings.AboveAverage,
        ),
      );
      goodSeries.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.fitnessRatings.Good,
        ),
      );
      excellentSeries.push(
        new ChartSeriesEntity<number>(
          this.datePipe.transform(day, 'dd MMM'), elem.fitnessRatings.Excellent,
        ),
      );
    });

    this.fitnessAgeRatingChart = [
      new ChartLineEntity<number>(
        this.translate.instant('company.excellent'),
        excellentSeries,
      ),
      new ChartLineEntity<number>(
        this.translate.instant('company.good'),
        goodSeries,
      ),
      new ChartLineEntity<number>(
        this.translate.instant('company.aboveAverage'),
        aboveAverageSeries,
      ),
      new ChartLineEntity<number>(
        this.translate.instant('company.average'),
        averageSeries,
      ),
      new ChartLineEntity<number>(
        this.translate.instant('company.belowAverage'),
        belowAverageSeries,
      ),
    ];
  }

  fillDeltaAgeChart(data: PaiScoreAgeDeltaStatEntity): void {
    const deltaRange = data.maxDelta - data.minDelta;
    const chartData: ChartLineEntity<number>[] = [];
    const deltaTicks = [];
    let minDelta = data.minDelta - Math.floor(Math.abs(deltaRange) * 0.3);
    let maxDelta = data.maxDelta + Math.ceil(Math.abs(deltaRange) * 0.3);

    minDelta = data.minDelta - minDelta < 3 ? data.minDelta - 3 : minDelta;
    maxDelta = maxDelta - data.maxDelta < 3 ? data.maxDelta + 3 : maxDelta;

    deltaTicks.push(minDelta);

    if (data.statisticForFirstDay) {
      const sigma = data.statisticForFirstDay.fitnessAgeDeltas.stdev;
      if (!sigma) {
        return;
      }
      const u = data.statisticForFirstDay.fitnessAgeDeltas.expectedValue;
      const series: ChartSeriesEntity<number>[] = [];

      for (let i = minDelta; i <= maxDelta; i = i + 0.1) {
        series.push(new ChartSeriesEntity<number>(Number(i), this.getGaussDot(i, sigma, u)));
      }

      if (series.length) {
        chartData.push(new ChartLineEntity<number>(
          this.datePipe.transform(new Date(data.statisticForFirstDay.date), 'dd MMM yyyy'),
          series,
        ));
        deltaTicks.push(u);
      }
    }

    if (data.statisticForLastDay) {
      const sigma = data.statisticForLastDay.fitnessAgeDeltas.stdev;
      if (!sigma) {
        return;
      }
      const u = data.statisticForLastDay.fitnessAgeDeltas.expectedValue;
      const series: ChartSeriesEntity<number>[] = [];

      for (let i = minDelta; i <= maxDelta; i = i + 0.1) {
        series.push(new ChartSeriesEntity<number>(Number(i), this.getGaussDot(i, sigma, u)));
      }

      if (series.length) {
        chartData.push(new ChartLineEntity<number>(
          this.datePipe.transform(new Date(data.statisticForLastDay.date), 'dd MMM yyyy'),
          series,
        ));
        deltaTicks.push(u);
      }
    }

    if (!data.statisticForFirstDay && !data.statisticForLastDay) {
      const series: ChartSeriesEntity<number>[] = [];
      series.push(new ChartSeriesEntity<number>(Number(data.minDelta), 0));
      series.push(new ChartSeriesEntity<number>(Number(data.maxDelta), 0));
      chartData.push(new ChartLineEntity<number>(
        '',
        series,
      ));
    }

    deltaTicks.push([maxDelta]);
    this.deltaAgeTicks = [...deltaTicks];
    this.deltaAgeChart = [...chartData];
  }

  private getTicks(min: number, max: number): number[] {
    let step = 5;
    const ticks = [];
    const range = Math.abs(min) + Math.abs(max);

    if (range / 5 > 30) {
      step = 10;
    }
    if (range / 5 > 100) {
      step = 20;
    }

    for (let i = min; i < max; i = i + step) {
      ticks.push(i);
    }
    ticks.push(max);
    return ticks;
  }

  private setDefaultDate(): void {
    const today = new Date();
    this.currentFromDate = new Date(today.setMonth(today.getMonth() - 1));
    this.currentToDate = new Date();
  }

  /**
   * Gauss distribution formula, return value of dot on y-axis
   * @param x value of dot on x-axis
   * @param sigma standard deviation
   * @param u expectation of the distribution
   */
  private getGaussDot(x: number, sigma: number, u: number): number {
    return (1 / (sigma * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * (((x - u) / sigma) ** 2));
  }
}

