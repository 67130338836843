import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

import {ProfileService} from '@services/profile.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyWithLicenceGuard implements CanActivate {
  constructor(
    private profileService: ProfileService,
    private router: Router,
  ) {
  }

  canActivate() {
    if (!this.profileService.isCompanyWithLicence) {
      return this.router.navigate(['/']);
    }

    return true;
  }
}
