import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {ProfileService} from '@services/profile.service';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})
export class EducationComponent implements OnInit, OnDestroy {

  trainer: ProfileEntity;
  tabIsDisabled: boolean = false;

  private destroy$ = new Subject<void>();

  get isTrainer() {
    return this.profileService.isProfile('TRAINER_TYPE');
  }

  get isPartner() {
    return this.profileService.isProfile('PARTNER_TYPE');
  }

  constructor(
    public profileService: ProfileService,
  ) {
  }

  ngOnInit() {
    this.listenTrainerChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    // update profile data
    this.profileService.getProfile();
  }

  /**
   * Listen trainer changes
   */
  listenTrainerChanges() {
    this.profileService.trainer
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((trainer: ProfileEntity) => {
        this.tabIsDisabled = trainer.under_consideration;
      });
  }

}
