import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { MobileService } from '@services/mobile.service';
import { CompanyService } from '@services/company.service';
import { DataService } from '@services/data.service';

import { AddEmployeeModalComponent } from './add-employee-modal/add-employee-modal.component';
import { SendEmailModalComponent } from './send-email-modal/send-email-modal.component';
import { ConfirmationComponent } from '@components/modals/confirmation/confirmation.component';
import { UploadCsvModalComponent } from './upload-csv-modal/upload-csv-modal.component';
import { Employee, EMPLOYEE_REQUEST } from '@models/employee';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { hasUnreadMessages } from '@modules/chats/store/chat.selectors';

@UntilDestroy()
@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit, OnDestroy {
  readonly hasUnreadChatMessages$: Observable<boolean>;
  readonly searchValueControl = new FormControl('');

  public employees: Employee[] = [];
  public isImportingEmployees = false;
  public importedEmployees: any;

  private destroy$ = new Subject<void>();
  private CSV_MAX_SIZE = 1e6; // 1MB

  get isMobile() {
    return this.mobileService.isMobile;
  }

  constructor(
    public dialog: MatDialog,
    private data: DataService,
    private translate: TranslateService,
    private mobileService: MobileService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar,
    private store: Store,
  ) {
    this.hasUnreadChatMessages$ = this.store.select(hasUnreadMessages);
  }

  ngOnInit() {
    this.subscribeToEmployeeParamsChanges();
    this.subscribeToEmployeeSearch();
    this.getEmployees();
  }

  ngOnDestroy() {
    this.companyService.resetEmployees();
  }

  disableEmployee(employee: Employee): void {
    this.dialog
      .open(ConfirmationComponent, {
        data: {
          headerTxt: this.translate.instant(
            'employees.disabling_employee_modal.header',
          ),
          bodyTxt: this.translate.instant(
            'employees.disabling_employee_modal.body',
          ),
          actionTxt: this.translate.instant(
            'employees.disabling_employee_modal.confirm',
          ),
          cancelTxt: this.translate.instant(
            'employees.disabling_employee_modal.cancel',
          ),
        },
        panelClass: 'confirmation-dialog',
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.companyService.disableEmployee(employee.id)),
        untilDestroyed(this),
      )
      .subscribe({
        next: () => {
          employee.disable();

          this.snackBar.open(
            this.translate.instant(
              'employees.disabling_employee_modal.success',
            ),
            '',
            { panelClass: 'success', duration: 5000 },
          );
        },
      });
  }

  enableEmployee(employee: Employee): void {
    this.dialog
      .open(ConfirmationComponent, {
        data: {
          headerTxt: this.translate.instant(
            'employees.enabling_employee_modal.header',
          ),
          bodyTxt: this.translate.instant(
            'employees.enabling_employee_modal.body',
          ),
          actionTxt: this.translate.instant(
            'employees.enabling_employee_modal.confirm',
          ),
          cancelTxt: this.translate.instant(
            'employees.enabling_employee_modal.cancel',
          ),
        },
        panelClass: 'confirmation-dialog',
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.companyService.enableEmployee(employee.id)),
        untilDestroyed(this),
      )
      .subscribe({
        next: () => {
          employee.enable();

          this.snackBar.open(
            this.translate.instant('employees.enabling_employee_modal.success'),
            '',
            { panelClass: 'success', duration: 5000 },
          );
        },
      });
  }

  resendInvite(employee: Employee): void {
    this.companyService
      .resendInvite(employee.id)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error.code === 400) {
            this.snackBar.open(error.error.message, '', {
              panelClass: 'error',
              duration: 5000,
            });
          }

          throw error;
        }),
        untilDestroyed(this),
      )
      .subscribe(({ request }) => {
        if (employee.original_request === EMPLOYEE_REQUEST.DECLINED) {
          employee.setPendingRequest();
          employee.setRequestStatus(request);
        }

        this.snackBar.open(
          this.translate.instant('employees.invite_resent'),
          '',
          { panelClass: 'success', duration: 5000 },
        );
      });
  }

  employeesOnScroll(event) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 5
    ) {
      if (
        this.employees.length < this.companyService.totalEmployees &&
        !this.companyService.isEmployeesAreLoading()
      ) {
        this.companyService.updateEmployeeRequestParams({
          skip: this.employees.length,
          limit: 20,
        });
      }
    }
  }

  getEmployees(): void {
    this.companyService
      .getEmployees()
      .pipe(untilDestroyed(this))
      .subscribe((employees) => {
        this.employees = employees;
      });
  }

  importCsvDialog() {
    const dialog = this.dialog.open(UploadCsvModalComponent, {
      panelClass: 'confirmation-dialog',
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((files: File[]) => {
        if (files) {
          this.uploadEmployees(files);
        }
      });
  }

  clearSearchValue(): void {
    this.searchValueControl.setValue('');
  }

  uploadEmployees(files: File[]) {
    if (files.length === 0) {
      return;
    }
    const csvFile = files[0];
    if (csvFile.size > this.CSV_MAX_SIZE) {
      this.data.showNotification(
        this.translate.instant('company.uploadSizeLimit'),
        null,
        4000,
        'error',
      );
      return;
    }
    if (csvFile.name.substr(csvFile.name.length - 4) !== '.csv') {
      this.data.showNotification(
        this.translate.instant('company.wrongFileFormat', { format: 'csv' }),
        null,
        4000,
        'error',
      );
      return;
    }

    this.companyService
      .uploadEmployees(csvFile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.isImportingEmployees = true;
          this.importedEmployees = response;
          if (this.importedEmployees.invalidEmails.length) {
            this.data.showNotification(
              this.translate.instant('company.importValidationMessage2'),
              null,
              3000,
              'success',
            );
          } else {
            this.data.showNotification(
              this.translate.instant('company.importValidationMessage1'),
              null,
              3000,
              'success',
            );
          }
        },
        (error) => {
          this.data.showNotification(error.error.message, null, 4000, 'error');
          throw error;
        },
      );
  }

  getEmailsCounter() {
    if (!this.importedEmployees) {
      return 0;
    }
    const counter =
      this.importedEmployees.validEmails.length >=
      this.importedEmployees.invalidEmails.length
        ? this.importedEmployees.validEmails.length
        : this.importedEmployees.invalidEmails.length;
    return new Array(counter);
  }

  importEmployees() {
    const dialog = this.dialog.open(ConfirmationComponent, {
      data: {
        headerTxt: this.translate.instant('company.addEmployees'),
        bodyTxt: this.translate.instant('company.uploadConfirmation'),
        actionTxt: this.translate.instant('common.confirm'),
      },
      panelClass: 'confirmation-dialog',
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmation) => {
        if (confirmation) {
          this.companyService
            .createEmployees(this.importedEmployees.validEmails)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (response) => {
                if (response.error) {
                  this.data.showNotification(
                    response.error.message,
                    null,
                    4000,
                    'warning',
                  );
                } else {
                  this.data.showNotification(
                    this.translate.instant('company.employeesAdded2'),
                    null,
                    3000,
                    'success',
                  );
                }
                this.companyService.updateEmployeeRequestParams({
                  skip: 0,
                  limit: 20,
                });
                this.isImportingEmployees = false;
                this.importedEmployees = null;
              },
              (error) => {
                this.data.showNotification(
                  error.error.message,
                  null,
                  4000,
                  'error',
                );
              },
            );
        }
      });
  }
  cancelImportingEmployees() {
    const dialog = this.dialog.open(ConfirmationComponent, {
      data: {
        headerTxt: this.translate.instant('company.cancelUpload'),
        bodyTxt: this.translate.instant('company.cancelUploadConfirmation'),
        actionTxt: this.translate.instant('company.yesCancel'),
      },
      panelClass: 'confirmation-dialog',
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmation) => {
        if (confirmation) {
          this.isImportingEmployees = false;
          this.importedEmployees = null;
        }
      });
  }

  addEmployeeDialog() {
    this.dialog
      .open(AddEmployeeModalComponent, { autoFocus: false })
      .afterClosed()
      .pipe(
        filter((isConfirm) => !!isConfirm),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.companyService.updateEmployeeRequestParams({
          skip: 0,
          limit: 20,
          isQueryChanged: true,
        });
      });
  }

  sendEmailDialog(index: number) {
    const dialog = this.dialog.open(SendEmailModalComponent, {
      width: '480px',
      maxWidth: '100vw',
      data: {
        email: this.employees[index].email,
      },
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmation) => {
        if (confirmation) {
          this.data.showNotification(
            this.translate.instant('company.emailSent'),
            null,
            3000,
            'success',
          );
        }
      });
  }

  deleteEmployeeDialog(id: number) {
    const dialog = this.dialog.open(ConfirmationComponent, {
      data: {
        id,
        headerTxt: this.translate.instant(
          'employees.deleting_employee_modal.header',
        ),
        bodyTxt: this.translate.instant(
          'employees.deleting_employee_modal.body',
        ),
        actionTxt: this.translate.instant(
          'employees.deleting_employee_modal.confirm',
        ),
        cancelTxt: this.translate.instant(
          'employees.deleting_employee_modal.cancel',
        ),
      },
      panelClass: 'confirmation-dialog',
    });

    dialog
      .afterClosed()
      .pipe(
        filter((confirmation) => !!confirmation.id),
        switchMap(() => this.companyService.deleteEmployee(id)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.companyService.filterEmployeesById(id);
        this.data.showNotification(
          this.translate.instant('company.userDeleted'),
          null,
          3000,
          'success',
        );
      });
  }

  private subscribeToEmployeeSearch(): void {
    (this.searchValueControl.valueChanges as Observable<string>)
      .pipe(debounceTime(600), untilDestroyed(this))
      .subscribe((searchValue) => {
        this.companyService.updateEmployeeRequestParams({
          skip: 0,
          limit: 20,
          query: searchValue,
          isQueryChanged: true,
          role: 'EMPLOYEE',
        });
      });
  }

  private subscribeToEmployeeParamsChanges(): void {
    this.companyService
      .employeeParamsChanges()
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
