export const TRAINER_TYPE = 'trainer';
export const PARTNER_TYPE = 'partner';
export const CLIENT_TYPE = 'client';
export const SHOW_SUBSCRIPTION = 'SHOW_SUBSCRIPTION';

export enum PROFILE_TYPES {
  TRAINER_TYPE = 'trainer',
  PARTNER_TYPE = 'partner',
  CLIENT_TYPE = 'client',
}


export const BUSINESS = 'business';
export const INDIVIDUAL = 'individual';
export const FOUNDATION = 'foundation';

export const ACCOUNT_TYPES = {
  BUSINESS,
  INDIVIDUAL,
  FOUNDATION,
};

export enum ACCOUNT_TYPES_TRAINER {
  BUSINESS = 'business',
  INDIVIDUAL = 'individual',
}

export enum ACCOUNT_TYPES_PARTNER {
  BUSINESS = 'business',
  INDIVIDUAL = 'individual',
  FOUNDATION = 'foundation',
}

/**
 * Training or event
 */
export enum SESSION {
  UNCONFIRMED = 'UNCONFIRMED',
  UPCOMING = 'UPCOMING',
  STARTED = 'STARTED',
  CANCELED = 'CANCELED',
  CANCELED_BY_TRAINER = 'CANCELED_BY_TRAINER',
  CANCELED_SOS = 'CANCELED_SOS',
  NOT_ENOUGH_MEMBERS = 'NOT_ENOUGH_MEMBERS',
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  ON_REQUEST = 'ON_REQUEST',
  EVENT_TYPE = 'event',
  TRAINING_TYPE = 'training',
  HIKING_ACTIVITY = 'HIKING',
  REST_TIME_MINUTES_MAX = 60,
  REST_TIME_MINUTES_MIN = 30,
}

export enum LANGUAGE {
  EN = 'en',
  NO = 'nb-nn',
}

export class ENUM {
  PROFILE_TYPES = PROFILE_TYPES;
  LANGUAGE = LANGUAGE;
  SESSION = SESSION;
}

export enum LOCATION_PRIORITY {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

export enum PROFILE_TABS {
  PERSONAL = 'PERSONAL',
  LOCATION = 'LOCATION',
  EDUCATION = 'EDUCATION',
  EXPERTISE = 'EXPERTISE',
  PAYMENT = 'PAYMENT',
  PRICING = 'PRICING',
}

export enum ERROR_CODES {
  JSON_SCHEMA_INVALID = 'JSON_SCHEMA_INVALID',
  RESTRICTED_ACCESS = 'RESTRICTED_ACCESS',
  CALENDAR_NO_SUCH_PROFILE = 'CALENDAR_NO_SUCH_PROFILE',
  COMMENTS_NO_SUCH_PROFILE = 'COMMENTS_NO_SUCH_PROFILE',
  FAVORITE_YOURSELF = 'FAVORITE_YOURSELF',
  FAVORITE_EXISTS = 'FAVORITE_EXISTS',
  FRIEND_YOURSELF = 'FRIEND_YOURSELF',
  FRIEND_EXISTS = 'FRIEND_EXISTS',
  FRIEND_UNAPPROVE = 'FRIEND_UNAPPROVE',
  TRAINER_PRICE_INVALID = 'TRAINER_PRICE_INVALID',
  TRAINER_LOCATION_INVALID = 'TRAINER_LOCATION_INVALID',
  PROFILE_EXISTS = 'PROFILE_EXISTS',
  RATINGS_CRITERIA_NOT_UNIQUE = 'RATINGS_CRITERIA_NOT_UNIQUE',
  RATINGS_CRITERIA_DONT_MATCH = 'RATINGS_CRITERIA_DONT_MATCH',
  RATINGS_EXIST = 'RATINGS_EXIST',
  RED_FLAG_TRAINING_NOT_FOUND = 'RED_FLAG_TRAINING_NOT_FOUND',
  RED_FLAG_DONT_PARTICIPATE = 'RED_FLAG_DONT_PARTICIPATE',
  RED_FLAG_NOT_IN_TIME = 'RED_FLAG_NOT_IN_TIME',
  RED_FLAG_NO_SUCH_REASON = 'RED_FLAG_NO_SUCH_REASON',
  RED_FLAG_EXISTS = 'RED_FLAG_EXISTS',
  RED_FLAG_ALREADY_JUDGED = 'RED_FLAG_ALREADY_JUDGED',
  SESSION_FEEDBACK_TRAINING_NOT_FOUND = 'SESSION_FEEDBACK_TRAINING_NOT_FOUND',
  SESSION_FEEDBACK_NO_TRAINER = 'SESSION_FEEDBACK_NO_TRAINER',
  SESSION_FEEDBACK_NOT_IN_TIME = 'SESSION_FEEDBACK_NOT_IN_TIME',
  SESSION_FEEDBACK_DONT_PARTICIPATE = 'SESSION_FEEDBACK_DONT_PARTICIPATE',
  SESSION_FEEDBACK_EXISTS = 'SESSION_FEEDBACK_EXISTS',
  PRICE_LIST_NO_TRAINER_PROFILE = 'PRICE_LIST_NO_TRAINER_PROFILE',
  PRICE_LIST_NO_SUCH_SESSION_TYPE = 'PRICE_LIST_NO_SUCH_SESSION_TYPE',
  RATING_NO_CLIENT = 'RATING_NO_CLIENT',
  RATING_YOURSELF = 'RATING_YOURSELF',
  RATING_NOT_TRAINER = 'RATING_NOT_TRAINER',
  RATING_DONT_PARTICIPATE = 'RATING_DONT_PARTICIPATE',
  RATING_EXISTS = 'RATING_EXISTS',
  SCHEDULE_NO_TRAINER_PROFILE = 'SCHEDULE_NO_TRAINER_PROFILE',
  INVITE_NOT_REJECTABLE = 'INVITE_NOT_REJECTABLE',
  INVITE_NOT_ACCEPTABLE = 'INVITE_NOT_ACCEPTABLE',
  INVITE_ALREADY_CONFIRMED = 'INVITE_ALREADY_CONFIRMED',
  INVITE_GROUP_FULL = 'INVITE_GROUP_FULL',
  TRAINING_NO_SUCH_PROFILE = 'TRAINING_NO_SUCH_PROFILE',
  TRAINING_NOT_CANCELABLE = 'TRAINING_NOT_CANCELABLE',
  TRAINING_ALREADY_REVIEWED = 'TRAINING_ALREADY_REVIEWED',
  TRAINING_ONLY_CLIENT_BOOKS = 'TRAINING_ONLY_CLIENT_BOOKS',
  TRAINING_BOOK_YOURSELF = 'TRAINING_BOOK_YOURSELF',
  TRAINING_NO_TRAINER = 'TRAINING_NO_TRAINER',
  TRAINING_CANT_BOOK_THIS_TRAINER = 'TRAINING_CANT_BOOK_THIS_TRAINER',
  TRAINING_TYPE_INVALID = 'TRAINING_TYPE_INVALID',
  TRAINING_TYPE_NOT_TRAINED = 'TRAINING_TYPE_NOT_TRAINED',
  TRAINING_STATUS_INVALID = 'TRAINING_STATUS_INVALID',
  TRAINING_SESSION_TYPE_INVALID = 'TRAINING_SESSION_TYPE_INVALID',
  TRAINING_MINIMUM_PARTICIPANTS = 'TRAINING_MINIMUM_PARTICIPANTS',
  TRAINING_NO_SESSION_TYPE = 'TRAINING_NO_SESSION_TYPE',
  TRAINING_PRICE_INVALID = 'TRAINING_PRICE_INVALID',
  TRAINING_NOT_ALL_CLIENTS = 'TRAINING_NOT_ALL_CLIENTS',
  TRAINING_NOT_ALL_FRIENDS = 'TRAINING_NOT_ALL_FRIENDS',
  TRAINING_TIME_ALREADY_BOOKED = 'TRAINING_TIME_ALREADY_BOOKED',
  TRAINING_TRAINER_UNAVAILABLE = 'TRAINING_TRAINER_UNAVAILABLE',
  TRAINING_START_DATE_INVALID = 'TRAINING_START_DATE_INVALID',
  PASSWORD_INVALID = 'PASSWORD_INVALID',
  DASHBOARD_NO_HEALTH_RESOURCE_PROFILE = 'DASHBOARD_NO_HEALTH_RESOURCE_PROFILE',
  PASSWORD_RECOVERY_NO_EMAIL = 'PASSWORD_RECOVERY_NO_EMAIL',
  USER_NOT_COMPLETED_REGISTRATION = 'USER_NOT_COMPLETED_REGISTRATION',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  USER_LOCATION_INVALID = 'USER_LOCATION_INVALID',
  TRAINING_PARTICIPANT_NO_SUCH_PROFILE = 'TRAINING_PARTICIPANT_NO_SUCH_PROFILE',
  REPORT_SELF = 'REPORT_SELF',
  REPORT_TOO_OFTEN = 'REPORT_TOO_OFTEN',
  TRAINING_INVALID_REGION = 'TRAINING_INVALID_REGION',
  TRAINING_CLIENT_INVITE = 'TRAINING_CLIENT_INVITE',
  INVITE_GROUP_ANOTHER_TRAINING = 'INVITE_GROUP_ANOTHER_TRAINING',
  TRAINING_BOOK_INVALID_TIME = 'TRAINING_BOOK_INVALID_TIME',
  TRAINER_INVALID_REGION = 'TRAINER_INVALID_REGION',
  FILES_EMAIL_NOT_EDITABLE = 'FILES_EMAIL_NOT_EDITABLE',
  EMAIL_NOT_CONFIRMED = 'EMAIL_NOT_CONFIRMED',
  TRAINING_TRAINER_NO_STRIPE_ACCOUNT = 'TRAINING_TRAINER_NO_STRIPE_ACCOUNT',
  TRAINING_CLIENT_NO_STRIPE_CARD = 'TRAINING_CLIENT_NO_STRIPE_CARD',
  RED_FLAG_TRAINING_NOT_FINISHED = 'RED_FLAG_TRAINING_NOT_FINISHED',
  RED_FLAG_TRAINER_DISMISS = 'RED_FLAG_TRAINER_DISMISS',
  RED_FLAG_GROUP_TRAINING = 'RED_FLAG_GROUP_TRAINING',
  TRAINING_TYPES_DONT_MATCH = 'TRAINING_TYPES_DONT_MATCH',
  EMAIL_TEMPLATE_NOT_FOUND = 'EMAIL_TEMPLATE_NOT_FOUND',
  TRAINING_TYPES_ENGLISH_IS_REQUIRED = 'TRAINING_TYPES_ENGLISH_IS_REQUIRED',
  INVALID_EVENT_TYPE = 'INVALID_EVENT_TYPE',
  TRAINING_TRAINER_INVITED = 'TRAINING_TRAINER_INVITED',
  EVENT_NOT_ALL_CLIENTS = 'EVENT_NOT_ALL_CLIENTS',
  EVENT_INVALID_EVENT_TYPE = 'EVENT_INVALID_EVENT_TYPE',
  EVENT_INVALID_GOAL_TYPE = 'EVENT_INVALID_GOAL_TYPE',
  EVENT_INVALID_LEVEL_TYPE = 'EVENT_INVALID_LEVEL_TYPE',
  EVENT_ALREADY_BOOKED = 'EVENT_ALREADY_BOOKED',
  EVENT_MINIMUM_PARTICIPANTS = 'EVENT_MINIMUM_PARTICIPANTS',
  EVENT_START_DATE_INVALID = 'EVENT_START_DATE_INVALID',
  EVENT_INVALID_EVENT_ACTIVITY_TYPE = 'EVENT_INVALID_EVENT_ACTIVITY_TYPE',
  EVENT_EVENT_TYPE_IS_REQUIRED = 'EVENT_EVENT_TYPE_IS_REQUIRED',
  NO_FREE_TIME = 'NO_FREE_TIME',
  USER_BILLING_ADDRESS_NOT_SUPPLIED = 'USER_BILLING_ADDRESS_NOT_SUPPLIED',
  EVENT_BOOK_YOURSELF = 'EVENT_BOOK_YOURSELF',
  EVENT_INVALID_PROVIDE_EVENT_TYPE = 'EVENT_INVALID_PROVIDE_EVENT_TYPE',
  EVENT_INVALID_PROVIDE_ACTIVITY_TYPE = 'EVENT_INVALID_PROVIDE_ACTIVITY_TYPE',
  EVENT_BOOK_INVALID_PROFILE = 'EVENT_BOOK_INVALID_PROFILE',
  EVENT_ALREADY_JOINED = 'EVENT_ALREADY_JOINED',
  EVENT_INVITE_GROUP_FULL = 'EVENT_INVITE_GROUP_FULL',
  EVENT_JOIN_YOURSELF = 'EVENT_JOIN_YOURSELF',
  EVENT_INVITE_YOURSELF = 'EVENT_INVITE_YOURSELF',
  TRAINING_INVALID_GOAL_TYPE = 'TRAINING_INVALID_GOAL_TYPE',
  RED_FLAG_EVENT_NOT_FOUND = 'RED_FLAG_EVENT_NOT_FOUND',
  RED_FLAG_GROUP_EVENT = 'RED_FLAG_GROUP_EVENT',
  EVENT_INVITE_PROVIDER_CLIENT_PROFILE = 'EVENT_INVITE_PROVIDER_CLIENT_PROFILE',
  TRAINING_BOOK_YOURSELF_INVITED = 'TRAINING_BOOK_YOURSELF_INVITED',
  EVENT_PROFILE_NO_CARDS = 'EVENT_PROFILE_NO_CARDS',
  TRAINING_PROFILE_NO_CARDS = 'TRAINING_PROFILE_NO_CARDS',
  RESTRICTED_ACCESS_EVENT = 'RESTRICTED_ACCESS_EVENT',
  TRAINING_NOT_FOUND = 'TRAINING_NOT_FOUND',
  EVENT_NOT_FOUND = 'EVENT_NOT_FOUND',
  ACCOUNT_NOT_CONFIRMED = 'ACCOUNT_NOT_CONFIRMED',
  RESEND_SMS_ERROR = 'RESEND_SMS_ERROR',
  RESEND_EMAIL_ERROR = 'RESEND_EMAIL_ERROR',
  INVALID_SYSTEM_CERTIFICATE = 'INVALID_SYSTEM_CERTIFICATE',
  INVALID_CERTIFICATE_FEATURED = 'INVALID_CERTIFICATE_FEATURED',
  EVENT_CREATION_PROVIDER_NO_STRIPE_ACCOUNT = 'EVENT_CREATION_PROVIDER_NO_STRIPE_ACCOUNT',
  EVENT_REQUEST_PROVIDER_NO_STRIPE_ACCOUNT = 'EVENT_REQUEST_PROVIDER_NO_STRIPE_ACCOUNT',
  SOCIAL_USER_EXIST = 'SOCIAL_USER_EXIST',
}

export enum TIMES_TYPE {
  TIME_25 = 25,
  TIME_55 = 55,
}

export enum UTV_VIDEO_STATUS {
  HIDDEN = 'HIDDEN',
  PUBLISHED = 'PUBLISHED',
}

export enum UTV_VIDEO_SUBSCRIPTION {
  FREE = 'FREE',
  UTV = 'UTV',
  UTV_PLUS = 'UTV+',
}

export enum UTV_PRODUCTS {
  UTV = 'UTV',
  UTV_PLUS = 'UTV+',
}

export enum UTV_SUBSCRIPTIONS_STATUS {
  PAID = 'PAID',
  CANCELED = 'CANCELED',
}

export enum UTV_SUBSCRIPTION_ACTION_TYPE {
  SUBSCRIBE = 'subscribe',
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
  CANCEL = 'cancel',
  RESTORE = 'restore',
}

export enum SIGN_IN_STRATEGY {
  LOCAL_COMPANY = 'local-company',
}

export enum BODY_PARTS {
  ANKLES = 'ANKLES',
  KNEES = 'KNEES',
  ELBOWS = 'ELBOWS',
  HIPS = 'HIPS',
  WRISTS = 'WRISTS',
  SHOULDERJOINTS = 'SHOULDERJOINTS',
  GLUTES = 'GLUTES',
  TRICEPS = 'TRICEPS',
  BICEPS = 'BICEPS',
  CHEST = 'CHEST',
  SHOULDERS = 'SHOULDERS',
  UPPER_BACK = 'UPPER_BACK',
  LUMBAR = 'LUMBAR',
  ABDOMEN = 'ABDOMEN',
  HAMSTRINGS = 'HAMSTRINGS',
  QUADRICEPS = 'QUADRICEPS',
  LEGS = 'LEGS',
  NECK = 'NECK',
  LATISSIMUS = 'LATISSIMUS',
}

export enum AGREEMENT_PAGE_TYPE {
  PRIVATE_POLICY = 'private-policy',
  COMPANY_AGREEMENT = 'company-agreement',
}
