import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermAndPolicyComponent } from './term-and-policy/term-and-policy.component';
import { MaterialSharedModule } from '@modules/material/material-shared.module';

@NgModule({
  declarations: [
    TermAndPolicyComponent,
  ],
  imports: [
    CommonModule,
    MaterialSharedModule,
  ],
})
export class SharedPagesModule { }
