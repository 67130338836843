import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';

interface Notification {
  message: string;
  action?: any;
  duration?: number;
  panelClass?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  public catchHttpError(error: HttpErrorResponse, notification?: Notification): void {
    console.error(error);

    if (notification) {
      this.openSnackBar(notification);
    }
  }

  public catchError(error: Error, notification?: Notification): void {
    console.error(error);

    if (notification) {
      this.openSnackBar(notification);
    }
  }

  private openSnackBar(notification: Notification) {
    const {
      message,
      action = null,
      duration = 3000,
      panelClass = 'error',
    } = notification;

    this.snackBar.open(message, action, {
      duration,
      panelClass,
      verticalPosition: 'top',
    });
  }
}
