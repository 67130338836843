<form [formGroup]="form">
  <app-material-input
    formControlName="name"
    [label]="'company.companyName' | translate"
    [readonly]="!isEditing"
    [errors]="{required: 'profile.messages.fieldRequired' | translate,
               maxlength: 'profile.messages.maxLength' | translate:{number: '50'}}">
  ></app-material-input>

  <app-material-select
    [label]="'company.country' | translate"
    *ngIf="countries?.length"
    [options]="countries"
    formControlName="country_id"
    valueFieldName="id"
    labelFieldName="name"
    [readonly]="!isEditing"
  ></app-material-select>

  <app-material-input
    formControlName="address"
    [label]="'company.address' | translate"
    [readonly]="!isEditing"
    [errors]="{required: 'profile.messages.fieldRequired' | translate,
               maxlength: 'profile.messages.maxLength' | translate:{number: '100'}}">
  ></app-material-input>

  <app-material-textarea
    class="description"
    [label]="'company.description' | translate"
    [maxlength]="'500'"
    [fluidHeight]="true"
    [formControl]="form.get('description')"
  [readonly]="!isEditing">
  </app-material-textarea>
</form>
