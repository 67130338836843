import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SESSION} from '../../utils/enum';
import {Subscription} from 'rxjs';
import {CalendarService} from '../../services/calendar.service';
import {SessionList} from '../../models/session';
import {TranslateService} from '@ngx-translate/core';
import {MobileService} from '../../services/mobile.service';
import {UserEntity} from '@models/user/user.entity';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit, OnDestroy {
  public requests: SessionList[];
  public upcoming: SessionList[];
  public archive: SessionList[];
  public list: SessionList[];

  public user: UserEntity;
  public activeTab: string;
  public requestTypeParams: { 'activeTab': string; 'trainingTypes': string[] }[];
  public selectedSession: any;
  public selectedEvent: any;

  // Variables for a quick cancel/confirm requests
  public containerForMessages: any;

  private SESSION: typeof SESSION = SESSION;
  private subs: Subscription;

  get isMobile() {
    return this.mobileService.isMobile;
  }

  constructor(
    public calendarService: CalendarService,
    private snackbar: MatSnackBar,
    public mobileService: MobileService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.subs = new Subscription();
    this.requestTypeParams = [
      {
        activeTab: 'requests',
        trainingTypes: ['UNCONFIRMED'],
      },
      {
        activeTab: 'upcoming',
        trainingTypes: ['UPCOMING', 'STARTED'],
      },
      {
        activeTab: 'archive',
        trainingTypes: ['CANCELED_BY_TRAINER', 'CANCELED_SOS', 'NOT_ENOUGH_MEMBERS', 'EXPIRED', 'FINISHED'],
      },
    ];

    // todo this stuff must be in en/nb.json
    // Texts for quick reject/confirm popup
    this.containerForMessages = {
      en: {
        reject: {
          header: 'Reject request',
          button: 'Reject',
          message: 'Are you sure you want to reject this request?',
          sucess: 'Training request was succesfully rejected!',
          error: 'Error happened! Request incomplete!',
        },
        confirm: {
          header: 'Confirm request',
          button: 'Confirm',
          message: 'Are you sure you want to confirm this request?',
          sucess: 'Training request was succesfully confirmed!',
          error: 'Error happened! Request incomplete!',
        },
        cancelScheduled: {
          placeholder: 'Reason for cancelling',
          buttonText: 'Cancel',
          cancelledSuccessfully: 'Training has been cancelled succesfully!',
          isNotUpcoming: 'Training is not upcoming already. You are not able to cancel it!',
        },
        cancelConfirmation: {
          header: 'Cancellation confirm',
          button: 'proceed',
          message: 'If you cancel this training, you\'ll be fined by Memento. Because too little time left before planned beginning of the training!',
        },
      },
      'nb-nn': {
        reject: {
          header: 'Avvis forespørsel',
          button: 'avvise',
          message: 'Are you sure you want to reject this request?',
          sucess: 'Norw: Training request was succesfully rejected!',
          error: 'Feil skjedde! Be om ufullstendig!',
        },
        confirm: {
          header: 'Bekreft forespørsel',
          button: 'bekrefte',
          message: 'Are you sure you want to confirm this request?',
          sucess: 'NORW: Training request was succesfully confirmed!',
          error: 'Feil skjedde! Be om ufullstendig!',
        },
        cancelConfirmation: {
          header: 'Avbestilling bekreftes',
          button: 'fortsette',
          message: 'Norwegian: If you cancel this training, you\'ll be fined by Memento. Because too little time left before planning beginning of the training!',
        },
        cancelScheduled: {
          placeholder: 'Årsak til kansellering',
          buttonText: 'Avbryt',
          cancelledSuccessfully: 'Treningen ble kansellert med suksess!',
          isNotUpcoming: 'Norw: Training is not upcoming already. You are not able to cancel it!',
        },
      },
    };
    this.selectTab(0);

    this.calendarService.getUpcoming().subscribe();
    this.calendarService.getFinished().subscribe();

    this.subs.add(
      this.calendarService.requests.subscribe(requests => {
        this.requests = requests;
        this.setList();
      }),
    );

    this.subs.add(
      this.calendarService.upcoming.subscribe(upcoming => {
        this.upcoming = upcoming;
        this.setList();
      }),
    );

    this.subs.add(
      this.calendarService.finished.subscribe(finished => {
        this.archive = finished;
        this.setList();
      }),
    );

    this.subs.add(
      this.calendarService.activities.subscribe(activities => {
        this.selectedEvent = activities.event;
        this.selectedSession = activities.session;
      }),
    );
  }


  ngOnDestroy() {
    this.subs.unsubscribe();
    this.calendarService.activity('event').closeAll();
  }

  selectTab(index: number) {
    if (this.activeTab === this.requestTypeParams[index].activeTab) {
      return;
    }
    this.activeTab = this.requestTypeParams[index].activeTab;
    this.setList();
  }

  /**
   * Open session modal
   * @param index list index
   */
  openSession(index: number) {
    const item = this.list[index];
    switch (item.type) {
      case this.SESSION.TRAINING_TYPE: {
        this.calendarService.activity('session', item).open();
        break;
      }
      case this.SESSION.EVENT_TYPE: {
        this.calendarService.activity('event', item).open();
        break;
      }
      default: {
        return false;
      }
    }
  }

  cancelRequest(event, index: number) {
    event.stopPropagation();
    const item = this.list[index];

    if (item.type === this.SESSION.EVENT_TYPE) {
      this.calendarService.resolveEvent(item.id, {event_status: this.SESSION.CANCELED_BY_TRAINER, message_of_cancellation: ''})
        .subscribe(response => {
          this.snackbar.open(this.translate.instant('events.eventCancelled'), '', {panelClass: 'info', duration: 5000});
        }, error => {
          this.snackbar.open(error.error.message, '', {panelClass: 'error', duration: 5000});
        });

    } else if (item.type === this.SESSION.TRAINING_TYPE) {
      this.calendarService.cancelTraining(item.id)
        .subscribe(response => {
          this.snackbar.open(this.translate.instant('requests.cancelSuccess'), '', {panelClass: 'info', duration: 5000});
        }, error => {
          this.snackbar.open(error.error.message, '', {panelClass: 'error', duration: 5000});
        });
    }
  }

  confirmRequest(event, index: number) {
    event.stopPropagation();
    const item = this.list[index];

    if (item.type === this.SESSION.EVENT_TYPE) {
      this.calendarService.resolveEvent(item.id, {
        event_status: this.SESSION.UPCOMING,
      }).subscribe(response => {
        this.snackbar.open(this.translate.instant('events.eventConfirmed'), '', {panelClass: 'success', duration: 5000});
      }, error => {
        this.snackbar.open(error.error.message, '', {panelClass: 'error', duration: 5000});
      });

    } else if (item.type === this.SESSION.TRAINING_TYPE) {
      this.calendarService.confirmTraining(item.id)
        .subscribe(response => {
          this.snackbar.open(this.translate.instant('requests.confirmSuccess'), '', {panelClass: 'success', duration: 5000});
        }, error => {
          this.snackbar.open(error.error.message, '', {panelClass: 'error', duration: 5000});
        });
    }
  }

  public closeSession() {
    this.calendarService.activity('session').close();
    this.calendarService.activity('event').close();
  }

  private setList() {
    this.list = this[this.activeTab];
  }
}
