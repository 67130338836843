import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MobileService } from '../../../services/mobile.service';
import {TabInterface} from '../utils/interface';

@Component({
  selector: 'app-material-header',
  templateUrl: './material-header.component.html',
  styleUrls: ['./material-header.component.scss'],
})
export class MaterialHeaderComponent implements AfterViewInit {
  @Input() tabs: TabInterface[];
  @Input() activeTab: TabInterface;
  @Input() name!: string;
  @Output() tabChange: EventEmitter<TabInterface> = new EventEmitter<TabInterface>();
  @Input() backLink: string;
  @Input() extra: TemplateRef<any>;
  @Input() isRound: boolean = false;
  @Input() isActiveText: boolean = false;
  @Input() isShowArrows: boolean = true;
  @ViewChild('tabsElement', {static: false}) tabsElement: ElementRef;
  @ViewChild('host', {static: true}) hostElement: ElementRef;
  activeIndex = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    public mobileService: MobileService,
  ) { }

  ngAfterViewInit() {
    if (!this.activeTab && this.tabs && this.tabs.length) {
      this.activeTab = this.tabs[0];
      this.activeIndex = 0;
    } else if (this.activeTab && this.tabs && this.tabs.length) {
      this.changeActiveTab(this.activeTab, this.tabs.findIndex((tab) => this.activeTab.id === tab.id));
    }

    this.cdr.detectChanges();
  }

  changeActiveTab(tab: TabInterface, index: number) {
    if (tab.disabled) {
      return;
    }
    this.activeIndex = index;
    this.activeTab = tab;
    this.tabsElement.nativeElement.scrollLeft = this.activeIndex * 192;
    this.tabChange.emit(this.activeTab);
  }

  changeTabOnArrow(number: number) {
    if (this.tabs[this.activeIndex + number] && this.tabs[this.activeIndex + number].disabled) {
      return;
    }
    this.activeIndex += number;
    this.activeTab = this.tabs[this.activeIndex];
    this.tabsElement.nativeElement.scrollLeft = this.activeIndex * 192;
    this.tabChange.emit(this.activeTab);
  }
}
