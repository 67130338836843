import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { Subscription } from 'rxjs';
import {filter, flatMap, take} from 'rxjs/operators';

import {ProfileService} from '@services/profile.service';
import {Goal} from '@models/goal';
import {EventTypeFlat} from '@models/event-type';
import {UserEntity} from '@models/user/user.entity';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss'],
})
export class ExpertiseComponent implements OnInit, OnDestroy {
  expertise: UntypedFormGroup;
  goals: Goal[];
  trainingTypes: Goal[];
  languages: any[];
  eventTypes: EventTypeFlat[];
  formSub: Subscription;

  public canEditProfile: boolean;
  public trainer: ProfileEntity;
  public user: UserEntity;
  private subs: Subscription[];

  constructor(
    public profileService: ProfileService,
  ) {
    this.canEditProfile = true;
  }

  ngOnInit() {
    if (this.profileService.isProfile('TRAINER_TYPE')) {
      this.profileService.getGoals();
      this.profileService.getTrainingTypes();
    }

    if (this.profileService.isProfile('PARTNER_TYPE')) {
      this.profileService.getEventTypes();
    }

    this.profileService.getLanguages();
    this.formSub = new Subscription();

    this.expertise = new UntypedFormGroup({
      goals: new UntypedFormControl(
        '',
        this.profileService.isProfile('TRAINER_TYPE') ? [Validators.required] : null
      ),
      trainingTypes: new UntypedFormControl(
        '',
        this.profileService.isProfile('TRAINER_TYPE') ? [Validators.required] : null,
      ),
      languages: new UntypedFormControl(''),
      ...(this.profileService.isProfile('PARTNER_TYPE') && {
        eventTypes: new UntypedFormControl('', [
          Validators.required,
        ]),
      }),
    });

    this.subs = [
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      },
      ),
      this.profileService.trainer.pipe(take(1)).subscribe(trainer => {
        this.trainer = trainer;
        this.userInit();
      }),
      this.profileService.user.subscribe(user => {
        this.user = user;
      }),
      this.profileService.goals.subscribe(goals => {
        this.goals = goals;
      }),
      this.profileService.trainingTypes.subscribe(types => {
        this.trainingTypes = types;
      }),
      this.profileService.languages.subscribe(langs => {
        this.languages = langs;
      }),
      this.profileService.eventTypes.subscribe(eventTypes => {
        this.eventTypes = eventTypes;
      }),

      this.expertise.statusChanges.subscribe((status) => {
        this.profileService.changeFormInvalidStatus(status !== 'VALID');
      }),
      this.profileService.saveProfileEvent.pipe(
        filter(() => this.expertise.valid),
      ).subscribe(() => {
        if (this.profileService.isProfile('PARTNER_TYPE')) {
          this.profileService.patchEventTypes(this.expertise.get('eventTypes').value).subscribe();
        } else {
          this.profileService.patchGoals(this.expertise.get('goals').value).pipe(
            flatMap(() => {
              return this.profileService.patchTrainingTypes(this.expertise.get('trainingTypes').value);
            }),
          ).subscribe();
        }
      }),
    ];

  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
    this.formSub.unsubscribe();
  }

  userInit() {
    this.formSub.unsubscribe();
    this.formSub = new Subscription();
    this.expertise.patchValue({
      goals: this.trainer.goals,
      trainingTypes: this.trainer.trainings,
      languages: this.trainer.languages,
      eventTypes: this.trainer.event_types,
    });
    if (this.trainer.under_consideration) {
      this.expertise.disable();
    } else {
      this.expertise.enable();
    }

  }

  removeItem(index: number, type) {
    if (this.expertise.controls[type]) {
      this.expertise.controls[type].value.splice(index, 1);
      this.expertise.controls[type].setValue(this.expertise.controls[type].value);
    }
  }

  compare(a, b) {
    return a && b && a.id === b.id;
  }
}
