<article class="employees_container">
  <app-material-header *ngIf="!isImportingEmployees" [name]="'company.employees' | translate">
    <div class="controls_wrapper">
      <ng-container *ngIf="!isMobile">
        <ng-container *ngTemplateOutlet="employeeSearch"></ng-container>
      </ng-container>

      <app-icon-button class="chat-icon" color="secondary" routerLink="../chat">
        <img src="assets/img/chats.svg" alt="Chats">

        <div *ngIf="hasUnreadChatMessages$ | async" class="chat-badge"></div>
      </app-icon-button>

      <app-button [icon]="isMobile" color="secondary" (click)="importCsvDialog()">
        <i>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33337 13.3333H12.6667V12H3.33337V13.3333ZM3.33337 6.66667H6.00004V10.6667H10V6.66667H12.6667L8.00004 2L3.33337 6.66667Z" fill="#14BFFB"/>
          </svg>
        </i>
        <span *ngIf="!isMobile">
        {{ "company.importCsv" | translate }}
      </span>
      </app-button>

      <app-button [icon]="isMobile" color="primary" (click)="addEmployeeDialog()">
        <i>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66663 5.33332C8.66663 3.85999 7.47329 2.66666 5.99996 2.66666C4.52663 2.66666 3.33329 3.85999 3.33329 5.33332C3.33329 6.80666 4.52663 7.99999 5.99996 7.99999C7.47329 7.99999 8.66663 6.80666 8.66663 5.33332ZM9.99996 6.66666V7.99999H12V9.99999H13.3333V7.99999H15.3333V6.66666H13.3333V4.66666H12V6.66666H9.99996ZM0.666626 12V13.3333H11.3333V12C11.3333 10.2267 7.77996 9.33332 5.99996 9.33332C4.21996 9.33332 0.666626 10.2267 0.666626 12Z" fill="white"/>
          </svg>
        </i>
        <span *ngIf="!isMobile">
        {{ "company.addEmployee" | translate }}
      </span>
      </app-button>
    </div>
  </app-material-header>

  <div class="mobile-search" *ngIf="isMobile && !isImportingEmployees">
    <ng-container *ngTemplateOutlet="employeeSearch"></ng-container>
  </div>

  <app-material-activity-header *ngIf="isImportingEmployees" class="activity-header" [ngClass]="{'mobile-activity-header': isMobile}">
    <div class="aditional-controls-wrapper">
      <button class="back" (click)="cancelImportingEmployees()">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.3334 5.33317H3.22008L6.94675 1.6065L6.00008 0.666504L0.666748 5.99984L6.00008 11.3332L6.94008 10.3932L3.22008 6.6665H11.3334V5.33317Z" fill="#F11C48"/>
        </svg>
      </button>

      <span class="back-text">
          {{"common.emails" | translate}}
        </span>
    </div>

    <div class="controls_wrapper import-controls-buttons">
      <app-button [icon]="isMobile" color="primary" (click)="importEmployees()">
        <i>
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z" fill="white"/>
          </svg>
        </i>

        <span *ngIf="!isMobile">{{ "common.confirm" | translate }}</span>
      </app-button>

      <app-button [icon]="isMobile" color="secondary" (click)="cancelImportingEmployees()">
        <i>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66659 1.2735L8.72659 0.333496L4.99992 4.06016L1.27325 0.333496L0.333252 1.2735L4.05992 5.00016L0.333252 8.72683L1.27325 9.66683L4.99992 5.94016L8.72659 9.66683L9.66659 8.72683L5.93992 5.00016L9.66659 1.2735Z" fill="white"/>
          </svg>
        </i>

        <span *ngIf="!isMobile">{{ "common.cancel" | translate }}</span>
      </app-button>
    </div>
  </app-material-activity-header>

  <div
    class="page_container page employees-page"
    [ngClass]="{'mt-80': isMobile && isImportingEmployees, 'mt-20': isMobile && !isImportingEmployees}"
  >
    <div *ngIf="!isImportingEmployees" class="component_window page_card">
      <div class="table_wrapper">
        <header class="table_header">
          <div class="table_head_cell">
            {{ 'company.user' | translate }}
          </div>
          <div class="table_head_cell">
            {{ 'company.email' | translate }}
          </div>
          <div class="table_head_cell">
            {{ 'company.phoneNumber' | translate }}
          </div>
          <div class="table_head_cell">
            {{ 'company.request' | translate }}
          </div>
          <div class="table_head_cell">
            {{ 'company.statusPai' | translate }}
          </div>
          <div class="table_head_cell">
            {{ 'company.actions' | translate }}
          </div>
        </header>

        <div class="page_content">
          <div class="table_body" *ngIf="employees && employees.length; else noSearchResults" (scroll)="employeesOnScroll($event)">

            <div class="table_row" *ngFor="let employee of employees; index as i">
              <div class="table_row_cell">
                <div
                  *ngIf="employee.user"
                  class="flex_wrapper user-avatar-container"
                  [class.disabled]="!employee.enabled"
                >
                  <div class="avatar_container">
                    <div class="avatar"
                         [ngClass]="{'avatar_empty': !employee.user || !employee.user.avatar}"
                         [style.backgroundImage]="employee.user.avatar && 'url(' + employee.user.avatar + ')'">
                    </div>
                  </div>

                  <span *ngIf="employee.user.first_name || employee.user.last_name" class="name">
                    {{employee.user.first_name || ''}} {{employee.user.last_name || ''}}
                  </span>

                  <span *ngIf="!employee.user.first_name && !employee.user.last_name" class="name">
                    {{'company.na' | translate}}
                  </span>
                </div>

                <div class="flex_wrapper" [class.disabled]="!employee.enabled" *ngIf="!employee.user">
                  <div class="avatar_container">
                    <div class="avatar avatar_empty"></div>
                  </div>

                  <span class="name">{{'company.na' | translate}}</span>
                </div>
              </div>

              <div class="table_row_cell">
                <span [class.disabled]="!employee.enabled">{{employee.email}}</span>
              </div>

              <div class="table_row_cell">
                <span *ngIf="employee.user" [class.disabled]="!employee.enabled">
                  {{employee.user.telephone || 'company.na' | translate}}
                </span>

                <span *ngIf="!employee.user" [class.disabled]="!employee.enabled">{{'company.na' | translate}}</span>
              </div>

              <div class="table_row_cell">
                <span [class.disabled]="!employee.enabled">{{employee.request}}</span>
              </div>

              <div class="table_row_cell">
                <span class="status" [ngClass]="{'active': employee.status_pai.state}">
                  {{employee.status_pai.name}}
                </span>
              </div>

              <div class="table_row_cell actions">
                <app-button color="secondary" [icon]="true" [matMenuTriggerFor]="menu">
                  <svg width="16" height="16" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.00002 3.33366C2.73335 3.33366 3.33335 2.73366 3.33335 2.00033C3.33335 1.26699 2.73335 0.666992 2.00002 0.666992C1.26669 0.666992 0.666687 1.26699 0.666687 2.00033C0.666687 2.73366 1.26669 3.33366 2.00002 3.33366ZM2.00002 4.66699C1.26669 4.66699 0.666687 5.26699 0.666687 6.00033C0.666687 6.73366 1.26669 7.33366 2.00002 7.33366C2.73335 7.33366 3.33335 6.73366 3.33335 6.00033C3.33335 5.26699 2.73335 4.66699 2.00002 4.66699ZM2.00002 8.66699C1.26669 8.66699 0.666687 9.26699 0.666687 10.0003C0.666687 10.7337 1.26669 11.3337 2.00002 11.3337C2.73335 11.3337 3.33335 10.7337 3.33335 10.0003C3.33335 9.26699 2.73335 8.66699 2.00002 8.66699Z" />
                  </svg>
                </app-button>

                <mat-menu #menu class="action-menu" yPosition="below">
                  <button mat-menu-item (click)="sendEmailDialog(i)">
                    <img src="/assets/img/mail.svg" alt="mail">

                    <span>{{ 'employees.send_email' | translate }}</span>
                  </button>

                  <button
                    *ngIf="employee.original_request === 'PENDING' || employee.original_request === 'DECLINED'"
                    mat-menu-item
                    (click)="resendInvite(employee)"
                  >
                    <img src="/assets/img/invitation.svg" alt="invitation">

                    <span>{{ 'employees.resend_invitation' | translate }}</span>
                  </button>

                  <button
                    *ngIf="false && employee.enabled && employee.original_request === 'ACCEPTED'"
                    mat-menu-item
                    (click)="disableEmployee(employee)"
                  >
                    <img src="/assets/img/disable.svg" alt="disable">

                    <span>{{ 'employees.disable_employee' | translate }}</span>
                  </button>

                  <button
                    *ngIf="false && !employee.enabled && employee.original_request === 'ACCEPTED'"
                    mat-menu-item
                    (click)="enableEmployee(employee)"
                  >
                    <img src="/assets/img/add-person.svg" alt="enable">

                    <span>{{ 'employees.enable_employee' | translate }}</span>
                  </button>
                </mat-menu>

                <app-button color="remove" [icon]="true" (click)="deleteEmployeeDialog(employee?.id)">
                  <i>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.99992 12.6667C3.99992 13.4 4.59992 14 5.33325 14H10.6666C11.3999 14 11.9999 13.4 11.9999 12.6667V4.66667H3.99992V12.6667ZM12.6666 2.66667H10.3333L9.66659 2H6.33325L5.66659 2.66667H3.33325V4H12.6666V2.66667Z"/>
                    </svg>
                  </i>
                </app-button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isImportingEmployees" class="component_window page_card">
      <div class="table_wrapper">
        <header class="import-header">
          <div class="table_head_cell">
            {{ 'common.valid' | translate }}
          </div>
          <div class="table_head_cell">
            {{ 'common.invalid' | translate }}
          </div>
        </header>

        <div class="page_content import-content">
          <div class="table_body" *ngIf="getEmailsCounter()">

            <div class="table_row import-table-row" *ngFor="let iterator of getEmailsCounter(); index as i">
              <div class="table_row_cell">
              <span>
                {{this.importedEmployees.validEmails[i] || ''}}
              </span>
              </div>
              <div class="table_row_cell">
              <span class="invalid-email">
                {{this.importedEmployees.invalidEmails[i] || ''}}
              </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</article>

<ng-template #employeeSearch>
  <div class="employee-search">
    <i class="search-icon">
      <svg width="18" height="18" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.8333 18.3333H19.5167L19.05 17.8833C20.6833 15.9833 21.6667 13.5167 21.6667 10.8333C21.6667 4.85 16.8167 0 10.8333 0C4.85 0 0 4.85 0 10.8333C0 16.8167 4.85 21.6667 10.8333 21.6667C13.5167 21.6667 15.9833 20.6833 17.8833 19.05L18.3333 19.5167V20.8333L26.6667 29.15L29.15 26.6667L20.8333 18.3333ZM10.8333 18.3333C6.68333 18.3333 3.33333 14.9833 3.33333 10.8333C3.33333 6.68333 6.68333 3.33333 10.8333 3.33333C14.9833 3.33333 18.3333 6.68333 18.3333 10.8333C18.3333 14.9833 14.9833 18.3333 10.8333 18.3333Z" fill="#9899A0"/>
      </svg>
    </i>

    <app-material-input
      [formControl]="searchValueControl"
      [placeholder]="'company.searchEmployee' | translate"
    ></app-material-input>

    <img
      *ngIf="searchValueControl.value"
      class="search-clear"
      src="/assets/img/search-clear.svg"
      alt="Clear"
      (click)="clearSearchValue()"
    >
  </div>
</ng-template>

<ng-template #noSearchResults>
  <div *ngIf="searchValueControl.value" class="no-search-results-container">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8333 18.3333H19.5167L19.05 17.8833C20.6833 15.9833 21.6667 13.5167 21.6667 10.8333C21.6667 4.85 16.8167 0 10.8333 0C4.85 0 0 4.85 0 10.8333C0 16.8167 4.85 21.6667 10.8333 21.6667C13.5167 21.6667 15.9833 20.6833 17.8833 19.05L18.3333 19.5167V20.8333L26.6667 29.15L29.15 26.6667L20.8333 18.3333ZM10.8333 18.3333C6.68333 18.3333 3.33333 14.9833 3.33333 10.8333C3.33333 6.68333 6.68333 3.33333 10.8333 3.33333C14.9833 3.33333 18.3333 6.68333 18.3333 10.8333C18.3333 14.9833 14.9833 18.3333 10.8333 18.3333Z" fill="#606873"/>
    </svg>

    <span class="no-search-results-container__text">{{ 'company.noSearchResults' | translate }}</span>
  </div>
</ng-template>
