import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {FeathersService} from '@services/feathers.service';
import {StorageService} from '@services/storage.service';

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(
    private feathers: FeathersService,
    private router: Router,
    private storage: StorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.feathers.isAutentificated() || this.storage.storage.getItem('jwt')) {
      return true;
    }

    if (state.url.includes('utv')) {
      this.storage.storage.setItem('redirectAfterLoginUrl', state.url);
    }

    return this.router.parseUrl('/login');
    return true
  }
}
