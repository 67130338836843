<header class="header" [ngClass]="{'header--has-tabs': tabs?.length, 'header--extra': extra, 'header--round': isRound}" #host>
  <div class="header-name" *ngIf="!isRound">
    <div class="back" *ngIf="backLink">
      <app-icon-button routerLink="{{backLink}}" color="accent">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M13.3333 7.33333H5.22L8.94666 3.60667L8 2.66667L2.66666 8L8 13.3333L8.94 12.3933L5.22 8.66667H13.3333V7.33333Z" fill="#f11c48"/>
        </svg>
      </app-icon-button>
    </div>
    <div class="header-name-text">{{name}}</div>
  </div>

  <app-button
    *ngIf="isShowArrows && activeIndex > 0"
    class="arrow arrow--left"
    [icon]="true"
    [border]="false"
    (click)="changeTabOnArrow(-1)"
  >
    <i>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2733 4.94L9.33333 4L5.33333 8L9.33333 12L10.2733 11.06L7.21999 8L10.2733 4.94Z"/>
      </svg>
    </i>
  </app-button>

  <app-button
    *ngIf="isShowArrows && activeIndex < tabs?.length - 1"
    class="arrow arrow--right"
    [icon]="true"
    [border]="false"
    (click)="changeTabOnArrow(1)"
  >
    <i>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66666 4L5.72666 4.94L8.77999 8L5.72666 11.06L6.66666 12L10.6667 8L6.66666 4Z" fill="black"/>
      </svg>
    </i>
  </app-button>

  <div class="header-content" *ngIf="extra || tabs">
    <div class="extra" *ngIf="extra">
      <template [ngTemplateOutlet]="extra"></template>
    </div>
    <nav class="navigation-bar" *ngIf="tabs" #tabsElement>
      <ul class="tabs">
        <li class="tab" [class.activeText]="isActiveText" [ngClass]="{'tab--active': tab.id === activeTab?.id, 'tab--disabled': tab.disabled}"
            (click)="changeActiveTab(tab, i)"
            *ngFor="let tab of tabs; index as i" [id]="'tab-' + tab.id"
        >
          {{ tab.title | translate }}
        </li>
      </ul>
    </nav>
  </div>

  <div class="header-buttons">
    <ng-content></ng-content>
  </div>
</header>
