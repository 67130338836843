import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

import {SESSION} from '../../../utils/enum';
import {SessionPreview} from '../../../models/session-preview';
import {CalendarService} from '../../../services/calendar.service';
import {MobileService} from '../../../services/mobile.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-training-view',
  templateUrl: './training-view.component.html',
  styleUrls: ['./training-view.component.scss'],
})
export class TrainingViewComponent implements OnInit, OnDestroy {
  public SESSION: typeof SESSION = SESSION;
  public actions: boolean;
  public session: any;
  @Input() data;

  private destroy$ = new Subject<void>();

  get isMobile() {
    return this.mobileService.isMobile;
  }

  constructor(
    public translate: TranslateService,
    protected calendarService: CalendarService,
    private mobileService: MobileService,
    protected snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.calendarService.getTraining(this.data.id).subscribe((session: SessionPreview) => {
      this.session = session as SessionPreview;
    });
    this.actions = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Confirm the request
   */
  confirm(): void {
    if (this.data.type === SESSION.EVENT_TYPE) {
      this.confirmEvent();
    } else if (this.data.type === this.SESSION.TRAINING_TYPE) {
      this.confirmTraining();
    }
  }

  /**
   * Reject the request
   */
  reject(): void {
    if (this.data.type === SESSION.EVENT_TYPE) {
      this.cancelEvent();
    } else if (this.data.type === this.SESSION.TRAINING_TYPE) {
      this.cancelTraining();
    }
  }

  /**
   * Comfirm the event
   */
  confirmEvent() {
    this.calendarService.resolveEvent(this.data.id, {
      event_status: this.SESSION.UPCOMING,
    }).pipe(
      finalize(() => {
        this.calendarService.activity('event').close();
      }),
      takeUntil(this.destroy$),
    )
      .subscribe(() => {
        this.snackbar.open(this.translate.instant('events.eventConfirmed'), '', {panelClass: 'success', duration: 3000});
      }, (error) => {
        this.snackbar.open(error.error.message, '', {panelClass: 'error', duration: 3000});
      });
  }

  /**
   * Comfirm the training
   */
  confirmTraining() {
    this.calendarService.confirmTraining(this.session.id)
      .pipe(
        finalize(() => {
          this.calendarService.activity('session').close();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.snackbar.open(this.translate.instant('requests.confirmSuccess'), '', {panelClass: 'success', duration: 3000});
      });
  }

  /**
   * Cancel the event
   */
  cancelEvent() {
    this.calendarService.resolveEvent(this.data.id, {
      event_status: this.SESSION.CANCELED_BY_TRAINER,
      message_of_cancellation: '',
    }).pipe(
      finalize(() => {
        this.calendarService.activity('event').close();
      }),
      takeUntil(this.destroy$),
    )
      .subscribe(() => {
        this.snackbar.open(this.translate.instant('events.eventCancelled'), '', {panelClass: 'info', duration: 3000});
      }, (error) => {
        this.snackbar.open(error.error.message, '', {panelClass: 'error', duration: 3000});
      });
  }

  /**
   * Cancel the training
   */
  cancelTraining() {
    this.calendarService.cancelTraining(this.session.id)
      .pipe(
        finalize(() => {
          this.calendarService.activity('session').close();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.snackbar.open(this.translate.instant('requests.cancelSuccess'), '', {panelClass: 'info', duration: 3000});
      });
  }

  public get isCancelled(): boolean {
    let status;

    if (!this.session) {
      return false;
    }

    if (this.session.type === this.SESSION.TRAINING_TYPE) {
      status = this.session.training_status;
    } else {
      status = this.session.event_status;
    }

    return status === this.SESSION.CANCELED ||
      status === this.SESSION.CANCELED_BY_TRAINER ||
      status === this.SESSION.CANCELED_SOS ||
      status === this.SESSION.NOT_ENOUGH_MEMBERS ||
      status === this.SESSION.EXPIRED;
  }

  public get isFinished(): boolean {
    let status;

    if (!this.session) {
      return false;
    }

    if (this.session.type === this.SESSION.TRAINING_TYPE) {
      status = this.session.training_status;
    } else {
      status = this.session.event_status;
    }
    return status === this.SESSION.FINISHED;
  }

  public get isConfirmed(): boolean {
    let status;

    if (!this.session) {
      return false;
    }

    if (this.session.type === this.SESSION.TRAINING_TYPE) {
      status = this.session.training_status;
    } else {
      status = this.session.event_status;
    }

    return status === this.SESSION.UPCOMING ||
      status === this.SESSION.STARTED;
  }

  public get isRequest(): boolean {
    let status;
    if (!this.session) {
      return false;
    }

    if (this.session.type === this.SESSION.TRAINING_TYPE) {
      status = this.session.training_status;
    } else {
      status = this.session.event_status;
    }

    return status === this.SESSION.UNCONFIRMED ||
      status === this.SESSION.ON_REQUEST;
  }
}
