import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Subscription} from 'rxjs';

import {ENUM} from '@util/enum';
import {fadeInOut} from '@modules/material/material-animations/fade-in-out';
import {CalendarService} from '@services/calendar.service';
import {ProfileService} from '@services/profile.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-month-view',
  templateUrl: './month-view.component.html',
  styleUrls: ['./month-view.component.scss'],
  animations: [fadeInOut],
})
export class MonthViewComponent extends ENUM implements OnInit, OnDestroy, OnChanges {
  public weekDays: Moment[];
  @Input() public currentMonth: Moment;
  public days: Moment[];
  public today: Moment;
  public sessions: any;

  // number of weeks is constant by design
  private numberOfWeeks = 6;
  private daysInWeek = 7;
  private subs: Subscription;
  constructor(
    private calendarService: CalendarService,
    private profileService: ProfileService,
    public translate: TranslateService,
    ) {
    super();
  }

  ngOnInit(): void {
    this.subs = new Subscription();
    this.createCalendar();
    this.today = moment();
    this.subs.add(
      this.calendarService.calendar.subscribe( calendar => {
        this.mapSessions(calendar);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.currentMonth) {
      this.createCalendar();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public convertDate(date: Moment): string {
    return date.clone().startOf('day').toString();
  }

  /**
   * Fire day selection event
   * @param day
   */
  public selectDay(day): void {
    this.calendarService.activity('day', day).open();
  }

  private createCalendar(): void {
    this.weekDays = new Array(this.daysInWeek).fill(undefined).map( (el, index) => {
      return this.currentMonth.clone().isoWeekday(index + 1);
    });
    this.days = new Array(this.numberOfWeeks * this.daysInWeek).fill(undefined).map( (el, index) => {
      return this.currentMonth.clone().date(0).isoWeekday(index + 1);
    });
  }

  private mapSessions(calendar: any[]): void {
    this.sessions = {};

    calendar.map( el => {
      const begin = moment(el.begin).startOf('day');
      if (this.sessions[begin.toString()]) {
        this.sessions[begin.toString()].push(el);
      } else {
        this.sessions[begin.toString()] = [el];
      }
    });
  }
}
