import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-trial-period',
  templateUrl: './trial-period.component.html',
  styleUrls: ['./trial-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    MatDialogModule,
  ],
})
export class TrialPeriodComponent {}
