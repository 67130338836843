import { Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import { SOCKET_EVENTS } from '../enums/chat.enum';
import { ChatHistory, ChatItem, ChatMessage, Member, MessageTyping } from '@modules/chats/interfaces';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ChatTrigger } from '../interfaces';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SocketService {

  private readonly _chat$ = new Subject<ChatItem>();
  private readonly _chatList$ = new Subject<ChatItem[]>();
  private readonly _chatCreate$ = new Subject<ChatItem>();
  private readonly _messageNew$ = new Subject<ChatMessage>();
  private readonly _chatHistory$ = new Subject<ChatHistory>();
  private readonly _messageTyping$ = new BehaviorSubject<MessageTyping>(null);
  private readonly _chatLeave$ = new BehaviorSubject<ChatItem>(null);
  private readonly _chatDelete$ = new Subject<{ chatId: ChatItem['chatId'] }>();
  private readonly _chatEdit$ = new Subject<ChatItem>();
  private readonly _memberStatus$ = new BehaviorSubject<Member>(null);
  private readonly _chatInfo$ = new Subject<ChatItem>();
  private readonly _messageStatus$ = new BehaviorSubject<ChatMessage>(null);
  private readonly _messageEdit$ = new Subject<ChatMessage>();
  private readonly _messageDelete$ = new Subject<ChatMessage>();
  private readonly _chatTrigger$ = new Subject<ChatTrigger>();
  private readonly _memberDelete$ = new Subject<ChatItem>();
  private readonly _membersAdd$ = new Subject<Member[]>();
  private readonly _exception$ = new Subject<HttpErrorResponse>();

  private socket: Socket;

  get socketConnection() {
    return this.socket;
  }

  get chat$(): Observable<ChatItem> {
    return this._chat$.asObservable();
  }
  get chatList$(): Observable<ChatItem[]> {
    return this._chatList$.asObservable();
  }
  get chatCreate$(): Observable<ChatItem> {
    return this._chatCreate$.asObservable();
  }
  get messageNew$(): Observable<ChatMessage> {
    return this._messageNew$.asObservable();
  }
  get chatHistory$(): Observable<ChatHistory> {
    return this._chatHistory$.asObservable();
  }
  get messageTyping$(): Observable<MessageTyping> {
    return this._messageTyping$.asObservable();
  }
  get chatLeave$(): Observable<ChatItem> {
    return this._chatLeave$.asObservable();
  }
  get chatDelete$(): Observable<{ chatId: ChatItem['chatId'] }> {
    return this._chatDelete$.asObservable();
  }
  get memberDelete$(): Observable<ChatItem> {
    return this._memberDelete$.asObservable();
  }
  get membersAdd$(): Observable<Member[]> {
    return this._membersAdd$.asObservable();
  }
  get chatEdit$(): Observable<ChatItem> {
    return this._chatEdit$.asObservable();
  }
  get memberStatus$(): Observable<Member> {
    return this._memberStatus$.asObservable();
  }
  get chatInfo$(): Observable<ChatItem> {
    return this._chatInfo$.asObservable();
  }
  get messageStatus$(): Observable<ChatMessage> {
    return this._messageStatus$.asObservable();
  }
  get messageEdit$(): Observable<ChatMessage> {
    return this._messageEdit$.asObservable();
  }
  get messageDelete$(): Observable<ChatMessage> {
    return this._messageDelete$.asObservable();
  }
  get chatTrigger$(): Observable<ChatTrigger> {
    return this._chatTrigger$.asObservable();
  }

  get exception$(): Observable<HttpErrorResponse> {
    return this._exception$.asObservable();
  }

  disconnect(): void {
    this.socket.disconnect();
  }

  connect(): void {
    this.init();
    this.listenConnectEvent();
  }

  emitData(event: SOCKET_EVENTS, params): void {
    this.socket.emit(event, params);
  }

  listenEvents(): void {
    this.socketConnection.on(SOCKET_EVENTS.CHAT_LIST, (data: ChatItem[]) => {
      this._chatList$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.CHAT, (data: ChatItem) => {
      this._chat$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.CHAT_CREATE, (data: ChatItem) => {
      this._chatCreate$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.MESSAGE_NEW, (data: ChatMessage) => {
      this._messageNew$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.CHAT_HISTORY, (data: ChatHistory) => {
      this._chatHistory$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.MESSAGE_TYPING, (data: MessageTyping) => {
      this._messageTyping$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.CHAT_LEAVE, (data: ChatItem) => {
      this._chatLeave$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.CHAT_EDIT, (data: ChatItem) => {
      this._chatEdit$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.CHAT_DELETE, (data: { chatId: ChatItem['chatId'] }) => {
      this._chatDelete$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.MEMBER_STATUS, (data: Member) => {
      this._memberStatus$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.CHAT_INFO, (data: ChatItem) => {
      this._chatInfo$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.MESSAGE_STATUS, (data: ChatMessage) => {
      this._messageStatus$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.MESSAGE_EDIT, (data: ChatMessage) => {
      this._messageEdit$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.MESSAGE_DELETE, (data: ChatMessage) => {
      this._messageDelete$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.CHAT_TRIGGER, (data) => {
      this._chatTrigger$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.MEMBER_DELETE, (data) => {
      this._memberDelete$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.MEMBER_ADD, (data) => {
      this._membersAdd$.next(data);
    });

    this.socketConnection.on(SOCKET_EVENTS.EXCEPTION, (data) => {
      this._exception$.next(data);
    });
  }

  private listenConnectEvent(): void {
    this.socketConnection.on('connect', () => {
      this.listenEvents();
    });
  }

  private init(): void {
    const host = environment.SOCKET_URL;
    const token = localStorage.getItem('jwt');

    this.socket = io(`${host}/chats`, {
      query: { token },
      transports: ['websocket'],
    });
  }
}
