import { Injectable } from '@angular/core';
import { CHAT_HISTORY_TYPE } from '../enums/chat.enum';
import { ChatHistoryRequest, ChatItem, NewMessage } from '../interfaces';
import { Store } from '@ngrx/store';
import { loadChatHistory, sendMessage } from '../store/chat.actions';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { chatHistory } from '../store/chat.selectors';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { RestApiService } from '@modules/chats/services/rest-api.service';
import { GroupChatService } from '@modules/chats/services/group-chat.service';
import { ChatService } from '@modules/chats/services/chat.service';

@Injectable({
  providedIn: 'root',
})
export class ChatMessagesService {
  private readonly messageLimit = 50 as const;
  private readonly scrollToBottomSubject$ = new Subject<void>();
  private readonly selectedFilesSubject$ = new Subject<File[]>();
  private readonly selectedImagesSubject$ = new BehaviorSubject<File[]>([]);

  constructor(
    private store: Store,
    private restApiService: RestApiService,
    private groupChatService: GroupChatService,
    private chatService: ChatService,
  ) { }

  loadMessages(chatItem: ChatItem): void {
    const chatHistoryRequest: ChatHistoryRequest = {
      chatId: chatItem.chatId,
      type: CHAT_HISTORY_TYPE.BEFORE,
      messageId: chatItem.lastMessage.messageId,
      limit: this.messageLimit,
    };

    this.store.dispatch(loadChatHistory({ chatHistoryRequest }));
  }

  loadOldMessages(chatItem: ChatItem): Observable<void> {
    return this.store.select(chatHistory)
      .pipe(
        take(1),
        tap((chatHistory) => {
          const chatHistoryRequest: ChatHistoryRequest = {
            chatId: chatItem.chatId,
            type: CHAT_HISTORY_TYPE.AFTER,
            messageId: 0,
            limit: this.messageLimit,
            cursor: chatHistory?.cursor,
          };

          this.store.dispatch(loadChatHistory({ chatHistoryRequest }));
        }),
        map(() => void 0),
      );
  }

  getScrollToBottomEvent(): Observable<void> {
    return this.scrollToBottomSubject$.asObservable();
  }

  scrollToBottom(): void {
    this.scrollToBottomSubject$.next();
  }

  addFiles(files: File[]): void {
    this.selectedFilesSubject$.next(files);
  }

  getFiles(): Observable<File[]> {
    return this.selectedFilesSubject$.asObservable();
  }

  addImages(images: File[]): void {
    const currentImages = this.selectedImagesSubject$.value;
    const nextImages = [...currentImages, ...images].slice(0, 10);

    this.selectedImagesSubject$.next(nextImages);
  }

  getImageUrls(): Observable<string[]> {
    return this.selectedImagesSubject$.asObservable()
      .pipe(
        switchMap((images) => this.getPreviewImages(images))
      );
  }

  removeImageByIndex(index: number): void {
    const filteredImages = this.selectedImagesSubject$.value.filter((image, imageIndex) => imageIndex !== index);

    this.selectedImagesSubject$.next(filteredImages);
  }

  sendMessage(chatItem: ChatItem, messageText: string): Observable<unknown> {
    if (!messageText && !this.selectedImagesSubject$.value.length) {
      return of(null);
    }

    const imageUrls$ = this.selectedImagesSubject$.value.map((image) => this.chatService.getFileUrl(image));

    this.selectedImagesSubject$.next([]);

    return forkJoin(imageUrls$.length ? imageUrls$ : of(null))
      .pipe(
        map((pictures) => imageUrls$.length ? pictures : []),
        tap((pictureLinks: string[]) => {
          const companyId = chatItem.options?.company?.id || JSON.parse(localStorage.getItem('company'))?.id;
          const message: NewMessage = {
            frameId: Date.now().toString(),
            chatId: chatItem.chatId,
            text: messageText,
            options: {
              company: {
                id: chatItem.options?.company?.id || companyId,
              },
              attachedPictures: pictureLinks.map((pictureLink) => ({ attachedPicture: pictureLink, attachedPicturePreview: pictureLink })),
            },
          };

          this.store.dispatch(sendMessage({ message }));
        }),
      );
  }

  private getPreviewImages(images: File[]): Observable<string[]> {
    const imageReadObservables: Observable<string>[] = [];

    images.forEach((image) => {
      const reader = new FileReader();

      const readerObservable = new Observable<string>((observer) => {
        reader.onload = (readerEvent) => {
          observer.next(readerEvent.target.result as string);
          observer.complete();
        };

        reader.readAsDataURL(image);
      });

      imageReadObservables.push(readerObservable);
    });

    return imageReadObservables.length ? forkJoin(imageReadObservables) : of([]);
  }
}
