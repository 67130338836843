import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';

import {PROFILE_TABS} from '../../../utils/enum';
import {MobileService} from '../../../services/mobile.service';
import {ProfileService} from '../../../services/profile.service';
import {ConfirmationComponent} from '../../modals/confirmation/confirmation.component';

@Component({
  selector: 'app-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderTabsComponent implements OnInit, OnDestroy {

  @Input()
  tabs: PROFILE_TABS[];

  @Input()
  isProfileConfirmed: boolean;

  @ViewChild('headerTabs', { static: true })
  headerTabs: ElementRef;

  @Output()
  changedTab = new EventEmitter<PROFILE_TABS>();

  private _currentTab: PROFILE_TABS;
  private tabToNavigate: PROFILE_TABS;
  private destroy$ = new Subject<void>();
  private isDialogOpened: boolean;
  private subs: Subscription;

  public isPartnerUnconfirmed: boolean;
  public limitedTab = PROFILE_TABS.PAYMENT;
  public canEditProfile: boolean;

  get currentTab() {
    return this._currentTab;
  }

  get isMobile() {
    return this.mobileService.isMobile;
  }

  get isFirstTab() {
    return this.tabs.indexOf(this.currentTab) === 0;
  }

  get isLastTab() {
    return this.tabs.indexOf(this.currentTab) === this.tabs.length - 1;
  }

  get isTrainer() {
    return this.profileService.isProfile('TRAINER_TYPE');
  }

  set currentTab(tabName) {
    if (this.isExistingTab(tabName)) {
      this._currentTab = tabName;
    } else {
      this._currentTab = PROFILE_TABS.PERSONAL;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private translate: TranslateService,
    private mobileService: MobileService,
    public profileService: ProfileService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.canEditProfile = true;
    this.isDialogOpened = false;
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.subs.add(
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      }),
    );
    this.setActiveTab(PROFILE_TABS.PERSONAL);
    this.listenRouter();
    this.isPartnerUnconfirmed = !this.isTrainer && !this.isProfileConfirmed;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Listen when router was changed
   */
  listenRouter() {
    this.route.queryParams
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((query) => {
        const { tab } = query;

        if (tab) {
          this.setActiveTab(tab);
        }
      });
  }

  /**
   * Activate another tab
   */
  changeTab(tab: PROFILE_TABS): any {
    if (this.isPartnerUnconfirmed && tab === this.limitedTab) {
      return;
    }

    this.tabToNavigate = tab;
    if (!this.canEditProfile) {
      this.openConfirmDialog();
    }

    if (!this.isDialogOpened) {
      this.setActiveTab(tab);
      this.router.navigate(['/profile'], {
        queryParams: {},
        fragment: null,
      });
    }
  }

  /**
   * Set the active tab and scroll to them
   */
  setActiveTab(tab: PROFILE_TABS) {
    const index = this.tabs.indexOf(tab);

    if (index !== -1) {
      this.currentTab = tab;

      this.changedTab.emit(this.currentTab);

      // Scroll to tab. Necessary check, because we don`t know if the viewChild was loaded
      if (this.headerTabs.nativeElement.children.length) {
        const { children } = this.headerTabs.nativeElement;
        this.headerTabs.nativeElement.scrollLeft = children[index].offsetLeft;
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  openConfirmDialog() {
    this.isDialogOpened = true;
    this.dialog.open(ConfirmationComponent, {
      data: {
        actionTxt: this.translate.instant('profile.confirmation.button1'),
        bodyTxt: this.translate.instant('profile.confirmation.message'),
        headerTxt: this.translate.instant('profile.confirmation.header1'),
      },
    })
      .afterClosed()
      .subscribe(() => {
        this.isDialogOpened = false;
        this.confirmCancelEditProfile();
      });
  }

  confirmCancelEditProfile = () => {
    this.profileService.editProfile(true);
    this.profileService.cancelEditProfile();
    this.dialog.closeAll();
    this.isDialogOpened = false;
    this.changeTab(this.tabToNavigate);
  };

  isExistingTab(tab: PROFILE_TABS) {
    return Object.values(PROFILE_TABS).includes(tab);
  }

  prevTab() {
    let index = this.tabs.indexOf(this.currentTab);
    if (index > 0) {
      index--;
    }

    this.setActiveTab(this.tabs[index]);
  }

  nextTab() {
    let index = this.tabs.indexOf(this.currentTab);
    if (index < this.tabs.length - 1) {
      index++;
    }

    this.setActiveTab(this.tabs[index]);
  }
}
