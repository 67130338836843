import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Employee } from '@models/employee';
import { ChatEmployee } from '../interfaces/chat-employee.interface';
import { Response } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {

  private apiUrl = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  public getEmployeeList(): Observable<ChatEmployee[]> {
    return this.http.get<Response<Employee>>(`${this.apiUrl}/employees?$limit=1000&"request"=ACCEPTED`)
      .pipe(
        map((response) => {
          return response.data
            .map((employee) => ({
              externalId: employee.user.id,
              firstName: employee.user.first_name || '',
              lastName: employee.user.last_name || '',
              picture: employee.user.avatar,
            }));
        }),
      );
  }

  uploadImage(data: FormData): Observable<{ key: string; url: string }> {
    return this.http.post<{ key: string; url: string }>(`${this.apiUrl}/chat-upload`, data);
  }

}
