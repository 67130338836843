<div class="input-container">
  <app-image-input
    class="logo-input"
    [openDialog]="openDialog"
    [formControl]="form.get('logo')"
    [label]="'company.logo' | translate"
    (imageUploaded)="showUploadNotification()"
  ></app-image-input>
</div>
<div class="buttons">
  <app-button (click)="openUploadDialog()">
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.83325 13.3333H13.1666V12H3.83325V13.3333ZM3.83325 6.66667H6.49992V10.6667H10.4999V6.66667H13.1666L8.49992 2L3.83325 6.66667Z" fill="#14BFFB"/>
    </svg>
    {{'common.upload' | translate}}
  </app-button>

  <app-button
    color="remove"
    [disabled]="!form.get('logo').value"
    [icon]="true"
    (click)="removeLogo()"
  >
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.99992 12.6667C3.99992 13.4 4.59992 14 5.33325 14H10.6666C11.3999 14 11.9999 13.4 11.9999 12.6667V4.66667H3.99992V12.6667ZM12.6666 2.66667H10.3333L9.66659 2H6.33325L5.66659 2.66667H3.33325V4H12.6666V2.66667Z" fill="#E8ECF3"/>
    </svg>
  </app-button>
</div>

