<label
  class="material_label"
  [ngClass]="{
    'material_label--focused': focused,
    'material_label--dirty': control.value,
    'material_label--disabled': control.disabled,
    'material_label--invalid': isInvalid
  }">
  {{label}}
</label>

<textarea
  class="material-textarea"
  [rows]="rows"
  [maxlength]="maxlength"
  [readonly]="readonly"
  (focus)="focus()"
  (blur)="blur()"
  [placeholder]="placeholder"
  (mouseenter)="emitEnterEvent()"
  [ngClass]="{
    'material-textarea--focused': focused,
    'material-textarea--dirty': control.value,
    'material-textarea--disabled': control.disabled,
    'material-textarea--invalid': isInvalid,
    'material-textarea--fluid': fluidHeight
  }"
  [formControl]="control">
</textarea>

<div class="hints-container">
  <div class="hints">
    <div class="error" *ngIf="error && !readonly" [ngClass]="{'error--red': isTouched}">
      *{{error}}
    </div>
    <div class="hint" *ngIf="hint && !error">
      *{{hint}}
    </div>
  </div>

  <div *ngIf="isShowCounter && maxlength" class="textarea-counter">
    {{counter}} / {{maxlength}}
  </div>
</div>
