import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {map, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {FeathersService} from '@services/feathers.service';
import {DataService} from '@services/data.service';
import {StorageService} from '@services/storage.service';
import {AuthService} from '@services/auth.service';
import {ProfileService} from '@services/profile.service';
import {PROFILE_TYPES} from '@util/enum';
import {fadeInOut} from '@modules/material/material-animations/fade-in-out';
import {dropDown} from '@modules/material/material-animations/drop-down';
import {MobileService} from '@services/mobile.service';
import {CalendarService} from '@services/calendar.service';
import {ZendeskService} from '@services/zendesk.service';
import {LanguageModalComponent} from '../../mobile/language-modal/language-modal.component';
import {ProfileTypeModalComponent} from '../../mobile/profile-type-modal/profile-type-modal.component';
import {StepperMobileService} from '@services/stepper-mobile.service';
import {ScrollLockService} from '@services/scroll-lock.service';
import {environment} from '../../../../environments/environment';
import { Subject } from 'rxjs';
import {UserEntity} from '@models/user/user.entity';
import {ProfileEntity} from '@models/user/profile.entity';
import { CompanyEntity } from '@models/company/company.entity';
import { Profile } from '@interfaces';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  animations: [fadeInOut, dropDown],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @Input()
  menuOpened: boolean;
  @ViewChild('profileAvatar', {static: false}) avatar: any;

  company: CompanyEntity;
  user: UserEntity;
  profile: ProfileEntity;
  showLanguageSettings: boolean;

  actions: any[];
  profiles: Profile[];
  profileType;

  protected destroy$ = new Subject<void>();


  get isDesktop(): boolean {
    return this.mobileService.isDesktop;
  }

  get isMobile(): boolean {
    return this.mobileService.isMobile;
  }

  get isTrainer(): boolean {
    return this.profileService.isProfile(PROFILE_TYPES.TRAINER_TYPE);
  }

  get isPartner(): boolean {
    return this.profileService.isProfile(PROFILE_TYPES.PARTNER_TYPE);
  }

  get isClient(): boolean {
    return this.profileService.isProfile(PROFILE_TYPES.CLIENT_TYPE);
  }

  get isShowForm(): boolean {
    return this.profiles.length > 1;
  }

  constructor(
    public profileService: ProfileService,
    public translate: TranslateService,
    public data: DataService,
    public mobileService: MobileService,
    public calendarService: CalendarService,
    protected feathers: FeathersService,
    protected router: Router,
    protected storageService: StorageService,
    protected auth: AuthService,
    protected http: HttpClient,
    protected zen: ZendeskService,
    protected scrollLockService: ScrollLockService,
    private dialog: MatDialog,
    private stepperMobileService: StepperMobileService,
  ) {
    this.profiles = [];
  }

  ngOnInit(): void {
    this.profileService.user
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.user = user;
        this.profiles = [];
        if (user && user.profiles.length) {
          user.profiles.forEach(profile => {
            this.profiles.push(this.getProfileByType(profile.type));

          });
        }
      });

    this.profileService.trainer
      .pipe(takeUntil(this.destroy$))
      .subscribe(trainer => {
        this.profile = trainer;
        this.setProfileTypes();
      });

    this.profileService.company
      .pipe(takeUntil(this.destroy$))
      .subscribe((company) => {
        this.company = company;
      });

  }

  setProfileTypes(): void {
    // const profile_type = this.isTrainer ? 'TRAINER_TYPE' : 'PARTNER_TYPE';
    this.profileType = new UntypedFormGroup({
      profile_type: new UntypedFormControl(this.profile.type, [
        Validators.required,
      ]),
      profile_type_short: new UntypedFormControl(this.profile.type, [
        Validators.required,
      ]),
    });

    this.profileType.controls.profile_type.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.switchProfile(value);
      });

    this.profileType.controls.profile_type_short.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.switchProfile(value);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.auth.logoutWithNavigation();
  }

  openMenu(): void {
    this.menuOpened = !this.menuOpened;
  }

  closeMenu(): void {
    if (this.menuOpened) {
      this.menuOpened = false;
    }
  }

  toggleLanguageSettings(open?: boolean): void {
    if (open || open === false) {
      this.showLanguageSettings = open;
    } else {
      this.showLanguageSettings = !this.showLanguageSettings;
    }
  }

  /**
   * Method to change application language.
   * @param language e.g. 'en', 'nb-nn'
   */
  changeLang(language: string): void {
    this.data.setLanguage(language);
    location.reload();
  }

  /**
   * Downloads PDF from /assets
   */
  downloadPDF(): void {
    this.http.get(`${environment.API_URL}/${this.getHelpGuide()}`, {
      responseType: 'blob',
    })
      .pipe(
        map(response => {
          return new Blob([response], {type: 'application/pdf'});
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(blob => {
        const downloadFile = window.document.createElement('a');
        downloadFile.href = window.URL.createObjectURL(blob);
        downloadFile.download = 'Memento U info guide.pdf';
        document.body.appendChild(downloadFile);
        downloadFile.click();
        document.body.removeChild(downloadFile);
      });
  }

  switchProfile(type: PROFILE_TYPES): void {
    this.profileService.switchProfile(type);
  }

  /**
   * Mobile only
   * Opens language modal
   */
  openLanguageModal(): void {
    this.dialog.open(LanguageModalComponent);
  }

  /**
   * Mobile only
   * Opens modal with profile type selection
   */
  openProfileTypeModal(): void {
    this.dialog.open(ProfileTypeModalComponent);
  }

  openStepperModile(): void {
    this.stepperMobileService.open();
  }

  /**
   * Return the help-guide url relative to type of the profile
   */
  getHelpGuide(): string {
    if (this.isTrainer) {
      return 'dashboard-guide-trainer-no.pdf';
    } else if (this.isPartner) {
      return 'dashboard-guide-partner-no.pdf';
    }
  }

  private getProfileByType(type: PROFILE_TYPES): Profile {
    return {
      [PROFILE_TYPES.TRAINER_TYPE]: {
        value: PROFILE_TYPES.TRAINER_TYPE,
        title: 'Memento trainer',
        titleShort: 'MT',
      },
      [PROFILE_TYPES.PARTNER_TYPE]: {
        value: PROFILE_TYPES.PARTNER_TYPE,
        title: 'Activity partner',
        titleShort: 'AP',
      },
      [PROFILE_TYPES.CLIENT_TYPE]: {
        value: PROFILE_TYPES.CLIENT_TYPE,
        title: this.translate.instant('common.client'),
        titleShort: 'U',
      },
    }[type];
  }
}
