import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { EMAIL_REG_EXP, PASSWORD_REG_EXP } from '@shared/utils';
import {Subject} from 'rxjs';
import {CompanyService} from '@services/company.service';
import {take, takeUntil} from 'rxjs/operators';
import {DataService} from '@services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {CompanySigninEntity} from '@models/company/company-signin.entity';
import {SIGN_IN_STRATEGY} from '@util/enum';
import {StorageService} from '@services/storage.service';
import {ProfileService} from '@services/profile.service';
import {CompanyEntity} from '@models/company/company.entity';
import { AuthService } from '@services/auth.service';
import {FeathersService} from '@services/feathers.service';
import { SocketControllerService } from '@modules/chats/services/socket-controller.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-sign-in',
  templateUrl: './company-sign-in.component.html',
  styleUrls: [
    './company-sign-in.component.scss',
    '../login/login.component.scss',
  ],
})
export class CompanySignInComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  constructor(
    public router: Router,
    private companyService: CompanyService,
    private data: DataService,
    private feathers: FeathersService,
    private translate: TranslateService,
    private storageService: StorageService,
    private profileService: ProfileService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private socketControllerService: SocketControllerService,
  ) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_REG_EXP),
      ]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_REG_EXP),
      ]),
    });

    this.readParams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitForm(): void {
    const data = new CompanySigninEntity({
      ...this.form.value,
      strategy: SIGN_IN_STRATEGY.LOCAL_COMPANY,
    });

    this.authService.logoutWithoutReload();

    this.companyService.signinCompany(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: {
        accessToken: string;
        company: CompanyEntity;
      }) => {
        // TODO Add company role
        this.storageService.storage.setItem('jwt', res.accessToken);
        this.storageService.storage.setItem('company', JSON.stringify(res.company));
        this.profileService.setCompany(res.company);
        this.socketControllerService.connectToChat().pipe(untilDestroyed(this)).subscribe();
        this.router.navigate(['/employees']);
      },
      (error) => {
        if (error.error.code === 401) {
          this.data.showNotification(error.error.message, null, 2500, 'error');
        }
      });
  }

  navigateToRestorePassword(): void {
    localStorage.setItem('restorePasswordType', 'company');

    this.router.navigate(['/restore-pass']);
  }

  private readParams(): void {
    const success = this.activatedRoute.snapshot.queryParams.confirmRegisterSuccess;

    if (success === 'true') {
      this.translate.get('company.signUpSuccessMessage')
        .pipe(take(1))
        .subscribe(str => {
          this.data.showNotification(
            str,
            '',
            5000,
            'success',
          );
        });
    }
  }
}
