<article class="page_card" *ngIf="form">
  <app-material-header
    class="profile-header"
    [isRound]="true"
    [tabs]="tabs"
    [activeTab]="activeTab"
    (tabChange)="activeTab = $event"
    [isActiveText]="true"
    [isShowArrows]="false"
  >
  </app-material-header>

  <div class="card_status" *ngIf="activeTab === tabs[0]">
    <div class="status_controls" >
      <div class="status">
        {{'company.generalInformation' | translate}}
      </div>
      <div class="buttons-container">
        <app-button
          class="edit_btn"
          color="primary"
          *ngIf="!isEditing"
          [icon]="!isDesktop"
          (click)="editProfile()"
        >
          <i>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 11.5V14H4.5L11.8733 6.62667L9.37333 4.12667L2 11.5Z" fill="black"/>
              <path d="M13.8067 3.75334L12.2467 2.19334C11.9867 1.93334 11.5667 1.93334 11.3067 2.19334L10.0867 3.41334L12.5867 5.91334L13.8067 4.69334C14.0667 4.43334 14.0667 4.01334 13.8067 3.75334Z" fill="black"/>
            </svg>
          </i>
          <span *ngIf="isDesktop">
            {{ 'common.edit' | translate }}
          </span>
        </app-button>

        <app-button
          class="save_btn"
          color="primary"
          *ngIf="isEditing"
          (click)="save()"
        >
          <i>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.33333 0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V2.66667L9.33333 0ZM6 10.6667C4.89333 10.6667 4 9.77333 4 8.66667C4 7.56 4.89333 6.66667 6 6.66667C7.10667 6.66667 8 7.56 8 8.66667C8 9.77333 7.10667 10.6667 6 10.6667ZM8 4H1.33333V1.33333H8V4Z" fill="#14BFFB"/>
            </svg>
          </i>
          <span>
            {{ 'common.save' | translate }}
          </span>
        </app-button>

        <app-button
          class="cancel_btn"
          *ngIf="isEditing"
          color="secondary"
          (click)="cancelEditProfile()"
        >
          <i>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.66683 1.27325L8.72683 0.333252L5.00016 4.05992L1.2735 0.333252L0.333496 1.27325L4.06016 4.99992L0.333496 8.72659L1.2735 9.66659L5.00016 5.93992L8.72683 9.66659L9.66683 8.72659L5.94016 4.99992L9.66683 1.27325Z" fill="#F11C48"/>
            </svg>
          </i>
          <span>
            {{ 'common.cancel' | translate }}
          </span>
        </app-button>

        <app-button
          class="delete_btn"
          color="remove"
          *ngIf="!isEditing"
          [icon]="!isDesktop"
          (click)="deleteCompany()"
        >
          <i>
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.99998 10.6667C0.99998 11.4 1.59998 12 2.33331 12H7.66665C8.39998 12 8.99998 11.4 8.99998 10.6667V2.66667H0.99998V10.6667ZM9.66665 0.666667H7.33331L6.66665 0H3.33331L2.66665 0.666667H0.333313V2H9.66665V0.666667Z" fill="#FFFFFF"/>
            </svg>
          </i>
          <span *ngIf="isDesktop">
            {{ 'company.deleteCompany' | translate }}
          </span>
        </app-button>
      </div>
    </div>
  </div>

  <section class="card_body">
    <div class="content">
      <ng-container [ngSwitch]="activeTab.id">
        <ng-container *ngSwitchCase="tabs[0].id">
          <app-general
            class="general-form"
            [form]="form"
            [countries]="countries"
            [isEditing]="isEditing"
          ></app-general>
        </ng-container>
        <ng-container *ngSwitchCase="tabs[1].id">
          <app-logo class="logo" [form]="form"></app-logo>
        </ng-container>
      </ng-container>
    </div>
  </section>
</article>
