<form [formGroup]="expertise" *ngIf="profileService.isProfile('TRAINER_TYPE')">
  <div class="input">
    <app-material-select formControlName="goals"
                         [options]="goals"
                         [multiSelect]="true"
                         [showValue]="'goal'"
                         [search]="true"
                         [readonly]="canEditProfile"
                         [label]="'profile.expertise.specialization' | translate"
                         [errors]="{required: 'profile.messages.fieldRequired' | translate}">
    </app-material-select>
  </div>

  <div class="list">

    <app-chips *ngFor="let goal of expertise.get('goals').value; index as i"
               [value]="goal.goal"
               [removable]="!trainer.under_consideration && !canEditProfile"
               (remove)="removeItem(i, 'goals')">

    </app-chips>
  </div>

  <app-material-select formControlName="trainingTypes"
                       [options]="trainingTypes"
                       [multiSelect]="true"
                       [showValue]="'type'"
                       [search]="true"
                       [readonly]="canEditProfile"
                       [label]="'profile.expertise.trainingTypes' | translate"></app-material-select>

  <div class="list">
    <app-chips *ngFor="let type of expertise.get('trainingTypes').value; index as i"
               [value]="type.type"
               [removable]="!trainer.under_consideration && !canEditProfile"
               (remove)="removeItem(i, 'trainingTypes')">
    </app-chips>
  </div>
<!--   <app-material-select formControlName="languages"
                       [options]="languages"
                       [multiSelect]="true"
                       [showValue]="'language'"
                       [search]="true"
                       [label]="'profile.expertise.languages' | translate"></app-material-select>

  <div class="list">
    <app-chips *ngFor="let lang of expertise.get('languages').value; index as i"
               [value]="lang.language"
               [removable]="!trainer.under_consideration"
               (remove)="removeItem(i, 'languages')">

    </app-chips>
  </div> -->
</form>

<form [formGroup]="expertise" *ngIf="profileService.isProfile('PARTNER_TYPE')">
  <app-material-select formControlName="eventTypes"
                       [options]="eventTypes"
                       [multiSelect]="true"
                       [showValue]="'type'"
                       [search]="true"
                       [idDuplicates]="true"
                       [readonly]="canEditProfile"
                       [label]="'profile.expertise.specialization' | translate"
                       [errors]="{required: 'profile.messages.fieldRequired' | translate}">
  </app-material-select>

  <div class="list">
    <app-chips *ngFor="let type of expertise.get('eventTypes').value; index as i"
               [value]="type.type"
               [removable]="!trainer.under_consideration && !canEditProfile"
               (remove)="removeItem(i, 'eventTypes')">
    </app-chips>
  </div>

<!--   <app-material-select formControlName="languages"
                       [options]="languages"
                       [multiSelect]="true"
                       [showValue]="'language'"
                       [search]="true"
                       [label]="'profile.expertise.languages' | translate"></app-material-select>

  <div class="list">
    <app-chips *ngFor="let lang of expertise.get('languages').value; index as i"
               [value]="lang.language"
               [removable]="!trainer.under_consideration"
               (remove)="removeItem(i, 'languages')">

    </app-chips>
  </div> -->
</form>
