import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';

import {ProfileService} from '@services/profile.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyGuard implements CanActivate {
  constructor(private profileService: ProfileService) {}

  canActivate(): boolean {
    return !!this.profileService.isCompany;
  }
}
