<article class="page_card">
  <header class="card_header" [ngClass]="{'mobile-header': mobileService.isMobile}">
    <app-header-tabs
      [tabs]="availableTabs"
      [isProfileConfirmed]="trainer.is_profile_confirmed"
      (changedTab)="changeTab($event)"
    ></app-header-tabs>
  </header>

  <div class="card_status" [ngClass]="{'mobile-status': mobileService.isMobile}">
    <button class="send_for_approval"
            [disabled]="!(stepperService.canSendForApproval | async) || !canEditProfile"
            *ngIf="isTrainer && !trainer.is_profile_confirmed && !trainer.under_consideration"
            (click)="sendForApproval(true)">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.9154 7.14811C19.2083 7.441 19.2083 7.91587 18.9154 8.20877L10.2723 16.8519C9.98991 17.1342 9.53583 17.1459 9.23941 16.8783L5.11853 13.1588C4.81105 12.8813 4.78677 12.407 5.0643 12.0995C5.34184 11.7921 5.81609 11.7678 6.12358 12.0453L9.71548 15.2874L17.8547 7.14811C18.1476 6.85521 18.6225 6.85521 18.9154 7.14811Z"
              fill="white"></path>
      </svg>
      <span>
        {{ 'profile.personal.sendToApproving' | translate }}
      </span>
    </button>
    <button class="send_for_approval send_for_approval--cancel"
            *ngIf="isTrainer && trainer.under_consideration"
            (click)="sendForApproval(false)">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.793 18.207C12.793 18.645 12.4379 19 12 19C11.5621 19 11.207 18.645 11.207 18.207L11.207 5.79296C11.207 5.35502 11.5621 5 12 5C12.4379 5 12.793 5.35502 12.793 5.79296L12.793 18.207Z"></path>
        <path
          d="M18.207 11.207C18.645 11.207 19 11.5621 19 12C19 12.4379 18.645 12.793 18.207 12.793L5.79296 12.793C5.35502 12.793 5 12.4379 5 12C5 11.5621 5.35502 11.207 5.79296 11.207L18.207 11.207Z"></path>
      </svg>
      <span>
        {{ 'profile.personal.cancelRequest' | translate }}
      </span>
    </button>

    <div class="status_controls">
      <div class="status">
        <div class="label">
          {{ 'common.status' | translate }}:
        </div>
        <div class="approval_status"
           *ngIf="trainer.id">
          <div class="status_text approved"
               *ngIf="trainer.is_profile_confirmed && !trainer.is_blocked && !trainer.under_consideration">
            {{ 'profile.status.approved' | translate}}
          </div>
          <div class="status_text not-approved" *ngIf="!trainer.is_profile_confirmed && !trainer.is_blocked && !trainer.under_consideration">
            {{ 'profile.status.notApproved' | translate}}
          </div>
          <div class="status_text under-review" *ngIf="trainer.under_consideration && !trainer.is_blocked">
            {{ 'profile.status.underReview' | translate}}
          </div>
          <div class="status_text blocked" *ngIf="trainer.is_blocked">
            {{ 'profile.status.blocked' | translate}}
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <button class="edit_btn"
                *ngIf="!trainer.under_consideration && canEditProfile"
                (click)="editProfile()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 11.5V14H4.5L11.8733 6.62667L9.37333 4.12667L2 11.5Z" fill="black"/>
            <path d="M13.8067 3.75334L12.2467 2.19334C11.9867 1.93334 11.5667 1.93334 11.3067 2.19334L10.0867 3.41334L12.5867 5.91334L13.8067 4.69334C14.0667 4.43334 14.0667 4.01334 13.8067 3.75334Z" fill="black"/>
            </svg>
          <span>
            {{ 'common.edit' | translate }}
          </span>
        </button>

        <button class="save_btn"
                *ngIf="!trainer.under_consideration && !canEditProfile"
                [disabled]="trainer.under_consideration"
                [disabled]="editingDisabled"
                (click)="saveProfile()">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33333 0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V2.66667L9.33333 0ZM6 10.6667C4.89333 10.6667 4 9.77333 4 8.66667C4 7.56 4.89333 6.66667 6 6.66667C7.10667 6.66667 8 7.56 8 8.66667C8 9.77333 7.10667 10.6667 6 10.6667ZM8 4H1.33333V1.33333H8V4Z" fill="#14BFFB"/>
          </svg>
          <span>
            {{ 'common.save' | translate }}
          </span>
        </button>

        <button class="cancel_btn"
                *ngIf="!trainer.under_consideration && !canEditProfile"
                (click)="cancelEditProfile()">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66683 1.27325L8.72683 0.333252L5.00016 4.05992L1.2735 0.333252L0.333496 1.27325L4.06016 4.99992L0.333496 8.72659L1.2735 9.66659L5.00016 5.93992L8.72683 9.66659L9.66683 8.72659L5.94016 4.99992L9.66683 1.27325Z" fill="#F11C48"/>
          </svg>
          <span>
            {{ 'common.cancel' | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>

  <section class="card_body">
    <div class="content">
      <app-stepper [ngClass]="{'stepper-mobile': !mobileService.isDesktop, 'stepper-mobile-opened': isStepperMobileOpened}"></app-stepper>
      <app-personal *ngIf="currentTab === 'PERSONAL' && trainer.id" class="profile-component"></app-personal>
      <app-location *ngIf="currentTab === 'LOCATION' && trainer.id" class="profile-component" [ngClass]="{'full-width': !isTrainer}"></app-location>
      <app-education *ngIf="currentTab === 'EDUCATION' && trainer.id" class="profile-component" [ngClass]="{'covered': trainer.under_consideration}"></app-education>
      <app-expertise *ngIf="currentTab === 'EXPERTISE' && trainer.id" class="profile-component"></app-expertise>
      <app-payment *ngIf="currentTab === 'PAYMENT' && trainer.id" class="profile-component"></app-payment>
      <app-pricing *ngIf="currentTab === 'PRICING' && trainer.id" class="profile-component"></app-pricing>
    </div>

    <div *ngIf="!mobileService.isDesktop" class="stepper-button-container">
      <button class="stepper_open_btn"
              *ngIf="!isStepperMobileOpened"
              (click)="toggleStepperMobile()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z" fill="white"/>
        </svg>
      </button>
      <button class="stepper_close_btn"
              *ngIf="isStepperMobileOpened"
              (click)="toggleStepperMobile()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="white"/>
        </svg>
      </button>
    </div>
  </section>
</article>

<!-- <app-material-fab [icon]="'ic_done'"
                  [text]="'profile.personal.sendToApproving' | translate"
                  (click)="sendForApproval(true)"
                  [disabled]="!canSendForApproval"
                  *ngIf="mobileService.isMobile && !trainer.under_consideration">
</app-material-fab>

<app-material-fab [icon]="'ic_cancel'"
                  [text]="'profile.personal.cancelRequest' | translate"
                  (click)="sendForApproval(false)"
                  *ngIf="mobileService.isMobile && trainer.under_consideration">
</app-material-fab> -->
