import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {StorageService} from './storage.service';
import {FeathersService} from './feathers.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { SocketService } from '@modules/chats/services/socket.service';

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  private apiUrl = environment.API_URL;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private storageService: StorageService,
    private _feathers: FeathersService,
    private socialAuthService: SocialAuthService,
    private router: Router,
    private socketService: SocketService,
  ) { }

  authenticate(data) {
    return this.httpService.postData('/authentication', data);
  }

  logoutWithNavigation() {
    this.logoutWithoutReload();
    this.router.navigate(['/login']);
  }

  logoutWithoutReload() {
    this._feathers.logout();
    this.socialAuthService.signOut().catch((error) => console.error(error));
    this.socketService.disconnect();

    this.storageService.storage.removeItem('jwt');
    this.storageService.storage.removeItem('fcu_jwt');
    this.storageService.storage.removeItem('user');
    this.storageService.storage.removeItem('company');
    this.storageService.storage.removeItem('userId');
    this.storageService.storage.removeItem('chatRoster');
    this.storageService.storage.removeItem('chatRooms');
    this.storageService.storage.removeItem('language');
    this.storageService.storage.removeItem('PROFILE_TYPE');
  }

  public setPasswordToken(email: string): Observable<any> {
    return this.http.patch(`${this.apiUrl}/forgot-password`, {email});
  }

  public resetPassword(token: string, new_password: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/reset-password?token=${token}`, {new_password});
  }

}
