import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {

  constructor(
    updates: SwUpdate,
  ) {
    updates.available.subscribe((event) => {
      updates.activateUpdate();
      document.location.reload();
    });
  }
}
