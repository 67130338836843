import {Injectable} from '@angular/core';

import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollLockService {

  popUpSelectors: any;
  scrollLockClass = 'scroll-lock';

  private _lock$ = new BehaviorSubject<boolean>(false);

  get scrollLock$(): Observable<boolean> {
    return this._lock$.asObservable();
  }

  constructor() { }

  disableScroll() {
    this._lock$.next(true);
    this.scrollLock(true);
  }

  enableScroll() {
    this._lock$.next(false);
    this.scrollLock(false);
  }

  switchScroll() {
    this._lock$.next(!this._lock$.getValue());
    this.scrollLock(this._lock$.getValue());
  }

  private scrollLock(state) {
    this.popUpSelectors = document.querySelector('app-root');

    if (state) {
      this.popUpSelectors.classList.add(this.scrollLockClass);

      disableBodyScroll(this.popUpSelectors, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
              return true;
            }
            // tslint:disable-next-line: no-parameter-reassignment
            el = el.parentNode;
          }
        },
      });
    } else {
      this.popUpSelectors.classList.remove(this.scrollLockClass);
      enableBodyScroll(this.popUpSelectors);
    }
  }
}
