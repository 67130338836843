<div class="personal-header">
  {{ 'profile.personal.personalInformation' | translate}}
</div>
<!-- <div class="personal-header">
  <div class="background"
       [ngClass]="{'default': !trainer.background}"
       [style.backgroundImage]="trainer.background ? 'url(' + trainer.background + ')' : 'url(/assets/img/default_background.png)'"></div>
  <div class="avatar"
       [style.backgroundImage]="trainer.profile_avatar ? 'url(' + trainer.profile_avatar + ')' : 'url(/assets/img/profile_default.svg)'">
  </div>
  <div class="trainer_name">
    {{user.first_name}} {{user.last_name}}
  </div>
  <div class="level">
    <span *ngIf="trainer.trainer_level && isTrainer">
      {{ 'profile.personal.personalTrainer' | translate}},
      {{ 'profile.personal.level' | translate}} {{trainer.trainer_level.level}}
    </span>
    <span *ngIf="!trainer.trainer_level && isTrainer">
      {{ 'profile.personal.levelNotDefined' | translate}}
    </span>
    <span *ngIf="isPartner">
      {{'header.activity_partner' | translate}}
    </span>
  </div>
  <div class="rating">
    <app-star-rating [receivedRating]="trainer.avg_rating"></app-star-rating>
  </div>

  <div class="approval_status"
       *ngIf="trainer.id">
    <i class="info_icon"></i>
    <div class="status_text approved"
         *ngIf="trainer.is_profile_confirmed && !trainer.is_blocked && !trainer.under_consideration">
      {{ 'profile.status.approved' | translate}}
    </div>
    <div class="status_text not-approved" *ngIf="!trainer.is_profile_confirmed && !trainer.is_blocked && !trainer.under_consideration">
      {{ 'profile.status.notApproved' | translate}}
    </div>
    <div class="status_text under-review" *ngIf="trainer.under_consideration && !trainer.is_blocked">
      {{ 'profile.status.underReview' | translate}}
    </div>
    <div class="status_text blocked" *ngIf="trainer.is_blocked">
      {{ 'profile.status.blocked' | translate}}
    </div>
  </div>
</div> -->

<form [formGroup]="personal" class="form-grid">
  <app-material-select formControlName="account_type"
                       [options]="accountTypes"
                       valueFieldName="value"
                       labelFieldName="title"
                       [readonly]="canEditProfile"
                       [label]="'profile.personal.accountType' | translate">
  </app-material-select>

  <app-material-input formControlName="email"
                      [label]="'profile.personal.email' | translate"></app-material-input>

 <!--  <app-material-input formControlName="org_name"
                      *ngIf="isBusiness"
                      [label]="'profile.personal.businessName' | translate"
                      [readonly]="canEditProfile"
                      [errors]="{required: 'profile.messages.fieldRequired' | translate,
                                 maxlength: 'profile.messages.maxLength' | translate:{number: '50'}}"></app-material-input>

  <app-material-input formControlName="org_name"
                      *ngIf="isFoundation"
                      [label]="'profile.personal.foundationName' | translate"
                      [readonly]="canEditProfile"
                      [errors]="{required: 'profile.messages.fieldRequired' | translate,
                                 maxlength: 'profile.messages.maxLength' | translate:{number: '50'}}"></app-material-input>

  <app-material-input formControlName="org_no"
                      *ngIf="isBusiness || isFoundation"
                      [label]="'profile.personal.businessNumber' | translate"
                      [readonly]="canEditProfile"
                      [errors]="{required: 'profile.messages.fieldRequired' | translate,
                                 maxlength: 'profile.messages.maxLength' | translate:{number: '50'}}"></app-material-input> -->

  <app-material-input formControlName="first_name"
                      class="first_name"
                      [label]="'profile.personal.firstName' | translate"
                      [readonly]="canEditProfile"
                      [errors]="{required: 'profile.messages.fieldRequired' | translate,
                                 maxlength: 'profile.messages.maxLength' | translate:{number: '50'}}"></app-material-input>

  <app-material-input formControlName="last_name"
                      [label]="'profile.personal.lastName' | translate"
                      [readonly]="canEditProfile"
                      [errors]="{required: 'profile.messages.fieldRequired' | translate,
                                 maxlength: 'profile.messages.maxLength' | translate:{number: '50'}}"></app-material-input>

  <app-material-input formControlName="telephone"
                      [label]="'profile.personal.phoneNumber' | translate"
                      [readonly]="canEditProfile"
                      [errors]="{required: 'profile.messages.fieldRequired' | translate,
                                 pattern: 'profile.messages.phonePatternError' | translate}"></app-material-input>

  <app-material-select formControlName="languages"
                       [options]="languages"
                       [multiSelect]="true"
                       [showValue]="'language'"
                       [search]="true"
                       [readonly]="canEditProfile"
                       [label]="'profile.personal.language' | translate"
                       [placeholder]="'profile.personal.languagePlaceholder' | translate"
                       [errors]="{required: 'profile.messages.fieldRequired' | translate}">
  </app-material-select>

  <app-material-input formControlName="trainer_level"
                      *ngIf="isTrainer"
                      class="level"
                      [ngClass]="{ 'reduced-indent': !personal.get('languages').value.length }"
                      [label]="'profile.personal.level' | translate"></app-material-input>

  <div class="list">
    <app-chips *ngFor="let lang of personal.get('languages').value; index as i"
               [value]="lang.language"
               [removable]="!trainer.under_consideration && !canEditProfile"
               (remove)="removeItem(i, 'languages')"
               class="chips"
    >

    </app-chips>
  </div>



</form>
