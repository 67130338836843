<label #labelEl
       (click)="toggleOptions()"
       [ngClass]="{
         'material_label--focused': showList,
         'material_label--dirty': control.value,
         'material_label--invalid': isInvalid,
         'material_label--disabled': control.disabled
       }"
       class="material_label">
  {{label}}
</label>

<div (click)="toggleOptions($event)"
     [ngClass]="{'material_input--focused': showList,
                 'material_input--invalid': isInvalid,
                 'material_input--disabled': control.disabled}"
     class="material_input">

  <!-- when we no need search -->
  <div *ngIf="!search" class="material_input--value">
    <div class="label-with-image">
      <img *ngIf="selectedImage" [src]="selectedImage" alt="badge" class="item-image">

      <span>{{selectedTitle}}</span>
    </div>
  </div>

  <div *ngIf="search" class="material_input--value">
    <div class="label-with-image" [ngClass]="{'no-image': !selectedImage}">
      <img *ngIf="selectedImage" [src]="selectedImage" alt="badge" class="item-image">

      <input #searchEl
             (input)="filter($event)"
             [placeholder]="showList ? ('common.startTyping' | translate) : ''"
             [value]="showList ? '' : selectedTitle"
             class="search"
             type="text">
    </div>
  </div>

  <i class="material_input--icon-suffix">
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.41 8.58997L12 13.17L16.59 8.58997L18 9.99997L12 16L6 9.99997L7.41 8.58997Z"></path>
    </svg>
  </i>
</div>

<div (click)="toggleOptions()" *ngIf="showList" class="overlay"></div>
<cdk-virtual-scroll-viewport
  *ngIf="showList"
  [@listAnimationUp]="optionsUp"
  [@listAnimation]="!optionsUp"
  [maxBufferPx]="windowHeight"
  [minBufferPx]="windowHeight"
  [ngClass]="{'options--up': optionsUp}"
  [style.height]="windowHeight + 'px'"
  class="options"
  [itemSize]="ITEM_SIZE">

  <ng-container *cdkVirtualFor="let option of filteredOptions; index as i">
    <li (click)="select(i)"
        [ngClass]="{'option--selected': option == controlObject}"
        class="option">

      <img [src]="option[labelImage]" alt="badge" class="item-image">

      <span>
        {{option[labelValue]}}
      </span>
    </li>
  </ng-container>
</cdk-virtual-scroll-viewport>

<div
  class="select-description"
  [ngClass]="{'select-description--invalid': isInvalid}">*{{description}}</div>
