import {Injectable} from '@angular/core';
import {StorageDirective} from '../directives/storage.directive';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  preferableStorage: number = 0;
  storageAvalaible: boolean;
  storage: any;

  KStorageType = {
    LOCAL: 0,
    SESSION: 1,
  };

  constructor() {
    if (this.preferableStorage !== this.KStorageType.LOCAL && this.preferableStorage !== this.KStorageType.SESSION) {
      throw new Error('Invalid KStorage type provided');
    }
    this.storageAvalaible = false;

    if (this.preferableStorage === this.KStorageType.LOCAL) {
      this.storage = localStorage;
    } else if (this.preferableStorage === this.KStorageType.SESSION) {
      this.storage = sessionStorage;
    } else {
      this.storage = null;
    }

    if (this.storage) {
      try {
        this.storage.setItem('KLS_test_entry', 'KLS_test_data');
        this.storage.removeItem('KLS_test_entry');
        this.storageAvalaible = true;
      } catch (err) {
        this.fallbackToMemoryStorage();
      }
    } else {
      this.fallbackToMemoryStorage();
    }
  }

  fallbackToMemoryStorage() {
    this.storage = new StorageDirective();
  }

}
