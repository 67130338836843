import {Component, EventEmitter, Input, Output} from '@angular/core';

import {MobileService} from '@services/mobile.service';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent {
  @Input() index: number;
  @Input() value: string;
  @Input() removable = true;
  @Output() remove = new EventEmitter();

  constructor(
    public mobile: MobileService,
  ) {
  }

  removeChip() {
    this.remove.emit(true);
  }
}
