<p class="input-image__name">{{label}}</p>

<div class="input-image"
  [ngClass]="{
    'input-image--invalid': control.invalid && control.touched,
    'input-image--preloader': preloader,
    'input-image--disabled': control.disabled
  }">

  <div
    *ngIf="imageUrl"
    (click)="changeImage(input)"
    class="input-image__background"
    [style.backgroundImage]="imageUrl">
  </div>

  <label class="input-image__label">
    <span class="empty-state">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M49 44.3333V11.6667C49 9.1 46.9 7 44.3333 7H11.6667C9.1 7 7 9.1 7 11.6667V44.3333C7 46.9 9.1 49 11.6667 49H44.3333C46.9 49 49 46.9 49 44.3333ZM19.8333 31.5L25.6667 38.5233L33.8333 28L44.3333 42H11.6667L19.8333 31.5Z" fill="#606873"/>
      </svg>
      <span>
        {{emptyStateText || 'common.imageUploadEmptyState' | translate}}
      </span>
    </span>
    <input
      #input
      (change)="imageUpload($event)"
      [accept]="fileFormat"
      class="hidden_input"
      type="file">
  </label>
</div>
