import { PrototypeEntity } from '@models/prototype.entity';

export class PaiScoreGroupSummaryStatEntity extends PrototypeEntity {
  averagePai: number = null;
  employeesRate: number = null;
  totalAcceptedEmployees: number = null;
  totalActiveEmployees: number = null;

  constructor(data?: object) {
    super();
    this.setData(data);
  }
}
