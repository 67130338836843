<div class="time_picker">
  <div class="time-selection">
    <div class="time-selection-hours-wrapper">
      <div class="time-selection-overlay"></div>

      <div class="time-selection-divider"></div>

      <cdk-virtual-scroll-viewport class="time-selection-hours"
                                   [itemSize]="56"
                                   (scrolledIndexChange)="onScroll($event, 'hours')"
                                   #hoursViewport>
        <div class="time-selection-hour" *cdkVirtualFor="let hour of hours">
          {{hour | date: 'HH'}}
        </div>
      </cdk-virtual-scroll-viewport>
      <div class="time-selection-overlay time-selection-overlay--reversed"></div>

    </div>
    <div class="time-selection-minutes-wrapper">
      <div class="time-selection-overlay"></div>

      <div class="time-selection-divider"></div>

      <cdk-virtual-scroll-viewport class="time-selection-minutes"
                                   [itemSize]="56"
                                   (scrolledIndexChange)="onScroll($event, 'minutes')"
                                   #minutesViewport>
        <div class="time-selection-hour" *cdkVirtualFor="let minute of  minutes">
          {{minute | date: 'mm'}}
        </div>
      </cdk-virtual-scroll-viewport>
      <div class="time-selection-overlay time-selection-overlay--reversed"></div>

    </div>
  </div>

  <div class="actions">
    <button class="cancel" mat-dialog-close>
      {{'common.cancel' | translate}}
    </button>
    <button class="ok" (click)="apply()" mat-dialog-close>
      {{'common.ok' | translate}}
    </button>
  </div>
</div>
