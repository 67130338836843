<div
  class="login sign-up"
  [ngClass]="{
    'login--webp': hasSupport('webp'),
    'login--jpf': hasSupport('jpf')
  }"
>
  <div class="login-wrapper">
    <div class="login-wrapper-header">
      <app-button class="back" [icon]="true" (click)="openConfirmDialog()">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2733 4.94L9.33331 4L5.33331 8L9.33331 12L10.2733 11.06L7.21998 8L10.2733 4.94Z"
            fill="#14BFFB"
          />
        </svg>
      </app-button>
      <div class="logo">
        <img src="/assets/logo.svg" alt="MIA logo" class="login-wrapper-logo" />
      </div>
    </div>
    <div class="login-wrapper-form">
      <div class="steps">
        <p class="steps-text">
          {{ 'signUp.registrationStep' | translate: { number: currentStep } }}
        </p>
        <div class="steps-dots">
          <div
            class="steps-dots__item"
            [ngClass]="{ current: currentStep === 1 }"
            (click)="openStep(1)"
          ></div>
          <div
            class="steps-dots__item"
            [ngClass]="{ current: currentStep === 2 }"
            (click)="openStep(2)"
          ></div>
          <div
            class="steps-dots__item"
            [ngClass]="{ current: currentStep === 3 }"
            (click)="openStep(3)"
          ></div>
        </div>
      </div>

      <ng-container [ngSwitch]="currentStep" *ngIf="form">
        <app-step-one [form]="form.get('one')" *ngSwitchCase="1"></app-step-one>
        <app-step-two [form]="form.get('two')" *ngSwitchCase="2"></app-step-two>
        <app-step-three
          [form]="form.get('three')"
          *ngSwitchCase="3"
        ></app-step-three>
      </ng-container>

      <div class="buttons">
        <app-button
          *ngIf="currentStep > 1"
          class="buttons-back"
          (click)="changeStep(-1)"
        >
          {{ 'common.back' | translate }}
        </app-button>
        <app-button
          *ngIf="currentStep === 1"
          color="primary"
          class="buttons-next"
          [disabled]="isDisabledNextStep(currentStep)"
          (click)="changeStep()"
        >
          {{ 'common.next' | translate }}
        </app-button>
        <app-button
          *ngIf="currentStep === 2"
          color="primary"
          class="buttons-next"
          [disabled]="isDisabledNextStep(2)"
          (click)="changeStep()"
        >
          {{ 'common.next' | translate }}
        </app-button>
        <app-button
          *ngIf="currentStep === 3"
          color="primary"
          [disabled]="form.invalid || !form.value.one.confirmPolicy"
          (click)="submitForm()"
        >
          {{ 'signUp.complete' | translate }}
        </app-button>
      </div>
    </div>

    <span *ngFor="let message of messages">{{ message }} <br /></span>

    <div class="splash"></div>
  </div>
</div>
