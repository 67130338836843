import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  addChatMembers,
  addChatMembersSuccess,
  createGroupChat,
  createGroupChatSuccess,
  createPrivateChat,
  createPrivateChatFailure,
  createPrivateChatSuccess,
  deleteChat,
  deleteChatSuccess,
  editGroupChat,
  editGroupChatSuccess,
  loadChat,
  loadChatHistory,
  loadChatHistoryPending,
  loadChatHistorySuccess,
  loadChatsList,
  loadChatsListSuccess,
  loadChatSuccess,
  loadEmployeeList,
  loadEmployeeListSuccess,
  removeChatMember,
  removeChatMemberSuccess,
  sendMessage,
  sendMessageSuccess,
} from './chat.actions';
import { SocketControllerService } from '../services/socket-controller.service';
import { of } from 'rxjs';

@Injectable()
export class ChatEffects {

  constructor(
    private actions$: Actions,
    private socketControllerService: SocketControllerService,
  ) {}

  loadChatList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadChatsList),
      switchMap(() => this.socketControllerService.getChatList()),
      map((chatList) => loadChatsListSuccess({ chatList })),
    );
  });

  loadChatHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadChatHistory),
      tap(() => loadChatHistoryPending()),
      switchMap(({ chatHistoryRequest }) => this.socketControllerService.getChatsHistory(chatHistoryRequest)),
      tap((chatHistory) => this.socketControllerService.markMessageAsReadFromChatHistory(chatHistory)),
      map((chatHistory) => loadChatHistorySuccess({ chatHistory })),
    );
  });

  loadMembersList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEmployeeList),
      switchMap(() => this.socketControllerService.getEmployeeList()),
      map((employeeList) => loadEmployeeListSuccess({ employeeList })),
    );
  });

  createGroupChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createGroupChat),
      switchMap(({ chatCreationRequest }) => this.socketControllerService.createGroupChat(chatCreationRequest)),
      map((chatItem) => createGroupChatSuccess({ chatItem })),
    );
  });

  createPrivateChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createPrivateChat),
      switchMap(({data}) => this.socketControllerService.createPrivateChat(data)),
      map((chatItem) => createPrivateChatSuccess( { chatItem })),
      catchError(() => of(createPrivateChatFailure)),
    )
  });

  editGroupChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editGroupChat),
      switchMap(({ body }) => this.socketControllerService.editGroupChat(body)),
      map((chatItem) => editGroupChatSuccess({ chatItem })),
    );
  });

  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendMessage),
      switchMap((data) => this.socketControllerService.sendMessage(data.message)),
      map((chatMessage) => sendMessageSuccess({ chatMessage })),
    );
    });

  deleteChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteChat),
      switchMap(({ chatId }) => this.socketControllerService.deleteChat(chatId)),
      map(({ chatId }) => deleteChatSuccess({ chatId })),
    );
  });

  loadChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadChat),
      switchMap(({ chatId }) => this.socketControllerService.loadChat(chatId)),
      map((chat) => loadChatSuccess({ chat })),
    );
  });

  deleteChatMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(removeChatMember),
      switchMap(({ chatId, externalId }) => this.socketControllerService.removeChatMember(chatId, externalId)),
      map((chatItem) => removeChatMemberSuccess({ chatItem })),
    );
  });

  addChatMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addChatMembers),
      switchMap(({ chatId, userIds }) => this.socketControllerService.addChatMembers(chatId, userIds)),
      map((members) => addChatMembersSuccess({ members })),
    );
  });
}
