import {Component, OnInit} from '@angular/core';
import {MaterialButtonComponent} from '@modules/material/material-button/material-button.component';

@Component({
  selector: 'app-icon-button',
  templateUrl: '../material-button/material-button.component.html',
  styleUrls: ['../material-button/material-button.component.scss', './material-icon-button.component.scss'],
})
export class MaterialIconButtonComponent extends MaterialButtonComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    this.currentClasses = {
      icon: this.icon,
      border: this.border,
      elevation: this.elevation,
      [this.color]: true,
    };
  }

}
