import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '@services/data.service';
import {TranslateService} from '@ngx-translate/core';
import { PASSWORD_REG_EXP } from '@shared/utils';
import {AuthService} from '@services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: [
    './change-password.component.scss',
    '../login/login.component.scss',
  ],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;
  private token: string;

  private destroy$ = new Subject<void>();

  constructor(
    public router: Router,
    private authService: AuthService,
    private data: DataService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.authService.logoutWithoutReload();
    this.readTokenFromParams();

    this.form = new UntypedFormGroup({
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_REG_EXP),
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
        this.confirmPasswordValidator(),
      ]),
    },
    {
      validators: this.confirmPasswordValidator(),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitForm(): void {
    if (!this.token) {
      return;
    }

    const password = this.form.value.password;

    this.authService.resetPassword(this.token, password)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const restorePasswordType = localStorage.getItem('restorePasswordType');

        this.data.showNotification(this.translate.instant('alerts.passwordResetSuccess'), null, 2500, 'success');
        localStorage.removeItem('restorePasswordType');

        restorePasswordType === 'company' ? this.router.navigate(['company-sign-in']) : this.router.navigate(['login']);
      });
  }

  private readTokenFromParams(): void {
    this.token = this.activatedRoute.snapshot.queryParams.token;
  }

  private confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (!this.form) {
        return null;
      }
      const isConfirm = this.form.get('password').value !== this.form.get('confirmPassword').value;
      return isConfirm ? {confirm: {value: control.value}} : null;
    };
  }

}
