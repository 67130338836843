import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DateTime} from 'luxon';

import {DataService} from '../../services/data.service';
import {BillingItem} from '../../models/billing-item';
import {MobileService} from '../../services/mobile.service';
import { OwlDateTimeComponent } from 'ng-pick-datetime-ex';

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss'],
})
export class BillingHistoryComponent implements OnInit {

  // Oldest and newest endpoint dates for search billing-transactions
  public fromMinSearchDate: any;
  public fromMaxSearchDate: any;
  public toMinSearchDate: any;
  public toMaxSearchDate: any;

  public today: any;
  public startStartDate: any;
  public startFinishDate: any;
  public searchStartValue: any;
  public searchStartValueFormatted: any;
  public searchFinishValue: any;
  public searchFinishValueFormatted: any;
  @ViewChild('hiddenLinkForCSV', { static: true }) hiddenLink: ElementRef;

  // This billings
  public billings: BillingItem[];
  public billingSubscription: any;

  // For pagination

  public pageSelected: number;
  public itemsToShow: number;
  public billingsQuantity: number;

  get isMobile() {
    return this.mobileService.isMobile;
  }

  constructor(
    private mobileService: MobileService,
    private data: DataService,
  ) { }

  ngOnInit() {
    this.pageSelected = 0;
    this.itemsToShow = 10;
    this.billingsQuantity = 0;

    // Setting min and max endpoints for owlDateTimePicker
    this.data.getUser();
    this.today = DateTime.local();
    this.fromMinSearchDate = DateTime.local(2018, 1, 11).startOf('day').toISO();
    this.toMinSearchDate = DateTime.local(2018, 1, 11).endOf('day').toISO();
    this.fromMaxSearchDate = this.today.startOf('day').toISO();
    this.toMaxSearchDate = this.today.endOf('day').toISO();
    this.startStartDate = DateTime.local().startOf('month').toISO();
    this.startFinishDate = this.today.toISO();

    // retrieving bills (billing-items)
    this.billingSubscription = this.data.billings.subscribe(
      (res: any) => {
        this.billingsQuantity = res.total;
        this.billings = res.data;
      },
      err => console.log(err),
    );

    this.data.getBillingHistory(null, null, this.itemsToShow, 0);

  }

  selectDate(picker: OwlDateTimeComponent<Date>, start: boolean) {
    // Detect if we changing the start endpoint
    if (start) {
      this.searchStartValue = picker.selected.toISOString();
      this.searchStartValueFormatted = new DateTime.fromISO(this.searchStartValue).toLocaleString(
        {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      this.startStartDate = this.searchStartValue;
      this.toMinSearchDate = this.searchStartValue;
    } else {
      this.searchFinishValue = picker.selected.toISOString();
      this.searchFinishValueFormatted = new DateTime.fromISO(this.searchFinishValue).toLocaleString(
        {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      this.fromMaxSearchDate = this.searchFinishValue;
      this.startFinishDate = this.searchFinishValue;
      this.searchFinishValue = new DateTime.fromISO(this.searchFinishValue).endOf('day').toISO();
    }
    this.newRequest();
  }

  clearDatePickers() {
    this.searchStartValue = null;
    this.searchFinishValue = null;
    this.searchStartValueFormatted = null;
    this.searchFinishValueFormatted = null;
    this.toMinSearchDate = DateTime.local(2018, 1, 11).endOf('day').toISO();
    this.startStartDate = DateTime.local().startOf('month').toISO();
    this.fromMinSearchDate = DateTime.local(2018, 1, 11).startOf('day').toISO();
    this.fromMaxSearchDate = this.today.startOf('day').toISO();
    this.startFinishDate = this.today.toISO();
    this.pageSelected = 0;
    this.data.getBillingHistory(null, null, this.itemsToShow, 0);
  }

  newRequest() {
    if (this.searchStartValue && this.searchFinishValue) {
      this.pageSelected = 0;
      this.data.getBillingHistory(this.searchStartValue, this.searchFinishValue, this.itemsToShow);
    }
  }

  getNewData(event) {
    this.pageSelected = event.start;
    const skip = this.pageSelected * this.itemsToShow;
    this.data.getBillingHistory(this.searchStartValue, this.searchFinishValue, this.itemsToShow, skip);
  }

  exportExcel() {
    this.data.getExcel();
  }

}
