<aside>
  <app-left-menu 
    *ngIf="feathers.isAutentificated()" 
    [ngClass]="{
      'mobile-menu': isMobile
    }">
  </app-left-menu>
</aside>

<!-- <div
  [ngClass]="{'mobile-stepper-screen--active': stepperMobileOpened}"
  class="mobile-stepper-screen">

  <div class="mobile-stepper-screen__close-btn" (click)="closeStepperMobile()" >
    <i class="icon-memento-close-icon icon-bold"></i>
  </div>

  <div class="mobile-stepper-screen__content">
    <app-stepper-mobile></app-stepper-mobile>
  </div>
   *ngIf="feathers.isAutentificated()"

</div> -->

<header *ngIf="feathers.isAutentificated()" [ngClass]="{'mobile-header': isMobile}">
  <app-header></app-header>
</header>

<main class="contentWrapper" [ngClass]="{'mobile-content': isMobile}">
  <router-outlet></router-outlet>
</main>
