import {Component, OnDestroy, OnInit} from '@angular/core';
import {delay} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {ProfileService} from '../../services/profile.service';


@Component({
  selector: 'app-tie-up-stripe',
  templateUrl: './tie-up-stripe.component.html',
  styleUrls: ['./tie-up-stripe.component.scss'],
})
export class TieUpStripeComponent implements OnInit, OnDestroy {

  public trainerProfile;
  public success: boolean;
  public response: boolean;
  public userSubscription: any;

  constructor(
    private router: Router,
    private profile: ProfileService,
  ) { }

  ngOnInit() {
    this.response = false;
    this.userSubscription = this.profile.trainer.subscribe(
      trainer => {
        this.trainerProfile = trainer;
        if (trainer.id) {
          this.getParams(location.search);
          this.userSubscription.unsubscribe();
        }
      },
    );
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  getParams(searchString: string) {
    const stateFirst = searchString.indexOf('?state=');
    const stateLast = searchString.indexOf('&');
    const state = decodeURIComponent(searchString.slice(stateFirst + 7, stateLast));
    if (parseInt(atob(state).slice(8).slice(0, -8), 10) === this.trainerProfile.id) {
      const codeFirst = searchString.indexOf('&code=');
      let code = searchString.slice(codeFirst + 6);
      code = code.includes('&') ? code.slice(0, code.indexOf('&')) : code;
      this.finalizeConnection(code);
    } else {
      this.response = true;
      this.success = false;
      setTimeout(() => this.router.navigate(['profile']), 10000);
    }
  }

  finalizeConnection(authCode: string) {
    const data = JSON.stringify({
      code: authCode,
      profile_id: this.trainerProfile.id,
    });
    this.profile.postStripeKey(data).subscribe(
      res => {
        this.response = true;
        this.success = true;
        of(1).pipe(
          delay(10000),
        ).subscribe(() => {
          this.router.navigate(['profile']);
        });
      },
      err => {
        this.response = true;
        this.success = false;
        of(1).pipe(
          delay(10000),
        ).subscribe(() => {
          this.router.navigate(['profile']);
        });
      },
    );
  }

}
