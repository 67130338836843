<div class="chart-header" >
  <app-button
    class="toggle"
    color="toggle"
    [icon]="true"
    [border]="true"
    [ngClass]="{open: showChart}"
    (click)="toggleShowState()"
  >
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.94 5.72668L8 8.78002L11.06 5.72668L12 6.66668L8 10.6667L4 6.66668L4.94 5.72668Z" fill="#14BFFB"/>
    </svg>
  </app-button>
  <div class="chart-header-content">
    <p>
      {{ title }}
    </p>
    <app-button
      *ngIf="infoText"
      class="info"
      [icon]="true"
      [border]="true"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99992 1.33337C4.31992 1.33337 1.33325 4.32004 1.33325 8.00004C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.6799 1.33337 7.99992 1.33337ZM8.66658 11.3334H7.33325V7.33337H8.66658V11.3334ZM8.66658 6.00004H7.33325V4.66671H8.66658V6.00004Z" fill="#14BFFB"/>
      </svg>
    </app-button>
    <div class="info-text">
      {{ infoText }}
    </div>
  </div>
</div>

<div #containerRef class="chart-content" [ngClass]="{show: showChart}">
  <ngx-charts-line-chart
    class="app-chart no-vertical-grid no-y-ticks"
    [legend]="true"
    [legendTitle]=""
    [legendPosition]="'below'"
    [tooltipDisabled]="true"
    [scheme]="colorScheme"
    [showXAxisLabel]="xAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisTickFormatting]="xAxisTickFormatting"
    [xAxisTicks]="ticks"
    [xScaleMin]="xScaleMin"
    [xScaleMax]="xScaleMax"
    [xAxisLabel]="xAxisLabel"
    [view]="[containerRef.offsetWidth, 400]"
    [results]="data"
    [curve]="curveMonotoneX"
  >
  </ngx-charts-line-chart>

  <div class="chart-arrow">
    <span>{{'company.lessFit' | translate}}</span>
    <svg width="859" height="12" viewBox="0 0 859 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM858.773 6L853 0.226497L847.227 6L853 11.7735L858.773 6ZM6 7H853V5H6V7Z" fill="url(#paint0_linear)"/>
      <defs>
      <linearGradient id="paint0_linear" x1="853" y1="6.00977" x2="6" y2="6" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3BCC99"/>
      <stop offset="0.486894" stop-color="#FFA400"/>
      <stop offset="1" stop-color="#F32052"/>
      </linearGradient>
      </defs>
    </svg>
    <span>{{'company.fit' | translate}}</span>
  </div>

  <div class="chart-description">
    <div *ngIf="firstDelta | isNumber" class="chart-description-line">
      {{data[0]?.name | date: 'EE, dd MMM yyyy'}}
      {{ 'company.avg' | translate}}:
      <span class="bold">
        {{+firstDelta?.toFixed(2)}}
        {{ 'company.yrs' | translate}}
      </span>

    </div>
    <div *ngIf="secondDelta | isNumber" class="chart-description-line">
      {{data[1]?.name | date: 'EE, dd MMM yyyy'}}
      {{ 'company.avg' | translate}}:
      <span class="bold">
        {{+secondDelta?.toFixed(2)}}
        {{ 'company.yrs' | translate}}
      </span>
    </div>
    <div *ngIf="(firstDelta | isNumber) && (secondDelta | isNumber)" class="chart-description-summ">
      <span *ngIf="firstDelta <= secondDelta">
        {{ 'company.improvedBy' | translate}}
      </span>
      <span *ngIf="firstDelta > secondDelta">
        {{ 'company.deterioratedBy' | translate}}
      </span>
      <span
        class="bold"
        [ngClass]="{
          improved: firstDelta < secondDelta,
          deteriorated: firstDelta > secondDelta
        }"
      >
        {{+improvedBy?.toFixed(2)}}
        {{ 'company.yrs' | translate}}
      </span>
    </div>
  </div>
</div>
