import { Injectable } from '@angular/core';
import { ChatEdit, ChatHistoryRequest, Member, NewMessage } from '@modules/chats/interfaces';
import { SocketService } from './socket.service';
import { CHAT_TYPE, MESSAGE_STATUS, SOCKET_EVENTS } from '../enums/chat.enum';
import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { RestApiService } from './rest-api.service';
import { ChatCreationRequest, ChatHistory, ChatItem, ChatMessage } from '../interfaces';
import { filter, map, tap } from 'rxjs/operators';
import { addNewMessage, clearChatStore, loadChat, loadChatsList, loadEmployeeList } from '../store/chat.actions';
import { ChatMessagesService } from './chat-messages.service';
import { ChatEmployee } from '../interfaces/chat-employee.interface';
import { DataService } from '@services/data.service';
import { ChatService } from '@modules/chats/services/chat.service';

@Injectable({
  providedIn: 'root',
})
export class SocketControllerService {

  constructor(
    private socket: SocketService,
    private store: Store,
    private restApiService: RestApiService,
    private chatMessagesService: ChatMessagesService,
    private dataService: DataService,
    private chatService: ChatService,
  ) {}

  listenEvents(): Observable<void> {
    return merge(
      this.listenChatTriggerEvents(),
      this.listenGettingNewMessageEvent(),
      this.handleExceptions(),
    )
      .pipe(
        map(() => void 0),
      );
  }

  connectToChat(): Observable<void> {
    this.store.dispatch(clearChatStore());
    this.socket.connect();
    this.store.dispatch(loadChatsList());
    this.store.dispatch(loadEmployeeList());

    return this.listenEvents();
  }

  sendMessage(data: NewMessage): Observable<ChatMessage> {
    this.socket.emitData(SOCKET_EVENTS.MESSAGE_NEW, data);

    return this.socket.messageNew$;
  }

  getChatList(): Observable<ChatItem[]> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_LIST, {});

    return this.socket.chatList$;
  }

  deleteChat(chatId: ChatItem['chatId']): Observable<{ chatId: ChatItem['chatId'] }> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_DELETE, { chatId });

    return this.socket.chatDelete$;
  }

  listenGettingNewMessageEvent(): Observable<ChatMessage> {
    return this.socket.messageNew$
      .pipe(
        filter(Boolean),
        tap((message) => {
          this.store.dispatch(addNewMessage({ message }));
          this.chatMessagesService.scrollToBottom();

          if (this.chatService.activeChatItem?.chatId === message.chatId) {
            this.markMessagesAsRead(message.chatId, [message.messageId]);
          }
        }),
      );
  }

  handleExceptions(): Observable<void> {
    return this.socket.exception$
      .pipe(
        tap(({ message }) => {
          this.dataService.showNotification(message, null, 2000, 'error');
        }),
        map(() => void 0),
      );
  }

  listenChatTriggerEvents(): Observable<void> {
    return this.socket.chatTrigger$.pipe(filter(Boolean))
      .pipe(
        tap(({ chatId }) => this.store.dispatch(loadChat({ chatId }))),
        map(() => void 0),
      );
  }

  loadChat(chatId: ChatItem['chatId']): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.CHAT, { chatId });

    return this.socket.chat$;
  }

  createPrivateChat(data): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_CREATE, data);

    return this.socket.chatCreate$.pipe(filter((chatItem) => chatItem.chatType === CHAT_TYPE.PRIVATE));
  }

  createGroupChat(data: ChatCreationRequest): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_CREATE, data);

    return this.socket.chatCreate$.pipe(filter((chatItem) => chatItem.chatType === CHAT_TYPE.GROUP));
  }

  editGroupChat(body: ChatEdit): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_EDIT, body);

    return this.socket.chatEdit$;
  }

  getChatsHistory(chatHistoryRequest: ChatHistoryRequest): Observable<ChatHistory> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_HISTORY, chatHistoryRequest);

    return this.socket.chatHistory$;
  }

  markMessageAsReadFromChatHistory({ chat, messages }: ChatHistory): void {
    const messageIds = new Set<ChatMessage['messageId']>();

    messages
      .filter((message) => message.status !== MESSAGE_STATUS.SEEN)
      .forEach((message) => messageIds.add(message.messageId));

    if (chat.newMessagesCount && !messageIds.has(chat.lastMessage.messageId)) {
      messageIds.add(chat.lastMessage.messageId);
    }

    if (messageIds.size) {
      this.markMessagesAsRead(chat.chatId, Array.from(messageIds));
    }
  }

  getEmployeeList(): Observable<ChatEmployee[]> {
    return this.restApiService.getEmployeeList();
  }

  removeChatMember(chatId: number, userId: number): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.MEMBER_DELETE, { chatId, userId });

    return this.socket.memberDelete$;
  }

  addChatMembers(chatId: number, userIds: number[]): Observable<Member[]> {
    this.socket.emitData(SOCKET_EVENTS.MEMBER_ADD, { chatId, userIds });

    return this.socket.membersAdd$;
  }

  private markMessagesAsRead(chatId, messageIds: number[]): void {
    this.socket.emitData(SOCKET_EVENTS.MESSAGE_STATUS, { chatId, messageIds });
  }
}
