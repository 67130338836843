import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-stripe-guide',
  templateUrl: './stripe-guide.component.html',
  styleUrls: ['./stripe-guide.component.scss'],
})
export class StripeGuideComponent implements OnInit {
  private GUIDE_STEPS_NUMBER = 6;
  public steps: number[] = Array(this.GUIDE_STEPS_NUMBER).fill(0).map((x,i)=>i);

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }

}
