import { Component, Input } from '@angular/core';
import { ChartLineEntity } from '@models/shared/chart-line.entitty';
import { curveMonotoneX } from 'd3-shape';

@Component({
  selector: 'app-pai-score-delta-chart',
  templateUrl: './pai-score-delta-chart.component.html',
  styleUrls: [
    '../pai-score-chart/pai-score-chart.component.scss',
    './pai-score-delta-chart.component.scss',
  ],
})
export class PaiScoreDeltaChartComponent {

  @Input()
  public title: string = '';
  @Input()
  public data: ChartLineEntity<any>[] = [];
  @Input()
  public infoText: string;
  @Input()
  xAxisLabel: string = '';
  @Input()
  yAxisLabel: string = '';
  @Input()
  set linesColors(colors: string[]) {
    if (colors) {
      this.colorScheme.domain = [...colors];
    }
  }
  @Input()
  set xAxisTicks(ticks: number[]) {
    if (ticks) {
      this.setTicks(ticks);
    }
  }
  @Input() yAxisIntegersOnly: boolean = false;
  @Input() firstDelta: number;
  @Input() secondDelta: number;

  readonly curveMonotoneX = curveMonotoneX;

  get improvedBy(): number {
    if (typeof this.firstDelta === 'number' && typeof this.secondDelta === 'number') {
      return Math.abs(this.firstDelta - this.secondDelta);
    }
  }

  public showChart: boolean = false;
  public showInfoText: boolean = false;

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  ticks: number[];
  xScaleMin: number;
  xScaleMax: number;
  yTicks: number[];

  timeline: boolean = true;

  colorScheme = {
    domain: ['#3BCC99', '#6C42DD'],
  };

  toggleShowState(): void {
    this.showChart = !this.showChart;
  }

  xAxisTickFormatting(value: number | string): number | string {
    if (typeof value === 'number') {
      return Math.round(value);
    }
    return value;
  }

  private setTicks(ticks: number[]): void {
    if (!ticks.length) {
      return;
    }

    const min = Number(ticks[0]);
    const max = Number(ticks[ticks.length - 1]);

    this.ticks = ticks.map((elem) => {
      return Number(elem);
    });

    this.xScaleMin = min === 0 ? 0.1 : min;
    this.xScaleMax = max === 0 ? 0.1 : max;
  }

}
