<article>
  <app-material-header
    class="pai-score-header"
    [name]="'company.paiScore' | translate | uppercase"
    [extra]="extra"
  ></app-material-header>

  <ng-template #extra>
    <app-pai-score-header
      [fromDate]="currentFromDate"
      [toDate]="currentToDate"
      (fromDateChange)="setFromDate($event)"
      (toDateChange)="setToDate($event)"
      (resetDate)="resetDate()"
    ></app-pai-score-header>
  </ng-template>

  <section class="score">
    <app-pai-score-number-stat
      *ngIf="summaryStat"
      [data]="summaryStat"
    ></app-pai-score-number-stat>

    <app-pai-score-chart
      *ngIf="averageScoreChart"
      class="chart"
      [data]="averageScoreChart"
      [linesColors]="['#6C42DD', '#14BFFB', '#FFA400', '#0E9063','#3BCC99']"
      [title]="'company.averagePaiScore' | translate | uppercase"
      [xAxisLabel]="'requests.date' | translate"
      [yAxisLabel]="'company.paiScore' | translate"
      [yAxisIntegersOnly]="true"
      [isRoundDomains]="true"
    >
    </app-pai-score-chart>

    <app-pai-score-chart
      *ngIf="activeEmployeesChart"
      class="chart"
      [linesColors]="['#14BFFB', '#FFA400', '#0E9063','#3BCC99', '#6C42DD']"
      [infoText]="'company.activeEmployeesInfo' | translate"
      [title]="'company.activeEmployees' | translate | uppercase"
      [data]="activeEmployeesChart"
      [xAxisLabel]="'requests.date' | translate"
      [yAxisLabel]="'company.noOfemployees' | translate"
      [yAxisIntegersOnly]="true"
    ></app-pai-score-chart>

    <app-pai-score-chart
      *ngIf="usersInBandsChart"
      class="chart"
      [linesColors]="['#3BCC99', '#6C42DD', '#14BFFB', '#FFA400', '#F11C48']"
      [data]="usersInBandsChart"
      [title]="'company.usersInBands' | translate | uppercase"
      [xAxisLabel]="'requests.date' | translate"
      [yAxisLabel]="'company.noOfemployees' | translate"
      [yAxisIntegersOnly]="true"
    >
    </app-pai-score-chart>

    <app-pai-score-chart
      *ngIf="fitnessAgeRatingChart"
      class="chart"
      [linesColors]="['#0E9063', '#3BCC99', '#6C42DD', '#14BFFB', '#FFA400']"
      [data]="fitnessAgeRatingChart"
      [title]="'company.usersInAgeRating' | translate | uppercase"
      [xAxisLabel]="'requests.date' | translate"
      [yAxisLabel]="'company.noOfemployees' | translate"
      [yAxisIntegersOnly]="true"
    >
    </app-pai-score-chart>

    <app-pai-score-delta-chart
      *ngIf="deltaAgeChart"
      class="chart"
      [linesColors]="['#6C42DD', '#3BCC99']"
      [infoText]="'company.fitnessAgeDeltaInfo' | translate"
      [title]="'company.fitnessAgeDelta' | translate | uppercase"
      [data]="deltaAgeChart"
      [xAxisLabel]="'company.ageMinusFitAge' | translate"
      [yAxisLabel]="'company.noOfemployees' | translate"
      [xAxisTicks]="deltaAgeTicks"
      [firstDelta]="firstDelta"
      [secondDelta]="secondDelta"
    >
    </app-pai-score-delta-chart>
  </section>
<article>
