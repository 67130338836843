<div class="modal">
  <header class="modal-header">
    <div class="modal-header-title">
      {{ "company.addEmployee" | translate }}
    </div>
    <button class="modal-header-close" mat-dialog-close>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path
          d="M18.186 16.5115L13.6744 12L18.186 7.48835C18.3915 7.28283 18.5581 6.55811 18 5.99998C17.4418 5.44184 16.7171 5.60843 16.5116 5.81393L12 10.3256L7.48835 5.81397C7.28288 5.60845 6.55814 5.44186 6.00002 5.99998C5.44191 6.55809 5.60847 7.28286 5.81397 7.48835L10.3256 12L5.81395 16.5116C5.60848 16.7171 5.44184 17.4418 6 18C6.55816 18.5582 7.2829 18.3915 7.48838 18.1861L12 13.6744L16.5116 18.186C16.7171 18.3915 17.4419 18.5581 18 18C18.5582 17.4418 18.3915 16.717 18.186 16.5115Z"></path>
      </svg>
    </button>
  </header>

  <div class="modal-body">
    <form class="modal-body-form" [formGroup]="emailsForm">
      <div class="email-input-row" *ngFor="let emailsControl of emails.controls; index as i">
        <app-material-input
          [formControl]="emailsControl"
          [label]="'profile.personal.email' | translate"
          class="email-input"
          [autofocus]="true"
        >
        </app-material-input>

        <app-button
          class="delete"
          [icon]="true"
          [disabled]="emails.controls.length < 2"
          color="remove"
          (click)="removeEmail(i)"
        >
          <i>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.99998 12.6667C3.99998 13.4 4.59998 14 5.33331 14H10.6666C11.4 14 12 13.4 12 12.6667V4.66667H3.99998V12.6667ZM12.6666 2.66667H10.3333L9.66665 2H6.33331L5.66665 2.66667H3.33331V4H12.6666V2.66667Z" fill="#F11C48"/>
            </svg>
          </i>
        </app-button>
      </div>

      <app-button color="secondary" (click)="addNewEmail()">
        <i>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6666 8.66671H8.66659V12.6667H7.33325V8.66671H3.33325V7.33337H7.33325V3.33337H8.66659V7.33337H12.6666V8.66671Z" fill="#14BFFB"/>
          </svg>
        </i>
        <span>
          {{ "company.addEmail" | translate }}
        </span>
      </app-button>
    </form>
  </div>

  <div class="modal-actions">
    <app-button color="close" (click)="close()">
      {{ "common.cancel" | translate }}
    </app-button>
    <app-button color="primary" [disabled]="emailsForm.invalid" (click)="submitEmails()">
      {{ "common.confirm" | translate }}
    </app-button>
  </div>
</div>
