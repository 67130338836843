import {Component, OnInit} from '@angular/core';

import {StepperService} from '../../services/stepper.service';
import {ProfileService} from '../../services/profile.service';
import {dropDown} from '../../modules/material/material-animations/drop-down';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  animations: [dropDown],
})
export class StepperComponent implements OnInit {
  public hintIsShown = true;

  get isPartner() {
    return this.profileService.isProfile('PARTNER_TYPE');
  }

  constructor(
    public stepperService: StepperService,
    public profileService: ProfileService,
  ) { }

  ngOnInit() {
  }

  toggleHint( event ) {
    event.stopPropagation();
    event.preventDefault();
    this.hintIsShown = !this.hintIsShown;
  }

  getLangStringIndex(i) {
    if (i === 3 && this.isPartner) {
      return 6;
    }
    return i;
  }
}
