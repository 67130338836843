import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from './http.service';

import {LOCATION_PRIORITY} from '../utils/enum';
import {ErrorService} from './error.service';

export interface RadiusPriority {
  radius: number;
  priority: string;
}

@Injectable({
  providedIn: 'root',
})
export class TrainingLocationService {

  constructor(
    private http: HttpService,
    private translate: TranslateService,
    private errorService: ErrorService,
  ) { }

  update(data) {
    return this.http.putData('/training-location', data);
  }

  /**
   * Convert absolute radiuses to detlas
   * @param radiuses
   */
  radiusToDelta(radiuses: any) {
    const primary_zone: RadiusPriority = radiuses.find((point: RadiusPriority) => point.priority === LOCATION_PRIORITY.PRIMARY);
    const secondary_zone: RadiusPriority = radiuses.find((point: RadiusPriority) => point.priority === LOCATION_PRIORITY.SECONDARY);
    const tertiary_zone: RadiusPriority = radiuses.find((point: RadiusPriority) => point.priority === LOCATION_PRIORITY.TERTIARY);

    const map = new Map();

    if (primary_zone) {
      map.set(LOCATION_PRIORITY.PRIMARY, primary_zone.radius / 1000);
    }

    if (secondary_zone) {
      map.set(LOCATION_PRIORITY.SECONDARY, (secondary_zone.radius - primary_zone.radius) / 1000);
    }

    if (tertiary_zone) {
      map.set(LOCATION_PRIORITY.TERTIARY, (tertiary_zone.radius - secondary_zone.radius) / 1000);
    }

    return map;
  }

  /**
   * Convert deltas to absolute radiuses
   * @param deltas
   */
  deltaToRadius(deltas: any): RadiusPriority[] {
    const radiuses = [];

    const {primary_zone, secondary_zone, tertiary_zone} = deltas;

    const radiusesMap = new Map()
      .set(LOCATION_PRIORITY.PRIMARY, primary_zone)
      .set(LOCATION_PRIORITY.SECONDARY, secondary_zone)
      .set(LOCATION_PRIORITY.TERTIARY, tertiary_zone);

    let radiusSum = 0;

    radiusesMap.forEach((radius: number, priority: string) => {
      if (radius) {
        radiusSum += Number(radius);

        radiuses.push({
          priority,
          radius: radiusSum * 1000,
        });
      }
    });

    return radiuses;
  }

  /**
   * Get user geolocation
   */
  getGeolocation(onSuccess, onError = (error) => {}) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        onSuccess(latLng);
      }, (error) => {
        onError(error);
      });
    } else {
      this.errorService.catchError(
        new Error('GeolocationIsNotSupported'), {
          message: this.translate.instant('profile.location.geolocationIsNotSupported'),
        },
      );
    }
  }

  /**
   * Create custom button for map
   */
  createLocationButton(): HTMLElement {
    const locationButton = document.createElement('button');
    locationButton.classList.add('my-location-button');
    return locationButton;
  }

}
