import {Point} from './point';
import {PrototypeEntity} from '@models/prototype.entity';

export class Country extends PrototypeEntity {
  id: number= null;
  name: string= null;
  default_coordinates?: Point = null;

  constructor(data?: object) {
    super();
    this.setData(data);
  }
}
