import {Component, Input, OnInit} from '@angular/core';
import {EventSession} from '../../../../models/session';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss'],
})
export class ParticipantsComponent implements OnInit {
  @Input() session: EventSession;

  constructor() { }

  ngOnInit() {
  }

}
