<section class="section section--joined" *ngIf="session?.participants?.joined">
  <div class="section-title">
    <span class="section-title--bold">
      {{'events.joined' | translate}}
    </span>
    ({{session.participants.joined.length}}/{{session.max_participants}})
  </div>
  <div class="section-block" *ngFor="let user of session.participants.joined">
    <div class="section-block-avatar" [style.backgroundImage]="'url(' + user.avatar + ')'">
    </div>
    <span class="section-block-name">
      {{user.first_name}} {{user.last_name}}
    </span>
    <div class="section-block-divider"></div>
    <span class="section-block-type">
      {{'header.' + user.profiles[0].type | translate}}
    </span>
  </div>
</section>
<section class="section section--invited" *ngIf="session?.participants?.invited?.length">
  <div class="section-title">
    <span class="section-title--bold">
      {{'events.invited' | translate}}
    </span>
  </div>
  <div class="section-block" *ngFor="let user of session.participants.invited">
    <div class="section-block-avatar"
         [style.backgroundImage]="'url(' + (user.avatar || '/assets/img/profile_default.svg') + ')'">

    </div>
    <span class="section-block-name">
      {{user.first_name}} {{user.last_name}}
    </span>
    <div class="section-block-divider"></div>
    <span class="section-block-type">
      {{'header.' + user.profiles[0].type | translate}}
    </span>
  </div>
</section>
