<div class="chart-header" >
  <app-button
    class="toggle"
    color="toggle"
    [icon]="true"
    [border]="true"
    [ngClass]="{open: showChart}"
    (click)="toggleShowState()"
  >
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.94 5.72668L8 8.78002L11.06 5.72668L12 6.66668L8 10.6667L4 6.66668L4.94 5.72668Z" fill="#14BFFB"/>
    </svg>
  </app-button>
  <div class="chart-header-content">
    <p>
      {{ title }}
    </p>
    <app-button
      *ngIf="infoText"
      class="info"
      [icon]="true"
      [border]="true"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99992 1.33337C4.31992 1.33337 1.33325 4.32004 1.33325 8.00004C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.6799 1.33337 7.99992 1.33337ZM8.66658 11.3334H7.33325V7.33337H8.66658V11.3334ZM8.66658 6.00004H7.33325V4.66671H8.66658V6.00004Z" fill="#14BFFB"/>
      </svg>
    </app-button>
    <div class="info-text">
      {{ infoText }}
    </div>
  </div>
</div>

<div #containerRef class="chart-content" [ngClass]="{show: showChart}">
  <ngx-charts-line-chart
    class="app-chart no-vertical-grid"
    [legend]="true"
    [legendTitle]=""
    [legendPosition]="'below'"
    [scheme]="colorScheme"
    [showXAxisLabel]="xAxisLabel"
    [showYAxisLabel]="yAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisTickFormatting]="xAxisTickFormatting"
    [yAxisTickFormatting]="yAxisTickFormattingFn"
    [xAxisTicks]="ticks"
    [xScaleMin]="xScaleMin"
    [xScaleMax]="xScaleMax"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [view]="[containerRef.offsetWidth, 400]"
    [results]="data"
    [curve]="curveMonotoneX"
    [roundDomains]="isRoundDomains"
  >
  </ngx-charts-line-chart>
</div>


