<form [formGroup]="form">
  <app-material-input
    formControlName="email"
    type="email"
    autofocus
    [label]="'profile.personal.email' | translate"
    [errors]="{
          required: 'common.errors.required' | translate,
          pattern: 'common.errors.emailInvalid' | translate
        }"
  >
  </app-material-input>

  <app-material-input
    formControlName="password"
    type="password"
    [label]="'common.password' | translate"
    maxlength="50"
    [isShowCounter]="false"
    [errors]="{
          required: 'common.errors.required' | translate,
          pattern: 'common.errors.passwordPattern' | translate
        }"
  >
  </app-material-input>

  <app-material-input
    formControlName="repeatPassword"
    type="password"
    [label]="'signUp.repeatPassword' | translate"
    maxlength="50"
    [isShowCounter]="false"
    [errors]="{required: 'common.errors.required' | translate}"
    [formError]="form.hasError('noMatch') ? ('common.errors.passwordDoesntMatch' | translate) : null"
  >
  </app-material-input>
  <div class="checkbox-container">
    <app-material-checkbox formControlName="confirmPolicy"></app-material-checkbox>
    <p>
      {{ 'signUp.readAndUnderstood' | translate }}
      <a [href]="privacyPolicyUrl" target="_blank">{{ 'signUp.privacyPolicy' | translate }}</a>
    </p>
  </div>
</form>
