<div class="login restore">
  <div class="login-wrapper">
    <div class="login-wrapper-header">
      <app-button
        (click)="navigateBack()"
        class="back"
        [icon]="true"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.2733 4.94L9.33331 4L5.33331 8L9.33331 12L10.2733 11.06L7.21998 8L10.2733 4.94Z" fill="#14BFFB"/>
        </svg>
      </app-button>
      <div class="logo">
        <img src="/assets/logo.svg" alt="MIA logo" class="login-wrapper-logo">
      </div>
    </div>

    <div class="login-wrapper-form">
      <h3 class="login-wrapper-form-name">
        {{'common.restorePassword' | translate}}
      </h3>

      <form [formGroup]="form" *ngIf="form">
        <app-material-input formControlName="email"
                            type="email"
                            autofocus
                            [label]="'profile.personal.email' | translate"
                            [errors]="{
                                  required: 'common.errors.required' | translate,
                                  pattern: 'common.errors.emailInvalid' | translate
                                }">

        </app-material-input>
      </form>

      <div class="buttons">
        <app-button
          color="primary"
          [disabled]="form.invalid"
          (click)="submitForm()"
        >
          {{'common.restore' | translate}} {{'common.password' | translate}}
        </app-button>
      </div>
    </div>

    <div class="splash"></div>
  </div>
</div>

