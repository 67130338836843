import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
// @ts-ignore
import {GestureHandlingOptions, LatLng} from 'googlemaps';

import {TrainingLocationService} from '../../../../../services/training-location.service';
import {ProfileService} from '../../../../../services/profile.service';

@Component({
  selector: 'app-activity-partner-map',
  templateUrl: './activity-partner-map.component.html',
  styleUrls: ['./activity-partner-map.component.scss'],
})
export class ActivityPartnerMapComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  marker: any;

  @Input() locationForm: UntypedFormGroup;

  @ViewChild('map', { static: true }) googleMap: ElementRef;

  get coordinatesGroup() {
    return <UntypedFormGroup>this.locationForm.get('coordinates');
  }

  get coordinates() {
    return <{lat: number; lng: number}> this.locationForm.get('coordinates').value;
  }

  map: any;
  mapOptions: any;

  // Default settings for map component.
  private defaultPosition = {
    lat: 59.912147,
    lng: 10.753112,
  };

  private defaultMapOptions = {
    zoom: 7,
    disableDoubleClickZoom: true,
    mapTypeControl: false,
    panControl: false,
    streetViewControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    center: this.defaultPosition,
    gestureHandling: 'cooperative' as GestureHandlingOptions,
  };
  public canEditProfile: boolean;
  private subs: Subscription;

  constructor(
    private profileService: ProfileService,
    private trainingLocationService: TrainingLocationService,
  ) {
    this.canEditProfile = true;
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.mapInit();
    this.subs.add(
      this.profileService.cancelEditProfileEvent.subscribe(() => {
        this.resetMarker();
      }),
    );
    this.subs.add(
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.destroy$.complete();
  }

  /**
   * Create map, set options and listeners
   */
  mapInit() {
    // Initialization of map component.
    this.map = new google.maps.Map(this.googleMap.nativeElement, this.defaultMapOptions);

    // init events
    google.maps.event.addListener(this.map, 'click', (event) => this.replaceMarker(event.latLng));

    // if coordinates exist
    if (this.coordinates.lat && this.coordinates.lng) {
      const position = new google.maps.LatLng(this.coordinates.lat, this.coordinates.lng);

      this.initMarker(position);
      // change default center to user point
      this.map.setCenter(position);
    }

    this.addLocationButton();
  }

  /**
   * Add location button on map
   */
  addLocationButton() {
    const button = this.trainingLocationService.createLocationButton();

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(button);

    button.addEventListener('click', () => {
      this.getGeolocation();
    });
  }

  /**
   * Add marker on map
   * @param position
   */
  initMarker(position: LatLng) {
    this.marker = new google.maps.Marker({
      position,
      map: this.map,
      draggable: true,
    });

    this.marker.addListener('dragend', (event) => this.replaceMarker(event.latLng));
  }

  resetMarker() {
    if (this.coordinates.lat && this.coordinates.lng) {
      const position = new google.maps.LatLng(this.coordinates.lat, this.coordinates.lng);

      this.replaceMarker(position);
    }
  }

  /**
   * MapEvent: Change position of marker
   * If marker was not created before - init new marker
   * @param event
   */
  replaceMarker(position: LatLng) {
    if (this.marker) {
      this.marker.setPosition(position);
    } else {
      this.initMarker(position);
    }

    this.map.setCenter(position);

    this.saveMarkerPosition(position);
  }

  /**
   * Patch coordinates into form
   * @param position
   */
  saveMarkerPosition(position: LatLng) {
    this.coordinatesGroup.patchValue({
      lat: position.lat(),
      lng: position.lng(),
    });
  }

  /**
   * getGeolocation
   */
  getGeolocation() {
    this.trainingLocationService.getGeolocation((position: LatLng) => {
      if (position) {
        this.replaceMarker(position);
      }
    });
  }

}
