import { createAction, props } from '@ngrx/store';
import { ChatEdit, ChatHistory, ChatHistoryRequest, ChatItem, ChatMessage, ChatCreationRequest, NewMessage, Member } from '../interfaces';
import { ChatEmployee } from '@modules/chats/interfaces/chat-employee.interface';

export const clearChatStore = createAction('[Chat] Clear Chat Store');

export const loadChatsList = createAction('[Chat] Load Chats List');
export const loadChatsListSuccess = createAction('[Chat] Load Chats List Success', props<{ chatList: ChatItem[] }>());

export const loadChatHistory = createAction('[Chat] Load Chat History', props<{ chatHistoryRequest: ChatHistoryRequest }>());
export const loadChatHistoryPending = createAction('[Chat] Load Chat History Pending');
export const loadChatHistorySuccess = createAction('[Chat] Load Chat History Success', props<{ chatHistory: ChatHistory }>());
export const clearChatHistory = createAction('[Chat] Clear Chat History');

export const loadEmployeeList = createAction('[Chat] Load Employee List');
export const loadEmployeeListSuccess = createAction('[Chat] Load Employee List Success', props<{ employeeList: ChatEmployee[] }>());

export const createGroupChat = createAction('[Chat] Create Group Chat', props<{ chatCreationRequest: ChatCreationRequest }>());
export const createGroupChatSuccess = createAction('[Chat] Create Group Chat Success', props<{ chatItem: ChatItem }>());

export const createPrivateChat = createAction('[Chat] Create Private Chat', props<{ data: ChatCreationRequest }>());
export const createPrivateChatSuccess = createAction('[Chat] Create Private Chat Success', props<{ chatItem: ChatItem }>());
export const createPrivateChatFailure = createAction('[Chat] Create Private Chat Failure');

export const editGroupChat = createAction('[Chat] Edit Group Chat', props<{ body: ChatEdit }>());
export const editGroupChatSuccess = createAction('[Chat] Edit Group Chat Success', props<{ chatItem: ChatItem }>());

export const sendMessage = createAction('[Chat] Send Message', props<{ message: NewMessage }>());
export const sendMessageSuccess = createAction('[Chat] Send Message Success', props<{ chatMessage: ChatMessage }>());

export const setPrivateActiveChatId = createAction('[Chat] Set Private Active Chat Id', props<{ id: ChatItem['chatId'] }>());
export const setGroupActiveChatId = createAction('[Chat] Set Group Active Chat Id', props<{ id: ChatItem['chatId'] }>());

export const addNewMessage = createAction('[Chat] Add New Message', props<{ message: ChatMessage }>());

export const deleteChat = createAction('[Chat] Delete Chat', props<{ chatId: ChatItem['chatId'] }>());
export const deleteChatSuccess = createAction('[Chat] Delete Chat Success', props<{ chatId: ChatItem['chatId'] }>());

export const loadChat = createAction('[Chat] Load Chat', props<{ chatId: ChatItem['chatId'] }>());
export const loadChatSuccess = createAction('[Chat] Load Chat Success', props<{ chat: ChatItem }>());

export const setUserExternalId = createAction('[Chat] Set User External Id', props<{ userExternalId: number }>());

export const addChatMembers = createAction('[Chat] Add Chat Member', props<{ chatId: ChatItem['chatId'], userIds: Array<ChatEmployee['externalId']> }>());
export const addChatMembersSuccess = createAction('[Chat] Add Chat Member Success', props<{ members: Member[] }>());

export const removeChatMember = createAction('[Chat] Remove Chat Member', props<{ chatId: ChatItem['chatId'], externalId: number }>());

export const removeChatMemberSuccess = createAction('[Chat] Remove Chat Member Success', props<{ chatItem: ChatItem }>());
