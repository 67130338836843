import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './material-button.component.html',
  styleUrls: ['./material-button.component.scss'],
})
export class MaterialButtonComponent implements OnInit, OnChanges {
  @HostBinding('class.disabled') @Input() disabled: boolean = false;
  @Input() icon: boolean = false;
  @Input() border: boolean = false;
  // TODO: should be divided into color and type: 'filled' | 'outlined' etc.
  @Input() color: 'primary' | 'secondary' | 'accent' | 'close' | 'toggle' | 'remove' | 'warning' = 'secondary';
  @Input() elevation: boolean = false;

  currentClasses: object = {};

  constructor() {}

  ngOnInit(): void {
    this.currentClasses = {
      icon: this.icon,
      border: this.border,
      elevation: this.elevation,
      [this.color]: true,
    };
  }

  ngOnChanges(): void {
    this.currentClasses = {
      icon: this.icon,
      border: this.border,
      elevation: this.elevation,
      [this.color]: true,
    };
  }

}
