import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {FeathersService} from '@services/feathers.service';

@Injectable()

export class AnonGuard implements CanActivate {

  constructor(
    public feathers: FeathersService,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (!this.feathers.isAutentificated()) {
      return true;
    }

    return this.router.parseUrl('/');
  }
}
