import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {HttpService} from './http.service';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  private _priceList = new BehaviorSubject([]);
  private _dataStore: {
    priceList: any[];
  };
  constructor(private http: HttpService) {
    this._dataStore = {
      priceList: [],
    };
  }

  public get priceList() {
    return this._priceList.asObservable();
  }

  public getPriceList(profileId: number) {
    this.http.getData(`/trainer-price-list?&profile_id=${profileId}`).subscribe(response => {
      this._dataStore.priceList = response.data;
      this._priceList.next(cloneDeep(this._dataStore.priceList));
    });
  }

  public savePrices(payload): Observable<any> {
    return this.http.patchData('/trainer-price-list', payload).pipe(
      map(response => {
        // todo dunno why server doesn't return new price list
        // this._dataStore.priceList = payload.price_list;
        // this._priceList.next(cloneDeep(this._dataStore.priceList));
        return response;
      }),
    );
  }
}
