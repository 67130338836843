import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

interface Notification {
  message: string;
  action?: any;
  duration?: number;
  panelClass?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientMessageService {

  constructor(
    private matSnackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  /**
   * Display message
   */
  public showMessage(message) {
    const translatedMessage = this.translate.instant(message);

    this.openSnackBar({
      message: translatedMessage,
    });
  }

  private openSnackBar(notification: Notification) {
    const {
      message,
      action = null,
      duration = 3000,
      panelClass = 'success',
    } = notification;

    this.matSnackBar.open(message, action, {
      duration,
      panelClass,
      verticalPosition: 'top',
    });
  }
}
