import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LANGUAGE} from '../../../utils/enum';
import {DataService} from '../../../services/data.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss'],
})
export class LanguageModalComponent implements OnInit {

  public languages: string[];
  public selectedLanguage: string;

  constructor(public translate: TranslateService, private data: DataService, private dialog: MatDialog) {
    this.languages = Object.values(LANGUAGE);
    this.selectedLanguage = translate.currentLang;
  }

  ngOnInit() {
  }

  select(lang: string): void {
    this.selectedLanguage = lang;
  }

  save(): void {
    if (this.selectedLanguage !== this.translate.currentLang) {
      this.data.setLanguage(this.selectedLanguage);
      location.reload();
    } else {
      this.dialog.closeAll();
    }
  }
}
