import {PrototypeEntity} from '@models/prototype.entity';
import { UtvSubscription } from '@models/utv/utv-subscription';

export class CompanyEntity extends PrototypeEntity {
  address: string = null;
  country_id: number = null;
  created_at: string = null;
  description: string = null;
  email: string = null;
  id: number = null;
  user_id: number = null;
  logo: string = null;
  name: string = null;
  updated_at: string = null;
  licence: UtvSubscription = null;
  enabled: boolean = null;
  external_user_id = null;
  status_changed_at: string = null;
  trial_period_expires_at: string = null;

  constructor(data?: object) {
    super();
    this.setData(data);

    this.setReadOnlyFields([
      'email',
      'created_at',
      'updated_at',
      'id',
      'user_id',
      'licence',
      'status_changed_at',
      'trial_period_expires_at',
    ]);
  }
}
