import {PrototypeEntity} from '../prototype.entity';

export class CompanySignupEntity extends PrototypeEntity {
  name: string = null;
  country_id: number = null;
  address: string = null;
  logo: string = null;
  email: string = null;
  password: string = null;
  description?: string = null;

  constructor(data?: object) {
    super();
    this.setData(data);
  }
}
