import { PrototypeEntity } from '@models/prototype.entity';

export class Employee extends PrototypeEntity {
  readonly id: number = null;
  company_id: number = null;
  email: string = null;
  enabled: boolean = null;
  user_id: number = null;
  request: string = null;
  original_request: EMPLOYEE_REQUEST = null;
  is_pai_data_permitted: boolean = null;
  is_profile_picture_permitted: boolean = null;
  is_contact_info_permitted: boolean = null;
  created_at: string = null;
  status_pai: StatusPai = null;
  user: User = null;

  constructor(employee: Employee) {
    super();

    this.setData(employee);

    this.status_pai = new StatusPai(employee.status_pai);
  }

  setPendingRequest(): void {
    this.original_request = EMPLOYEE_REQUEST.PENDING;
  }

  setRequestStatus(request): void {
    this.request = request;
  }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
  }
}

export class StatusPai {
  name: string;
  state: boolean;

  constructor({ name, state }: StatusPai) {
    this.name = name;
    this.state = state;
  }
}

export enum EMPLOYEE_REQUEST {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  DISABLED = 'DISABLED',
  ACCEPTED = 'ACCEPTED',
}

interface User {
  id: number;
  avatar: string;
  first_name: string;
  last_name: string;
  telephone: string;
}
