import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {cloneDeep} from 'lodash';
import {map} from 'rxjs/operators';
import * as momentjs from 'moment';
import {Moment} from 'moment';
import {extendMoment} from 'moment-range';
import {HttpService} from './http.service';
import {SessionPreview} from '../models/session-preview';
import {ProfileService} from './profile.service';
import {SessionList} from '../models/session';
import {SESSION} from '../utils/enum';

const moment = extendMoment(momentjs);

interface CalendarActivities {
  day: Moment;
  session: number;
  event: number;
  schedule: any;
}

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  // todo should be private when all calendar stuff is moved here.
  public readonly _trainings: BehaviorSubject<any>;
  public readonly _bookings: BehaviorSubject<any>;
  // todo legacy start
  public _legacySchedule: BehaviorSubject<any>;

  private _schedule: BehaviorSubject<any>;
  private readonly _totalRequests: BehaviorSubject<number>;
  private readonly _requests: BehaviorSubject<SessionList[]>;
  private readonly _upcoming: BehaviorSubject<SessionList[]>;
  private readonly _finished: BehaviorSubject<SessionList[]>;
  private readonly _calendar: BehaviorSubject<SessionList[]>;
  private _currentRange: BehaviorSubject<string[]>;
  private SESSION: typeof SESSION = SESSION;
  private readonly _activitiesStore: CalendarActivities;
  private _activities: BehaviorSubject<CalendarActivities>;
  private _activityOpen: boolean;

  // legacy end
  private _dataStore: {
    schedule: [];
    trainings: SessionPreview[];
    bookings: [];
    upcoming: SessionList[];
    requests: SessionList[];
    finished: SessionList[];
    calendar: SessionList[];
  };

  constructor(private http: HttpService,
    private profileService: ProfileService) {
    this._schedule = new BehaviorSubject([]);
    this._trainings = new BehaviorSubject([]);
    this._bookings = new BehaviorSubject([]);
    this._currentRange = new BehaviorSubject([]);
    this._totalRequests = new BehaviorSubject(0);
    this._requests = new BehaviorSubject([]);
    this._upcoming = new BehaviorSubject([]);
    this._finished = new BehaviorSubject([]);
    this._calendar = new BehaviorSubject([]);
    // todo legacy start
    this._legacySchedule = new BehaviorSubject([]);
    // legacy end

    this._activitiesStore = {
      day: null,
      session: null,
      event: null,
      schedule: null,
    };
    this._activities = new BehaviorSubject(this._activitiesStore);

    this._dataStore = {
      schedule: [],
      trainings: [],
      bookings: [],
      upcoming: [],
      requests: [],
      finished: [],
      // todo calendar option should be removed in future, because it duplicates finished and upcoming
      calendar: [],
    };
  }

  public get schedule(): Observable<any> {
    return this._schedule.asObservable();
  }

  // todo temporal setter. Remove when calendar is remade
  public set schedule(value) {
    this._schedule.next(value);
  }

  // todo legacy start
  public get legacySchedule(): Observable<any> {
    return this._legacySchedule.asObservable();
  }

  // legacy end

  public get trainings(): Observable<any> {
    return this._trainings.asObservable();
  }

  public get bookings(): Observable<any> {
    return this._bookings.asObservable();
  }

  public get totalRequests(): Observable<any> {
    return this._totalRequests.asObservable();
  }

  public get requests(): Observable<any> {
    return this._requests.asObservable();
  }

  public get upcoming(): Observable<any> {
    return this._upcoming.asObservable();
  }

  public get finished(): Observable<any> {
    return this._finished.asObservable();
  }

  public get calendar(): Observable<any> {
    return this._calendar.asObservable();
  }

  public get currentRange(): any {
    return this._currentRange.asObservable();
  }

  public get activities(): Observable<CalendarActivities> {
    return this._activities.asObservable();
  }

  public get activityOpen(): boolean {
    return this._activityOpen;
  }

  getSchedule(trainerId: number, timeRange: boolean = false): void {
    if (timeRange) {
      const range = [
        moment().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        moment().endOf('day').days(14).utc().format('YYYY-MM-DD HH:mm:ss'),
      ];
      return this.http
        .getData(`/trainers-schedule?&profile_id=${trainerId}&time_range[$contained]=${range[0]}&time_range[$contained]=${range[1]}`)
        .pipe(
          map((response: any) => {
            return response.data.map(scheduleItem => {
              scheduleItem.time_range = moment.range(moment(scheduleItem.time_range[0]).local(), moment(scheduleItem.time_range[1]).local());
              return scheduleItem;
            });
          }),
        )
        .subscribe(schedule => {
          this._dataStore.schedule = schedule;
          this._schedule.next(cloneDeep(this._dataStore.schedule));
        });
    }
    this.http.getData(`/trainers-schedule?&profile_id=${trainerId}`).subscribe(response => {
      this._dataStore.schedule = response.data;
      this._schedule.next(cloneDeep(this._dataStore.schedule));
    });
  }

  createSchedule(schedule: { profile_id: number; time_ranges: any[] }): Observable<any> {
    return this.http.postData('/trainers-schedule', schedule).pipe(
      map((response: any) => {
        // todo legacy start
        this._legacySchedule.next(cloneDeep(response));
        // legacy end
        const mapped = response.data.map(scheduleItem => {
          scheduleItem.time_range = moment.range(moment(scheduleItem.time_range[0]).local(), moment(scheduleItem.time_range[1]).local());
          return scheduleItem;
        });
        this._dataStore.schedule = mapped;
        this._schedule.next(cloneDeep(this._dataStore.schedule));
        return mapped;
      }),
    );
  }

  cancelTraining(id: number): Observable<any> {
    return this.http.patchData(`/trainings/${id}?type=cancel`, '').pipe(
      map(response => {
        this.getRequests().subscribe();
        this.getUpcoming().subscribe();
        this.getFinished().subscribe();
      }),
    );
  }

  confirmTraining(id: number): Observable<any> {
    return this.http.patchData(`/trainings/${id}?type=confirm`, '').pipe(
      map(response => {
        this.getRequests().subscribe();
        this.getUpcoming().subscribe();
        this.getFinished().subscribe();
      }),
    );
  }

  /**
   * GET list of active requests
   */
  getRequests(): Observable<any> {
    return this.http.getData(`/requests/${this.profileService.profileType}`).pipe(
      map((response: SessionList[]) => {
        this._dataStore.requests = response;
        this._totalRequests.next(response.length);
        this._requests.next(cloneDeep(response));
      }),
    );
  }

  /**
   * GET list of UPCOMING/STARTED (confirmed) sessions
   */
  getUpcoming(): Observable<any> {
    const query = `?status[]=${this.SESSION.UPCOMING}&status[]=${this.SESSION.STARTED}`;
    return this.http.getData(`/calendar/${this.profileService.profileType}${query}`).pipe(
      map((response: SessionList[]) => {
        this._dataStore.upcoming = response;
        this._upcoming.next(cloneDeep(response));
      }),
    );
  }

  /**
   * GET list of FINISHED/CANCELED_SOS/CANCELED_SOS/NOT_ENOUGH_MEMBERS/CANCELED_BY_TRAINER(archived) sessions
   */
  getFinished(): Observable<any> {
    const query = `?status[]=${this.SESSION.FINISHED}&status[]=${this.SESSION.CANCELED_SOS}&status[]=${this.SESSION.EXPIRED}&status[]=${this.SESSION.NOT_ENOUGH_MEMBERS}&status[]=${this.SESSION.CANCELED_BY_TRAINER}`;
    return this.http.getData(`/calendar/${this.profileService.profileType}${query}`).pipe(
      map((response: SessionList[]) => {
        this._dataStore.finished = response;
        this._finished.next(cloneDeep(response));
      }),
    );
  }

  /**
   * GET single training
   * @param id
   */
  getTraining(id): Observable<any> {
    return this.http.getData(`/trainings/${id}?profileType=${this.profileService.profileType}`);
  }

  /**
   * GET single event
   * @param id
   */
  getEvent(id): Observable<any> {
    return this.http.getData(`/events/${this.profileService.profileType}/${id}`);
  }

  /**
   * Can do either cancellation or confirmation
   * @param id
   * @param payload
   */
  resolveEvent(id, payload: { event_status: SESSION; message_of_cancellation?: string }): Observable<any> {
    return this.http.patchData(`/events/${this.profileService.profileType}/${id}`, payload).pipe(
      map(response => {
        this.getRequests().subscribe();
        this.getUpcoming().subscribe();
        this.getFinished().subscribe();
      }),
    );
  }

  getCalendar(range?: Date[] | string[], singleCall = false): Observable<any> {
    let url;
    if (range) {
      url = `/calendar/${this.profileService.profileType}?time_range[$overlap][0]=${range[0]}&time_range[$overlap][1]=${range[1]}`;
    } else {
      url = `/calendar/${this.profileService.profileType}`;
    }
    return this.http.getData(url).pipe(
      map((response: SessionList[]) => {
        if (!singleCall) {
          this._dataStore.calendar = response;
          this._calendar.next(cloneDeep(response));
        }

        return response;
      }),
    );
  }

  public activity(type: string, option?: any) {
    if (this._activitiesStore[type] === undefined) {
      throw new Error(`Unknown activity ${type}, here is the list of available activities:`);
    }
    return {
      open: () => {
        this._activitiesStore[type] = option || {};
        this._activities.next({...this._activitiesStore});
        this._activityOpen = true;
      },
      close: () => {
        this._activitiesStore[type] = null;
        this._activities.next({...this._activitiesStore});
        for (const key in this._activitiesStore) {
          if (this._activitiesStore[key]) {
            this._activityOpen = true;
            return;
          }
        }
        this._activityOpen = false;
      },
      closeAll: () => {
        for (const key in this._activitiesStore) {
          this._activitiesStore[key] = null;
          this._activities.next({...this._activitiesStore});
        }
        this._activityOpen = false;
      },
    };
  }
}
