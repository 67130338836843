import {Component, HostBinding, HostListener, OnInit} from '@angular/core';

import {UserMenuComponent} from './user-menu/user-menu.component';
import {PROFILE_TYPES} from '@util/enum';
import {CompanyEntity} from '@models/company/company.entity';
import { filter, pairwise, switchMap } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent extends UserMenuComponent implements OnInit {

  @HostBinding('class.opened')
    menuOpened = false;

  requestsQuantity: number;
  company: CompanyEntity;

  @HostBinding('class.menu-mobile')
  get isMobile(): boolean {
    return this.mobileService ? this.mobileService.isMobile : false;
  }

  @HostBinding('class.menu-desktop')
  get isDesktop(): boolean {
    return this.mobileService ? this.mobileService.isDesktop : false;
  }

  get isClient(): boolean {
    return this.profileService.isProfile(PROFILE_TYPES.CLIENT_TYPE);
  }

  get isCompany(): boolean {
    return this.profileService.isCompany;
  }

  get isCompanyEnabled(): boolean {
    return this.profileService.isCompanyEnabled;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (this.isDesktop && this.menuOpened && event.target.innerWidth < 1500) {
      this.toggleDrawer();
    }
  }

  ngOnInit(): void {
    this.readStorage();
    this.listenTotalRequests();
    this.listenRouterEvents();
    this.getCompany();

  }

  readStorage(): void {
    if (localStorage.getItem('menuOpened') && this.isDesktop) {
      this.menuOpened = true;
    }
  }

  toggleDrawer(status?: boolean): void {
    this.menuOpened = status !== undefined ? status : !this.menuOpened;
    if (this.isDesktop) {
      localStorage.setItem('menuOpened', `${this.menuOpened ? this.menuOpened : ''}`);
    }

    if (this.menuOpened && this.isMobile) {
      this.scrollLockService.disableScroll();
    } else {
      this.scrollLockService.enableScroll();
    }
  }

  private listenTotalRequests(): void {
    this.data.requestsQuantity
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res: number) => this.requestsQuantity = res,
        error: (err: any) => this.data.showNotification(err, null, 3500, 'error'),
      });

    this.profileService.user
      .pipe(
        filter((user) => !!user?.id),
        switchMap(() => this.calendarService.getRequests()),
        untilDestroyed(this),
      )
      .subscribe();

    this.calendarService.totalRequests
      .pipe(untilDestroyed(this))
      .subscribe( total => {
        this.requestsQuantity = total;
      });
  }

  private listenRouterEvents(): void {
    this.router.events
      .pipe(
        filter((val) => val instanceof NavigationEnd),
        pairwise(),
        untilDestroyed(this),
      )
      .subscribe(([prev, curr]: [NavigationEnd, NavigationEnd]) => {
        if (this.isMobile) {
          const prevEnd = prev.url.indexOf('#') > -1 ? prev.url.indexOf('#') : undefined;
          const currEnd = curr.url.indexOf('#') > -1 ? curr.url.indexOf('#') : undefined;
          if (prev.url.substring(0, prevEnd) !== curr.url.substring(0, currEnd)) {
            this.toggleDrawer(false);
          }
        }
      });
  }

  private getCompany(): void {
    this.profileService.getCompany()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.profileService.company
      .pipe(
        untilDestroyed(this),
      )
      .subscribe( (company) => {
        this.company = company;
      });
  }

}
