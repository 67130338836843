export class PrototypeEntity {
  @enumerable(false)
  protected skipFields: string[];

  /**
   * Needs to be called in the constructor of the child entity
   * @param data - an object passed from the entity
   */
  setData(data?: object) {
    if (data) {
      for (const key of Object.keys(data)) {
        if (Object.prototype.hasOwnProperty.call(this, key)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this[key] = data[key];
        }
      }
    }
  }
  /**
   * Call this method to format entity into an object prepared for sending to back-end
   */
  prepareBody(skipEmpty: boolean = false) {
    const object: any = {};
    for (const [key, value] of Object.entries(this)) {
      if (this.skipFields && this.skipFields.includes(key) || key === 'skipFields') {
        continue;
      }
      if (skipEmpty && (value === undefined || value === null || value === '')) {
        continue;
      }
      object[key] = value;
    }
    return object;
  }

  setReadOnlyFields(fields: string[]) {
    this.constructor.prototype.skipFields = fields;
  }

}

/**
 * @enumerable decorator that sets the enumerable property of a class field to false.
 * @param value true|false
 */
function enumerable(value: boolean) {
  return function (target: any, propertyKey: string) {
    const descriptor = Object.getOwnPropertyDescriptor(target, propertyKey) || {};
    if (descriptor.enumerable !== value) {
      descriptor.enumerable = value;
      descriptor.writable = true;
      Object.defineProperty(target, propertyKey, descriptor);
    }
  };
}
