<article>
  <app-material-header [name]="'company.eventsAndGroups' | translate">
    <a href="https://memento-production-s3.s3.eu-central-1.amazonaws.com/Bedriftsguide.pdf" target="_blank">
      <app-button color="primary" *ngIf="isDesktop">
        <i>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6666 6H9.99998V2H5.99998V6H3.33331L7.99998 10.6667L12.6666 6ZM3.33331 12V13.3333H12.6666V12H3.33331Z" fill="white"/>
          </svg>
        </i>
        {{'company.downloadGuide' | translate}}
      </app-button>
      <app-button [icon]="true" color="primary" *ngIf="!isDesktop">
        <i>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6666 6H9.99998V2H5.99998V6H3.33331L7.99998 10.6667L12.6666 6ZM3.33331 12V13.3333H12.6666V12H3.33331Z" fill="white"/>
          </svg>
        </i>
      </app-button>
    </a>
  </app-material-header>
  <div class="wrapper">
    <h4 class="heading">
      {{'company.eventsGroupsHeader1' | translate}}
    </h4>
    <div class="text">
      {{'company.eventsGroupsText1' | translate}}
    </div>

    <div class="images">
      <img class="one" src="/assets/img/company-events-and-groups/1.png" alt="">
      <img class="two" src="/assets/img/company-events-and-groups/2.png" alt="">
      <img class="three" src="/assets/img/company-events-and-groups/3.png" alt="">
    </div>

    <h4 class="heading">
      {{'company.eventsGroupsHeader2' | translate}}
    </h4>
    <div class="text">
      {{'company.eventsGroupsText2' | translate}}
    </div>

    <div class="images">
      <img class="four" src="/assets/img/company-events-and-groups/4.png" alt="">
    </div>

    <div class="text">
      {{'company.eventsGroupsFooter' | translate}}
      <a href="mailto:feedback@mementonetwork.no">feedback@mementonetwork.no</a>
    </div>
  </div>
</article>
