<label class="checkbox" [ngClass]="{'checkbox--readonly': readonly}">
  <input type="checkbox"
         class="checkbox-input"
         [checked]="isChecked && 'checked'"
         [disabled]="control.disabled || readonly"
         (change)="change($event)">
  <span class="checkbox-checkmark {{size}} {{borderColor}}" [ngClass]="{'checkbox-checkmark--checked': isChecked}">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0039 17.0011L5.00391 12.0011L6.41391 10.5911L10.0039 14.1711L17.5939 6.58105L19.0039 8.00105L10.0039 17.0011Z" fill="white"></path>
    </svg>
  </span>
  <span class="checkbox-label">
    {{label}}
  </span>
</label>
