<div class="calendar">
  <header class="calendar-header">
    <div class="calendar-header-weekdays">
      <div class="calendar-header-weekday" *ngFor="let weekday of weekDays">
        {{weekday | date: 'EEEE':null:translate.currentLang}}
      </div>
    </div>
  </header>
  <div class="calendar-body">
    <div class="calendar-body-day"
         *ngFor="let day of days; index as i"
         (click)="selectDay(day)"
         [ngClass]="{
          'calendar-body-day--today': today.dayOfYear() === day.dayOfYear(),
          'calendar-body-day--current-month': today.get('month') === day.get('month')
         }">
      <div class="calendar-body-day-header">
        {{day | date: 'dd':null:translate.currentLang}}
      </div>
      <div class="calendar-body-day-sessions">
        <div class="calendar-body-session"
             [ngClass]="{
                          'calendar-body-session--event': session.type === SESSION.EVENT_TYPE,
                          'calendar-body-session--training': session.type === SESSION.TRAINING_TYPE
                        }"
             *ngFor="let session of sessions[convertDate(day)]">
          <span>
            {{session.training || session.event_type}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
