import {
  Component,
  Host,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {filter, find, findIndex} from 'lodash';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

import {listAnimation, listAnimationUp} from '../material-animations/select-option';
import {MobileService} from '@services/mobile.service';

interface IMultiselectOptions {
  maxSelectedItems: number;
  lockLastElement: boolean;
}

@Component({
  selector: 'app-material-select',
  templateUrl: './material-select.component.html',
  styleUrls: ['./material-select.component.scss'],
  animations: [
    listAnimation,
    listAnimationUp,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaterialSelectComponent,
    multi: true,
  }],
})
export class MaterialSelectComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  @Input() formControlName: string;
  @Input() formGroupName: string;
  @Input() options: any[];
  @Input() label: string;
  @Input() search: boolean;
  @Input() multiSelect: boolean | IMultiselectOptions;
  @Input() showValue;
  @Input() idDuplicates: boolean;
  @Input() errors: any;
  @Input() hint: string;
  @Input() readonly: boolean;

  @Input() valueFieldName: string;
  @Input() labelFieldName: string;
  @Input() inputFieldLabel: string;
  @Input() placeholder: string = '';
  @Input() touchNeeded = true;

  @HostBinding('class.host-selected') public showList = false;
  public control: AbstractControl;
  public ITEM_SIZE: number = 40;
  onChange: any;
  onTouch: any;
  error: string;
  searchValue: string;
  checkedOptions: any[];
  filteredOptions: any[];
  public optionsUp = false;
  windowHeight: number;

  @ViewChild('searchEl', { static: false }) searchEl;
  @ViewChild('labelEl', { static: true }) labelEl;

  private sub: Subscription;
  // Flag to check if multi select has an options
  private hasMultiSelectOptions: boolean = false;
  private isAllOptionsDisabled: boolean = false;
  // Store an indexes of already selected items
  private alreadySelectedItems: number[] = [];

  get inputLabel(): string {
    if (!this.multiSelect && this.valueFieldName && this.labelFieldName) {
      return this.inputFieldLabel;
    } else {
      return !this.multiSelect ? this.control.value : this.placeholder;
    }
  }

  get isInvalid(): boolean {
    if (this.touchNeeded) {
      return (this.control.dirty || this.control.touched) && this.control.invalid;
    } else {
      return this.control.invalid;
    }
  }

  get isTouched(): boolean {
    return this.control.dirty || this.control.touched;
  }

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private mobile: MobileService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.searchValue = '';
    this.checkedOptions = [];
    this.sub = new Subscription();
  }

  writeValue(obj: any): void {
    // this.control.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit() {
    if (this.controlContainer) {
      if (this.formControlName && !this.formGroupName) {
        this.control = this.controlContainer.control.get(this.formControlName);

        this.initInputLabel();
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }

    this.filteredOptions = [...this.options];
    if (this.mobile.isMobile) {
      this.sub.add(this.route.fragment.subscribe(fragment => {
        if (fragment === null) {
          this.showList = false;
        }
      }));
    }


    if (this.multiSelect) {
      this.checkedOptions = new Array(this.options.length).fill(false);
      // Check if select component has additional multiselect options.
      this.hasMultiSelectOptions = (typeof this.multiSelect === 'object');

      this.mapOptions();
      this.sub.add(
        this.control.valueChanges.subscribe(value => {
          this.filter();
        }),
      );
    }

    this.control.valueChanges.subscribe((value) => {
      this.setError();
    });

    // also we need check errors on start
    this.setError();
  }

  /**
   * Init input label if object[] was set as options
   */
  initInputLabel() {
    if (this.valueFieldName && this.labelFieldName) {
      const findOption = this.options.find((item) => item[this.valueFieldName] === this.control.value);

      if (findOption) {
        this.inputFieldLabel = findOption[this.labelFieldName];
      }
    }
  }

  /**
   * Prevents bug on the first init of options being unselected
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.multiSelect && this.control) {
      this.checkedOptions = new Array(this.options.length).fill(false);
      if (this.search) {
        this.filter();
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * Get label of option
   */
  getOptionLabel(option) {
    if (this.valueFieldName && this.labelFieldName) {
      return option[this.labelFieldName];
    } else {
      return this.showValue ? option[this.showValue] : option;
    }
  }

  /**
   * Whether to show list of options or not
   */
  toggleOptions($event = null): void {
    if (this.control.disabled || this.readonly) {
      return;
    }

    this.control.markAsTouched();

    if ($event) {
      // calculate if options are needed to be shown upwords
      this.optionsUp = $event.clientY + window.innerHeight / 2.7 > window.innerHeight;
    }
    this.showList = !this.showList;
    if (this.search && this.showList) {
      this.searchEl.nativeElement.focus();
    } else if (this.search && !this.showList) {
      this.searchEl.nativeElement.blur();
      this.searchValue = '';
      this.searchEl.nativeElement.value = '';
      this.filter();
    }

    if (this.showList) {
      document.addEventListener('keydown', this.keyListener);
      this.router.navigate([location.pathname], { fragment: 'mat-select' });
    } else {
      document.removeEventListener('keydown', this.keyListener);
      this.router.navigate([location.pathname], { fragment: null });
    }

    if (this.showList && this.mobile.isMobile) {
      window.scrollTo(0, 0);
    }
    this.setWindowHeight();
  }

  /**
   * Used to select option
   * Has different behavior depending on multiselect option
   * @param index index of selected item
   */
  select(index: number): void {
    if (this.searchValue) {
      const param = {
        ...this.filteredOptions[index],
      };

      delete param.checked;
      index = findIndex(this.options, param);
    }
    if (this.multiSelect) {
      this.checkedOptions[index] = this.checkedOptions[index] ? false : this.options[index];
      if (this.hasMultiSelectOptions) {
        // If we have value selected we add this value index to array of already selected items.
        if (this.checkedOptions[index]) {
          this.alreadySelectedItems = [...this.alreadySelectedItems, index];
        } else {
          // Dont allow to unselect last element if that option was provided in multiselect settings.
          if (this.isElementLocked()) {
            return;
          }

          // Otherwise remove already selected element.
          const removeAtIndex = this.alreadySelectedItems.indexOf(index);
          this.alreadySelectedItems = [
            ...this.alreadySelectedItems.slice(0, removeAtIndex),
            ...this.alreadySelectedItems.slice(removeAtIndex + 1, this.alreadySelectedItems.length),
          ];
        }
      }

      this.control.setValue(filter(this.checkedOptions, (el) => el));
    } else {
      if (this.valueFieldName && this.labelFieldName) {
        this.control.setValue(this.options[index][this.valueFieldName]);
        this.inputFieldLabel = this.options[index][this.labelFieldName];
      } else {
        this.control.setValue(this.options[index]);
      }

      this.showList = false;
    }
  }

  /**
   * One method to select them all
   * One method to unselect them all also
   */
  selectAll(): void {
    if (this.isSelectAll()) {
      this.checkedOptions = new Array(this.options.length).fill(false);
    } else {
      this.checkedOptions = [...this.options];
    }
    this.control.setValue(filter(this.checkedOptions, (el) => el));
  }

  isSelectAll(): boolean {
    return filter(this.checkedOptions, (el) => el).length === this.options.length;
  }

  /**
   * Method to filter/search through values
   * @param $event
   */
  filter($event?): void {
    this.searchValue = this.searchValue || '';
    if ($event) {
      this.searchValue = $event.target.value;
    }
    this.mapOptions();

    this.filteredOptions = this.filteredOptions.filter(el => {
      return el[this.showValue].toLowerCase().includes(this.searchValue.toLowerCase());
    });
  }

  /**
   * This is used to tag selected options
   */
  mapOptions() {
    this.alreadySelectedItems = [];
    this.checkedOptions = new Array(this.options.length).fill(false);
    this.filteredOptions = [...this.options];

    this.options.forEach((option, index) => {
      // exception for the case if ids can have duplicates
      const firstCondition = this.idDuplicates && find(this.control.value, { enum_type: option.enum_type });
      const secondCondition = !this.idDuplicates && find(this.control.value, { id: option.id });
      if (firstCondition || secondCondition) {
        if (this.hasMultiSelectOptions) {
          this.alreadySelectedItems = Array.from(new Set([...this.alreadySelectedItems, index]));
          // TODO: Find a better solution. Hack because of typescript compiler type check.
          const multiSelect = this.multiSelect as IMultiselectOptions;
          this.isAllOptionsDisabled = (multiSelect.maxSelectedItems <= this.alreadySelectedItems.length);
        }

        this.checkedOptions[index] = option;
        this.filteredOptions[index] = { ...option, checked: true };
      }
    });
  }

  /**
   * Check if element is last selected and multi select options provided
   */
  isElementLocked(): boolean {
    if (this.hasMultiSelectOptions) {
      const multiSelect = this.multiSelect as IMultiselectOptions;
      return (multiSelect.lockLastElement && (this.alreadySelectedItems.length <= 1));
    }

    return false;
  }

  /**
   * Set options modal height
   */
  private setWindowHeight() {
    if (this.options.length * this.ITEM_SIZE > window.innerHeight) {
      this.windowHeight = window.innerHeight * 0.3;
    } else {
      this.windowHeight = this.options.length * this.ITEM_SIZE;
    }
  }

  private keyListener = (event) => {
    if (event.key === 'Escape') {
      this.toggleOptions();
    }
  };

  private setError() {
    if (!this.control.errors) {
      this.error = '';
    } else if (this.errors) {
      this.error = this.errors[Object.keys(this.control.errors)[0]];
    }
  }

}
