import {Component, OnInit} from '@angular/core';
import {TabInterface} from '@modules/material/utils/interface';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProfileService} from '@services/profile.service';
import {CountriesService} from '@services/countries.service';
import {Country} from '@models/country';
import {CompanyEntity} from '@models/company/company.entity';
import {ConfirmationComponent} from '@components/modals/confirmation/confirmation.component';
import {MatDialog} from '@angular/material/dialog';
import {MobileService} from '@services/mobile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@services/auth.service';
import { filter, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  tabs: TabInterface[] = [
    {
      id: 'profile',
      title: 'common.profile',
    },
    {
      id: 'logo',
      title: 'company.logo'
    },
  ];
  form: UntypedFormGroup;
  activeTab: TabInterface = this.tabs[0];
  isEditing: boolean = false;
  countries: Country[] = [];
  company: CompanyEntity;

  constructor(
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private countriesService: CountriesService,
    private dialog: MatDialog,
    private mobileService: MobileService,
    private snackbar: MatSnackBar,
    private authService: AuthService,
  ) { }

  get isDesktop(): boolean {
    return this.mobileService.isDesktop;
  }

  ngOnInit(): void {
    this.profileService.getCompany().pipe(untilDestroyed(this)).subscribe();
    this.profileService.company.pipe(
      untilDestroyed(this),
    ).subscribe((company) => {
      this.form = null;

      setTimeout(() => {
        this.form = this.fb.group({
          ...company,
          name: new UntypedFormControl(company.name, [Validators.required, Validators.maxLength(50)]),
          address: new UntypedFormControl(company.address, [Validators.required, Validators.maxLength(100)]),
        });
      });

      this.company = company;
    });

    this.countriesService.getCountriesList()
      .pipe(untilDestroyed(this))
      .subscribe((countries) => {
        this.countries = countries;
      });

  }

  setDefaultFormData(company: CompanyEntity): void {
    this.form.patchValue({
      ...company,
      name: company.name,
      address: company.address,
    });
  }

  cancelEditProfile(): void {
    this.cancelEditProfileConfirm();
  }

  editProfile(): void {
    this.isEditing = true;
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    this.isEditing = false;

    this.saveProfile()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe();
  }

  deleteCompany(): void {
    const dialog = this.dialog.open(ConfirmationComponent, {
      data: {
        actionTxt: this.translate.instant('company.yesDelete'),
        bodyTxt: this.translate.instant('company.deleteCompanyConfirmation'),
        headerTxt: this.translate.instant('company.deleteCompany'),
      },
    });

    dialog.afterClosed()
      .pipe(
        filter((confirm) => confirm),
        switchMap(() => this.profileService.deleteCompany()),
        untilDestroyed(this),
      )
      .subscribe((success: boolean) => {
        if(success) {
          this.showSuccessMessage(this.translate.instant('company.deleteCompanySuccess'));
          this.authService.logoutWithNavigation();
        }
      });
  }

  private saveProfile(): Observable<CompanyEntity> {
    return this.profileService.patchCompany(new CompanyEntity({
      ...this.form.value,
      logo: this.form.value.logo || undefined,
    }));
  }

  private cancelEditProfileConfirm() {
    const dialog = this.dialog.open(ConfirmationComponent, {
      data: {
        actionTxt: this.translate.instant('common.save'),
        bodyTxt: this.translate.instant('company.confirmEditModeExit'),
        headerTxt: this.translate.instant('company.exitEditing'),
      },
    });

    dialog.afterClosed()
      .pipe(
        filter((isConfirm) => isConfirm !== undefined),
        switchMap((isConfirm) => {
          this.isEditing = false;

          if (isConfirm) {
            return this.saveProfile();
          }

          this.setDefaultFormData(this.company);

          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private showSuccessMessage(message: string): void {
    this.snackbar.open(
      message,
      '',
      {
        duration: 2500,
        panelClass: 'success',
        verticalPosition: 'top',
        horizontalPosition: 'right',
      },
    );
  }
}
