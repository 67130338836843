<div class="system-certificates-list">
  <div
    class="system-certificates-list__item"
    *ngFor="let certificate of certificatesFormArray.controls; let i = index;"
    [ngClass]="{ 'system-certificates-list__item--disabled': certificate.disabled }">

    <div class="select-holder">
      <app-material-select-image
        [labelImage]="'logo_link'"
        [labelValue]="'title'"
        [label]="'profile.personal.certificatesBadge' | translate"
        [description]="'profile.personal.certificatesList' | translate"
        [options]="systemCertificates"
        [search]="true"
        [readonly]="canEditProfile"
        [formControl]="getSystemCertificateIdControl(certificate)"
      >
      </app-material-select-image>

      <div class="remove-holder">
        <button
          class="remove-button"
          [disabled]="canEditProfile"
          (click)="removeCertificate(i, certificate)">
          <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999674 10.6667C0.999674 11.4 1.59967 12 2.33301 12H7.66634C8.39967 12 8.99967 11.4 8.99967 10.6667V2.66667H0.999674V10.6667ZM9.66634 0.666667H7.33301L6.66634 0H3.33301L2.66634 0.666667H0.333008V2H9.66634V0.666667Z" fill="#F11C48"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="feature-holder">
      <app-material-checkbox
        [label]="'profile.personal.showThisCertificate' | translate"
        [readonly]="canEditProfile"
        [checked]="getCertificateFeatureControl(certificate).value"
        [formControl]="getCertificateFeatureControl(certificate)">
      </app-material-checkbox>
    </div>

    <div
      class="certificate-image"
      [ngClass]="{
        'certificate-image--invalid': getCertificateImageControl(certificate).invalid && getCertificateImageControl(certificate).touched,
        'background-disabled': canEditProfile}">

      <div
        *ngIf="getCertificateImageUrl(certificate)"
        (click)="openCertificateModal(imageInput, certificate)"
        class="certificate-image__background"
        [appPdfPreview]="getCertificateImageControlValue(certificate)"
        [style.backgroundImage]="getCertificateImageUrl(certificate)">
      </div>

      <label class="certificate-image__label" #imageInput>
        <input
          (change)="imageUpload($event, certificate, canvasContainer)"
          [readonly]="canEditProfile"
          accept="image/jpeg, image/png, application/pdf"
          class="hidden_input"
          *ngIf="!canEditProfile"
          type="file">
      </label>
    </div>

  </div>
</div>

<div class="controls-bottom">
  <button
    class="add-button"
    [disabled]="certificatesFormArray.invalid || canEditProfile || certificatesFormArray.length >= MAX_CERTIFICATES_NUMBER"
    (click)="addCertificate()">
    <i class="icon-memento-plus"></i>
    <span class="text">{{'common.add' | translate}}</span>
  </button>
</div>
