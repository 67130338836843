<nav class="tabs" #headerTabs >
  <div class="tab"
      *ngFor="let tab of tabs;"
       [ngClass]="{'tab--active': tab === currentTab, 'tab--disabled': isPartnerUnconfirmed && tab === limitedTab}"
       (click)="changeTab(tab)">
    {{'profile.' + tab.toLowerCase() + '.' + tab.toLowerCase() + (isTrainer ? 'Trainer' : 'Partner') | translate}}
  </div>
</nav>

<button
  class="nav-button nav-button--prev"
  (click)="prevTab()"
  [disabled]="isFirstTab"
  *ngIf="isMobile">
  <i class="icon-memento-arrow-right"></i>
</button>

<button
  class="nav-button nav-button--next"
  (click)="nextTab()"
  [disabled]="isLastTab"
  *ngIf="isMobile">
  <i class="icon-memento-arrow-right"></i>
</button>