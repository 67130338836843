<div class="page">
  <div class="page_header"></div>
  <div class="page_card" *ngIf="!calendarService.activityOpen">
    <header class="card_header" [ngClass]="{'mobile-header': isMobile}">
      <div class="tabs">
        <div class="tab"
             [ngClass]="{'tab--active': activeTab === 'requests'}"
             (click)="selectTab(0)">
          {{ 'requests.requests' | translate }}
          <span *ngIf="requests.length">
            &nbsp;({{requests.length}})
          </span>
        </div>
        <div class="tab"
             [ngClass]="{'tab--active': activeTab === 'upcoming'}"
             (click)="selectTab(1)">
          {{ 'requests.upcoming' | translate }}
          <span *ngIf="upcoming.length">
            &nbsp;({{upcoming.length}})
          </span>
        </div>
        <div class="tab"
             [ngClass]="{'tab--active': activeTab === 'archive'}"
             (click)="selectTab(2)">
          {{ 'requests.archive' | translate }}
          <span *ngIf="archive.length">
            &nbsp;({{archive.length}})
          </span>
        </div>
      </div>
    </header>
    <div class="requests_component page_content card_body" [ngClass]="{'mobile-header': isMobile}">
      <div class="row row--header" [ngClass]="activeTab">
        <div class="row--header-col">{{ 'requests.name' | translate}}</div>
        <div class="row--header-col">{{ 'requests.date' | translate}}</div>
        <div class="row--header-col">{{ 'requests.time' | translate}}</div>
        <div class="row--header-col">{{ 'requests.duration' | translate}}</div>
        <div class="row--header-col">{{ 'requests.trainingType' | translate}}</div>
        <div class="row--header-col">{{ 'requests.eventName' | translate}}</div>
        <div class="row--header-col">{{ 'requests.address' | translate}}</div>
        <div *ngIf="activeTab !== 'archive'" class="row--header-col">{{ 'requests.action' | translate}}</div>
        <div *ngIf="activeTab === 'archive'" class="row--header-col">{{ 'requests.status' | translate}}</div>
      </div>
      <div class="row" *ngFor="let item of list; index as i" (click)="openSession(i)">
        <div class="row-col row-col--profile">
          <div class="row-col-avatar" [style.backgroundImage]="'url(' + (item?.profile?.profile_avatar || '/assets/img/profile_default.svg')+ ')'">
          </div>
          <div class="row-col-name" *ngIf="item.profile">
            {{item.profile.first_name}} {{item.profile.last_name}}
          </div>
          <div class="row-col-name" *ngIf="!item.profile">
            {{'requests.groupTraining' | translate}}
          </div>
          <div class="row-col-name row-col-name--session-type">
            <ng-container *ngIf="item.type === SESSION.EVENT_TYPE">
              <span *ngIf="!item.is_public">
                {{'events.publicEvent' | translate}}
              </span>
              <span *ngIf="item.is_public">
                {{'events.publicEvent' | translate}}
              </span>
            </ng-container>
            <ng-container *ngIf="item.type === SESSION.TRAINING_TYPE">
              <span>
                {{item.session_type}}
              </span>
            </ng-container>
          </div>
        </div>

        <div class="row-col">
          <div>
            {{item.begin | date:'dd/MM/yy'}}
          </div>
        </div>

        <div class="row-col">
          <div>
            {{item.begin | date:'HH:mm'}}
          </div>
        </div>

        <div class="row-col">
          <div>
            {{item.duration}}m
          </div>
        </div>

        <div class="row-col">
          <div>
            {{item.training || item.event_type}}
          </div>
        </div>

        <div class="row-col">
          <div>
            {{item.name || '-'}}
          </div>
        </div>

        <div class="row-col">
          <div>
            {{item.location || '-'}}
          </div>
        </div>

        <div class="row-col" *ngIf="activeTab !== 'archive'">
          <button class="row-col-action cancel_btn"
                  (click)="cancelRequest($event, i)">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.66683 1.27325L8.72683 0.333252L5.00016 4.05992L1.2735 0.333252L0.333496 1.27325L4.06016 4.99992L0.333496 8.72659L1.2735 9.66659L5.00016 5.93992L8.72683 9.66659L9.66683 8.72659L5.94016 4.99992L9.66683 1.27325Z" fill="#F11C48"/>
            </svg>
            <span>
              {{'common.cancel' | translate}}
            </span>
          </button>
          <button class="row-col-action confirm_btn"
                  (click)="confirmRequest($event, i)"
                  *ngIf="activeTab === 'requests'">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.99998 7.78002L1.21998 5.00002L0.273315 5.94002L3.99998 9.66668L12 1.66668L11.06 0.726685L3.99998 7.78002Z" fill="white"/>
            </svg>
            <span>
              {{'common.confirm' | translate}}
            </span>
          </button>
        </div>

        <div class="row-col" *ngIf="activeTab === 'archive'">
          <div>
            {{'requests.' + (item.training_status || item.event_status) | translate}}
          </div>
        </div>
      </div>
    </div>

  </div>
  <app-material-activity *ngIf="selectedSession">
    <app-material-activity-header>
      <button class="back" (click)="closeSession()">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z"
            fill="#3C474B"/>
        </svg>
      </button>
      <span class="back-text">
        {{'events.training' | translate}}
      </span>
    </app-material-activity-header>
    <app-training-view [data]="selectedSession">

    </app-training-view>
  </app-material-activity>

  <app-material-activity *ngIf="selectedEvent">
    <app-material-activity-header class="two-line-header">
      <button class="back" (click)="closeSession()">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z"
            fill="#3C474B"/>
        </svg>
      </button>
      <span class="back-text">
        {{'events.event' | translate}}
      </span>
    </app-material-activity-header>
    <app-event-view class="two-line-header-tabs" [data]="selectedEvent">

    </app-event-view>
  </app-material-activity>
</div>
