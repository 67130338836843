<label #labelEl
       (click)="toggleOptions()"
       [ngClass]="{
         'material_label--focused': showList,
         'material_label--dirty': this.control.value && !multiSelect,
         'material_label--disabled': control.disabled,
         'material_label--invalid': isInvalid,
         'material_label--error': error
       }"
       class="material_label">
  {{label}}
</label>

<div (click)="toggleOptions($event)"
     [ngClass]="{
       'material_input--focused': showList,
       'material_input--disabled': control.disabled,
       'material_input--invalid': isInvalid,
       'material_input--error': error
     }"
     class="material_input">
  <span *ngIf="!search || readonly" class="material_input--value">
    {{inputLabel}}
  </span>
  <span *ngIf="search && !readonly" class="material_input--value">
    <input #searchEl
           (input)="filter($event)"
           [placeholder]="showList ? ('common.startTyping' | translate) : ''"
           class="search"
           type="text">
  </span>
  <i class="material_input--icon-suffix" [ngClass]="{'material_input--icon-suffix-error': isInvalid}">
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.94 0.726562L4 3.7799L7.06 0.726562L8 1.66656L4 5.66656L0 1.66656L0.94 0.726562Z" fill="#606873"/>
    </svg>
  </i>
</div>

<div (click)="toggleOptions()" *ngIf="showList" class="overlay"></div>
<cdk-virtual-scroll-viewport 
  *ngIf="showList"
  [@listAnimationUp]="optionsUp"
  [@listAnimation]="!optionsUp"
  [maxBufferPx]="windowHeight"
  [minBufferPx]="windowHeight"
  [ngClass]="{'options--up': optionsUp}"
  [style.height]="windowHeight + 'px'"
  class="options"
  [itemSize]="ITEM_SIZE"
>
  <li (click)="selectAll()"
      *ngIf="multiSelect && !hasMultiSelectOptions"
      class="option">
    <div class="checkbox">
      <input [checked]="isSelectAll() && 'checked'" type="checkbox">
      <span class="checkmark"></span>
    </div>
    <span>{{'common.selectAll' | translate}}</span>
  </li>
  <ng-container *cdkVirtualFor="let option of filteredOptions; index as i">
    <li (click)="select(i)"
        [ngClass]="{'option--selected': option === control.value,
                    'option--locked': alreadySelectedItems.includes(i) && isElementLocked(),
                    'option--disabled': isAllOptionsDisabled && !option.checked }"
        class="option">
      <div *ngIf="multiSelect" class="checkbox">
        <input [checked]="option.checked && 'checked'" type="checkbox">
        <span class="checkmark"></span>
      </div>
      <span>
        {{getOptionLabel(option)}}
      </span>
    </li>
  </ng-container>
</cdk-virtual-scroll-viewport>

<div class="hints">
  <div class="error" *ngIf="error && !readonly" [ngClass]="{'error--red': isTouched}">
    *{{error}}
  </div>
  <div class="hint" *ngIf="hint && !error">
    *{{hint}}
  </div>
</div>
