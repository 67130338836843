import {Component, OnInit} from '@angular/core';

import {TrainingViewComponent} from '../training-view/training-view.component';
import {EventSession} from '../../../models/session';

enum TABS {
  INFO = 'INFO',
  PARTICIPANTS = 'PARTICIPANTS',
}
@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss'],
})
export class EventViewComponent extends TrainingViewComponent implements OnInit {
  public TABS: typeof TABS = TABS;
  public activeTab: TABS = TABS.INFO;

  ngOnInit() {
    this.session = new EventSession();
    this.calendarService.getEvent(this.data.id).subscribe(event => {
      this.session = event as EventSession;
    });
    this.actions = true;
  }

  public changeTab(tab: TABS) {
    this.activeTab = tab;
  }
}
