<div class="day" *ngIf="!hideView">
  <div class="day-hours">
    <div class="day-hour" *ngFor="let hour of hours;">
      <span>
        {{hour | date: 'HH:mm': null : translate.currentLang}}
      </span>
    </div>
  </div>
  <div class="day-slots">
    <div class="day-slot" *ngFor="let hour of hours;">
    </div>
    <div class="day-session"
         [ngClass]="{
           'day-session--event': session.type === SESSION.EVENT_TYPE,
           'day-session--training': session.type === SESSION.TRAINING_TYPE
         }"
         [ngStyle]="{
           'top': calculatePixels(session.begin) + '%',
           'height': calculatePixels(session.begin, session.duration) + '%',
           'animation-delay': (i * 200) + 'ms'
          }"
         *ngFor="let session of sessions; index as i"
         (click)="openSession(session)"
         [@fadeInOut]>
      {{session.training || session.event_type}}
    </div>
  </div>
</div>
