<section class="actions" [ngClass]="{'mobile-content': isMobile}">
  <div class="modal-actions" *ngIf="isConfirmed || isRequest">
    <button (click)="confirm()"
            class="confirm_btn"
            *ngIf="isRequest">
      <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.99998 7.78002L1.21998 5.00002L0.273315 5.94002L3.99998 9.66668L12 1.66668L11.06 0.726685L3.99998 7.78002Z" fill="white"/>
      </svg>
      <span>
        {{ 'common.confirm' | translate }}
      </span>
    </button>
    <button (click)="reject()"
            class="cancel_btn"
            *ngIf="isConfirmed || isRequest">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.66683 1.27325L8.72683 0.333252L5.00016 4.05992L1.2735 0.333252L0.333496 1.27325L4.06016 4.99992L0.333496 8.72659L1.2735 9.66659L5.00016 5.93992L8.72683 9.66659L9.66683 8.72659L5.94016 4.99992L9.66683 1.27325Z" fill="#F11C48"/>
      </svg>
      <span>
        {{ (isRequest ? 'common.reject' : 'requests.cancelTraining') | translate }}
      </span>
    </button>
  </div>
</section>

<div class="page_content modal" [ngClass]="{'modal--actions': isConfirmed || isRequest}">
  <div class="modal-body">
    <!--todo must be redone in future-->
<!--     <app-session-preview-participants
      *ngIf="session"
      [isGroupTraining]="session.number_of_participants[0] > 1"
      [participants]="session.participants"
      [minMaxParticipants]="'(' + session.number_of_participants[0] + '-' + session.number_of_participants[1] + ')'"
      [sessionType]="session.session_type">
    </app-session-preview-participants> -->

    <section class="modal-body-section">
      <div class="modal-body-section-title">
        {{'requests.sessionDetails' | translate}}
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.date' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.begin | date:'d MMMM yyyy':0:translate.currentLang }}
        </span>
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.time' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.begin | date:'HH:mm':0:translate.currentLang }}
        </span>
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.duration' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.session_type }}
        </span>
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.sessionType' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.session }}
        </span>
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.goal' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.goal }}
        </span>
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.currentStatus' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.status }}
        </span>
      </div>
    </section>

    <section class="modal-body-section">
      <div class="modal-body-section-title">
        {{'calendar.location' | translate}}
      </div>
      <div class="modal-body-section-cell modal-body-section-cell--location">
        <div class="modal-body-section-cell-title">
          {{'events.addresse' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.location }}
        </span>
      </div>
      <app-material-checkbox [readonly]="true"
                             [checked]="session?.is_outdoor"
                             [label]="'requests.outdoor' | translate"></app-material-checkbox>
    </section>

    <section class="modal-body-section">
      <div class="modal-body-section-title">
        {{'events.payment' | translate}}
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.sessionPrice' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.price }} NOK
        </span>
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.additionalFee' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.region_fee }} NOK
        </span>
      </div>
      <div class="modal-body-section-cell">
        <div class="modal-body-section-cell-title">
          {{'events.total' | translate}}
        </div>
        <span class="modal-body-section-cell-text">
          {{ session?.region_fee + session?.price }} NOK
        </span>
      </div>
    </section>
  </div>
</div>
