import {Component, Input, OnInit} from '@angular/core';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-billing-item',
  templateUrl: './billing-item.component.html',
  styleUrls: ['./billing-item.component.scss'],
})
export class BillingItemComponent implements OnInit {
  @Input() bill;
  public formattedDate: string;

  constructor() { }

  ngOnInit() {
    // parse date
    this.formattedDate = new DateTime.fromISO(this.bill.date).toLocaleString(
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

  }

}
