import { Component, Inject, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take, takeUntil} from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import {DataService} from './services/data.service';
import {FeathersService} from './services/feathers.service';
import {StorageService} from './services/storage.service';
import {AuthService} from './services/auth.service';
import {HttpService} from './services/http.service';
import {ProfileService} from './services/profile.service';
import {StepperService} from './services/stepper.service';
import {PricingService} from './services/pricing.service';
import {CalendarService} from './services/calendar.service';
import {AppUpdateService} from './services/app-update.service';
import {MobileService} from './services/mobile.service';
import {StepperMobileService} from './services/stepper-mobile.service';
import {ProfileEntity} from './models/user/profile.entity';
import {UserEntity} from './models/user/user.entity';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PREVIOUS_URL_TOKEN } from '@shared/tokens';
import { ProjectVersionService } from '@shared/services';
import { SocketControllerService } from '@modules/chats/services/socket-controller.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'app';
  spinnerVisible: boolean;
  XMPPStatusConnectionSubscription: any;
  loggedInStatusSubscription: any;
  loggedIn: boolean;
  user: UserEntity;
  trainer: ProfileEntity;
  stepperMobileOpened: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    public stepperService: StepperService,
    public feathers: FeathersService,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private dataService: DataService,
    private storageService: StorageService,
    private auth: AuthService,
    private http: HttpService,
    private profileService: ProfileService,
    private pricingService: PricingService,
    private calendarService: CalendarService,
    private updateService: AppUpdateService, // don't remove, need for PWA updates
    private mobileService: MobileService,
    private stepperMobileService: StepperMobileService,
    private projectVersionService: ProjectVersionService,
    private socketControllerService: SocketControllerService,
    @Inject(PREVIOUS_URL_TOKEN) public previousUrl$: Observable<string>,
  ) {
    translate.setDefaultLang('en');
    this.dataService.getCurrentLanguage();
  }

  get isMobile() {
    return this.mobileService.isMobile;
  }

  ngOnInit() {
    this.projectVersionService.setProjectVersionToLocalStorage();
    this.profileService.user.subscribe(user => {
      this.user = user;
    });
    this.profileService.trainer.subscribe(trainer => {
      this.trainer = trainer;
    });

    this.profileService.trainer.pipe(
      take(2),
    ).subscribe(trainer => {
      if (trainer.id) {
        this.pricingService.getPriceList(trainer.id);
        this.calendarService.getSchedule(trainer.id, true);
      }
    });

    this.dataService.userNotification.subscribe(
      (response: any) => {
        if (response === 'close') {
          this.snackBar.dismiss();
        } else {
          this.openSnackBar(response.notifyMessage, response.notifyAction, response.notifyDuration, response.class);
        }
      },
    );

    this.http.spinner.subscribe(
      (response: any) => {
        setTimeout(() => this.spinnerVisible = response);
      }, (error) => {
        this.spinnerVisible = false;
      },
    );


    if (this.feathers.isAutentificated()) {
      try {
        if (!this.profileService.isCompany) {
          const user = JSON.parse(this.storageService.storage.getItem('user'));
          this.profileService.getProfile();
        }
        else {
          const company = JSON.parse(this.storageService.storage.getItem('company'));
          this.profileService.getCompany();
        }
      } catch (e) {
        this.auth.logoutWithNavigation();
      }
    }

    this.connectToChats();
    this.listeMobileStepperState();
    this.previousUrl$.subscribe();
  }

  /**
   * Listen when state mobile service was changed
   */
  listeMobileStepperState() {
    this.stepperMobileService.isWindowOpen
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((state: boolean) => {
        this.stepperMobileOpened = state;
      });
  }

  /**
   * Hide stepper mobile
   */
  closeStepperMobile() {
    this.stepperMobileService.close();
  }

  openSnackBar(message: string, action: string = '✕', durationVal: number, additionalClass: string) {
    if (!action) {
      action = '✕';
    }

    this.snackBar.open(message, action, {
      duration: durationVal,
      panelClass: additionalClass,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }

  private connectToChats(): void {
    this.socketControllerService.connectToChat().pipe(untilDestroyed(this)).subscribe();
  }

}
