<div class="table_row">
  <div class="table_row_cell table_row_cell--profile">
    <div class="avatar_container">
      <div class="avatar"
           [ngClass]="{'avatar_empty': !bill.client_avatar}"
           [style.backgroundImage]="bill.client_avatar && 'url(' + bill.client_avatar + ')'">

      </div>
    </div>
    <span class="name">
      {{bill.client_name}}
    </span>
  </div>
  <div class="table_row_cell">
    <span>
       {{bill.created_at | date: 'dd/MM/yyyy'}}
    </span>
  </div>
  <div class="table_row_cell">
    <span>
       {{bill.type_of_session}}
    </span>
  </div>
  <div class="table_row_cell">
    <span>
       {{'billingHistory.' + bill.training_status | translate}}
    </span>
  </div>
  <div class="table_row_cell">
    <span *ngIf="bill.price">
      {{bill.price}} NOK
    </span>
    <span *ngIf="!bill.price">
      -
    </span>
  </div>
  <div class="table_row_cell">
    <span>
      {{bill.trainer_profit}} NOK
    </span>
  </div>
</div>
