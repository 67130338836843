<article class="page_card" >
  <div class="body page_content pricing-page-content">
    <div class="title-wrapper">
      <!-- <div class="step-holder" [class.active]="isFirstStepCompleted">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80474 0.202772C8.06509 0.473135 8.06509 0.91148 7.80474 1.18184L3.36029 5.79723C3.09994 6.06759 2.67783 6.06759 2.41748 5.79723L0.195262 3.48954C-0.0650874 3.21917 -0.0650874 2.78083 0.195262 2.51046C0.455612 2.2401 0.877722 2.2401 1.13807 2.51046L2.88889 4.32862L6.86193 0.202772C7.12228 -0.0675908 7.54439 -0.0675908 7.80474 0.202772Z" fill="#F32052"/>
        </svg>
      </div> -->
      <div class="title">
        {{"pricing.mainTitle" | translate }}
      </div>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99992 0.333496C3.31992 0.333496 0.333252 3.32016 0.333252 7.00016C0.333252 10.6802 3.31992 13.6668 6.99992 13.6668C10.6799 13.6668 13.6666 10.6802 13.6666 7.00016C13.6666 3.32016 10.6799 0.333496 6.99992 0.333496ZM7.66658 10.3335H6.33325V6.3335H7.66658V10.3335ZM7.66658 5.00016H6.33325V3.66683H7.66658V5.00016Z" fill="#F11C48"/>
      </svg>
    </div>

    <!-- price-form-setting -->
    <div class="price-setting">
      <form
      [formGroup]="formGroupSettings"
      class="price-setting__form"
      >

        <div formGroupName="time25">
          <div formGroupName="priceGroup">
            <app-material-input
              class="form-input form-input--order-1"
              formControlName="price"
              [label]="'pricing.ptDurationMin' | translate:{duration: 25}"
              [type]="'tel'"
              [autocomplete]="'off'"
              [readonly]="canEditProfile"
              [description]="'pricing.priceFromTo' | translate:{from: getDurationMinPrice(25), to: getDurationMaxPrice(25)}"
              [errors]="{max: 'profile.messages.maxNumber' | translate:{number: getDurationMaxPrice(25)},
                        min: 'profile.messages.minNumber' | translate:{number: getDurationMinPrice(25)},
                        pattern: 'common.errors.numbersOnly' | translate}">
            </app-material-input>
          </div>
        </div>

        <div formGroupName="time55">
          <div formGroupName="priceGroup">
            <app-material-input
              class="form-input form-input--order-2"
              formControlName="price"
              [label]="'pricing.ptDurationMin' | translate:{duration: 55}"
              [type]="'tel'"
              [autocomplete]="'off'"
              [readonly]="canEditProfile"
              [description]="'pricing.priceFromTo' | translate:{from: getDurationMinPrice(55), to: getDurationMaxPrice(55)}"
              [errors]="{max: 'profile.messages.maxNumber' | translate:{number: getDurationMaxPrice(55)},
                        min: 'profile.messages.minNumber' | translate:{number: getDurationMinPrice(55)},
                        pattern: 'common.errors.numbersOnly' | translate}">
            </app-material-input>
          </div>
        </div>
      </form>

    </div>
    <!-- price-form-setting -->

    <div class="title-wrapper">
      <!-- <div class="step-holder" [class.active]="isSecondStepCompleted">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80474 0.202772C8.06509 0.473135 8.06509 0.91148 7.80474 1.18184L3.36029 5.79723C3.09994 6.06759 2.67783 6.06759 2.41748 5.79723L0.195262 3.48954C-0.0650874 3.21917 -0.0650874 2.78083 0.195262 2.51046C0.455612 2.2401 0.877722 2.2401 1.13807 2.51046L2.88889 4.32862L6.86193 0.202772C7.12228 -0.0675908 7.54439 -0.0675908 7.80474 0.202772Z" fill="#F32052"/>
        </svg>
      </div> -->
      <div class="title">
        {{"pricing.title" | translate }}
      </div>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99992 0.333496C3.31992 0.333496 0.333252 3.32016 0.333252 7.00016C0.333252 10.6802 3.31992 13.6668 6.99992 13.6668C10.6799 13.6668 13.6666 10.6802 13.6666 7.00016C13.6666 3.32016 10.6799 0.333496 6.99992 0.333496ZM7.66658 10.3335H6.33325V6.3335H7.66658V10.3335ZM7.66658 5.00016H6.33325V3.66683H7.66658V5.00016Z" fill="#F11C48"/>
      </svg>
    </div>

    <div class="types-setting">
      <div class="types-setting__coll">
        <div class="types-setting__item" *ngFor="let item of trainingsControl25.controls;">
          <app-material-checkbox
            class="form-checkbox"
            [readonly]="canEditProfile"
            [checked]="item.get('checked').value"
            [formControl]="item.get('checked')">
          </app-material-checkbox>
          <app-material-input
            class="form-input"
            [formControl]="item.get('price')"
            [label]="getTrainingFieldDescription(item.controls)"
            [readonly]="'true'"
            [type]="'number'">
          </app-material-input>
        </div>
      </div>

      <div class="types-setting__coll">
        <div class="types-setting__item" *ngFor="let item of trainingsControl55.controls;">
          <app-material-checkbox
            class="form-checkbox"
            [readonly]="canEditProfile"
            [checked]="item.get('checked').value"
            [formControl]="item.get('checked')">
          </app-material-checkbox>
          <app-material-input
            class="form-input"
            [formControl]="item.get('price')"
            [label]="getTrainingFieldDescription(item.controls)"
            [readonly]="'true'"
            [type]="'number'">
          </app-material-input>
        </div>
      </div>
    </div>

  </div>
</article>
