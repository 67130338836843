import { Component, OnInit } from '@angular/core';
import { MobileService } from '@services/mobile.service';

@Component({
  selector: 'app-events-and-groups',
  templateUrl: './events-and-groups.component.html',
  styleUrls: ['./events-and-groups.component.scss']
})
export class EventsAndGroupsComponent implements OnInit {

  constructor(
    private mobileService: MobileService,
  ) { }

  ngOnInit() {
  }

  get isDesktop(): boolean {
    return this.mobileService.isDesktop;
  }

}
