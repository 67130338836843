import {Component, OnInit} from '@angular/core';

import {ProfileService} from '@services/profile.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {

  constructor(
    private profileService: ProfileService,
  ) { }

  get isTrainer() {
    return this.profileService.isProfile('TRAINER_TYPE');
  }

  get isPartner() {
    return this.profileService.isProfile('PARTNER_TYPE');
  }

  ngOnInit() {
  }

}
