import {PrototypeEntity} from '@models/prototype.entity';
import {ProfileEntity} from '@models/user/profile.entity';
import {UtvSubscription} from '@models/utv/utv-subscription';

export class UserEntity extends PrototypeEntity {
  billing_address: string = null;
  can_change_password: boolean = null;
  city: any = null;
  city_id: number = null;
  country_id: number = null;
  country: any = null;
  email: string = null;
  im_user: string = null;
  im_token: string = null;
  facebook_id: string = null;
  finished_registration: boolean = null;
  first_name: string = null;
  google_id: string = null;
  id: number = null;
  is_admin: boolean = false;
  is_confirmed: boolean = false;
  last_name: string = '';
  login_count: number = null;
  postcode: any = null;
  postcode_id: number = null;
  profile_picture: string = null;
  telephone: string = null;
  selected: boolean = null;
  created_at: string = null;
  updated_at: string = null;
  profiles: ProfileEntity[] = [];
  subscription: UtvSubscription = null;
  familiar_with_utv: boolean = false;

  constructor(data?: Partial<UserEntity>) {
    super();
    this.setData(data);
  }

  static checkTrainerProfileType(user) {
    const trainerProfileArr = user.profiles.filter((profile) => profile.type === 'trainer');
    return !!trainerProfileArr.length;
  }
  static getTrainerProfileType(user) {
    const trainerProfileArr = user.profiles.filter((profile) => profile.type === 'trainer' );
    return trainerProfileArr[0];
  }

}
