import {Component, OnDestroy, OnInit} from '@angular/core';
import {from, Subject, Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ACCOUNT_TYPES_PARTNER, ACCOUNT_TYPES_TRAINER, LANGUAGE,} from '../../../utils/enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

import {ProfileService} from '../../../services/profile.service';

import {UserEntity} from '@models/user/user.entity';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss'],
})
export class PersonalComponent implements OnInit, OnDestroy {
  personal;
  accountTypes;
  languages: any[];
  private formControls: any[];

  public canEditProfile: boolean;
  public trainer: ProfileEntity;
  public user: UserEntity;
  private subs: Subscription;
  private formSub: Subscription;
  private destroy$ = new Subject();

  get isTrainer() {
    return this.profileService.isProfile('TRAINER_TYPE');
  }

  get isPartner() {
    return this.profileService.isProfile('PARTNER_TYPE');
  }

  get isBusiness(): boolean {
    return this.trainer.account_type === ACCOUNT_TYPES_PARTNER.BUSINESS;
  }

  get isFoundation(): boolean {
    return this.trainer.account_type === ACCOUNT_TYPES_PARTNER.FOUNDATION;
  }

  constructor(
    private profileService: ProfileService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.canEditProfile = true;

    if (this.isTrainer) {
      this.accountTypes = Object.values(ACCOUNT_TYPES_TRAINER).map((value) => {
        return {
          value,
          title: value,
        };
      });
    } else {
      this.accountTypes = Object.values(ACCOUNT_TYPES_PARTNER).map((value) => {
        const translateKey = `profile.personal.${value}Type`;
        const hasTranslate = this.hasTranslation(translateKey);

        let valueName = value;

        if (hasTranslate) {
          valueName = this.translate.instant(translateKey);
        }

        return {
          value,
          title: valueName,
        };
      });
    }

    this.languages = Object.values(LANGUAGE).map((value) => {
      return {
        value,
        title: value,
      };
    });

    this.subs = new Subscription();
    this.formSub = new Subscription();
  }

  hasTranslation(key: string): boolean {
    return this.translate.instant(key) !== key;
  }

  ngOnInit() {
    this.profileService.getLanguages();
    this.personal = new UntypedFormGroup({
      account_type: new UntypedFormControl('', [
        Validators.required,
      ]),
      languages: new UntypedFormControl('', [
        Validators.required,
      ]),
      billing_address: new UntypedFormControl({
        value: '',
        disabled: true,
      }),
      trainer_level: new UntypedFormControl({
        value: '',
        disabled: true,
      }),
      first_name: new UntypedFormControl('', [
        Validators.required, Validators.maxLength(50),
      ]),
      last_name: new UntypedFormControl('', [
        Validators.required, Validators.maxLength(50),
      ]),
      email: new UntypedFormControl({
        value: '',
        disabled: true,
      }),
      telephone: new UntypedFormControl('', [
        Validators.required, Validators.pattern(/^((\+)|(00))[0-9]{8,14}$/),
      ]),
      // org_name: new FormControl('', [
      //   Validators.required, Validators.maxLength(50),
      // ]),
      // org_no: new FormControl('', [
      //   Validators.required, Validators.maxLength(50),
      // ]),
    });

    this.formControls = [
      {
        formName:'first_name',
        apiName:'patchUser',
      },{
        formName:'last_name',
        apiName:'patchUser',
      },{
        formName:'telephone',
        apiName:'patchUser',
      },{
      //   formName:'org_name',
      //   apiName:'patchProfile'
      // },{
      //   formName:'org_no',
      //   apiName:'patchProfile'
      // },{
        formName:'account_type',
        apiName:'patchProfile',
      },{
        formName:'billing_address',
        apiName:'patchProfile',
      },{
        formName:'languages',
        apiName:'patchLanguages',
      },
    ];

    this.subs.add(
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      }),
    );
    this.subs.add(
      this.profileService.trainer.subscribe(trainer => {
        this.trainer = trainer;
        this.profileInit();
      }),
    );
    this.subs.add(
      this.profileService.languages.subscribe(langs => {
        this.languages = langs;
      }),
    );
    this.subs.add(
      this.profileService.user.subscribe(user => {
        this.user = user;
        this.userInit();
      }),
    );
    this.subs.add(
      this.profileService.cancelEditProfileEvent.subscribe(() => {
        this.formSub.unsubscribe();
        this.userInit();
        this.profileInit();
        this.subscribeToFormChanges();
      }),
    );
    this.subs.add(
      this.profileService.saveProfileEvent.subscribe(() => {
        this.savePersonalFormeValues();
      }),
    );

    this.subscribeToFormChanges();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.formSub.unsubscribe();
    this.destroy$.complete();
  }

  userInit() {
    this.personal.patchValue({
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email,
      telephone: this.user.telephone,
      languages: this.trainer.languages,
    });
  }

  profileInit() {
    this.personal.patchValue({
      account_type: this.trainer.account_type,
      trainer_level: this.trainer.trainer_level && this.trainer.trainer_level.level,
      billing_address: this.trainer.billing_address,
      // org_name: this.trainer.org_name,
      // org_no: this.trainer.org_no,
    });

    if (this.trainer.under_consideration) {
      this.personal.disable();
    } else {
      this.personal.get('account_type').enable();
      // this.personal.get('org_name').enable();
      // this.personal.get('org_no').enable();
      this.personal.get('first_name').enable();
      this.personal.get('last_name').enable();
      this.personal.get('telephone').enable();
      this.personal.get('languages').enable();
    }
  }

  removeItem(index: number, type) {
    if (this.personal.controls[type]) {
      this.personal.controls[type].value.splice(index, 1);
      this.personal.controls[type].setValue(this.personal.controls[type].value);
    }
  }

  subscribeToFormChanges(): void {
    this.formSub = this.personal.valueChanges
      .pipe(
        filter(() => {
          let isValid = this.personal.valid;
          if (!isValid) {
            this.profileService.changeFormInvalidStatus(true);
          }
          return isValid;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((result: FormData) => {
        this.profileService.changeFormInvalidStatus(false);
      });
  }

  savePersonalFormeValues() {
    from(this.formControls).pipe(
      map((item) => {
        if (!this.profileService[item.apiName]) {
          throw new Error(`Method ${item.apiName} is not defined in ProfileService`);
        }
        if (!this.personal.get(item.formName)) {
          throw new Error(`Field ${item.formName} is not defined in FormGroup`);
        }
        return item;
      }),
      filter((item) => {
        return this.personal.get(item.formName).valid;
      }),
      distinctUntilChanged(),
    ).subscribe(item => {
      const value = this.personal.get(item.formName).value;
      if (item.formName === 'languages') {
        this.profileService[item.apiName](value).subscribe();
      }
      else {
        const object = {};
        object[item.formName] = value;
        this.profileService[item.apiName](object).subscribe();
      }
    }, error => {
      this.snackbar.open(
        error.message,
        '',
        {
          panelClass: 'error',
        });
      throw error;
    });
  }

}
