import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatOption} from '@angular/material/core';
import {Subscription} from 'rxjs';
import {findIndex} from 'lodash';
import * as momentjs from 'moment';
import {Moment} from 'moment';
import {extendMoment} from 'moment-range';
import {CalendarService} from '@services/calendar.service';
import {ProfileService} from '@services/profile.service';
import {SESSION} from '@util/enum';
import {TimePickerComponent} from './time-picker/time-picker.component';

const moment = extendMoment(momentjs);

interface PatchUserScheduleInterface {
  profile_id: number;
  time_ranges: any[];
}

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss'],
})
export class ScheduleModalComponent implements OnInit, OnDestroy {
  @Input() public selectedTimeRange;
  @Output() onclose = new EventEmitter<boolean>();
  @ViewChild('allSelected', { static: false }) private allSelected: MatOption;

  timeRangeCollection: Array<Date[]>;
  currentRange: string[];
  daysAvailable: any[];
  daySet: UntypedFormGroup;
  timeRange: UntypedFormGroup;
  public schedule;
  public trainings;
  public bookings;


  public selectedTimeRangeIndex: number;

  private SESSION: typeof SESSION = SESSION;
  private subs: Subscription[];
  private trainer;

  constructor(
    public translate: TranslateService,
    private calendarService: CalendarService,
    private profileService: ProfileService,
    private matDialog: MatDialog,
    private snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.timeRangeCollection = [];
    // 28 days or 4 weeks
    this.daysAvailable = new Array(28).fill(null).map((day, i) => {
      const date = moment().utc().startOf('day').add('day', i);
      return {
        day: date.format('dddd, DD MMMM'),
        moment: date,
        id: i,
      };
    });

    this.subs = [
      this.calendarService.schedule.subscribe(schedule => {
        this.schedule = schedule;
      }),
      this.profileService.trainer.subscribe(trainer => {
        this.trainer = trainer;
      }),
      this.calendarService.currentRange.subscribe(range => {
        this.currentRange = range;
      }),
      this.calendarService.calendar.subscribe(bookings => {
        this.bookings = bookings;
      }),
    ];

    if (this.selectedTimeRange && Object.keys(this.selectedTimeRange).length > 0) {
      this.editInit();
    } else {
      this.formsInit();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  /**
   * Create/update time range(s)
   */
  save() {
    if (this.selectedTimeRange) {
      this.schedule.splice(this.selectedTimeRangeIndex, 1);
    }
    const intervals = [];
    this.daySet.value.days.forEach(date => {
      const start = this.timeRange.value.startAt.split(':');
      const finish = this.timeRange.value.finishAt.split(':');

      const range = moment().range(
        date.moment.clone().local().hours(start[0]).minutes(start[1]),
        date.moment.clone().local().hours(finish[0]).minutes(finish[1]),
      );
      intervals.push(range);
    });
    this.create(intervals);
  }

  /**
   * Remove selected time range
   */
  remove() {
    this.schedule.splice(this.selectedTimeRangeIndex, 1);
    this.calendarService.createSchedule({
      profile_id: this.trainer.id,
      time_ranges: this.schedule.map(schedule => {
        return [
          schedule.time_range.start.clone().utc().format('YYYY-MM-DD HH:mm:ssZ'),
          schedule.time_range.end.clone().utc().format('YYYY-MM-DD HH:mm:ssZ'),
        ];
      }),
    }).subscribe(() => {
      this.snackbar.open(this.translate.instant('profile.messages.scheduleUpdated'), null, {
        duration: 4000,
        panelClass: 'success',
      });
      this.calendarService.activity('schedule').close();
    });
  }

  /**
   * Removes selected day from selection
   * @param index index of the day for removal
   */
  removeDay(index: number) {
    const arr = [...this.daySet.value.days];
    arr.splice(index, 1);
    this.daySet.setValue({
      days: arr,
    });
  }

  /**
   * Create interval(s)
   * @param intervals
   */
  create(intervals: Moment[]) {
    this.calendarService.createSchedule(this.mergeIntervals(intervals))
      .subscribe(() => {
        this.snackbar.open(this.translate.instant('profile.messages.scheduleUpdated'), null, {
          duration: 4000,
          panelClass: 'success',
        });
        this.calendarService.activity('schedule').close();
      });
  }

  /**
   * Open time picker modal
   * @param field
   */
  openTimePicker(field: string): void {
    if (!this.timeRange.get(field)) {
      console.warn(`Field ${field} is unsupported`);
      return;
    }
    this.matDialog.closeAll();
    this.matDialog.open(TimePickerComponent, {
      data: {
        form: this.timeRange.get(field),
      },
      width: '288px',
    });
  }

  private editInit(): void {
    this.selectedTimeRangeIndex = findIndex(this.schedule, (el: any) => this.selectedTimeRange.id === el.id);
    this.daySet = new UntypedFormGroup({
      days: new UntypedFormControl([{
        moment: this.selectedTimeRange.time_range.start,
        id: 1,
      }], [Validators.required]),
    });
    this.timeRange = new UntypedFormGroup({
      startAt: new UntypedFormControl(this.selectedTimeRange.time_range.start.format('HH:mm'), [
        Validators.required,
        this.validateRange,
      ]),
      finishAt: new UntypedFormControl(this.selectedTimeRange.time_range.end.format('HH:mm'), [
        Validators.required,
        this.validateRange,
      ]),
    });
  }

  private formsInit(): void {
    this.daySet = new UntypedFormGroup({
      days: new UntypedFormControl('', [Validators.required]),
    });
    this.timeRange = new UntypedFormGroup({
      startAt: new UntypedFormControl('', [
        Validators.required,
        this.validateRange,
      ]),
      finishAt: new UntypedFormControl('', [
        Validators.required,
        this.validateRange,
      ]),
    });
  }

  /**
   * Merges all intervals
   */
  private mergeIntervals(intervals: any[]): PatchUserScheduleInterface {
    const scheduleMapped = this.schedule.map(schedule => schedule.time_range);
    const spread = [...intervals, ...scheduleMapped].sort((a, b) => {
      return a.start.clone().utc() - b.start.clone().utc();
    });
    let newSchedule = [];
    spread.reduce((accumulator, current, index) => {
      if (index === 0) {
        return current;
      }
      const merge = accumulator.add(current);

      if (index === spread.length - 1) {
        if (merge) {
          newSchedule.push(accumulator);
        } else {
          newSchedule.push(current);
        }

      }

      if (merge) {
        return merge;
      } else {
        newSchedule.push(accumulator);
        return current;
      }

    }, spread[0]);

    if (spread.length <= 1) {
      newSchedule = [...spread];
    }

    return {
      profile_id: this.trainer.id,
      time_ranges: newSchedule.map(schedule => [
        schedule.start.clone().utc().format('YYYY-MM-DD HH:mm:ssZ'),
        schedule.end.clone().utc().format('YYYY-MM-DD HH:mm:ssZ'),
      ]),
    };
  }

  /**
   * Custom validator for a reactive form, which checks if start of range is bigger than end
   */
  private validateRange = () => {
    const control = this.timeRange;
    if (!control) {
      return null;
    }
    const start = control.get('startAt').value.split(':');
    const finish = control.get('finishAt').value.split(':');

    if (start.length < 2 || finish.length < 2) {
      return null;
    }

    if (start[0] > finish[0] || (start[0] >= finish[0] && start[1] > finish[1])) {
      return {range: true};
    }

    return null;
  };
}
