import {
  AfterViewInit,
  Component,
  ElementRef,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-material-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaterialInputComponent,
    multi: true,
  }],
})
export class MaterialInputComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() autofocus: boolean;
  @Input() formControlName: string;
  @Input() formGroupName: string;
  @Input() type = 'text';
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() hint: string;
  @Input() dummy: boolean;
  @Input() autocomplete = 'on';
  @Input() description;
  @Input() formControl: UntypedFormControl;
  @Input() readonly;
  @Input() maxlength;
  @Input() isShowCounter: boolean = true;
  @Input() touchNeeded = true;
  @Input() placeholder: string = '';
  @Input() formError: string = '';
  @Input() set errors(errors) {
    this.errorsObj = {...errors};
    if (errors && this.control) {
      this.setError();
    }
  }

  @ViewChild('inputEl', { static: true }) inputEl: ElementRef<HTMLInputElement>;
  @ViewChild('labelEl', { static: true }) labelEl;

  defaultId = Math.random();

  public errorsObj: any;
  public focused: boolean;
  public onChange: any;
  public onTouch: any;
  public control: AbstractControl;
  public error: string;
  public isPassword = false;

  get counter(): string {
    return this.control.value ? this.control.value.length : '0';
  }

  get isInvalid(): any {
    if (this.touchNeeded) {
      return (this.control.dirty || this.control.touched) && this.control.invalid;
    } else {
      return this.control.invalid;
    }
  }

  get isTouched(): boolean {
    return this.control.dirty || this.control.touched;
  }

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
  ) { }

  writeValue(obj: any): void {
    // this.control.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    if (this.formControl) {
      this.control = this.formControl;
    } else {
      if (this.controlContainer) {
        if (this.formControlName && !this.formGroupName) {
          this.control = this.controlContainer.control.get(this.formControlName);
        }
      } else {
        console.warn('Can\'t find parent FormGroup directive');
      }
    }

    this.control.valueChanges.subscribe(() => {
      this.setError();
    });

    this.isPassword = this.type === 'password';

    this.setError();
  }

  ngAfterViewInit() {
    if (this.autofocus && !this.readonly) {
      setTimeout(() => {
        this.inputEl.nativeElement.focus();
      }, 0);
    }
  }

  focus(): void {
    if (!this.readonly) {
      this.focused = true;
    }
  }

  blur(): void {
    this.focused = false;
    this.setError();
  }

  public keydown(event): boolean {
    if (event.code === 'Enter') {
      event.preventDefault();
    }

    if (this.dummy) {
      event.preventDefault();
      return false;
    }
  }

  private setError() {
    if (!this.control.errors) {
      this.error = '';
    } else if (this.errorsObj) {
      this.error = this.errorsObj[Object.keys(this.control.errors)[0]];
    }
  }

}
