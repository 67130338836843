import {Directive, ElementRef, Input, OnInit} from '@angular/core';

import {CertificatesService} from '../services/certificates.service';

@Directive({
  selector: '[appPdfPreview]',
})
export class PdfPreviewDirective implements OnInit {

  @Input('appPdfPreview')
  set image(appPdfPreview) {

    const data = {
      image: appPdfPreview,
    };

    const isPdf = this.certificatesService.checkFormat(data, 'pdf');

    if (isPdf) {
      this.renderMedia(data);
    } else {
      this.el.nativeElement.innerHTML = '';
    }
  }

  constructor(
    private certificatesService: CertificatesService,
    private el: ElementRef,
  ) { }

  ngOnInit() {
  }

  renderMedia(data) {
    this.certificatesService.setPdfView(data, this.el.nativeElement, 1);
  }

}
