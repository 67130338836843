import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ScrollLockService} from './scroll-lock.service';

@Injectable({
  providedIn: 'root',
})
export class StepperMobileService {

  private _windowOpen = new BehaviorSubject<boolean>(false);

  get isWindowOpen() {
    return this._windowOpen.asObservable();
  }

  constructor(
    private scrollLockService: ScrollLockService,
  ) { }

  /**
   * Display mobile stepper
   */
  open() {
    this._windowOpen.next(true);
    this.scrollLockService.disableScroll();
  }

  /**
   * Hide monile stepper
   */
  close() {
    this._windowOpen.next(false);
    this.scrollLockService.enableScroll();
  }
}
