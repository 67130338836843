<div class="step"
     *ngFor="let step of stepperService.steps; index as i"
     [ngClass]="{
       'active': stepperService.currentStep === i,
       'finished': stepperService.currentStep > i
     }">
  <div class="circle"
       (click)="toggleHint($event)"
       [ngClass]="{'clickable': !hintIsShown && stepperService.currentStep === i}">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" stroke="#606873"/>
      <path d="M9.99998 14.7799L7.21998 11.9999L6.27332 12.9399L9.99998 16.6666L18 8.66656L17.06 7.72656L9.99998 14.7799Z" fill="#606873"/>
    </svg>
  </div>

  <div class="text"
       (click)="toggleHint($event)"
       [ngClass]="{'clickable': !hintIsShown && stepperService.currentStep === i}">
    <span class="text_title">
      {{'stepsTips.header' + (i + 1) | translate}}
    </span>
    <span class="text_body">
      {{'stepsTips.clarification' + getLangStringIndex(i + 1) | translate}}
    </span>
  </div>

  <!-- <div class="hint"
       [routerLink]="stepperService.currentLink.link"
       [queryParams]="{tab: stepperService.currentLink.query}"
       [fragment]="stepperService.currentLink.anchor"
       [@dropDown]
       *ngIf="stepperService.currentStep === i && stepperService.currentHint && hintIsShown">
    <div class="hint_content">
      <span class="hint_title">
        {{stepperService.currentHeader}}
      </span>
      <div class="hint_body" [innerHTML]="stepperService.currentHint | safe:'html'"></div>
    </div>
    <i class="close" (click)="toggleHint($event)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M18.186 16.5115L13.6744 12L18.186 7.48835C18.3915 7.28283 18.5581 6.55811 18 5.99998C17.4418 5.44184 16.7171 5.60843 16.5116 5.81393L12 10.3256L7.48835 5.81397C7.28288 5.60845 6.55814 5.44186 6.00002 5.99998C5.44191 6.55809 5.60847 7.28286 5.81397 7.48835L10.3256 12L5.81395 16.5116C5.60848 16.7171 5.44184 17.4418 6 18C6.55816 18.5582 7.2829 18.3915 7.48838 18.1861L12 13.6744L16.5116 18.186C16.7171 18.3915 17.4419 18.5581 18 18C18.5582 17.4418 18.3915 16.717 18.186 16.5115Z"></path>
      </svg>
    </i>
  </div> -->
</div>
