import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {FeathersService} from './feathers.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';

@Injectable()
export class HttpService {
  spinner = new Subject();

  constructor(
    private http: HttpClient,
    private feathers: FeathersService,
    private translate: TranslateService,
  ) {
  }

  showSpinner(show: boolean) {
    this.spinner.next(show);
  }

  patchData(action: string, data: any) {
    const url = environment.API_URL + action;
    return this.http.patch(url, data);
  }

  putData(action: string, data: any) {
    const url = environment.API_URL + action;
    return this.http.put(url, data);
  }

  postData(action: string, data: any, options?: any): any {
    const url = environment.API_URL + action;
    return this.http.post(url, data, options);
  }

  deleteData(action: string) {
    const url = environment.API_URL + action;
    return this.http.delete(url);
  }

  getData(action: any, params: any = {}): any {
    const url = environment.API_URL + action;
    this.showSpinner(true);
    if (this.feathers.isAutentificated()) {
      return this.http.get(url, {
        headers: new HttpHeaders().set('Accept-Language', this.translate.currentLang),
        params,
      }).pipe(
        map(response => {
          this.showSpinner(false);
          return response;
        }),
      );
    } else {
      return this.http.get(url);
    }
  }

  getBlob(action: any): any {
    const url = environment.API_URL + action;
    if (this.feathers.isAutentificated()) {
      return this.http.get(url, {
        headers: new HttpHeaders().set('Accept-Language', this.translate.currentLang),
        responseType: 'blob',
      });
    }
  }

}
