import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Router} from '@angular/router';
import { EMAIL_REG_EXP, PASSWORD_REG_EXP } from '@shared/utils';
import {detect} from 'detect-browser';
import { Subject } from 'rxjs';
import {CompanyService} from '@services/company.service';
import { takeUntil } from 'rxjs/operators';
import {CompanySignupEntity} from '@models/company/company-signup.entity';
import {DataService} from '@services/data.service';
import {TranslateService} from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '@components/modals/confirmation/confirmation.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: [
    './sign-up.component.scss',
    '../login/login.component.scss',
  ],
})
export class SignUpComponent implements OnInit, OnDestroy {
  user: any;
  messages: string[];
  wrongCredentials: boolean;
  showRestore: boolean;
  currentStep: number = 1;

  public form: UntypedFormGroup;

  isDisabledNextStep(currentStep: number): boolean {
    if (currentStep === 1) {
      return this.form.get('one').invalid || !this.form.get('one').get('confirmPolicy').value;
    }

    return currentStep === 2 && this.form.get('two').invalid;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public router: Router,
    private companyService: CompanyService,
    private data: DataService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.wrongCredentials = false;
    this.showRestore = false;
    this.user = {
      email: '',
      password: '',
    };
    this.messages = [];
    this.form = new UntypedFormGroup({
      one: new UntypedFormGroup({
        email: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(EMAIL_REG_EXP),
        ]),
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(PASSWORD_REG_EXP),
        ]),
        repeatPassword: new UntypedFormControl('', [Validators.required]),
        confirmPolicy: new UntypedFormControl(''),
      },
      {
        validators: this.confirmPasswordValidator(),
      }),
      two: new UntypedFormGroup({
        name: new UntypedFormControl('', [
          Validators.required,
        ]),
        country_id: new UntypedFormControl('', [
          Validators.required,
        ]),
        address: new UntypedFormControl('', [
          Validators.required,
        ]),
        description: new UntypedFormControl(''),
      }),
      three: new UntypedFormGroup({
        logo: new UntypedFormControl(),
      }),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openConfirmDialog(): void {
    const dialog = this.dialog.open(ConfirmationComponent, {
      data: {
        actionTxt: this.translate.instant('profile.confirmation.button1'),
        bodyTxt: this.translate.instant('profile.confirmation.leaveRegistrationText'),
        headerTxt: this.translate.instant('profile.confirmation.leaveRegistration'),
      },
    });

    dialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirm: boolean) => {
        if(confirm) {
          this.router.navigate(['company-sign-in']);
        }
      });
  }

  openStep(step: number): void {
    if (step - this.currentStep === 1) {
      !this.isDisabledNextStep(this.currentStep) ? this.changeStep(1) : null;
    }

    if (step - this.currentStep === 2) {
      !this.isDisabledNextStep(this.currentStep + 1) ? this.changeStep(2) : null;
    }

    if (step < this.currentStep) {
      this.changeStep(step - this.currentStep);
    }
  }

  // TODO: refactor to a directive
  hasSupport(imageType: string): boolean {
    const browser = detect();
    switch (browser && browser.name) {
      case 'chrome':
      case 'firefox':
      case 'edge':
        return imageType === 'webp';
      case 'safari':
        return imageType === 'jpf';
    }
  }

  changeStep(count = 1) {
    if (this.currentStep + count <= 3 && this.currentStep + count > 0) {
      this.currentStep += count;
    }
  }

  submitForm(): void {
    const logo = this.form.value.three.logo;
    if (logo) {
      this.uploadLogo(this.form.value.three.logo);
    } else {
      this.signUp();
    }
  }

  private signUp(logoUrl?: string): void {
    const data = new CompanySignupEntity({
      ...this.form.value.one,
      ...this.form.value.two,
    });
    if (logoUrl) {
      data.logo = logoUrl;
    }

    this.companyService.signupCompany(data.prepareBody(true))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: {success: boolean}) => {
        if (res.success) {
          this.data.showNotification(
            this.translate.instant('signUp.weSendConfirmationEmail'),
            null,
            3000,
            'success',
          );
          this.router.navigate(['company-sign-in']);
        }
      }, (error) => {
        if (error.error) {
          this.data.showNotification(
            error.error.message,
            null,
            3000,
            'error',
          );
        }
      });
  }

  private uploadLogo(logo): void {
    const formData = new FormData();
    formData.append('logo', logo);

    this.companyService.uploadLogo(formData)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((value: {url: string; key: string}) => {
        this.signUp(value.url);
      }, (error) => {
        if (error.error) {
          this.data.showNotification(
            error.error.message,
            null,
            3000,
            'error',
          );
        }
      });
  }

  private confirmPasswordValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      if (!this.form) {
        return null;
      }

      const { password, repeatPassword } = form.value;

      return password !== repeatPassword ? { noMatch: true } : null;
    }
  }
}
