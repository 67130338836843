import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

import {PROFILE_TYPES} from '../../../utils/enum';
import {ProfileService} from '../../../services/profile.service';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-profile-type-modal',
  templateUrl: './profile-type-modal.component.html',
  styleUrls: ['./profile-type-modal.component.scss'],
})
export class ProfileTypeModalComponent implements OnInit, OnDestroy {
  public types: string[];
  public profile: ProfileEntity;
  public currentType: string;

  private subs: Subscription;

  constructor(public profileService: ProfileService, private dialog: MatDialog) {
    this.types = Object.values(PROFILE_TYPES);
  }

  ngOnInit() {
    this.subs = new Subscription();

    this.subs.add(
      this.profileService.trainer.subscribe(profile => {
        this.profile = profile;
        this.currentType = profile.type;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  select(type: string): void {
    this.currentType = type;
  }

  selectProfileType(): void {
    if (this.currentType === this.profile.type) {
      this.dialog.closeAll();
    } else {
      this.profileService.switchProfile(this.currentType);
    }
  }
}
