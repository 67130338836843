import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {catchError, debounceTime, filter, map, takeUntil, tap, throttleTime,} from 'rxjs/operators';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';

import {Duration} from '@models/duration';
import {TrainingType} from '@models/training-type';
import {PriceListItem} from '@models/price-list-item';

import {TIMES_TYPE} from '@util/enum';

import {DataService} from '@services/data.service';
import {ProfileService} from '@services/profile.service';
import {ErrorService} from '@services/error.service';
import {ProfileEntity} from '@models/user/profile.entity';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})

export class PricingComponent implements OnInit, OnDestroy {

  destroy$ = new Subject();
  trainer: ProfileEntity;
  formGroupSettings: UntypedFormGroup;
  combinedTrainings = new Map();
  baseTraining: TrainingType;

  public canEditProfile: boolean;
  private subs: Subscription;

  get priceGroup25() {
    return <UntypedFormArray>this.getTimeFormGroup(TIMES_TYPE.TIME_25).get('priceGroup');
  }

  get priceGroup55() {
    return <UntypedFormArray>this.getTimeFormGroup(TIMES_TYPE.TIME_55).get('priceGroup');
  }

  get priceControl25() {
    return this.priceGroup25.get('price');
  }

  get priceControl55() {
    return this.priceGroup55.get('price');
  }

  get trainingsControl25() {
    return <UntypedFormArray>this.getTimeFormGroup(TIMES_TYPE.TIME_25).get('trainings');
  }

  get trainingsControl55() {
    return <UntypedFormArray>this.getTimeFormGroup(TIMES_TYPE.TIME_55).get('trainings');
  }

  /**
   * Return true if prices fields are not empty and valid
   */
  get isFirstStepCompleted() {
    return (this.priceControl25.valid && this.priceControl25.value) || (this.priceControl55.valid && this.priceControl55.value);
  }

  /**
   * Return true if least one control was checked
   */
  get isSecondStepCompleted() {
    const time25 = this.trainingsControl25.controls.some((control: any) => {
      return control.get('checked').value;
    });

    const time55 = this.trainingsControl55.controls.some((control: any) => {
      return control.get('checked').value;
    });

    return time55 || time25;
  }

  constructor(
    private dataService: DataService,
    private profileService: ProfileService,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) {
    this.canEditProfile = true;
    this.subs = new Subscription();
  }

  ngOnInit() {
    this.subs.add(
      this.profileService.editProfileEvent.subscribe(event => {
        this.canEditProfile = event;
      }),
    );
    this.initSettingsForm();
    this.getTrainer();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.destroy$.complete();
  }

  /**
   * Get form
   * @param timeMin
   */
  getTimeFormGroup(timeMin: TIMES_TYPE) {
    return this.formGroupSettings.get(`time${timeMin}`);
  }

  /**
   * Get description for training fields
   * @param controls
   */
  getTrainingFieldDescription(controls) {
    const { name, participantsMin, participantsMax, duration } = controls;
    const minStr = this.translateService.instant('pricing.min');

    if (participantsMin === participantsMax) {
      return `${name.value} (${participantsMin.value}-${participantsMax.value}) ${duration.value}${minStr}`;
    } else {
      return `${name.value} (${participantsMin.value}) ${duration.value}${minStr}`;
    }
  }

  getDurationMinPrice(minType: TIMES_TYPE) {
    return this.combinedTrainings.get(minType) ? this.combinedTrainings.get(minType).price[0] : null;
  }

  getDurationMaxPrice(minType: TIMES_TYPE) {
    return this.combinedTrainings.get(minType) ? this.combinedTrainings.get(minType).price[1] : null;
  }

  /**
   * Init form for price settings
   */
  initSettingsForm() {
    this.formGroupSettings = new UntypedFormGroup({
      time25: new UntypedFormGroup({
        priceGroup: new UntypedFormGroup({
          price: new UntypedFormControl(null),
          duration_id: new UntypedFormControl(null),
        }),
        trainings: new UntypedFormArray([]),
      }),
      time55: new UntypedFormGroup({
        priceGroup: new UntypedFormGroup({
          price: new UntypedFormControl(null),
          duration_id: new UntypedFormControl(null),
        }),
        trainings: new UntypedFormArray([]),
      }),
    });
  }

  /**
   * Listen changes price settings
   */
  listenFormSettings() {
    // check when init
    if (!this.priceControl25.value) {
      this.trainingsControl25.disable();
    }

    if (!this.priceControl55.value) {
      this.trainingsControl55.disable();
    }

    // listen price changes
    this.priceGroup25.valueChanges
      .pipe(
        tap(() => {
          if (this.priceGroup25.invalid || !this.priceControl25.value) {
            this.trainingsControl25.disable();
          } else {
            this.trainingsControl25.enable();
          }
          if (!this.priceControl25.value) {
            this.resetFormData(TIMES_TYPE.TIME_25);
          }
        }),
        filter(() => {
          return this.priceGroup25.valid;
        }),
        debounceTime(500),
        takeUntil(this.destroy$),
      )
      .subscribe((result: any) => {
        this.recalculateTrainingsLists(TIMES_TYPE.TIME_25);
      });

    // listen price changes
    this.priceGroup55.valueChanges
      .pipe(
        tap(() => {
          if (this.priceGroup55.invalid || !this.priceControl55.value) {
            this.trainingsControl55.disable();
          } else {
            this.trainingsControl55.enable();
          }

          if (!this.priceControl55.value) {
            this.resetFormData(TIMES_TYPE.TIME_55);
          }
        }),
        filter(() => {
          return this.priceGroup55.valid;
        }),
        debounceTime(500),
        takeUntil(this.destroy$),
      )
      .subscribe((result: any) => {
        this.recalculateTrainingsLists(TIMES_TYPE.TIME_55);
      });

    this.profileService.saveProfileEvent.pipe(
      filter(() => this.formGroupSettings.valid),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.updatePricing(this.formGroupSettings.getRawValue());
    });
  }

  /**
   * Get trainer data
   */
  getTrainer() {
    this.profileService.trainer
      .pipe(
        filter((trainer: ProfileEntity) => {
          return !!trainer.id;
        }),
        throttleTime(100),
        catchError((error) => {
          this.errorService.catchHttpError(error, {
            message: this.translateService.instant('notification.coudntGetTrainerData'),
          });

          return error;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((trainer: ProfileEntity) => {
        this.trainer = trainer;
        this.getData(trainer);
      });
  }

  /**
   * Get common data
   */
  getData(trainer: ProfileEntity) {
    forkJoin(
      this.dataService.getTrainingDurationsData(trainer.trainer_level.id),
      this.dataService.getAllTrainingTypesData(),
      this.dataService.getTrainerPriceListData(trainer.id),
    )
      .pipe(
        map((request: any) => {
          return request.map((item) => {
            return item.data;
          });
        }),
        catchError((error) => {
          this.errorService.catchHttpError(error, {
            message: this.translateService.instant('notification.coudntGetTrainingData'),
          });

          return error;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(([durations, trainingTypes, prices]) => {
        this.combineTrainerData(durations, trainingTypes, prices);
      });
  }

  /**
   * Combine data to common object for better use
   * @param durations
   * @param trainingTypes
   * @param prices
   */
  combineTrainerData(durations: Duration[], trainingTypes: TrainingType[], prices: PriceListItem[]) {
    // sort by participants count
    trainingTypes.sort((a, b) => {
      return a.participants[0] - b.participants[0];
    });

    // add trainingTypes
    this.baseTraining = trainingTypes.find((type) => type.is_base_training);
    const otherTrainings = trainingTypes.filter((type) => !type.is_base_training);

    durations.forEach((combinedTraining) => {
      const actualPrice = prices.find((item) => combinedTraining.id === item.duration_id);

      this.combinedTrainings.set(combinedTraining.duration, {
        ...combinedTraining,
        actualPrice,
        otherTrainings,
      });
    });

    this.appendPriceSettings();
  }

  /**
   * Append values and validators to price fields and related trainings
   */
  appendPriceSettings() {
    this.combinedTrainings.forEach((combinedTraining, timeMin) => {
      const { actualPrice, id } = combinedTraining;
      const price = actualPrice ? parseFloat(actualPrice.price).toFixed() : null;

      // set validators for price field
      this.getTimeFormGroup(timeMin).get('priceGroup').get('price').setValidators([
        Validators.pattern(/^\d+$/),
        Validators.min(combinedTraining.price[0]),
        Validators.max(combinedTraining.price[1]),
      ]);

      // set values for price field
      this.getTimeFormGroup(timeMin).get('priceGroup').patchValue({
        price,
        duration_id: id,
      }, {
        emitEvent: false,
      });

      this.appendTrainingFields(combinedTraining, timeMin, price);
    });

    this.listenFormSettings();
  }

  /**
   * calculate and set values for trainings fields
   * @param combinedTraining
   * @param timeMin
   * @param price
   */
  appendTrainingFields(combinedTraining, timeMin, price) {
    const { duration, otherTrainings } = combinedTraining;
    const training_types = combinedTraining.actualPrice ? combinedTraining.actualPrice.training_types : [];

    otherTrainings.forEach((item: TrainingType) => {
      const { client_percent, type, participants, id } = item;

      const calculatedPrice = this.calculateTrainingPrice(price, client_percent);
      const trainingsArray = <UntypedFormArray>this.getTimeFormGroup(timeMin).get('trainings');
      const checked = training_types.includes(id);

      trainingsArray.push(new UntypedFormGroup({
        id: new UntypedFormControl(id),
        price: new UntypedFormControl(calculatedPrice),
        checked: new UntypedFormControl(checked),
        percent: new UntypedFormControl(client_percent),
        name: new UntypedFormControl(type),
        duration: new UntypedFormControl(duration),
        participantsMin: new UntypedFormControl(participants[0]),
        participantsMax: new UntypedFormControl(participants[1]),
      }));
    });
  }

  /**
   * When price was changed, we need recalculate price for all related trainings
   * @param timeMin
   */
  recalculateTrainingsLists(timeMin: TIMES_TYPE) {
    const price = this.getTimeFormGroup(timeMin).get('priceGroup').get('price').value;
    const trainingsArray = <UntypedFormArray>this.getTimeFormGroup(timeMin).get('trainings');

    trainingsArray.controls.forEach((item: any) => {
      const percent = item.get('percent').value;

      item.patchValue({
        price: this.calculateTrainingPrice(price, percent),
      }, {
        emitEvent: false,
      });
    });
  }

  /**
   * Calculate training price
   * @param startPrice
   * @param percent
   */
  calculateTrainingPrice(startPrice, percent) {
    return ((startPrice / 100) * percent).toFixed(2);
  }

  /**
   * When form was filled correctly, we need save data
   * @param formData
   */
  updatePricing(formData: FormData) {
    const trainingIdDefault = this.baseTraining.id;
    const preparedData = [];

    Object.values(formData).forEach((time) => {
      if (time.priceGroup.price) {
        const { duration_id, price } = time.priceGroup;

        // return id only checked trainings
        const training_types = time.trainings
          .filter((training: any) => {
            return training.checked;
          })
          .map((training) => {
            return training.id;
          });

        // add default training
        training_types.push(trainingIdDefault);

        const result = {
          duration_id,
          training_types,
          price: Number(price),
        };

        preparedData.push(result);
      }
    });

    this.dataService.patchPriceList(this.trainer.id, preparedData);
  }

  /**
   * If price field was set empty, we need reset all related checkboxes
   * @param timeMin
   */
  resetFormData(timeMin: TIMES_TYPE) {
    const trainingsArray = <UntypedFormArray>this.getTimeFormGroup(timeMin).get('trainings');

    trainingsArray.controls.forEach((item: any) => {
      item.get('checked').reset();
    });
  }

}
