import { Component, NgZone, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {detect} from 'detect-browser';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {DataService} from '@services/data.service';
import {StorageService} from '@services/storage.service';
import {ProfileService} from '@services/profile.service';
import {FeathersService} from '@services/feathers.service';
import { EMAIL_REG_EXP, PASSWORD_REG_EXP } from '@shared/utils';
import {AuthService as Auth} from '@services/auth.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { from } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserEntity } from '@models/user/user.entity';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  user: any;
  messages: string[];
  wrongCredentials: boolean;
  showRestore: boolean;
  isLogining: boolean;

  public loginForm: UntypedFormGroup;
  public restoreForm: UntypedFormGroup;

  constructor(
    private feathers: FeathersService,
    public router: Router,
    private zone: NgZone,
    private data: DataService,
    private translate: TranslateService,
    private storageService: StorageService,
    private profileService: ProfileService,
    private authService: Auth,
    private socialAuthService: SocialAuthService,
  ) {
  }

  ngOnInit() {
    this.wrongCredentials = false;
    this.showRestore = false;
    this.user = {
      email: '',
      password: '',
    };
    this.messages = [];
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_REG_EXP),
      ]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_REG_EXP),
      ]),
    });
    this.restoreForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_REG_EXP),
      ]),
    });

    this.subscribeToAuthenticationState();
  }

  goFeathers(provider: 'google' | 'facebook', token: string): void {
    from(this.feathers.authenticate({ strategy: provider, identity_token: token }))
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next: (res) => {
          if (res.user.finished_registration === false) {
            this.storageService.storage.removeItem('feathers-jwt');
            this.data.showNotification(this.translate.instant('login.socialFail'), null, 3500, 'error');
            return;
          }

          this.storageService.storage.setItem('jwt', res.accessToken);
          this.storageService.storage.setItem('userId', res.user.id);
          this.data.setUser(res.user);
          this.redirectAfterLogin();
          this.profileService.setUser(res.user);
        },
        error: (error) => {
          this.storageService.storage.removeItem('feathers-jwt');
          this.data.showNotification(error.message, null, 3500, 'error');
        },
      });
  }

  login() {
    if (this.isLogining) {
      return;
    }

    const { email, password } = this.loginForm.value;
    const loginData = {
      strategy: 'local',
      email,
      password,
    };

    this.isLogining = true;

    this.authService.logoutWithoutReload();

    from(this.feathers.authenticate(loginData))
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next: (res: { accessToken: string; user: UserEntity }) => {
          this.storageService.storage.setItem('jwt', res.accessToken);
          this.storageService.storage.setItem('userId', res.user.id);

          this.data.showNotification(
            this.translate.instant('login.loginSuccess'),
            null,
            2000,
            'success',
          );
          this.data.setUser(res.user);

          this.redirectAfterLogin();
          this.profileService.setUser(res.user);
        },
        error: (err) => {
          this.isLogining = false;
          this.translate.instant('login.loginError');
          console.error(err);
          this.data.showNotification(err.message, null, 3000, 'error');
        },
      });
  }

  // TODO: refactor to a directive
  hasSupport(imageType: string): boolean {
    const browser = detect();
    switch (browser && browser.name) {
      case 'chrome':
      case 'firefox':
      case 'edge':
        return imageType === 'webp';
      case 'safari':
        return imageType === 'jpf';
    }
  }

  navigateToRestorePassword(): void {
    localStorage.setItem('restorePasswordType', 'user');

    this.router.navigate(['/restore-pass']);
  }

  private redirectAfterLogin(): void {
    const redirectAfterLoginUrl = this.storageService.storage.getItem('redirectAfterLoginUrl');

    if (redirectAfterLoginUrl) {
      this.router.navigateByUrl(redirectAfterLoginUrl).then(() => {
        this.storageService.storage.removeItem('redirectAfterLoginUrl');
      });

      return;
    }

    this.router.navigate(['/utv']);
  }

  private subscribeToAuthenticationState(): void {
    this.socialAuthService.authState
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((socialUser) => {
        if (socialUser.provider === 'GOOGLE') {
          this.goFeathers('google', socialUser.idToken);
        }
      });
  }
}
