import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-material-textarea',
  templateUrl: './material-textarea.component.html',
  styleUrls: ['./material-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MaterialTextareaComponent,
      multi: true,
    },
  ],
})
export class MaterialTextareaComponent implements OnInit, OnDestroy {
  @Input() autofocus: any;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() description;
  @Input() formControl: UntypedFormControl;
  @Input() readonly;
  @Input() placeholder: string = '';
  @Input() maxlength;
  @Input() isShowCounter: boolean = true;
  @Input() rows = 1;
  @Input() allowSpaces = false;
  @Input() touchNeeded = true;
  @Input() fluidHeight = false;
  @Input() hint: string;
  @Input() set errors(errors) {
    this.errorsObj = { ...errors };
    if (errors && this.control) {
      this.setError();
    }
  }

  @Output() mouseenter = new EventEmitter<void>();

  public errorsObj: any;
  public error: string;
  control: UntypedFormControl;
  focused: boolean;
  destroy$ = new Subject<void>();

  get counter(): string {
    return this.control.value ? this.control.value.length : '0';
  }

  get controlValue(): string {
    return this.control.value;
  }

  get isInvalid(): boolean {
    if (this.touchNeeded) {
      return (
        (this.control.dirty || this.control.touched) && this.control.invalid
      );
    } else {
      return this.control.invalid;
    }
  }

  get isTouched(): boolean {
    return this.control.dirty || this.control.touched;
  }

  constructor() {}

  ngOnInit() {
    this.formInit();
    this.listenFormControl();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  formInit() {
    if (this.formControl) {
      this.control = this.formControl;
    }
  }

  emitEnterEvent(): void {
    this.mouseenter.emit();
  }

  /**
   * Listen form control
   */
  listenFormControl() {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.allowSpaces) {
        this.whiteSpaceValidator(this.control);
        this.setError();
      }
    });
  }

  /**
   * User shouldn't have possibility to start from whitespase
   * @param control
   */
  whiteSpaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;

    if (isWhitespace) {
      this.control.patchValue(this.controlValue.trim(), {
        emitEvent: false,
      });
    }
  }

  focus(): void {
    if (!this.readonly) {
      this.focused = true;
    }
  }

  blur(): void {
    this.focused = false;
    this.setError();
  }

  private setError() {
    if (!this.control.errors) {
      this.error = '';
    } else if (this.errorsObj) {
      this.error = this.errorsObj[Object.keys(this.control.errors)[0]];
    }
  }
}
